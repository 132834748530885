import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createProductCategory, getProductCategoryDetail, updateProductCategoryDetail } from '../../Redux/Actions/productCategoryAction';

class Edit_Product_Category extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categoryId: "",
            categoryName: "",
            parentId: "0",
            categoryDescription: "",
            isEdit: false,
            successMessage: '',
            errorMessage: '',
            errorData: {},
            showLoader: false,
            isActive: "1"
        }

        if (this.props.match.path === "/edit-product-category/:categoryId") {
            // this.props.match.params.couponId;
            this.fetchCategoryDetail(this.props.match.params.categoryId);
        }

    }

    fetchCategoryDetail = async (categoryId) => {
        let categoryDetail = await this.props.getProductCategoryDetail(categoryId);
        console.log("**RES**",categoryDetail)
        if (categoryDetail ?.data ?.data ?.length > 0) {
            // product category found
            categoryDetail = categoryDetail.data.data[0];

            this.setState({
                categoryId: categoryDetail.id,
                categoryName: categoryDetail.name,
                // parentId: categoryDetail.parentId,
                categoryDescription: categoryDetail.description,
                isActive:categoryDetail.isActive,
                isEdit: true,
            })
        }
        else {
            // category not found
            // redirect user to product category page
            this.props.history.push("/product-category");
        }
    }

    handleChange = (e, key) => {
        if (this.state.errorData && this.state.errorData[key] && this.state.errorData[key][0]) {
            this.state.errorData[key][0] = null;
        }

        let { value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            [key]: value
        }))
    }


    validateCategoryInfo = () => {
        let isFormValid = true;
        this.state.errorData = {
            categoryName: [],
            parentId: [],
            categoryDescription: []
        }

        if (this.state.categoryName.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.categoryName = ["Category Name field cannot be empty."]
        }

        if (this.state.categoryDescription.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.categoryDescription = ["Category description field cannot be empty."]
        }

        if (this.state.parentId.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.parentId = ["Category Parent Id field cannot be empty."]
        }

        let tempState = this.state;
        this.setState({
            ...tempState
        })

        return isFormValid;

    }


    handleSubmit = async () => {
        await this.setState({
            successMessage: '',
            errorMessage: '',
            showLoader: true
        });

        if (this.validateCategoryInfo()) {
            // category validations passed
            let res;
            const { categoryId, categoryName, parentId, categoryDescription,isActive } = this.state;

            if (this.state.isEdit) {
                // call update action
                res = await this.props.updateProductCategoryDetail(
                    { categoryName, parentId, categoryDescription,isActive },
                    categoryId
                )
                
            }
            else {
                // call create action
                res = await this.props.createProductCategory(
                    { categoryName, parentId, categoryDescription,isActive }
                )
            }
            
            if (res && res.data) {
                if (res.data.isSuccess) {
                    // success case
                    this.setState({
                        successMessage: res.data.message,
                        showLoader: false
                    })

                    window.scrollTo(0, 0);

                    if (this.state.isEdit) {
                        setTimeout(() => {
                            this.setState({
                                successMessage: ''
                            })
                        }, 4000);
                    }
                    else {
                        this.setState({
                            categoryId: "",
                            categoryName: "",
                            parentId: "0",
                            categoryDescription: "",
                        })

                        setTimeout(() => {
                            this.setState({
                                successMessage: ''
                            })
                        }, 4000);
                    }

                }
                else {
                    // response with failed case
                    this.setState({
                        errorMessage: res.data.message,
                        showLoader: false
                    })
                }
            }
            else {
                // error to get respone
                this.setState({
                    errorMessage: "Something went wrong. Please try again later.",
                    showLoader: false
                })
            }
        }
        else {
            // discount validations failed
            this.setState({
                showLoader: false
            });
        }

    }

    render() {
        return (
            <div class="admin-card">
                <div class="admin-card-head">
                    <h2>{this.props.match.params.categoryId ? "Edit" : "Create"} Product Category</h2>
                </div>
                <div class="admin-card-box">
                    {(() => {
                        if (this.state.successMessage !== '' && this.state.successMessage !== undefined) {
                            return <div className="alert alert-success">{this.state.successMessage}</div>
                        } else if (this.state.errorMessage !== '' && this.state.errorMessage !== undefined) {
                            return <div className="alert alert-danger">{this.state.errorMessage}</div>
                        }
                    })()}
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>category name
                                    <sup>*</sup>
                                </label>
                                <input value={this.state.categoryName} onChange={(e) => this.handleChange(e, "categoryName")} type="text" class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData ?.categoryName &&
                                        this.state.errorData.categoryName[0]}
                                </span>
                            </div>
                        </div>
                        
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Description
                                    <sup>*</sup>
                                </label>
                                <input value={this.state.categoryDescription} onChange={(e) => this.handleChange(e, "categoryDescription")} type="text" class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData ?.categoryDescription &&
                                        this.state.errorData.categoryDescription[0]}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <label>Is Active?</label>
                                <div className="custom-radio-input">
                                    <p className="container-sub mr-2">
                                        Inactive
                                        <span>
                                            <input
                                                type="radio"
                                                value="0"
                                                onChange={(event) =>
                                                    this.handleChange(event, "isActive")
                                                }
                                                checked={this.state.isActive === 0 || this.state.isActive === "0"}
                                                name="isActiveRadio"
                                            />
                                        </span>
                                        <span class="checkmark"></span>
                                    </p>

                                    <p className="container-sub mr-2">
                                        Active
                                        <span>
                                            <input
                                                type="radio"
                                                value="1"
                                                onChange={(event) =>
                                                    this.handleChange(event, "isActive")
                                                }
                                                checked={this.state.isActive === 1 || this.state.isActive === "1"} 
                                                name="isActiveRadio"
                                            />
                                        </span>
                                        <span class="checkmark"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-btn text-center">
                                <button type="button" onClick={this.handleSubmit} class="orange-outline-btn">submit
                                {(() => {
                                        if (this.state ?.showLoader) {
                                            return <span class="spinner-border spinner-border-sm ml-1"></span>
                                        }
                                    })()}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ createProductCategory, getProductCategoryDetail, updateProductCategoryDetail }, dispatch)
}

export default connect(null, mapDispatchToProps)(Edit_Product_Category)