import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getShipment = (orderId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/shipping/shipment/${orderId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("orders list response from backend", res);
        return res.data;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
};

export const purchaseLabel = (payload) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/shipping/transaction`,
            data: { ...payload },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("orders list response from backend", res);
        return res.data;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
};

