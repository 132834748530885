export function checkPermission(route, permissionObj, type) {
    if(Object.keys(permissionObj).length === 0) {
        return 0;
    }
    if(route.includes('dashboard')) {
        return permissionObj.dashboard[type];
    }
    if(route.includes('order')) {
        return permissionObj.orders_list[type];
    }
    if(route.includes('user')) {
        return permissionObj.users_list[type];
    }
    if(route.includes('payment')) {
        return permissionObj.payments_list[type];
    }
    if(route.includes('coupon')) {
        return permissionObj.coupons_list[type];
    }
    if(route.includes('product-category')) {
        return permissionObj.category_list[type];
    }
    if(route.includes('product')) {
        return permissionObj.product_list[type];
    }
    if(route.includes('report-management')) {
        return permissionObj.report_management[type];
    }
    if(route.includes('subscription')) {
        return permissionObj.subscription_management[type];
    }
    if(route.includes('email-template')) {
        return permissionObj.email_templates[type];
    }
    if(route.includes('activities')) {
        return permissionObj.activities[type];
    }
    if(route.includes('covid-19-test')) {
        return permissionObj.covid_19_test[type];
    }
    if(route.includes('notifications')) {
        return permissionObj.notifications[type];
    }
    if(route.includes('page')) {
        return permissionObj.pages[type];
    }
    if(route.includes('setting')) {
        return permissionObj.settings[type];
    }

}

export function getPathName(permissionObj) {
    const key = Object.keys(permissionObj).find((keyName) => {
        if(permissionObj[keyName].R) {
            return keyName;
        }
    });
    switch (key) {
        case 'dashboard': {
            return '/dashboard';
        }
        case 'orders_list': {
            return '/orders';
        }
        case 'users_list': {
            return '/users';
        }
        case 'payments_list': {
            return '/payments';
        }
        case 'coupons_list': {
            return '/coupon';
        }
        case 'category_list': {
            return '/product-category';
        }
        case 'product_list': {
            return '/product';
        }
        case 'report_management': {
            return '/report-management';
        }
        case 'subscription_management': {
            return '/subscriptions';
        }
        case 'email_templates': {
            return '/email-templates';
        }
        case 'activities': {
            return '/activities';
        }
        case 'pages': {
            return '/pages';
        }
        case 'settings': {
            return '/settings';
        }
        case 'covid_19_test': {
            return '/covid-19-testing';
        }
        case 'notifications': {
            return '/notifications';
        }
    }
}

const cssPagedMedia = (function () {
    var style = document.createElement("style");
    document.head.appendChild(style);
    return function (rule) {
        style.innerHTML = rule;
    };
})();

cssPagedMedia.size = function (size) {
    cssPagedMedia("@page {size: " + size + "}");
};

const labelPrint = {
    init: () => {
        cssPagedMedia.size("1in 2.85in");
    }
};

const labelPrintNew = {
    init: () => {
        cssPagedMedia.size("1in 2.85in");
    }
};

export {
    labelPrint,
    labelPrintNew,
    cssPagedMedia
};
