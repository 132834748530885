import React  from "react";
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import './settings.scss';

import {debounce} from "lodash";
import {
    searchPanel,
    getPanelList,
    getAllPathogens,
    updatePanel,
} from "../../Redux/Actions/settingAction";
import InfiniteScroll from "react-infinite-scroll-component";
import {Button, Modal} from "react-bootstrap";
import CreatableSelect from 'react-select/creatable';

class PanelSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allPanels:[],
            loadPanels: false,
            offset: 0,
            prevOffset: 0,
            maxLimit: 20,
            hasMore: true,
            searchKeyword: "",
            showEditModal: "",
            editPanel: {},
            allPathogens: [],
            errorMessage: '',
            savePanelLoading: false,
            errorData: {
                pathogens: [],
            }
        };
    }
    async componentDidMount() {
        this.fetchMoreData(true);
        this.fetchAllPathogens();
    }

    async fetchAllPathogens() {
        const allPathogens = await this.props.getAllPathogens();
        const finalPathogens = (allPathogens? allPathogens.data: []).map((pathogen) => {
            return {
                label: pathogen.name,
                value: pathogen.name
            }
        });
        this.setState({
            allPathogens: finalPathogens
        });
    }

    fetchMoreData = async (firstLoad) => {
        this.setState({
            loadPanels: true
        });

        if (this.state.offset !== this.state.prevOffset || firstLoad) {
            if (this.state.hasMore && this.state.maxLimit && (this.state.maxLimit === "all" || this.state.maxLimit > this.state.offset)) {
                let result;
                if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
                    result = await this.props.searchPanel(this.state.offset, this.state.searchKeyword.trim());
                }
                else {
                    result = await this.props.getPanelList(this.state.offset);
                }

                if (result ?.data ?.data ?.length > 0) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            prevOffset: prevState.offset,
                            offset: prevState.offset + 20,
                            allPanels: prevState.allPanels.concat(result.data.data),
                            loadPanels: false
                        }
                    })
                }
                else {
                    this.setState({ hasMore: false, loadPanels: false });
                }
            } else {
                this.setState({
                    loadPanels: false
                })
            }
        } else {
            this.setState({
                loadPanels: false
            })
        }

    };

    handleChange = async (event, field) => {
        event.preventDefault();
        if (field === "maxLimit") {
            await this.setState({
                [field]: event.target.value === "all" ? event.target.value : +event.target.value
            })
            this.fetchMoreData(false);
        }
        else {
            await this.setState({
                [field]: event.target.value,
                offset: 0,
                prevOffset: 0,
                hasMore: true
            });

            this.debounceSearchCall();
        }
    };

    debounceSearchCall = debounce(() => {
        this.setState({
            allPanels: [],
        });
        this.fetchMoreData(true);
    }, 1000);

    onShowEditModal = (panelObj) => {
        this.setState({
            showEditModal: true,
            editPanel: {
                ...panelObj,
                pathogens: !panelObj.pathogens? []: JSON.parse(panelObj.pathogens).map((pathogen) => {
                    return {
                        label: pathogen,
                        value: pathogen
                    }
                })
            },
        });
    };

    handleCloseEditModal = () => {
        this.setState({
            showEditModal: false,
            editPanel: {}
        });
    };

    validatePanelForm = (formData) => {
        let isFormValid = true;
        const error = {
            pathogens: [],
        };

        if (formData.editPanel.pathogens?.length === 0) {
            isFormValid = false;
            error.pathogens = ["Pathogens cannot be empty!"];
        }

        this.setState({
            ...this.state,
            errorData: error
        });
        return isFormValid;
    };

    handleSavePanel = async () => {
        const formValid = this.validatePanelForm(this.state);
        if(!formValid) {
            return;
        }

        this.setState({
            savePanelLoading: true
        });

        const body = {
            pathogens: this.state.editPanel.pathogens,
        };
        const response = await this.props.updatePanel(this.state.editPanel.id, body);

        if (response && response.data) {
            if (response.data.isSuccess) {
                this.setState({
                    editPanel: {},
                    showEditModal: false,
                    savePanelLoading: false,
                    offset: 0,
                    prevOffset: 0,
                    hasMore: true,
                    errorMessage: '',
                });

                this.fetchAllPathogens();
                this.debounceSearchCall();
            }
            else {
                // response with failed case
                this.setState({
                    errorMessage: response.data.errorMessage,
                    savePanelLoading: false
                })
            }
        }
        else {
            this.setState({
                errorMessage: "Something went wrong. Please try again later.",
                savePanelLoading: false
            })
        }
    };

    handlePathogenChange = (newValue) => {
        if(newValue && newValue.length !== 0) {
            if (this.state.errorData && this.state.errorData['pathogens'] && this.state.errorData['pathogens'][0]) {
                this.state.errorData['pathogens'][0] = null;
            }
        }
        this.setState(prevState => ({
            ...prevState,
            editPanel: {
                ...this.state.editPanel,
                pathogens: newValue
            }
        }));

    };

    render() {
        const {
            allPanels,
            loadPanels,
            showEditModal,
        } = this.state;

        const panelsList = allPanels?.map((panelObj, index) => {
            return (
                <tr key={`${panelObj.id}-${index}`}>
                    <td>{panelObj.name}</td>
                    <td>{panelObj.pathogens? JSON.parse(panelObj.pathogens).join(', '): ''}</td>
                    <td>
                        <div className="vendor-table-btn">
                            <button
                                type="button"
                                className="orange-outline-btn"
                                onClick={() => this.onShowEditModal(panelObj)}
                            >
                                Edit
                            </button>
                        </div>
                    </td>
                </tr>
            );
        });
        return (
            <div className="admin-card">
                <div className="admin-card-box">
                    <div className="vendor-table">
                        <div className="vendor-table-head">
                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <div className="entries-drop">
                                        <label>show</label>
                                        <select className="entries-controls"
                                                onChange={(e) => this.handleChange(e, "maxLimit")}
                                                value={this.state.maxLimit}>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="all">All</option>
                                        </select>
                                        <p>Entries</p>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8">
                                    <div className="vendor-table-head-right">
                                        <div className="vendor-table-search">
                                            <input className="form-control"
                                                   onChange={(e) => this.handleChange(e, "searchKeyword")}
                                                   value={this.state.searchKeyword} type="text" placeholder="search"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="vendor-table-body">
                            <div className="table-responsive">
                                <InfiniteScroll
                                    dataLength={this.state.allPanels.length}
                                    next={() => this.fetchMoreData(false)}
                                    hasMore={this.state.hasMore}
                                >
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th>Panel</th>
                                            <th>Pathogens</th>
                                            <th style={{'width': '15%'}}>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody> {panelsList} </tbody>
                                    </table>
                                </InfiniteScroll>
                                {loadPanels ? (
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (null)}

                                <Modal show={showEditModal} onHide={this.handleCloseEditModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit Panel</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {(() => {
                                            if (this.state.errorMessage) {
                                                return <div className="alert alert-danger">{this.state.errorMessage}</div>
                                            }
                                        })()}
                                        <form>
                                            <div className="form-group col-md-12">
                                                <label>Pathogens*</label>
                                                <CreatableSelect
                                                    isMulti
                                                    onChange={this.handlePathogenChange}
                                                    options={this.state.allPathogens}
                                                    value={this.state.editPanel.pathogens}
                                                />
                                                <span style={{color: "red", fontSize: "12px"}}
                                                      className="form-field-error">
                                                    {this.state.errorData?.category &&
                                                    this.state.errorData.category[0]}
                                                </span>
                                            </div>
                                            <div className="form-btn text-center">
                                                <button type="button" onClick={(e) => this.handleSavePanel(e)}
                                                        className="orange-outline-btn">submit
                                                    {(() => {
                                                        if (this.state?.savePanelLoading) {
                                                            return <span
                                                                className="spinner-border spinner-border-sm ml-1"></span>
                                                        }
                                                    })()}
                                                </button>
                                            </div>
                                        </form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={this.handleCloseEditModal}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        ...state
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        searchPanel,
        getPanelList,
        getAllPathogens,
        updatePanel,
    }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PanelSettings));
