import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import '../../Styles/track-orders.scss';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrderTracking } from '../../Redux/Actions/orderAction';


const Order_Track = (props) => {
    let dispatch = useDispatch();
    // const orderList = useSelector((state) => state.orderReducer.orderList);
    const orderTrackStatusRecord = useSelector((state) => state.cartReducer.getAllTracks)?.map((i) => i.elements[0].text)

    useEffect(() => {
        (async () => {
            await dispatch(getOrderTracking(props?.match?.params?.trackId))

        })();
    }, []);


    let stage4 = orderTrackStatusRecord?.find((a) =>
        a.includes("delivered")
    );
    let stage3 = orderTrackStatusRecord?.find(
        (a) => a.includes("In Transit") || a.includes("Out for Delivery")
    );
    let stage2 = orderTrackStatusRecord?.find((a) =>
        a.includes("Accepted")
    );
    return (
        <div class="admin-card">
            <div class="admin-card-head">
                <h2>order history</h2>
            </div>
            <div class="admin-card-box">

                <div class="track-order-box">
                    <ul>
                        <li class="active">
                            <div class="track-order-icon">
                                <i class="fas fa-shopping-basket"></i>
                            </div>
                            <p>order received</p>
                        </li>
                        <li className={stage2 ? 'active' : null}>
                            <div class="track-order-icon">
                                <i class="fas fa-box"></i>
                            </div>
                            <p>pick up</p>
                        </li>
                        <li className={stage3 ? 'active' : null}>
                            <div class="track-order-icon">
                                <i class="fas fa-truck"></i>
                            </div>
                            <p>in transit</p>
                        </li>
                        <li className={stage4 ? 'active' : null}>
                            <div class="track-order-icon">
                                <i class="fas fa-check-circle"></i>
                            </div>
                            <p>delivered</p>
                        </li>
                    </ul>
                </div>
                <div class="vendor-table">
                    <div class="vendor-table-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td colspan="2">tracking details:</td>
                                    </tr>
                                </thead>
                                <tbody>

                                    {/* {this.state.orderTrackingTemplate.length > 0 ? this.state.orderTrackingTemplate : 'Tracking details not found'} */}
                                    {
                                      orderTrackStatusRecord?.length>0?  orderTrackStatusRecord?.map((i) => {
                                            return (

                                                <li>{i}</li>
                                            )
                                        }):<div>No track details present</div>
                                    }


                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="vendor-table-bottom">
                        {/* <button onClick={() => this.fetchOrdertrackInfo(false)} type="button" class="orange-outline-btn">retrack
                    {(() => {
                            if (this.state?.showLoader) {
                                return <span class="spinner-border spinner-border-sm ml-1"></span>
                            }
                        })()}
                    </button> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Order_Track;
