import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    createTestResults,
    getPanel,
} from "../../Redux/Actions/activityAction";

import 'react-confirm-alert/src/react-confirm-alert.css';

class AddTestResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingData: false,
            saveLoading: false,
            errorMessage: '',
            testResult: {},
            panelPathogens: [],
            errorData: {}
        };

    }

    async componentDidMount() {
        const {selectedItem} = this.props;
        this.setState({
            loadingData: true,
        });
        const apiPromise = ([
            this.props.getPanel(selectedItem.productId)
        ]);
        const apiResponse = await Promise.all(apiPromise);
        const panel = apiResponse[0].data;
        const allPathogens = panel.pathogens? JSON.parse(panel.pathogens).sort((a, b) => a-b): [];

        this.setState({
            testResult: allPathogens.reduce((acc, pathogen) => {
                acc[pathogen] = 'Not Detected';
                return acc;
            }, {}),
            panelPathogens: allPathogens,
            loadingData: false,
        });
    }

    validateTestResult = (formData) => {
        let isFormValid = true;
        const error = {};

        this.state.panelPathogens.forEach((pathogen) => {
           if(!this.state.testResult[pathogen]) {
               error[pathogen] = 'This is required field!';
           }
        });

        this.setState({
            ...this.state,
            errorData: error
        });
        return isFormValid;
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const {selectedItem} = this.props;
        const formValid = this.validateTestResult(this.state);
        if(!formValid) {
            return;
        }

        this.setState({
            saveLoading: true
        });

        let body = {
            testResult: JSON.stringify(this.state.testResult),
            productId: selectedItem.productId,
            orderId: selectedItem.orderId,
            kitRegistrationId: selectedItem.id,
            accessionId: selectedItem.accessionId
        };
        let response;
        response = await this.props.createTestResults(body);

        if (response && response.data) {
            if (response.data.isSuccess) {
                this.setState({
                    saveLoading: false
                });
                this.props.handleCloseReportModal();
            }
            else {
                // response with failed case
                this.setState({
                    errorMessage: response.data.errorMessage,
                    saveLoading: false
                })
            }
        }
        else {
            this.setState({
                errorMessage: "Something went wrong. Please try again later.",
                saveLoading: false
            })
        }
    };

    onSelectTestResult = (e, panelPathogen) => {
        this.setState({
            testResult: {
                ...this.state.testResult,
                [panelPathogen]: e.target.value
            },
            errorData: {
                ...this.state.errorData,
                [panelPathogen]: null,
            }
        })
    };

    render() {
        const {
            panelPathogens,
            loadingData,
            testResult,
            errorData
        } = this.state;

        const panelPathogenList = panelPathogens.map((panelPathogen, index) => {
            return (
                <div className="col-lg-12 col-md-12 d-flex mb-2" key={index}>
                    <div className="col-md-7 pathogen">
                        <label
                            className={`mb-0 ${testResult[panelPathogen] === 'Detected' && 'detected'}` }
                        >{panelPathogen}
                        </label>
                        <span className="d-block form-field-error">
                            {errorData[panelPathogen]}
                        </span>
                    </div>
                    <div className="col-md-5">
                        <select
                            className="form-control"
                            value={testResult[panelPathogen]}
                            onChange={(e) => this.onSelectTestResult(e, panelPathogen)}
                        >
                            <option value={'Not Detected'}>Not Detected</option>
                            <option value={'Detected'}>Detected</option>
                        </select>
                    </div>

                </div>
            );
        });
        return (
            <div class="admin-card">
                <div class="admin-card-box">
                    {
                        this.state.errorMessage &&
                        <div className="alert alert-danger">{this.state.errorMessage}</div>
                    }

                    {loadingData ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (null)}

                    <div className="row mt-3">
                        {panelPathogenList}
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-btn text-center">
                            <button type="button" onClick={(e)=> this.handleSubmit(e)} class="orange-outline-btn">submit
                                {(() => {
                                    if (this.state ?.saveLoading) {
                                        return <span class="spinner-border spinner-border-sm ml-1"></span>
                                    }
                                })()}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        createTestResults,
        getPanel,
    }, dispatch);
};

export default connect(null, mapDispatchToProps)(AddTestResult)
