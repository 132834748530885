import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

function generatePassword() {
  var length = 8,
    charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}


export const getUsersList = (offset, limit = 10) => async (dispatch) => {
  // console.log("getOrdersList offset is", offset);
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/users?offset=${offset}&limit=${limit}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });
    // console.log("orders list response from backend", res);
    return res;
  }
  catch (error) {
    console.log('wrong', error);
    dispatch(handleError(error));
  }
}

export const searchUser = (offset, searchKeyword) => async (dispatch) => {
  // console.log("getOrdersList offset is", offset);
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/users/by/search?offset=${offset}&search=${searchKeyword}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });
    // console.log("orders list response from backend", res);
    return res;
  }
  catch (error) {
    console.log('wrong', error);
    dispatch(handleError(error));
  }
}

export const getUserDetail = (userId) => async (dispatch) => {
  // console.log(`update profile`, data)
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: 'get',
      url: `${URL}/api/v1/admin/users/${userId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    dispatch({ type: "LOADING_COMPLETED" });
    // console.log("user detail response from backend", res);
    return res;

  } catch (error) {
    dispatch(handleError(error));
  }
}

export const getUserAgreement = (userId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/org-user-agreement/${userId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;

  } catch (error) {
    dispatch(handleError(error));
  }
}

export const updateProfile = (data) => async (dispatch) => {
  console.log(`update profile`, data)
  try {
    dispatch({ type: "LOADING_STARTED" });
    let formData = new FormData()
    for (var key in data) {
      formData.append(key, data[key]);
    }
    // formData.append("selectedImg", data.selectedImg);
    // formData.append("lastname", data.lastname);

    const res = await axios({
      method: 'post',
      url: `${URL}/api/v1/admin/users/${data.id}/updateProfile`,
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });

    return res;
  } catch (error) {
    return error
  }
}

export const updateUserAgreement = (userId, agreementData) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "put",
      url: `${URL}/api/v1/admin/org-user-agreement/${userId}`,
      data: agreementData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });
    return res.data;
  } catch (error) {
    return error
  }
}

export const setUserInfo = (userInfo) => async (dispatch) => {

  dispatch({
    type: "SETUSERINFO",
    payload: userInfo
  });
}

export const subscribeNewsLetter = (email) => async (dispatch) => {
  // console.log("params in subscribeNewsLetter", email);
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/users/newsLetterSubscription`,
      data: { email },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });
    dispatch({ type: "LOADING_COMPLETED" });

    return res;
  }
  catch (error) {
    dispatch(handleError(error));
  }
}

export const changePassword = (email, oldPassword, newPassword, confirmNewPassword) => async (dispatch) => {
  // console.log(`change password params`, {email, oldPassword, newPassword, confirmNewPassword})
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: 'post',
      url: `${URL}/api/v1/admin/change-password`,
      data: { email, oldPassword, newPassword, confirmNewPassword },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    dispatch({ type: "LOADING_COMPLETED" });
    // console.log("change password response from backend", res);
    return res;

  } catch (error) {
    dispatch(handleError(error));
  }
}

export const getCountries = () => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/cart/get-countries-list`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });

    return res.data;
  }
  catch (error) {
    dispatch(handleError(error));
  }
};


export const getStates = (countryId) => async (dispatch) => {
  if(!countryId) {
    return;
  }
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/cart/get-states-list/${countryId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });

    return res.data;
  }
  catch (error) {
    dispatch(handleError(error));
  }
};

export const getCities = (stateId) => async (dispatch) => {
    if(!stateId) {
      return;
    }
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/cart/get-cities-list/${stateId}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });

      return res.data;
    }
    catch (error) {
      dispatch(handleError(error));
    }
};
