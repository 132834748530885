import { combineReducers } from "redux";
import { productReducer } from './productReducer';
import { settingReducer } from './settingReducer';
import { cartReducer } from './cartReducer';
import { commonReducer } from "./commonReducer";
import { authReducer } from "./authReducer";
import { userReducer } from "./userReducer";
import { teamReducer } from "./teamReducer";
import { companyReducer } from "./companyReducer";
import { dashboardReducer } from "./dashboardReducer";
import { approvalReducer } from "./approvalReducer";
import { templateReducer } from "./templateReducer";
import { historyReducer } from "./historyReducer";

const appReducer = combineReducers({
  productReducer,
  cartReducer,
  commonReducer,
  authReducer,
  userReducer,
  teamReducer,
  companyReducer,
  dashboardReducer,
  approvalReducer,
  templateReducer,
  historyReducer,
  settingReducer,
});

let rootReducer = null;
export default rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};
