import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getCovidAppointments,
    confirmCheckIn,
    getAppointmentDetail,
    generateTestReport,
    getCovidLocations,
} from "../../Redux/Actions/covidAction";
import { formatPhoneNumber } from "react-phone-number-input";
import CovidAssessment from "./covidAssessment";
import CovidVisitInfo from "./covidVisitInfo";

import './covid19.scss';
import moment from "moment";
import {Button, Modal} from "react-bootstrap";
import {debounce} from "lodash";
import ReactToPrint from "react-to-print";
import {labelPrintNew} from "../helper/helper";
import ConfirmationCodeLabel from "./confirmation-code-label";
import CovidLocations from "./covidLocations";

const Covid19Testing = (props) => {
    const dispatch = useDispatch();

    const [appointmentList, setAppointmentList] = useState([]);
    const [loading, setLoading] = useState({
        loadAppointments: false,
        loadCovidLocations: false,
        processCheckIn: false,
        processGenerateReport: false,
        fetchAppointmentDetail: {},
    });
    const [checkIn, setCheckIn] = useState(null);
    const [showConfirmCheckIn, setShowConfirmCheckIn] = useState(false);
    const [generateReport, setGenerateReport] = useState('');
    const [onGenerateReport, setOnGenerateReport] = useState(false);
    const [selectedAppointmentId, setSelectedAppointmentId] = useState(0);
    const [appointmentDetail, setAppointmentDetail] = useState({
        appointmentId: '',
        assessmentDetail: {},
        covidVisitInfo: {}
    });
    const [showAppointmentDetail, setShowAppointmentDetail] = useState(false);
    const [appointmentFromDateFilter, setAppointmentFromDateFilter] = useState(moment().format('YYYY-MM-DD'));
    const [appointmentToDateFilter, setAppointmentToDateFilter] = useState(moment().format('YYYY-MM-DD'));
    const [locationFilter, setLocationFilter] = useState('');
    const [allLocation, setAllLocation] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [testResult, setTestResult] = useState('Negative');
    const [userRole, setUserRole] = useState('Negative');
    const componentRef = {};

    const settings = useSelector((state) => state.settingReducer);

    useEffect(() => {
        (async () => {
            fetchAllLocations();
            await fetchAppointmentList(appointmentFromDateFilter, appointmentToDateFilter, searchText, locationFilter);
        })();
    }, []);

    useEffect(() => {
        setUserRole(settings.role);
    }, [settings.role]);

    const fetchAppointmentList = async (fromDate, toDate, searchText, locationId) => {
        setLoading((prev) => ({...prev, loadAppointments: true}));
        const appointmentResponse = await dispatch(getCovidAppointments(fromDate, toDate, searchText, locationId));
        setLoading((prev) => ({...prev, loadAppointments: false}));
        if(!appointmentResponse.isSuccess) {
            alert('Error fetching patient details. Please try again.');
            return;
        }
        setAppointmentList(appointmentResponse.data);
    };

    const fetchAllLocations = async () => {
        const locationResponse = await dispatch(getCovidLocations());
        if(!locationResponse.isSuccess) {
            return;
        }
        setAllLocation(locationResponse.data);
    };

    const getAppointmentDate = (appointment) => {
        return (
            <div className={'d-flex flex-column'}>
                <div>{moment(appointment.appointmentDate).format('ddd MMM DD/YY')}</div>
                <div style={{'font-size': '12px'}}>{appointment.appointmentStart}-{appointment.appointmentEnd}</div>
            </div>
        );
    };

    const clickCheckIn = (appointment) => {
        setCheckIn(appointment.id);
        setShowConfirmCheckIn(true);
    };

    const clickGenerateReport = (appointment) => {
        setGenerateReport(appointment.id);
        setOnGenerateReport(true);
    };

    const viewReport = (appointment) => {
        window.open(appointment.report_doc, "_blank");
    };

    const onChangeAppointmentFromDateFilter = async (fromDate) => {
        if(new Date(fromDate).setHours(0,0,0,0) > new Date(appointmentToDateFilter).setHours(0,0,0,0)) {
            alert('FROM date cannot be greater than TO date');
            return;
        }
        setAppointmentFromDateFilter(fromDate);
        await fetchAppointmentList(fromDate, appointmentToDateFilter, searchText, locationFilter);
    };

    const onChangeAppointmentToDateFilter = async (toDate) => {
        debugger
        if(new Date(toDate).setHours(0,0,0,0) < new Date(appointmentFromDateFilter).setHours(0,0,0,0)) {
            alert('TO date cannot be less than FROM date');
            return;
        }
        setAppointmentToDateFilter(toDate);
        await fetchAppointmentList(appointmentFromDateFilter, toDate, searchText, locationFilter);
    };

    const onChangeLocationFilter = async (value) => {
        setLocationFilter(value);
        await fetchAppointmentList(appointmentFromDateFilter, appointmentToDateFilter, searchText, value);
    };

    const debounceSearchCall = useCallback(debounce(fetchAppointmentList, 1000), []);

    const handleAppointmentSearch = async (event) => {
        const searchValue = event.target.value;
        setSearchText(searchValue);
        debounceSearchCall(appointmentFromDateFilter, appointmentToDateFilter, searchValue, locationFilter);
    };

    const removeAppointmentFromDateFilter = async () => {
        setAppointmentFromDateFilter('');
        await fetchAppointmentList('', appointmentToDateFilter, searchText, locationFilter);
    };

    const removeAppointmentToDateFilter = async () => {
        setAppointmentToDateFilter('');
        await fetchAppointmentList(appointmentFromDateFilter, '', searchText, locationFilter);
    };

    const removeLocationFilter = async () => {
        setLocationFilter('');
        await fetchAppointmentList(appointmentFromDateFilter, appointmentToDateFilter, searchText, '');
    };

    const getActiveAppointmentCount = () => {
        return appointmentList.filter(({status}) => status === 'active').length;
    };
    const closeCheckInModal = () => {
        setCheckIn(null);
        setShowConfirmCheckIn(false);
    };

    const handleCloseReportGenerate = () => {
        setGenerateReport(null);
        setOnGenerateReport(false);
    };

    const closeAppointmentDetailModal = () => {
        setAppointmentDetail({
            appointmentId: '',
            assessmentDetail: {},
            covidVisitInfo: {}
        });
        setShowAppointmentDetail(false);
    };

    const prepareAppointmentDetail = async (appointment) => {
        setLoading((prev) => ({...prev, fetchAppointmentDetail: {...prev.fetchAppointmentDetail, [appointment.id]: true}}));
        const appointmentDetailResponse = await dispatch(getAppointmentDetail(appointment.id));
        setLoading((prev) => ({...prev, fetchAppointmentDetail: {...prev.fetchAppointmentDetail, [appointment.id]: false}}));
        if(!appointmentDetailResponse.isSuccess) {
            alert('Error fetching appointment detail.');
            return;
        }
        setAppointmentDetail(appointmentDetailResponse.data);
        setShowAppointmentDetail(true);
    };

    const processCheckIn = async () => {
        setLoading((prev) => ({...prev, processCheckIn: true}));
        const checkInResponse = await dispatch(confirmCheckIn(checkIn));
        setLoading((prev) => ({...prev, processCheckIn: false}));
        if(!checkInResponse.isSuccess) {
            alert(checkInResponse.errorMessage);
            return;
        }
        // update the is_checked_in for this appointment
        setAppointmentList(prev => (
           prev.map((appointment) => {
               if(appointment.id === checkIn) {
                   appointment.is_checked_in = 1;
               }
               return appointment;
           })
        ));
        closeCheckInModal();
    };

    const processGenerateTestReport = async () => {
        setLoading((prev) => ({...prev, processGenerateReport: true}));
        const generateReportResponse = await dispatch(generateTestReport(generateReport, {'covid-19': testResult}));
        setLoading((prev) => ({...prev, processGenerateReport: false}));
        if(!generateReportResponse.isSuccess) {
            alert(generateReportResponse.errorMessage);
            return;
        }
        // update the is_report_generated and report_doc url for this appointment
        setAppointmentList(prev => (
           prev.map((appointment) => {
               if(appointment.id === generateReport) {
                   appointment.is_report_generated = 1;
                   appointment.report_doc = generateReportResponse.data;
               }
               return appointment;
           })
        ));
        handleCloseReportGenerate();
    };

    const printConfirmationCode = () => {
        labelPrintNew.init();
        return (<i className="fa fa-print" aria-hidden="true" title={'Print'}></i>);
    };

    const renderAppointments = () => {
        if(appointmentList.length === 0) {
            return (
                <tr>
                    <td colspan={8} className={'text-center'}>
                        <span>There are no appointments found</span>
                    </td>
                </tr>
            )
         }
        return appointmentList?.map((appointment) => {
            return (
                <tr key={`${appointment.id}`} className={selectedAppointmentId == appointment.id ? 'selected-row': ''}
                    onClick={() => setSelectedAppointmentId(appointment.id)}>
                    <td>
                        {
                            appointment.status === 'active' ?
                            <span title={'Active Appointment'} className={'active-circle'}><i class="fa fa-circle"></i></span>:
                                appointment.status === 'cancelled' ? <span title={'Cancelled Appointment'} className={'cancelled-circle'}><i class="fa fa-circle"></i></span>: ''
                        }
                        {appointment.patient_first_name} {appointment.patient_last_name}
                    </td>
                    <td>{appointment.date_of_birth && moment(new Date(appointment.date_of_birth)).format('YYYY-DD-MM')}</td>
                    <td>{appointment.patient_email}</td>
                    <td>{formatPhoneNumber(appointment.primary_phoneNumber || appointment.patient_mobile)}</td>
                    <td>{getAppointmentDate(appointment)}</td>
                    <td className={'confirmation'}>
                        <span>{appointment.confirmation_code}</span>
                        <span>&nbsp;&nbsp;</span>
                        <ReactToPrint trigger={printConfirmationCode} content={() => componentRef[appointment.id]} />
                        <span className={'d-none'}>
                            <ConfirmationCodeLabel ref={(el) => (componentRef[appointment.id] = el)} item={appointment}/>
                        </span>
                    </td>
                    <td>{appointment.test_type}</td>
                    <td>
                        {
                            appointment.status === 'cancelled' ?
                                <div className="vendor-table-btn">
                                    <button className="orange mr-2"
                                            onClick={() => prepareAppointmentDetail(appointment)}
                                            disabled={loading.fetchAppointmentDetail[appointment.id]}
                                    >
                                        View Details
                                        {loading.fetchAppointmentDetail[appointment.id] ? (
                                            <span className="spinner-border ml-1"></span>
                                        ) : null}
                                    </button>
                                </div> :
                                <div className="vendor-table-btn">
                                    <button className="orange mr-2"
                                            onClick={() => prepareAppointmentDetail(appointment)}
                                            disabled={loading.fetchAppointmentDetail[appointment.id]}
                                    >
                                        View Details
                                        {loading.fetchAppointmentDetail[appointment.id] ? (
                                            <span className="spinner-border ml-1"></span>
                                        ) : null}
                                    </button>

                                    {
                                        appointment.is_checked_in === 0 ?
                                            (
                                                <button
                                                    type="button"
                                                    className="orange-outline-btn"
                                                    onClick={() => clickCheckIn(appointment)}
                                                >
                                                    Check-In <i className="fa fa-check"></i>
                                                </button>
                                            ) :
                                            ['admin', 'doctor'].includes(userRole) && (
                                                appointment.is_report_generated === 0 ?
                                                    <button
                                                        type="button"
                                                        className="secondary-outline-btn"
                                                        onClick={() => clickGenerateReport(appointment)}
                                                    >
                                                        Generate Report
                                                    </button> :
                                                    <button
                                                        type="button"
                                                        className="orange-outline-btn primary-outline"
                                                        onClick={() => viewReport(appointment)}
                                                    >
                                                        View Report <i className="fa fa-eye"></i>
                                                    </button>
                                            )
                                    }
                                </div>
                        }

                    </td>
                </tr>
            );
        });
    };

    const prepareTab = (tabName) => {
        const obj = {
            loadCovidLocations: tabName === 'locations',
        };
        setLoading(prev => ({
            ...prev,
            ...obj
        }));
    };

    return (
        <div className="admin-card">
            <div className="admin-card-box">
                <div className="custom-tab">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <a className="nav-item nav-link active" id="patients-tab" data-toggle="tab"
                               href="#appointments" role="tab" aria-controls="custom-nav-patients"
                               aria-selected="true">Appointments</a>
                            <a className="nav-item nav-link" id="locations-tab" data-toggle="tab"
                               href="#locations" role="tab" aria-controls="custom-nav-locations"
                               aria-selected="true" onClick={() => prepareTab('locations')}>Locations</a>
                        </div>
                    </nav>

                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="appointments" role="tabpanel"
                             aria-labelledby="patients-tab">
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <div className="admin-card-head d-flex justify-content-between mb-2">
                                        <div>
                                            <span className={'mr-2'}>Filter</span>
                                            <div>
                                                <span style={{'font-size': '12px'}}><b> FROM </b> </span>
                                                <input type="date"
                                                       name="appointment_date"
                                                       value={appointmentFromDateFilter}
                                                       onChange={(e) => onChangeAppointmentFromDateFilter(e.target.value)}
                                                />
                                                {
                                                    appointmentFromDateFilter &&
                                                    <>
                                                    <span title={'remove filter'} onClick={removeAppointmentFromDateFilter}>
                                                        <i className={"fa fa-times-circle"}></i>
                                                    </span>
                                                    </>
                                                }
                                                <span style={{'font-size': '12px'}}><b>  &nbsp;TO </b> </span>
                                                <input type="date"
                                                       name="appointment_date"
                                                       value={appointmentToDateFilter}
                                                       onChange={(e) => onChangeAppointmentToDateFilter(e.target.value)}
                                                />
                                                {
                                                    appointmentToDateFilter &&
                                                    <>
                                                    <span title={'remove filter'} onClick={removeAppointmentToDateFilter}>
                                                        <i className={"fa fa-times-circle"}></i>
                                                    </span>
                                                    </>
                                                }
                                                <select
                                                    className={"location-filter"}
                                                    value={locationFilter}
                                                    onChange={(e) => onChangeLocationFilter(e.target.value)}
                                                >
                                                    <option value='' selected disabled>--Select location--</option>
                                                    {
                                                        allLocation.map((location) => {
                                                            return (
                                                                <option key={location.id} value={location.id}>{location.name}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                                {
                                                    locationFilter &&
                                                    <>
                                                    <span title={'remove filter'} onClick={removeLocationFilter}>
                                                        <i className={"fa fa-times-circle"}></i>
                                                    </span>
                                                    </>
                                                }
                                            </div>
                                            <div style={{'font-size': '14px'}}>
                                                <span><b>Total Appointments:</b> {getActiveAppointmentCount()}</span>
                                            </div>
                                        </div>
                                        <div className="search w-25">
                                            <input className="form-control"
                                                   type="text"
                                                   onChange={(e) => handleAppointmentSearch(e)}
                                                   placeholder="search"
                                            />
                                        </div>
                                    </div>
                                    <div className="vendor-table">
                                        <div className="vendor-table-body">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                        <th style={{'width': '16%'}}>Full Name</th>
                                                        <th style={{'width': '8%'}}>DOB</th>
                                                        <th style={{'width': '17%'}}>Email</th>
                                                        <th style={{'width': '10%'}}>Phone</th>
                                                        <th style={{'width': '10%'}}>Date</th>
                                                        <th style={{'width': '10%'}}>Code</th>
                                                        <th style={{'width': '8%'}}>Test Type</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                    loading.loadAppointments? (
                                                        <tr>
                                                            <td colSpan={8} className={'text-center'}>
                                                                <span className="spinner-border" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        ) : renderAppointments()
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="locations" role="tabpanel"
                             aria-labelledby="locations-tab">
                            { loading.loadCovidLocations && <CovidLocations /> }
                        </div>
                    </div>


                </div>
            </div>

            {showConfirmCheckIn ? (
                <div className="modal" style={{ display: "block" }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">
                                    {" "}
                                    Are you sure you have collected the sample, verified all the test related procedures and wish
                                    to proceed with the check-in?
                                </h4>
                                <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    onClick={closeCheckInModal}
                                >
                                    <span>
                                      <i class="far fa-times-circle"></i>
                                    </span>
                                </button>
                            </div>

                            <div className="modal-body" style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <button
                                    className="orange-outline-btn"
                                    onClick={processCheckIn}
                                    disabled={loading.processCheckIn}
                                >
                                    <span className={'mr-2'}>Yes</span>
                                    {loading.processCheckIn? (
                                      <span class="spinner-border"></span>
                                    ) : null}
                                </button>
                                <button
                                    className="danger-outline-btn"
                                    onClick={closeCheckInModal}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <Modal show={onGenerateReport} onHide={handleCloseReportGenerate} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Add Test Results</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-lg-12 col-md-12 d-flex mb-2">
                        <div className="col-md-7 pl-0 pathogen">
                            <label
                                className={`mb-0 ${testResult === 'Positive' && 'detected'}` }
                            >COVID-19
                            </label>
                            {/*<span className="d-block form-field-error">*/}
                            {/*    {errorData[panelPathogen]}*/}
                            {/*</span>*/}
                        </div>
                        <div className="col-md-5">
                            <select
                                className="form-control"
                                value={testResult}
                                onChange={(e) => setTestResult(e.target.value)}
                            >
                                <option value={'Negative'}>Negative</option>
                                <option value={'Positive'}>Positive</option>
                                <option value={'Inconclusive'}>Inconclusive</option>
                            </select>
                        </div>

                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-btn text-center">
                            <button type="button"
                                    disabled={loading.processGenerateReport}
                                    onClick={processGenerateTestReport}
                                    className="orange-outline-btn">submit
                                {loading.processGenerateReport? (
                                    <span class="spinner-border"></span>
                                ) : null}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseReportGenerate}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {showAppointmentDetail ? (
                <div className="modal detail" style={{ display: "block" }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div>
                                    <div className="custom-tab">
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            onClick={closeAppointmentDetailModal}
                                        >
                                            <span>
                                              <i className="far fa-times-circle"></i>
                                            </span>
                                        </button>

                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab-detail" role="tablist">
                                                <a className="nav-item nav-link active" id="assessment-tab" data-toggle="tab"
                                                   href="#assessment" role="tab" aria-controls="custom-nav-assessment"
                                                   aria-selected="true">Assessment</a>

                                                <a className="nav-item nav-link" id="covid-visit-tab" data-toggle="tab"
                                                   href="#covid-visit" role="tab" aria-controls="custom-nav-covid-visit"
                                                   aria-selected="true">Test Detail</a>
                                            </div>
                                        </nav>

                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="assessment" role="tabpanel"
                                                 aria-labelledby="assessment-tab">
                                                <CovidAssessment
                                                    assessmentDetail={appointmentDetail.assessmentDetail}
                                                />
                                            </div>

                                            <div className="tab-pane fade" id="covid-visit" role="tabpanel"
                                                 aria-labelledby="covid-visit-tab">
                                                <CovidVisitInfo
                                                    visitInfo={appointmentDetail.covidVisitInfo}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Covid19Testing;
