import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getReportList = (offset) => async (dispatch) => {
    console.log("getOrdersList offset is", offset);
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/report-management?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("orders list response from backend**", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}
export const searchUserReport = (offset, searchKeyword) => async (dispatch) => {
    console.log("getOrdersList offset is", offset);
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/admin/report-management/search?offset=${offset}&search=${searchKeyword}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      // console.log("orders list response from backend", res);
      return res;
    }
    catch (error) {
      console.log('wrong', error);
      dispatch(handleError(error));
    }
  }


export const uploadReport = (uploadReport) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/report-management/upload-report`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: uploadReport
        });
        await getReportList(0)
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

export const getReportDetails = (userId) => async (dispatch) => {
  try {
      const res = await axios({
          method: "get",
          url: `${URL}/api/v1/admin/report-management/${userId}`,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
          }
      });

      return res;
  }
  catch (error) {
      console.log('wrong', error);
      dispatch(handleError(error));
  }
}


