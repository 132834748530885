import React, { Component } from "react";
import "../../Styles/user-detail.scss";
import profileAvtar from "../../Assets/images-2/dummyUser.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserDetail, updateProfile, updateUserAgreement, getUserAgreement } from "../../Redux/Actions/userAction";

class User_Detail extends Component {
  fieldsList = {
    address: "",
    addressId: null,
    city: "",
    country: "",
    createts: "",
    email: "",
    full_name: "",
    id: 0,
    imagename: "",
    isacceptedtnc: 0,
    isOrganisationUser: false,
    is_active: 0,
    phonenumber: "",
    role: "",
    state: "",
    type: "",
    updatedTS: "",
    updatedts: "",
    userId: 0,
    zipCode: "",
    successMessage: "",
    errorMessage: "",
    discount_percent: "",
    order_units: "",
    selectedImg: null,
    showAgreementSaveLoader: false,
    authData: {
      name: "",
      image: "",
    },
  };
  timestamp = "?time=" + new Date().getTime();

  constructor(props) {
    super(props);
    this.state = { ...this.fieldsList };
    this.fileSelectHandler = this.fileSelectHandler.bind(this);

    this.fileUpload = React.createRef();
  }

  async fileSelectHandler(e) {
    let imgStatus = false;
    if (e.target.files[0] !== undefined)
      if (e.target.files[0].type !== undefined) {
        let imgType = e.target.files[0].type.split("/");
        let MimeTypeArray = ["jpg", "jpeg", "png", "gif"];
        // console.log(imgType, 'imgType');
        if (imgType[1] !== undefined) {
          if (MimeTypeArray.includes(imgType[1])) {
            if (e.target && e.target.files[0]) {
              let img = e.target.files[0];
              this.setState({
                selectedImg: img,
              });
              this.readFile(img);
            }
          } else {
            imgStatus = true;
          }
        }
      }
    this.setState({ invalidImage: imgStatus });
  }

  fetchUser = async (userId) => {
    let result = await this.props.getUserDetail(userId);
    console.log("user detail response from backend", result);
    if (result && result.data && !result.isError) {
      this.setUserState(result.data.data);
    } else {
      console.log("something went wrong while getting user details");
    }
  };

  fetchUserAgreement = async (userId) => {
    let result = await this.props.getUserAgreement(userId);
    if (result && result.success) {
      this.setUserAgreementState(result.data);
    } else {
      console.log("something went wrong while getting user agreement details");
    }
  };

  setUserState(userInfo) {
    this.setState({
      ...userInfo,
    });
  }


  setUserAgreementState(userAgreementInfo) {
    this.setState({
      discount_percent: userAgreementInfo.discount_percent,
      order_units: userAgreementInfo.order_units,
    });
  }

  componentDidMount() {
    this.fetchUser(this.props.match.params.userId);
    this.fetchUserAgreement(this.props.match.params.userId);
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.match.params.userId !== this.props.match.params.userId) {
      this.getProductDetail(nextProps.match.params.productid);
    }
  }

  readFile(file) {
    //Create an instance of FileReader
    const fileReader = new FileReader();
    //Implement onLoad function
    fileReader.onloadend = (event) =>
      this.setState({
        authData: {
          ...this.state.authData,
          image: [fileReader.result],
        },
      });
    //Start reading the file
    fileReader.readAsDataURL(file);
  }

  handleChange = (e, key) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  handleAgreementChange = (e, key) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  handleSubmit = async () => {
    let st = this.state;
    this.setState((prevState) => ({
      ...st,
      successMessage: "",
      errorMessage: "",
      showLoader: true,
    }));
    const profile = this.state;

    const result = await this.props.updateProfile({ ...profile });
    console.log("result after update is", result);

    if (result.status === 201) {
      // console.log(this.state.authData, 'authdata')
      // if (this.state.authData.image !== undefined) {
      //     this.state.imagename = this.state.authData.image[0];
      // }
      this.setState((prevState) => ({
        ...prevState,
        timestamp: Date.now(),
        successMessage: "Profile updated successfully!",
        showLoader: false,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        errorMessage: "Something went wrong! Please try after some time.",
        showLoader: false,
      }));
    }
  };

  handleAgreementSave = async () => {
    this.setState({
      successMessage: "",
      errorMessage: "",
      showAgreementSaveLoader: true,
    });
    let {
      discount_percent,
      order_units,
    } = this.state;

    if(!discount_percent || !order_units ||
        !discount_percent.toString().trim() || !order_units.toString().trim()
    ) {
      this.setState({
        errorMessage: "Please fill all the required fields in the user agreement",
        showAgreementSaveLoader: false,
      });
      return;
    }

    if(discount_percent && isNaN(discount_percent)) {
      this.setState({
        errorMessage: "Discount percent should be number",
        showAgreementSaveLoader: false,
      });
      return;
    }

    discount_percent = parseFloat(discount_percent);
    if(discount_percent && (discount_percent > 100 || discount_percent < 0)) {
      this.setState({
        errorMessage: "Discount percent should be between 0-100",
        showAgreementSaveLoader: false,
      });
      return;
    }

    if(isNaN(order_units)) {
      this.setState({
        errorMessage: "Order Units should be number",
        showAgreementSaveLoader: false,
      });
      return;
    }
    order_units = parseFloat(order_units);

    const result = await this.props.updateUserAgreement(this.state.id, {
      discount_percent: discount_percent,
      order_units: order_units
    });
    if (result.isSuccess) {
      this.setState((prevState) => ({
        ...prevState,
        successMessage: "User Agreement updated successfully!",
        showAgreementSaveLoader: false,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        errorMessage: result.errorMessage,
        showAgreementSaveLoader: false,
      }));
    }
  };

  triggerClick = () => {
    this.fileUpload.current.click();
  };

  render() {
    return (
      <div class="admin-card">
        <div class="order-heading">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <h2 class="mb-0">user profile</h2>
            </div>
          </div>
        </div>
        <div class="admin-card-box">
          <section className="cart-section">
            <div className="container-fluid">
              {/* <div className="cart-section-head">
                                <h2>profile</h2>
                                <p>Setup your general profile details.</p>
                            </div> */}
              {(() => {
                if (
                  this.state.successMessage !== "" &&
                  this.state.successMessage !== undefined
                ) {
                  return (
                    <div className="alert alert-success">
                      {this.state.successMessage}
                    </div>
                  );
                } else if (
                  this.state.errorMessage !== "" &&
                  this.state.errorMessage !== undefined
                ) {
                  return (
                    <div className="alert alert-danger">
                      {this.state.errorMessage}
                    </div>
                  );
                }
              })()}
              <div className="profile-body">
                <div className="row">
                  <div className="col-lg-7 col-md-6 order-md-1 order-2">
                    <div className="profile-form">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              value={this.state?.full_name}
                              onChange={(event) =>
                                this.handleChange(event, "full_name")
                              }
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              value={this.state?.lastname}
                              onChange={(event) =>
                                this.handleChange(event, "lastname")
                              }
                            />
                          </div>
                        </div> */}
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              disabled={true}
                              type="text"
                              className="form-control"
                              placeholder="Email Address"
                              value={this.state?.email}
                              onChange={(event) =>
                                this.handleChange(event, "email")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Phone Number"
                              value={this.state?.phonenumber}
                              onChange={(event) =>
                                this.handleChange(event, "phonenumber")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address"
                              value={this.state?.address}
                              onChange={(event) =>
                                this.handleChange(event, "address")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            {/* <select name="country" className="form-control">
                                                                        <option value="volvo">country</option>
                                                                        <option value="saab">country1</option>
                                                                        <option value="mercedes">country2</option>
                                                                        <option value="audi">countr3</option>
                                                                        </select> */}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Country"
                              value={this.state?.country}
                              onChange={(event) =>
                                this.handleChange(event, "country")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            {/* <select name="state" className="form-control">
                                                                    <option value="volvo">state</option>
                                                                    <option value="saab">state1</option>
                                                                    <option value="mercedes">state2</option>
                                                                    <option value="audi">state3</option>
                                                                    </select> */}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="State"
                              value={this.state?.state}
                              onChange={(event) =>
                                this.handleChange(event, "state")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            {/* <select name="country" className="form-control">
                                                                    <option value="volvo">city</option>
                                                                    <option value="saab">city1</option>
                                                                    <option value="mercedes">city2</option>
                                                                    <option value="audi">city3</option>
                                                                    </select> */}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                              value={this.state?.city}
                              onChange={(event) =>
                                this.handleChange(event, "city")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Zip Code"
                              value={this.state?.zipCode}
                              onChange={(event) =>
                                this.handleChange(event, "zipCode")
                              }
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Is Active?</label>
                            <div className="custom-radio-input">
                              <p className="container-sub mr-2">
                                Inactive
                                <span>
                                  <input
                                    type="radio"
                                    value="0"
                                    onChange={(event) =>
                                      this.handleChange(event, "is_active")
                                    }
                                    checked={this.state.is_active == 0}
                                    name="radio"
                                  />
                                </span>
                                <span class="checkmark"></span>
                              </p>

                              <p className="container-sub mr-2">
                                Active
                                <span>
                                  <input
                                    type="radio"
                                    value="1"
                                    onChange={(event) =>
                                      this.handleChange(event, "is_active")
                                    }
                                    checked={this.state.is_active == 1}
                                    name="radio"
                                  />
                                </span>
                                <span class="checkmark"></span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="checkout-btn">
                        <button type="button" onClick={this.handleSubmit}>
                          continue
                          {(() => {
                            if (this.state?.showLoader) {
                              return (
                                <span class="spinner-border spinner-border-sm ml-1"></span>
                              );
                            }
                          })()}
                        </button>
                      </div>
                    </div>
                  </div>

                  {
                    this.state.isOrganisationUser === 1 &&
                    <div className="col-lg-5 col-md-6 order-md-1 order-2">
                      <div className="profile-form">
                        <div className="row ml-2">

                          <h5>
                            Organization User Agreement
                          </h5>
                          <div>
                            Allow
                            <input className="ml-1 mr-1 mb-1" type="text" style={{width: '50px'}}
                                   value={this.state?.discount_percent}
                                   onChange={(event) =>
                                this.handleAgreementChange(event, "discount_percent")
                              }/>
                            % discount to the organization if the order exceeds
                            <input className="ml-1 mr-1 mb-1" type="text" style={{width: '100px'}}
                                   value={this.state?.order_units}
                                   onChange={(event) =>
                                this.handleAgreementChange(event, "order_units")
                              }/> units
                          </div>

                          <div className="checkout-btn">
                            <button type="button" onClick={this.handleAgreementSave}>
                              Save
                              {(() => {
                                if (this.state?.showAgreementSaveLoader) {
                                  return (
                                      <span className="spinner-border spinner-border-sm ml-1"></span>
                                  );
                                }
                              })()}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  }


                  {/* <div className="col-lg-4 col-md-5 order-md-2 order-1">
                    <div className="profile-picture">
                      <div className="profile-picture-head">
                        <h3>profile picture</h3>
                      </div>
                      <div className="user-profile-img">
                        <img
                          src={
                            this.state.authData.image ||
                            (this.state.imagename &&
                              this.state.imagename !== "undefined")
                              ? this.state.authData.image ||
                                this.state.imagename
                              : profileAvtar
                          }
                        />
                      </div>
                      {(() => {
                        if (
                          this.state?.invalidImage &&
                          this.state.invalidImage
                        ) {
                          return (
                            <span className="form-field-error">
                              Invalid image format
                            </span>
                          );
                        }
                      })()}

                      <div className="upload-btn">
                        <button onClick={this.triggerClick} type="button">
                          upload image
                        </button>
                      </div>
                      <input
                        ref={this.fileUpload}
                        className="droup-file-input"
                        type="file"
                        onChange={this.fileSelectHandler}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getUserDetail, updateProfile, updateUserAgreement, getUserAgreement }, dispatch);
};

export default connect(null, mapDispatchToProps)(User_Detail);
