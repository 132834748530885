export const initialState = {
  teamActivity: null,
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TEAM_ACTIVITY":
      return {
        ...state,
        teamActivity: action.payload.data.data.Items,
        lastEvaluatedKey: action.payload.data.data.LastEvaluatedKey
      };

    case "ADD_MORE_TEAM_ACTIVITY":
      return {
        ...state,
        teamActivity: [...state.teamActivity, ...action.payload.data.data.Items],
        lastEvaluatedKey: action.payload.data.data.LastEvaluatedKey
      };

    default:
      return state;
  }
};
