import React, { useEffect, useState } from 'react';
import "./covid19.scss";

const CovidAssessment = (props) => {
    const [assessmentDetail, setAssessmentDetail] = useState({
        future_travel_recreation: null,
        possible_social_distancing: null,
        closed_contact: null,
        risk_resident: null,
        work_in_healthcare: null,
        risk_workplace: null,
        asked_for_test: null,
        symptoms_experienced: null,
        symptoms: {
            is_chills: false,
            is_headache: false,
            is_congestion: false,
            is_muscle_ache: false,
            is_cough: false,
            is_nausea: false,
            is_diarrhea: false,
            is_loss_of_taste: false,
            is_fatigue: false,
            is_difficult_breathing: false,
            is_fever: false,
            is_sore_throat: false,
            symptom_date: null,
        },
        is_acknowledge: false
    });

    useEffect(() => {
        setAssessmentDetail(props.assessmentDetail);
    }, []);

    return (
        <div id="assessment-container">
            <div className="container">
                <div id="assessment-row" className="row justify-content-center align-items-center">
                    <div id="assessment-box" className="col-md-12">
                        <form id="assessment-form" className="form">
                            <div className={"section"}>
                                <h5>Travel and exposure</h5>
                                <div className={"section-content col-md-12"}>
                                    <div>
                                        <div className="form-group">
                                            <label>
                                                <b>Are you seeking a test to prevent possible spread of COVID-19 for future travel or recreation</b> (according to CDC guidelines)?
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="future_travel_recreation" value="1" className={'mr-2'}
                                                        checked={assessmentDetail.future_travel_recreation === 1}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="future_travel_recreation" value="0" className={'mr-2'}
                                                        checked={assessmentDetail.future_travel_recreation === 0}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mt-4"}>
                                        <div className="form-group">
                                            <label>
                                                <b>Are you seeking a test to prevent possible spread of COVID-19 after being at a place where social distancing was not possible?</b>
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="possible_social_distancing" value="1" className={'mr-2'}
                                                        checked={assessmentDetail.possible_social_distancing === 1}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="possible_social_distancing" value="0" className={'mr-2'}
                                                        checked={assessmentDetail.possible_social_distancing === 0}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mt-4"}>
                                        <div className="form-group">
                                            <label>
                                                <b>Have you had close contact with someone with a confirmed case of COVID-19?</b>
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="closed_contact" value="1" className={'mr-2'}
                                                        checked={assessmentDetail.closed_contact === 1}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="closed_contact" value="0" className={'mr-2'}
                                                        checked={assessmentDetail.closed_contact === 0}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mt-4"}>
                                        <div className="form-group">
                                            <label>
                                                <b>Are you a resident in a special setting where the risk of COVID-19 transmission may be high?</b>
                                                    (This may include long-term care, correctional and detention facilities; homeless shelters; assisted-living facilities and group homes.)
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="risk_resident" value="1" className={'mr-2'}
                                                        checked={assessmentDetail.risk_resident === 1}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="risk_resident" value="0" className={'mr-2'}
                                                        checked={assessmentDetail.risk_resident === 0}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"section"}>
                                <h5>Workplace risks</h5>
                                <div className={"section-content col-md-12"}>
                                    <div>
                                        <div className="form-group">
                                            <label>
                                                <b>Do you work in health care?</b>
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="work_in_healthcare" value="1" className={'mr-2'}
                                                        checked={assessmentDetail.work_in_healthcare === 1}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="work_in_healthcare" value="0" className={'mr-2'}
                                                        checked={assessmentDetail.work_in_healthcare === 0}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mt-4"}>
                                        <div className="form-group">
                                            <label>
                                                <b>Do you work in a special setting where the risk of COVID-19 transmission may be high?</b>
                                                    (This may include long-term care, correctional and detention facilities; homeless shelters; assisted-living facilities and group homes.)
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="risk_workplace" value="1" className={'mr-2'}
                                                        checked={assessmentDetail.risk_workplace === 1}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="risk_workplace" value="0" className={'mr-2'}
                                                        checked={assessmentDetail.risk_workplace === 0}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"section"}>
                                <h5>Medical history</h5>
                                <div className={"section-content col-md-12"}>
                                    <div>
                                        <div className="form-group">
                                            <label>
                                                <b>Have you been asked or referred to get tested by a health care provider?</b>
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="asked_for_test" value="1" className={'mr-2'}
                                                        checked={assessmentDetail.asked_for_test === 1}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="asked_for_test" value="0" className={'mr-2'}
                                                        checked={assessmentDetail.asked_for_test === 0}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mt-4"}>
                                        <div className="form-group">
                                            <label>
                                                <b>Have you experienced any symptoms in the last 14 days?</b>
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="symptoms_experienced" value="1" className={'mr-2'}
                                                        checked={assessmentDetail.symptoms_experienced === 1}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="symptoms_experienced" value="0" className={'mr-2'}
                                                        checked={assessmentDetail.symptoms_experienced === 0}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        assessmentDetail.symptoms_experienced === 1 &&
                                        <div className={"mt-4"}>
                                            <label>
                                                <b>Which symptoms have you experienced?</b> (Select any that apply)
                                            </label>
                                            <div className="form-group checklist">
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <label className="radio-inline mr-3 w-50">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_chills"
                                                            checked={assessmentDetail.symptoms.is_chills}
                                                        />
                                                        <span>Chills</span>
                                                    </label>
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_headache"
                                                            checked={assessmentDetail.symptoms.is_headache}
                                                        />
                                                        <span>Headache</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <label className="radio-inline mr-3 w-50">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_congestion"
                                                            checked={assessmentDetail.symptoms.is_congestion}
                                                        />
                                                        <span>Congestion or runny nose</span>
                                                    </label>
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_muscle_ache"
                                                            checked={assessmentDetail.symptoms.is_muscle_ache}
                                                        />
                                                        <span>Muscle or body aches</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <label className="radio-inline mr-3 w-50">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_cough"
                                                            checked={assessmentDetail.symptoms.is_cough}
                                                        />
                                                        <span>Cough</span>
                                                    </label>
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_nausea"
                                                            checked={assessmentDetail.symptoms.is_nausea}
                                                        />
                                                        <span>Nausea or vomiting</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <label className="radio-inline mr-3 w-50">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_diarrhea"
                                                            checked={assessmentDetail.symptoms.is_diarrhea}
                                                        />
                                                        <span>Diarrhea</span>
                                                    </label>
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_loss_of_taste"
                                                            checked={assessmentDetail.symptoms.is_loss_of_taste}
                                                        />
                                                        <span>New loss of taste or smell</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <label className="radio-inline mr-3 w-50">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_fatigue"
                                                            checked={assessmentDetail.symptoms.is_fatigue}
                                                        />
                                                        <span>Fatigue</span>
                                                    </label>
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_difficult_breathing"
                                                            checked={assessmentDetail.symptoms.is_difficult_breathing}
                                                        />
                                                        <span>Shortness of breath or difficulty breathing</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <label className="radio-inline mr-3 w-50">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_fever"
                                                            checked={assessmentDetail.symptoms.is_fever}
                                                        />
                                                        <span>Fever</span>
                                                    </label>
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="is_sore_throat"
                                                            checked={assessmentDetail.symptoms.is_sore_throat}
                                                        />
                                                        <span>Sore throat</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div>
                                                Approximately what date did your symptoms begin?
                                                <input type="date" name="symptom_date"
                                                       disabled={true}
                                                       value={assessmentDetail.symptoms.symptom_date}
                                                        />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="col-md-12 p-0 pl-4 d-flex flex-row align-items-center">
                                <label className="radio-inline mr-3">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="is_acknowledge"
                                        checked={assessmentDetail.is_acknowledge}
                                    />
                                    <b className={'ml-3 pt-1 d-block'}>I acknowledge that I have answered these questions truthfully to the best of my knowledge.</b>
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CovidAssessment;
