import React  from "react";
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import './settings.scss';

import {debounce} from "lodash";
import {
    searchShippingSettings,
    getShippingSettingsList,
} from "../../Redux/Actions/settingAction";
import InfiniteScroll from "react-infinite-scroll-component";
import {Button, Modal} from "react-bootstrap";
import EditShippingSettings from "./edit-shipping-settings";

class ShippingSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allShippingSettings:[],
            loadShippingSettings: false,
            offset: 0,
            prevOffset: 0,
            maxLimit: 20,
            hasMore: true,
            searchKeyword: "",
            errorMessage: '',
            errorData: {
            },
            showEditModal: false,
            editSettingObj: {},
        };
    }
    async componentDidMount() {
        this.fetchMoreData(true);
    }

    fetchMoreData = async (firstLoad) => {
        this.setState({
            loadShippingSettings: true
        });

        if (this.state.offset !== this.state.prevOffset || firstLoad) {
            if (this.state.hasMore && this.state.maxLimit && (this.state.maxLimit === "all" || this.state.maxLimit > this.state.offset)) {
                let result;
                if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
                    result = await this.props.searchShippingSettings(this.state.offset, this.state.searchKeyword.trim());
                }
                else {
                    result = await this.props.getShippingSettingsList(this.state.offset);
                }

                if (result ?.data ?.data ?.length > 0) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            prevOffset: prevState.offset,
                            offset: prevState.offset + 20,
                            allShippingSettings: prevState.allShippingSettings.concat(result.data.data),
                            loadShippingSettings: false
                        }
                    })
                }
                else {
                    this.setState({ hasMore: false, loadShippingSettings: false });
                }
            } else {
                this.setState({
                    loadShippingSettings: false
                })
            }
        } else {
            this.setState({
                loadShippingSettings: false
            })
        }

    };

    handleChange = async (event, field) => {
        event.preventDefault();
        if (field === "maxLimit") {
            await this.setState({
                [field]: event.target.value === "all" ? event.target.value : +event.target.value
            });
            this.fetchMoreData(false);
        }
        else {
            await this.setState({
                [field]: event.target.value,
                offset: 0,
                prevOffset: 0,
                hasMore: true
            });
            this.debounceSearchCall();
        }
    };

    debounceSearchCall = debounce(() => {
        this.setState({
            allShippingSettings: [],
        });
        this.fetchMoreData(true);
    }, 1000);

    handleCloseEditSettings = (updatedShippingSettings) => {
        this.setState({
            showEditModal: false,
            editSettingObj: {},
            allShippingSettings: this.state.allShippingSettings.map((settings) => {
                if(updatedShippingSettings && settings.mainProductId == updatedShippingSettings.productId) {
                    return {
                        ...settings,
                        ...updatedShippingSettings
                    };
                }
                return settings;
            })
        });
    };

    onEditClick = (settingsObj) => {
        this.setState({
            showEditModal: true,
            editSettingObj: settingsObj
        });
    };

    render() {
        const {
            allShippingSettings,
            loadShippingSettings,
            showEditModal,
            editSettingObj,
        } = this.state;

        const shippingSettings = allShippingSettings?.map((setting, index) => {
            return (
                <tr key={`${setting.id}-${index}`}>
                    <td>{setting.productName}</td>
                    <td>{setting.length || 0}</td>
                    <td>{setting.width || 0}</td>
                    <td>{setting.height || 0}</td>
                    <td>{setting.distance_unit || 'in'}</td>
                    <td>{setting.weight || 0}</td>
                    <td>{setting.mass_unit || 'lb'}</td>
                    <td>
                        <button style={{'min-width': '80px'}}
                            type="button" onClick={(e) => this.onEditClick(setting)} className="orange-outline-btn">
                            Edit
                        </button>
                    </td>
                </tr>
            );
        });
        return (
            <div className="admin-card">
                <div className="admin-card-box">
                    <div className="vendor-table mt-2">
                        <div className="vendor-table-head">
                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <div className="entries-drop">
                                        <label>show</label>
                                        <select className="entries-controls"
                                                onChange={(e) => this.handleChange(e, "maxLimit")}
                                                value={this.state.maxLimit}>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="all">All</option>
                                        </select>
                                        <p>Entries</p>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8">
                                    <div className="vendor-table-head-right">
                                        <div className="vendor-table-search">
                                            <input className="form-control"
                                                   onChange={(e) => this.handleChange(e, "searchKeyword")}
                                                   value={this.state.searchKeyword} type="text" placeholder="search"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="vendor-table-body">
                            <div className="table-responsive">
                                {loadShippingSettings ? (
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (null)}

                                <InfiniteScroll
                                    dataLength={this.state.allShippingSettings.length}
                                    next={() => this.fetchMoreData(false)}
                                    hasMore={this.state.hasMore}
                                >
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Length</th>
                                            <th>Width</th>
                                            <th>Height</th>
                                            <th>Distance Unit</th>
                                            <th>Weight</th>
                                            <th>Mass Unit</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody> {shippingSettings} </tbody>
                                    </table>
                                </InfiniteScroll>

                                <Modal show={showEditModal} onHide={this.handleCloseEditSettings}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit Parcel Settings</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <EditShippingSettings
                                            handleCloseEditSettings={this.handleCloseEditSettings}
                                            settingsEditData={editSettingObj}
                                        />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={this.handleCloseEditSettings}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        ...state
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        searchShippingSettings,
        getShippingSettingsList,
    }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShippingSettings));
