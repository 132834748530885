import React, { Component } from "react";
import "../../Styles/order-detail.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getOrderDetail,
  updateOrderDetail,
  getOrderTracking,
} from "../../Redux/Actions/orderAction";
import ReactToPrint from "react-to-print";
import KitIdLabel from "./kit-id-label";
import {labelPrint} from "../helper/helper";
import { Rating } from 'react-simple-star-rating'
import './kit-label.scss';

class Order_Detail extends Component {
  statePrevCopy = {};

  constructor(props) {
    super(props);
    this.state = {
      orderDetail: {},
      shippingAddress: {},
      isEditForm: false,
      errorData: {},
      componentRef: {},
    };
  }

  componentDidMount() {
    this.fetchOrderDetail();
  }

  fetchOrderDetail = async () => {
    const result = await this.props.getOrderDetail(
      this.props.match.params.orderId
    );
    if (result ?.data ?.data) {
      await this.setState({
        orderDetail: result.data.data,
        shippingAddress: result.data.data.address
          ? JSON.parse(result.data.data.address)
          : null,
      });

      // this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
    }
  };

  onEdit = () => {
    this.statePrevCopy = {
      ...this.state
    };
    this.setState({
      isEditForm: true,
    });
  };

  handleChange = (event, field) => {
    console.log("event is", event.target.value);
    event.persist();

    if (
      this.state.errorData &&
      this.state.errorData[field] &&
      this.state.errorData[field][0]
    ) {
      this.state.errorData[field][0] = null;
    }

    this.setState((prevState) => {
      return (prevState.orderDetail[field] = event.target.value);
    });
  };

  validateUpdate = () => {
    let isFormValid = true;
    this.state.errorData = {
      trackingCompany: [],
      trackingId: [],
    };

    if (
      this.state.orderDetail.trackingId &&
      this.state.orderDetail.trackingId.length > 0
    ) {
      if (
        !this.state.orderDetail.trackingCompany ||
        this.state.orderDetail.trackingCompany.trim().length === 0
      ) {
        isFormValid = false;
        this.state.errorData.trackingCompany = [
          "Tracking company is required.",
        ];
      }
    }

    if (
      this.state.orderDetail.trackingCompany &&
      this.state.orderDetail.trackingCompany.length > 0
    ) {
      if (
        !this.state.orderDetail.trackingId ||
        this.state.orderDetail.trackingId.trim().length === 0
      ) {
        isFormValid = false;
        this.state.errorData.trackingId = ["Tracking Id is required."];
      }
    }

    let tempState = this.state;

    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  onSaveForm = async () => {
    if (this.validateUpdate()) {
      this.setState({
        isEditForm: false,
      });

      const res = await this.props.updateOrderDetail(this.state.orderDetail);
      if (res && res.data && !res.data.isError) {
        this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
      } else {
        this.setState({
          ...this.statePrevCopy,
        });
      }
    }
  };

  onCancel = async () => {
    console.log("state copy is", this.statePrevCopy);

    await this.setState({
      ...this.statePrevCopy,
    });

    console.log("state is", this.state);
  };

  printKitId = () => {
    labelPrint.init();
    return (<i className="fa fa-print" aria-hidden="true" title={'Print kit number'}></i>);
  };

  onShipClick = (orderId) => {
    this.props.history.push(`/orders/${orderId}/process-shipping`);
  };

  render() {
    const date = this.state.orderDetail.createTS
      ? new Date(this.state.orderDetail.createTS).toISOString().substring(0, 10)
      : null;
    const orderItems =
      this.state.orderDetail.items &&
        this.state.orderDetail.items.length > 0 ? (
          this.state.orderDetail.items.map((i, index) => {
            return (
              <div key={i.id} class="cart-box">
                <div class="cart-box-detail">
                  <div class="cart-box-img">
                    <img src={i.image_name} />
                  </div>
                  <div class="cart-box-item-detail">
                    <h2>{i.name}</h2>
                    {/* <p>
                      <span>Plant Type: </span>Decorative House Plant
                  </p>
                    <p>
                      <span>Frequency: </span>Monthly
                  </p> */}
                  </div>
                </div>
                {/* <div class="cart-box-item-price">*/}
                {/*  <p><span>${(i.pricePerUnit).toFixed(2)}</span> x <span>{i.orderedQuantity}</span></p>*/}
                {/*  <p>${(i.orderedQuantity * i.pricePerUnit).toFixed(2)}</p>*/}
                {/*</div> */}
                 <div class="cart-box-item-price flex-column">
                   <div class="">
                     <b>Kit No: </b> {i.registrationKitId || 'N/A'}
                     {
                       i.registrationKitId &&
                       <>
                         <span>&nbsp;&nbsp;</span>
                         <ReactToPrint trigger={this.printKitId} content={() => this.state.componentRef[i.id]} />
                         <span className={'d-none'}>
                        <KitIdLabel ref={(el) => (this.state.componentRef[i.id] = el)} item={i}/>
                       </span>
                       </>
                     }
                   </div>
                </div>
              </div>
            );
          })
        ) : (
          <h3>Loading...</h3>
        );
    return (
      <div class="admin-card">
        <div class="order-heading">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <h2 class="mb-0">order details</h2>
              <p>Check your order details.</p>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="order-edit">
                {/*{(() => {*/}
                {/*  if (!this.state.isEditForm) {*/}
                {/*    return (*/}

                {/*      <button*/}
                {/*        onClick={this.onEdit}*/}
                {/*        type="text"*/}
                {/*        class="orange-outline-btn"*/}
                {/*      >*/}
                {/*        <i class="far fa-edit"></i> Update*/}
                {/*      </button>*/}
                {/*    );*/}
                {/*  } else {*/}
                {/*    return (*/}
                {/*      <>*/}
                {/*        <button*/}
                {/*          onClick={this.onSaveForm}*/}
                {/*          type="text"*/}
                {/*          class="orange-outline-btn"*/}
                {/*        >*/}
                {/*          <i class="fa fa-check"></i> Save*/}
                {/*        </button>*/}
                {/*        <button*/}
                {/*          onClick={this.onCancel}*/}
                {/*          type="text"*/}
                {/*          class="orange-outline-btn"*/}
                {/*        >*/}
                {/*          <i class="fa fa-times"></i> Cancel*/}
                {/*        </button>*/}
                {/*      </>*/}
                {/*    );*/}
                {/*  }*/}
                {/*})()}*/}
              </div>
            </div>
          </div>
        </div>
        <div class="admin-card-box">
          <section class="track-section">
            <div class="container">
              {/* <div class="cart-box">
                                <div class="cart-box-detail">
                                    <div class="cart-box-img">
                                        <img src={productImg} />
                                    </div>
                                    <div class="cart-box-item-detail">
                                        <h2>home grown</h2>
                                        <p>
                                            <span>Plant Type:</span>Decorative House Plant</p>
                                        <p>
                                            <span>Duration:</span>Monthly</p>
                                    </div>
                                </div>
                                <div class="cart-box-item-price">
                                    <p>$45</p>
                                </div>
                            </div> */}

              {orderItems}

              <div class="order-detail">
                <div class="d-flex justify-content-end mb-5">
                  <div className="text-right">
                    {
                      this.state.orderDetail.status !== 'pending' &&
                      <button
                          type="button"
                          className="ml-2 btn btn-sm btn-outline-primary"
                          onClick={() => this.onShipClick(this.state.orderDetail.id)}
                      ><i className="fa fa-ship" aria-hidden="true"></i> Ship
                      </button>
                    }
                    {
                      this.state.orderDetail.trackingId && this.state.orderDetail.labelURL &&
                      <button
                          type="button"
                          className="ml-2 secondary-outline-btn"
                          onClick={() => window.open(this.state.orderDetail.labelURL, "_blank")}
                      ><i className="fa fa-print" aria-hidden="true"></i> Send Label
                      </button>
                    }
                    {
                      this.state.orderDetail.return_trackingId && this.state.orderDetail.return_labelURL &&
                      <button
                          type="button"
                          className="ml-2 secondary-outline-btn"
                          onClick={() => window.open(this.state.orderDetail.return_labelURL, "_blank")}
                      ><i className="fa fa-print" aria-hidden="true"></i> Return Label
                      </button>
                    }
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="order-detail-left">
                      <div class="table-responsive">
                        <table class="table">
                          <tr>
                            <td>Order No.:</td>
                            <td>
                              #
                              {this.state.orderDetail.orderNumber
                                ? this.state.orderDetail.orderNumber
                                : this.state.orderDetail.id}
                            </td>
                          </tr>
                          <tr>
                            <td>Recipient:</td>
                            <td>
                              {this.state.shippingAddress.firstname
                                ? `${this.state.shippingAddress.firstname} ${this.state.shippingAddress.lastname}`
                                : `-`}
                            </td>
                          </tr>
                          <tr>
                            <td>Shipping Address:</td>
                            <td>
                              {this.state.shippingAddress.address
                                ? `${this.state.shippingAddress.address}, ${this.state.shippingAddress.city}, ${this.state.shippingAddress.state}, ${this.state.shippingAddress.country} - ${this.state.shippingAddress.zipCode}`
                                : `-`}
                            </td>
                          </tr>

                          <tr>
                            <td>Process Date:</td>
                            <td>{date ? date : `-`}</td>
                          </tr>
                          <tr>
                            <td>Order Status:</td>
                            {(() => {
                              if (!this.state.isEditForm) {
                                return (
                                  <td>
                                    {this.state.orderDetail.status
                                      ? this.state.orderDetail.status
                                      : `-`}
                                  </td>
                                );
                              } else {
                                return (
                                  <select
                                    onChange={(event) => {
                                      this.handleChange(event, "status");
                                    }}
                                    value={this.state.orderDetail.status}
                                    class="edit-input form-control"
                                  >
                                    <option value="Confirmed">Confirmed</option>
                                    <option value="Canceled">Canceled</option>
                                    <option value="Processing">Processing</option>
                                    <option value="Shipped">Shipped</option>
                                    <option value="Completed">Completed</option>
                                  </select>
                                );
                              }
                            })()}
                            {/* <td>{this.state.orderDetail.status ? this.state.orderDetail.status : `-`}</td> */}
                          </tr>
                          <tr>
                            <td>Payment Status:</td>
                            <td>
                              {this.state.orderDetail.paymentStatus
                                ? this.state.orderDetail.paymentStatus
                                : `-`}
                            </td>
                          </tr>
                          <tr>
                            <td>Recepits Voucher:</td>
                            <td>-</td>
                          </tr>
                          <tr>
                            <td>Tracking Company:</td>
                            {(() => {
                              if (!this.state.isEditForm) {
                                return (
                                  <td>
                                    {this.state.orderDetail.trackingCompany
                                      ? this.state.orderDetail.trackingCompany
                                      : `-`}
                                  </td>
                                );
                              } else {
                                return (
                                  <select
                                    onChange={(event) => {
                                      this.handleChange(
                                        event,
                                        "trackingCompany"
                                      );
                                    }}
                                    value={
                                      this.state.orderDetail.trackingCompany
                                    }
                                    class="edit-input form-control"
                                  >
                                    <option selected="true" disabled="disabled">
                                      Select a tracking company
                                    </option>
                                    <option value="USPS">USPS</option>
                                  </select>
                                );
                              }
                            })()}
                            <span className="form-field-error">
                              {this.state.errorData ?.trackingCompany &&
                                this.state.errorData.trackingCompany[0]}
                            </span>
                          </tr>
                          <tr>
                            <td>Send Tracking Number:</td>
                            <td>
                              {this.state.orderDetail.trackingId
                                  ?
                                  <a href={this.state.orderDetail.trackingURLProvider} target="_blank">{this.state.orderDetail.trackingId}</a>
                                  : `N/A`}
                            </td>
                          </tr>
                          <tr>
                            <td>Return Tracking Number:</td>
                            <td>
                              {this.state.orderDetail.return_trackingId
                                  ?
                                  <a href={this.state.orderDetail.return_trackingURLProvider} target="_blank">{this.state.orderDetail.return_trackingId}</a>
                                  : `N/A`}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="order-detail-right">
                      <div class="table-responsive">
                        <table class="table">
                          {
                            this.state.orderDetail?.items?.length === 1 &&
                            <tr>
                              <td>Item Price</td>
                              <td>{this.state.orderDetail ?.items ?.length > 0 ? `$${(this.state.orderDetail.items[0].pricePerUnit).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-"}</td>
                            </tr>
                          }
                          {
                            this.state.orderDetail?.items?.length === 1 &&
                            <tr>
                              <td>Quantity</td>
                              <td>{this.state.orderDetail ?.items ?.length > 0 ? `${this.state.orderDetail.items[0].orderedQuantity}` : "-"}</td>
                            </tr>
                          }
                          <tr>
                            <td>Subtotal</td>
                            <td>
                              {this.state.orderDetail.subTotal
                                ? `$${this.state.orderDetail.subTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>Shipping</td>
                            <td>-</td>
                          </tr>
                          {this.state.orderDetail.discount && +this.state.orderDetail.discount > 0 ?
                            <tr>
                              <td>Discount</td>
                              <td>{`-$${(+this.state.orderDetail.discount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                            </tr> : null}
                          <tr>
                            <td>Tax</td>
                            <td>-</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              <b>
                                {this.state.orderDetail.total
                                  ? `$${this.state.orderDetail.total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                  : "-"}
                              </b>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {/*{(() => {*/}
                {/*  if (*/}
                {/*    !this.state.isEditForm &&*/}
                {/*    this.state.orderDetail.trackingId*/}
                {/*  ) {*/}
                {/*    return (*/}
                {/*      <div className="text-right  mb-4">*/}
                {/*        <Link*/}
                {/*          to={`/orders/track/${this.state.orderDetail.id}`}*/}
                {/*          type="text"*/}
                {/*          class="orange-outline-btn"*/}
                {/*        >*/}
                {/*          <i class="far fa-edit"></i> Track this order*/}
                {/*          </Link>*/}
                {/*      </div>*/}
                {/*    );*/}
                {/*  }*/}
                {/*})()}*/}

              </div>
              <div class="order-detail-right">
                <div class="table-responsive">
                  <table class="table">
                    <tr>
                      <td>Accugenedx Commission ({this.state.orderDetail ?.admin_commission_percent ? `${this.state.orderDetail.admin_commission_percent}%` : "-"})</td>
                      <td>{this.state.orderDetail ?.admin_commission_percent && this.state.orderDetail.total ? `$${((this.state.orderDetail.total * this.state.orderDetail.admin_commission_percent) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-"}</td>
                    </tr>
                    <tr>
                      <td>Transaction Fee ({this.state.orderDetail ?.transaction_fee_percent ? `${this.state.orderDetail.transaction_fee_percent}%` : "-"})</td>
                      <td>{this.state.orderDetail ?.transaction_fee_percent && this.state.orderDetail.total ? `$${((this.state.orderDetail.total * this.state.orderDetail.transaction_fee_percent) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-"}</td>
                    </tr>
                    <tr>
                      <td>Additional Transaction Fee</td>
                      <td>{this.state.orderDetail.additional_transaction_fee ? `$${this.state.orderDetail.additional_transaction_fee.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-"}</td>
                    </tr>
                    <tr>
                      <td><b>Total Subtractable Amount</b></td>
                      <td><b>{this.state.orderDetail.subtractable_amount ? `$${this.state.orderDetail.subtractable_amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-"}</b></td>
                    </tr>
                    <tr>
                      <td><b>Vendor Receivable Amount</b></td>
                      <td><b>{this.state.orderDetail.total && this.state.orderDetail.subtractable_amount ? `$${(this.state.orderDetail.total - this.state.orderDetail.subtractable_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-"}</b></td>
                    </tr>
                  </table>
                </div>
              </div>
              {
                this.state.orderDetail.rating &&
                <div className="row mt-2">
                  <div className="col-md-12">
                    <h4>Feedback</h4>
                    <div>
                      <Rating
                          ratingValue={this.state.orderDetail.rating}
                          allowHalfIcon={true}
                          showTooltip={false}
                          readonly={true}
                      />
                    </div>
                  </div>
                  <div className={"col-md-6 mt-3"}>
                    <textarea className={'form-control'}
                              readOnly={false}
                              value={this.state.orderDetail.feedback_text}
                              disabled={true}
                    ></textarea>
                  </div>
                </div>
              }
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getOrderDetail, updateOrderDetail, getOrderTracking },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Order_Detail);
