import React, { Component } from 'react'
import "../../Styles/order-detail.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getEmailTemplateDetail, updateEmailTemplate } from '../../Redux/Actions/emailTemplateAction';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import "./emailTemplate.scss";

class EditEmailTemplate extends Component {

    generatedContentTemplate = "";

    constructor(props) {
        super(props)

        this.state = {
            emailTemplateDetail: {
                subject: '',
                body: '',
                emailTokens: []
            },
            isEditForm: false,
            errorData: {},
            showLoader: false
        };

        this.modules = {
            toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ size: [] }],
                [{ 'color': [] }, { 'background': [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                    { 'indent': '-1' }, { 'indent': '+1' }],
                ['clean']
            ],
            clipboard: {
                matchVisual: false
            },
        };

        this.formats = [
            'header', 'font', 'size', 'color',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            // 'link', 'image', 'video'
        ];

        this.rteChange = this.rteChange.bind(this);
    }

    componentDidMount() {
        this.fetchPageDetail();
    }

    fetchPageDetail = async () => {
        this.setState({
            showLoader: true
        })
        const result = await this.props.getEmailTemplateDetail(this.props.match.params.templateId);
        if (result ?.data ?.data) {
            this.setState({
                emailTemplateDetail: result.data.data,
                showLoader: false
            })
        }
    }

    onEdit = () => {
        this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
        this.setState({
            isEditForm: true
        })
    }

    rteChange = (content, delta, source, editor) => {
        this.setState(prevState => {
            return (
                prevState.emailTemplateDetail["body"] = editor.getHTML()
            )
        });
    }

    handleChange = (event, field) => {
        if (this.state.errorData && this.state.errorData[field] && this.state.errorData[field][0]) {
            this.state.errorData[field][0] = null;
        }

        event.persist();
        this.setState(prevState => {
            return (
                prevState.emailTemplateDetail[field] = event.target.value
            )
        });
    }

    validateInfo = () => {
        let isFormValid = true;
        this.state.errorData = {
            "subject": [],
            "body": []
        }

        if (!this.state.emailTemplateDetail.subject || this.state.emailTemplateDetail.subject.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.subject = ["Subject cannot be empty"]
        }

        if (
            !this.state.emailTemplateDetail.body ||
            this.state.emailTemplateDetail.body.trim().length === 0 ||
            !this.state.emailTemplateDetail.body.trim().replace(/<[^>]*>?/gm, '')
        ) {
            isFormValid = false;
            this.state.errorData.body = ["Body cannot be empty"]
        }

        let tempState = this.state;

        this.setState({
            ...tempState
        })

        return isFormValid;
    };

    onSaveForm = async () => {

        if (!this.validateInfo()) {
           return ;
        }

        this.setState({
            isEditForm: false,
            showLoader: true
        });

        const res = await this.props.updateEmailTemplate(this.state.emailTemplateDetail);
        if (res && res.data && !res.data.isError) {
            this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
            this.setState({
                errorData: {},
                showLoader: false
            });
        }
        else {
            this.setState({
                ...this.statePrevCopy,
                errorData: {
                    message: 'Something went wrong. Please try again.'
                },
                showLoader: false
            })
        }

    }

    onCancel = async () => {
        this.setState({
            ...this.statePrevCopy
        })
    }


    render() {
        return (
            <div class="admin-card email-template">
                <div class="order-heading">

                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <h2 class="mb-0">Email Template</h2>
                            {/* <p>Check your page details.</p> */}
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="order-edit">
                                {(() => {
                                    if (!this.state.isEditForm) {
                                        return (
                                            <button onClick={this.onEdit} type="text" class="orange-outline-btn">
                                                <i class="far fa-edit"></i> Update
                                            </button>
                                        )
                                    }
                                    else {
                                        return (
                                            <>
                                                <button onClick={this.onSaveForm} type="text" class="orange-outline-btn">
                                                    <i class="fa fa-check"></i> Save
                                                </button>
                                                <button onClick={this.onCancel} type="text" class="danger-outline-btn ml-2">
                                                    <i class="fa fa-times"></i> Cancel
                                                </button>
                                            </>
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                    </div>


                </div>
                <div className={'row'}>
                    <div className="admin-card-box col-md-8">
                        <section className="track-section">
                            <div className="container-full">
                                {this.state.showLoader ? (
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (null)}
                                {(() => {
                                    if (this.state.errorData.message) {
                                        return <div className="alert alert-danger">{this.state.errorData.message}</div>
                                    }
                                })()}
                                <form>
                                    <div className="form-group">
                                        <label>Subject</label>
                                        {(() => {
                                            return (
                                                <>
                                                    <input type="text"
                                                           onChange={(event) => this.handleChange(event, "subject")}
                                                           value={this.state.emailTemplateDetail.subject}
                                                           className="form-control"
                                                           id="pageTitleInput"
                                                           disabled={!this.state.isEditForm}
                                                    />
                                                    <span style={{color: "red", fontSize: "12px"}}
                                                          className="form-field-error">
                                                    {this.state.errorData?.subject &&
                                                    this.state.errorData.subject[0]}
                                                </span>
                                                </>
                                            )
                                        })()}

                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="pageContentTextarea">Body</label>
                                        {(() => {
                                            return (
                                                <>
                                                    <ReactQuill theme="snow" modules={this.modules}
                                                                readOnly={!this.state.isEditForm}
                                                                formats={this.formats} onChange={this.rteChange}
                                                                value={this.state.emailTemplateDetail.body || ''}
                                                                placeholder="Write something..."/>
                                                    <span style={{color: "red", fontSize: "12px"}}
                                                          className="form-field-error">
                                                    {this.state.errorData?.body &&
                                                    this.state.errorData.body[0]}
                                                </span>
                                                </>
                                            )
                                        })()}

                                    </div>
                                </form>

                            </div>
                        </section>
                    </div>
                    <div className="admin-card-box col-md-4">
                        {
                            this.state.emailTemplateDetail.emailTokens.length === 0?
                                <div className={'text-center'}>
                                    <b>No Tokens available for this email template</b>
                                </div>:
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <td><b>Token</b></td>
                                        <td><b>Description</b></td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.emailTemplateDetail.emailTokens.map((emailToken) => (
                                            <tr>
                                                <td>{emailToken.token}</td>
                                                <td>{emailToken.description}</td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>

                                </table>
                        }

                    </div>
                </div>

            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getEmailTemplateDetail, updateEmailTemplate }, dispatch);
}

export default connect(null, mapDispatchToProps)(EditEmailTemplate)
