import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getAllFAQs, searchFAQs, deleteFAQ } from '../../Redux/Actions/helpAction';
import {debounce} from "lodash";
import AddFAQ from './add-faq';

class HelpPages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allFAQs:[],
            loadFAQs: false,
            offset: 0,
            prevOffset: 0,
            maxLimit: 10,
            hasMore: true,
            searchKeyword: "",
            showAddNewModal: "",
            faqData: null,
            faqEdit: false,
            removeFAQ: false,
            deleteProcess: false,
            faqId: null,
        };
    }

    async componentDidMount() {
        this.fetchMoreData(true);
    }

    fetchMoreData = async (firstLoad) => {
        this.setState({
            loadFAQs: true
        });

        if (this.state.offset !== this.state.prevOffset || firstLoad) {
            if (this.state.hasMore && this.state.maxLimit && (this.state.maxLimit === "all" || this.state.maxLimit > this.state.offset)) {
                let result;
                if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
                    result = await this.props.searchFAQs(this.state.offset, this.state.searchKeyword.trim());
                }
                else {
                    result = await this.props.getAllFAQs(this.state.offset);
                }

                if (result ?.data ?.data ?.length > 0) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            prevOffset: prevState.offset,
                            offset: prevState.offset + 10,
                            allFAQs: prevState.allFAQs.concat(result.data.data),
                            loadFAQs: false
                        }
                    })
                }
                else {
                    this.setState({ hasMore: false, loadFAQs: false });
                }
            } else {
                this.setState({
                    loadFAQs: false
                })
            }
        } else {
            this.setState({
                loadFAQs: false
            })
        }

    };

    handleChange = async (event, field) => {
        event.preventDefault();
        if (field === "maxLimit") {
            await this.setState({
                [field]: event.target.value === "all" ? event.target.value : +event.target.value
            });
            this.fetchMoreData(false);
        }
        else {
            await this.setState({
                [field]: event.target.value,
                offset: 0,
                prevOffset: 0,
                hasMore: true
            });

            this.debounceSearchCall();
        }
    };

    debounceSearchCall = debounce(() => {
        this.setState({
            allFAQs: [],
        });
        this.fetchMoreData(true);
    }, 1000);

    prepareFAQEdit = (faqId) => {
        this.setState({
            showAddNewModal: true,
            faqEdit: true,
            faqData: this.state.allFAQs.find(faq => faq.id === faqId)
        });
    };

    showAddNewFAQ = () => {
        this.setState({
            showAddNewModal: true,
        });
    };

    handleCloseAddFAQ = () => {
        this.setState({
            showAddNewModal: false,
            faqData: null,
            faqEdit: false
        });
    };

    reloadFAQs = () => {
        this.setState({
            offset: 0,
            prevOffset: 0,
            hasMore: true,
            deleteProcess: false,
            faqId: null,
        });
        this.handleCloseAddFAQ();

        this.debounceSearchCall();
    };

    deleteFAQ = (faqId) => {
        this.setState({
            removeFAQ: true,
            faqId
        })
    };

    closeModalCancelFAQ = () => {
        this.setState({
            removeFAQ: false,
            deleteProcess: false,
            faqId: null,
        });
    };

    processDeleteFAQ = async () => {
        this.closeModalCancelFAQ();
        this.setState({
            deleteProcess: true,
        });
        await this.props.deleteFAQ(this.state.faqId);

        this.reloadFAQs();
    };

    render() {

        const {
            allFAQs,
            loadFAQs,
            showAddNewModal,
            faqEdit,
            faqData,
            removeFAQ,
            deleteProcess,
        } = this.state;

        const faqList = allFAQs?.map((faqObj, index) => {
            return (
                <tr key={`${faqObj.id}-${index}`}>
                    <td>{faqObj.category? JSON.parse(faqObj.category).join(', '): ''}</td>
                    <td className={'heading'}>{faqObj.heading}</td>
                    <td>
                        <div className="vendor-table-btn">
                            <button
                                type="button"
                                className="danger-outline-btn mr-2"
                                onClick={() => this.deleteFAQ(faqObj.id)}
                            >
                                Delete
                            </button>

                            <button
                                type="button"
                                className="orange-outline-btn"
                                onClick={() => this.prepareFAQEdit(faqObj.id)}
                            >
                                Edit
                            </button>
                        </div>
                    </td>
                </tr>
            );
        });

        return (
            <div className="admin-card">
                {
                    !showAddNewModal && <>
                        <div className="admin-card-head d-flex justify-content-end mb-2">
                            <button type="text" className="orange-outline-btn" onClick={this.showAddNewFAQ}>
                                <i className="fas fa-plus"></i> Add FAQ
                            </button>
                        </div>
                        <div className="admin-card-box">
                            <div className="vendor-table">
                                <div className="vendor-table-head">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4">
                                            <div className="entries-drop">
                                                <label>show</label>
                                                <select className="entries-controls"
                                                        onChange={(e) => this.handleChange(e, "maxLimit")}
                                                        value={this.state.maxLimit}>
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="all">All</option>
                                                </select>
                                                <p>Entries</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8">
                                            <div className="vendor-table-head-right">
                                                <div className="vendor-table-search">
                                                    <input className="form-control"
                                                           onChange={(e) => this.handleChange(e, "searchKeyword")}
                                                           value={this.state.searchKeyword} type="text" placeholder="search"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="vendor-table-body">
                                    <div className="table-responsive">
                                        {
                                            !showAddNewModal && <InfiniteScroll
                                                dataLength={allFAQs.length}
                                                next={() => this.fetchMoreData(false)}
                                                hasMore={this.state.hasMore}
                                            >
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                        <th>Category</th>
                                                        <th className={'w-50'}>Heading</th>
                                                        <th className={'action'}>Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody> {faqList} </tbody>
                                                </table>
                                            </InfiniteScroll>
                                        }

                                        {(loadFAQs || deleteProcess) ? (
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (null)}


                                    </div>
                                </div>
                            </div>
                        </div>

                        {removeFAQ ? (
                            <div
                                className="modal"
                                id="removeItem"
                                style={{ display: "block" }}
                            >
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">
                                                {" "}
                                                Are you sure you want to remove this FAQ?
                                            </h4>
                                            <button
                                                type="button"
                                                class="close"
                                                data-dismiss="modal"
                                                onClick={this.closeModalCancelFAQ}
                                            >
                                                <span>
                                                  <i class="far fa-times-circle"></i>
                                                </span>
                                            </button>
                                        </div>

                                        <div className="modal-body" style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                            <button
                                                className="orange-outline-btn"
                                                onClick={(e) => {
                                                    this.processDeleteFAQ();
                                                }}
                                            >
                                                Yes
                                            </button>
                                            <button
                                                className="danger-outline-btn"
                                                onClick={this.closeModalCancelFAQ}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                    </>
                }
                {
                    showAddNewModal &&
                       <div>
                            <AddFAQ
                                faqData={faqData}
                                faqEdit={faqEdit}
                                handleCloseAddFAQ={this.handleCloseAddFAQ}
                                reloadFAQs={this.reloadFAQs}
                            />
                       </div>
                }

            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getAllFAQs, searchFAQs, deleteFAQ }, dispatch);
};

export default connect(null, mapDispatchToProps)(HelpPages)
