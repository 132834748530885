import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {
    getAllAppointments,
    changeAppointmentStatus,
    changeAllAppointmentStatus
} from "../../Redux/Actions/covidAction";
import moment from "moment";
import {debounce} from "lodash";

const CovidLocationAppointmentList = (props) => {
    const dispatch = useDispatch();
    const { selectedLocationId } = props;

    const [loadingAppointments, setLoadingAppointments] = useState(false);
    const [appointmentList, setAppointmentList] = useState([]);
    const [selectedAppointment, setSelectedAppointment] = useState(0);
    const [appointmentDateFilter, setAppointmentDateFilter] = useState(moment().format('YYYY-MM-DD'));
    const [searchText, setSearchText] = useState('');
    const [appointmentId, setAppointmentId] = useState(null);
    const [action, setAction] = useState('');
    const [allActionModal, setAllActionModal] = useState(false);
    const [changingAppointmentStatus, setChangingAppointmentStatus] = useState(false);

    useEffect(() => {
        (async () => {
            await fetchAppointmentList(appointmentDateFilter, searchText);
        })();
    }, []);

    const fetchAppointmentList = async (filterDate, searchText) => {
        setLoadingAppointments(true);
        const appointmentResponse = await dispatch(getAllAppointments(selectedLocationId, filterDate, searchText));
        setLoadingAppointments(false);
        if(!appointmentResponse.isSuccess) {
            alert('Error fetching covid locations. Please try again.');
            return;
        }
        setAppointmentList(appointmentResponse.data);
    };

    const disableAppointment = (appointmentId) => {
        setAppointmentId(appointmentId);
        setAction('disable');
    };

    const enableAppointment = (appointmentId) => {
        setAppointmentId(appointmentId);
        setAction('enable');
    };

    const renderLocationAppointments = () => {
        if(appointmentList.length === 0) {
            return (
                <tr>
                    <td colspan={5} className={'text-center'}>
                        <span>There are no appointments found</span>
                    </td>
                </tr>
            )
        }

        return appointmentList?.map((appointment) => {
            return (
                <tr key={`${appointment.id}`} className={selectedAppointment == appointment.id ? 'selected-row': ''}
                    onClick={() => setSelectedAppointment(appointment.id)}>
                    <td>{moment(new Date(appointment.date)).format('YYYY-DD-MM')}</td>
                    <td>{appointment.start}</td>
                    <td>{appointment.end}</td>
                    <td>
                        {
                            appointment.available === 0?
                            <span className={'booked'}>Booked</span>:
                                appointment.available === 1?
                                    <span className={'available'}>Available</span>:
                                        appointment.available === 2?
                                            <span className={'reserved'}>Reserved</span>:
                                            appointment.available === 3?
                                                <span className={'disabled'}>Disabled</span>:
                                                    <span>Unknown</span>
                        }
                    </td>
                    <td>
                        <div className="vendor-table-btn">
                            {
                                appointment.available === 3 &&
                                    <button
                                        className="orange-outline-btn"
                                        onClick={() => enableAppointment(appointment.id)}
                                    >
                                        Enable
                                    </button>
                            }
                            {
                                appointment.available === 1 &&
                                <button
                                    className="orange ml-2"
                                    onClick={() => disableAppointment(appointment.id)}
                                >
                                    Disable
                                </button>
                            }
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const onChangeAppointmentDateFilter = async (date) => {
        setAppointmentDateFilter(date);
        await fetchAppointmentList(date, searchText);
    };

    const debounceSearchCall = useCallback(debounce(fetchAppointmentList, 1000), []);

    const handleAppointmentSearch = async (event) => {
        const searchValue = event.target.value;
        setSearchText(searchValue);
        debounceSearchCall(appointmentDateFilter, searchValue);
    };

    const closeAppointmentAction = () => {
        setAppointmentId(null);
        setAction(null);
        setAllActionModal(false);
    };

    const handleAllAction = (action) => {
        setAllActionModal(true);
        setAction(action);
    };

    const processAppointmentAction = async () => {
        const id = appointmentId;
        setChangingAppointmentStatus(true);
        const statusResponse = await dispatch(changeAppointmentStatus(id, {locationId: selectedLocationId, status: action}));
        setChangingAppointmentStatus(false);
        closeAppointmentAction();
        if(!statusResponse.isSuccess) {
            alert('Error updating the appointment status. Please try again.');
            return;
        }
        setAppointmentList(prev => {
            return prev.map((item) => {
                if(item.id === id) {
                    item.available = statusResponse.data.available
                }
                return item;
            });
        })
    };

    const processAllAppointmentAction = async () => {
        setChangingAppointmentStatus(true);
        const body = {selectedDate: appointmentDateFilter, locationId: selectedLocationId, status: action};
        const statusResponse = await dispatch(changeAllAppointmentStatus(body));
        if(!statusResponse.isSuccess) {
            alert('Error updating the appointment status. Please try again.');
        } else {
            await fetchAppointmentList(appointmentDateFilter, searchText);
        }
        setChangingAppointmentStatus(false);
        closeAppointmentAction();
    };

    return (
        <div className="admin-card">
            <div className="row mt-2">
                <div className="col-md-12">
                    <div className="admin-card-head d-flex justify-content-end mb-2">
                        <div>
                            <button
                                className="orange-outline-btn mr-3"
                                onClick={() => handleAllAction('enable')}
                            >
                                Enable All
                            </button>
                        </div>
                        <div>
                            <button
                                className="danger-outline-btn"
                                onClick={() => handleAllAction('disable')}
                            >
                                Disable All
                            </button>
                        </div>
                    </div>
                    <div className="admin-card-head d-flex justify-content-between mb-2">
                        <div>
                            <span className={'mr-2'}>Filter</span>
                            <input type="date"
                                   name="appointment_date"
                                   value={appointmentDateFilter}
                                   onChange={(e) => onChangeAppointmentDateFilter(e.target.value)}
                            />
                        </div>
                        <div className="search w-50">
                            <input className="form-control"
                                   type="text"
                                   onChange={(e) => handleAppointmentSearch(e)}
                                   placeholder="search"
                            />
                        </div>
                    </div>
                    <div className="vendor-table" style={{height: '58vh', 'overflow-y': 'auto'}}>
                        <div className="vendor-table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th style={{'width': '22%'}}>Date</th>
                                        <th style={{'width': '15%'}}>Start</th>
                                        <th style={{'width': '15%'}}>End</th>
                                        <th style={{'width': '20%'}}>Availability</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        loadingAppointments? (
                                            <tr>
                                                <td colSpan={6} className={'text-center'}>
                                                <span className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </span>
                                                </td>
                                            </tr>
                                        ) : renderLocationAppointments()
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {appointmentId ? (
                <div
                    className="modal"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">
                                    {" "}
                                    {`Are you sure you want to ${action} this appointment?`}
                                </h4>
                                <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    onClick={closeAppointmentAction}
                                >
                                    <span>
                                      <i class="far fa-times-circle"></i>
                                    </span>
                                </button>
                            </div>

                            <div className="modal-body" style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <button
                                    className="orange-outline-btn"
                                    onClick={processAppointmentAction}
                                    disabled={changingAppointmentStatus}
                                >
                                    Yes
                                    {
                                        changingAppointmentStatus &&
                                        <span className="spinner-border ml-2" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </span>
                                    }
                                </button>
                                <button
                                    className="danger-outline-btn"
                                    onClick={closeAppointmentAction}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {allActionModal ? (
                <div
                    className="modal"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">
                                    {" "}
                                    {`Are you sure you want to ${action} all the appointments?`}
                                </h4>
                                <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    onClick={closeAppointmentAction}
                                >
                                    <span>
                                      <i class="far fa-times-circle"></i>
                                    </span>
                                </button>
                            </div>

                            <div className="modal-body" style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <button
                                    className="orange-outline-btn"
                                    onClick={processAllAppointmentAction}
                                    disabled={changingAppointmentStatus}
                                >
                                    Yes
                                    {
                                        changingAppointmentStatus &&
                                        <span className="spinner-border ml-2" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </span>
                                    }
                                </button>
                                <button
                                    className="danger-outline-btn"
                                    onClick={closeAppointmentAction}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
};

export default CovidLocationAppointmentList;
