import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createCoupon, getCouponDetail, updateCouponDetail } from '../../Redux/Actions/couponAction';
import { getAllProducts } from '../../Redux/Actions/productCategoryAction';

class Coupon extends Component {

    constructor(props) {
        super(props);

        this.state = {
            couponId: "",
            couponName: "",
            couponCode: "",
            couponType: "%",
            discount: "",
            minimumOrderAmount: "",
            couponValidity: "",
            level: "",
            productId: "",
            allProducts: [],
            minimumDate: "",
            couponDescription: "",
            isEdit: false,
            successMessage: '',
            errorMessage: '',
            errorData: {},
            showLoader: false,
            pageLoading: false,
        }

        if (this.props.match.path === "/edit-coupon/:couponId") {
            // this.props.match.params.couponId;
            this.fetchCouponDetail(this.props.match.params.couponId);
        }

    }

    fetchCouponDetail = async (couponId) => {
        this.setState({
            pageLoading: true
        });
        let couponDetail = await this.props.getCouponDetail(couponId);

        if (couponDetail ?.data ?.data) {
            // product found
            couponDetail = couponDetail.data.data;

            this.setState({
                couponId: couponDetail.id,
                couponName: couponDetail.name,
                couponCode: couponDetail.code,
                couponType: couponDetail.type,
                level: couponDetail.level,
                productId: couponDetail.productId,
                discount: couponDetail.discount.toString(),
                minimumOrderAmount: couponDetail.minOrderAmount.toString(),
                couponValidity: couponDetail.validUptoTS,
                couponDescription: couponDetail.description,
                isEdit: true,
            })
        }
        else {
            // coupon not found
            // redirect user to coupon page
            this.props.history.push("/coupon");
        }
        this.setState({
            pageLoading: false
        });
    }

    async componentDidMount() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        console.log("[year, month, day].join('-')", typeof [year, month, day].join('-'));

        const allProducts = await this.props.getAllProducts();
        this.setState({
            minimumDate: [year, month, day].join('-'),
            allProducts: allProducts.data?.data
        })
    }

    handleChange = (e, key) => {
        if (this.state.errorData && this.state.errorData[key] && this.state.errorData[key][0]) {
            this.state.errorData[key][0] = null;
        }

        let { value } = e.target;

        if (key === "couponCode") {
            value = value.toUpperCase();
        }

        this.setState(prevState => ({
            ...prevState,
            [key]: value
        }))
    }


    validateDiscountAmount = () => {
        let isFormValid = true;
        this.state.errorData = {
            discount: []
        }

        if (this.state.couponType.trim() === "$" && +this.state.discount.trim() > +this.state.minimumOrderAmount.trim()) {
            isFormValid = false;
            this.state.errorData.discount = ["Discount amount cannot be greater than minimum order amount"];
        }

        if (this.state.couponType.trim() === "%" && +this.state.discount.trim() > 100) {
            isFormValid = false;
            this.state.errorData.discount = ["Discount % cannot be greater than 100%"];
        }

        let tempState = this.state;
        this.setState({
            ...tempState
        });

        return isFormValid;

    }


    validateCouponInfo = () => {
        let isFormValid = true;
        this.state.errorData = {
            couponName: [],
            couponCode: [],
            couponType: [],
            discount: [],
            minimumOrderAmount: [],
            couponValidity: [],
            level: [],
            productId: [],
            couponDescription: []
        }

        if (this.state.couponName.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.couponName = ["Coupon Name cannot be empty."]
        }

        if (this.state.couponCode.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.couponCode = ["Coupon Code cannot be empty."]
        }

        if (this.state.couponType.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.couponType = ["Coupon Type cannot be empty."]
        }
        else if (this.state.couponType.trim().length > 0 && this.state.couponType.trim() != "%" && this.state.couponType.trim() != "$") {
            isFormValid = false;
            this.state.errorData.couponType = ["Coupon Type can either be '$' or '%'"]
        }

        if (this.state.discount.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.discount = ["Discount Value cannot be empty."]
        }
        else if (this.state.discount.trim().length > 0 && isNaN(+this.state.discount.trim())) {
            isFormValid = false;
            this.state.errorData.discount = ["Enter a valid discount."]
        }

        if (this.state.minimumOrderAmount.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.minimumOrderAmount = ["Minimum Order Amount cannot be empty."]
        }
        else if (this.state.minimumOrderAmount.trim().length > 0 && isNaN(+this.state.minimumOrderAmount.trim())) {
            isFormValid = false;
            this.state.errorData.minimumOrderAmount = ["Enter a valid minimum amount."]
        }

        if (this.state.couponValidity.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.couponValidity = ["Valid Upto field cannot be empty."]
        }

        if (this.state.level.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.level = ["Level cannot be empty."]
        }

        if (
            this.state.level.trim().length &&
            this.state.level === 'Product' &&
            (!this.state.productId || this.state.productId.toString().trim().length === 0)
        ) {
            isFormValid = false;
            this.state.errorData.productId = ["Product cannot be empty."]
        }

        if (this.state.couponDescription.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.couponDescription = ["Coupon Description field cannot be empty."]
        }

        let tempState = this.state;
        this.setState({
            ...tempState
        })

        return isFormValid;

    }


    handleSubmit = async () => {
        await this.setState({
            successMessage: '',
            errorMessage: '',
            showLoader: true
        });

        if (this.validateCouponInfo()) {
            // validations passed
            if (this.validateDiscountAmount()) {
                // discount validations passed
                let res;
                const { couponName, couponCode, couponType, couponDescription, discount, minimumOrderAmount, couponValidity, level, productId } = this.state;

                if (this.state.isEdit) {
                    // call update action
                    res = await this.props.updateCouponDetail(
                        { couponName, couponCode, couponType, couponDescription, discount, minimumOrderAmount, couponValidity, level, productId },
                        this.state.couponId
                    )
                }
                else {
                    // call create action
                    res = await this.props.createCoupon(
                        { couponName, couponCode, couponType, couponDescription, discount, minimumOrderAmount, couponValidity, level, productId }
                    )
                }

                if (res && res.data) {
                    if (res.data.isSuccess) {
                        // success case
                        this.setState({
                            successMessage: res.data.message,
                            showLoader: false
                        })

                        window.scrollTo(0, 0);

                        if (this.state.isEdit) {
                            setTimeout(() => {
                                this.setState({
                                    successMessage: ''
                                })
                            }, 4000);
                        }
                        else {

                            this.setState({
                                couponName: "",
                                couponCode: "",
                                discount: "",
                                minimumOrderAmount: "",
                                couponValidity: "",
                                level: "",
                                productId: "",
                                minimumDate: "",
                                couponDescription: "",
                            })

                            setTimeout(() => {
                                this.setState({
                                    successMessage: ''
                                })
                            }, 4000);
                        }

                    }
                    else {
                        // response with failed case
                        this.setState({
                            errorMessage: res.data.message,
                            showLoader: false
                        })
                    }
                }
                else {
                    // error to get respone
                    this.setState({
                        errorMessage: "Something went wrong. Please try again later.",
                        showLoader: false
                    })
                }
            }
            else {
                // discount validations failed
                this.setState({
                    showLoader: false
                });
            }
        }
        else {
            // validations failed
            this.setState({
                showLoader: false
            });
        }
    }

    render() {
        return (
            <div class="admin-card">
                <div class="admin-card-head">
                    {/* <h2>Create Coupon</h2> */}
                    <h2>{this.props.match.params.couponId ? "Edit" : "Create"} Coupon</h2>
                </div>
                {this.state.pageLoading ? (
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (null)}
                <div class="admin-card-box">
                    {(() => {
                        if (this.state.successMessage !== '' && this.state.successMessage !== undefined) {
                            return <div className="alert alert-success">{this.state.successMessage}</div>
                        } else if (this.state.errorMessage !== '' && this.state.errorMessage !== undefined) {
                            return <div className="alert alert-danger">{this.state.errorMessage}</div>
                        }
                    })()}
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>coupon name
                                    <sup>*</sup>
                                </label>
                                <input value={this.state.couponName} onChange={(e) => this.handleChange(e, "couponName")} type="text" class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData ?.couponName &&
                                        this.state.errorData.couponName[0]}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>coupon code
                                    <sup>*</sup>
                                </label>
                                <input value={this.state.couponCode} onChange={(e) => this.handleChange(e, "couponCode")} type="text" class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData ?.couponCode &&
                                        this.state.errorData.couponCode[0]}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>type
                                    <sup>*</sup>
                                </label>
                                <input disabled={true} value={this.state.couponType} onChange={(e) => this.handleChange(e, "couponType")} type="text" class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData ?.couponType &&
                                        this.state.errorData.couponType[0]}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>discount value
                                    <sup>*</sup>
                                </label>
                                <input value={this.state.discount} onChange={(e) => this.handleChange(e, "discount")} type="text" class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData ?.discount &&
                                        this.state.errorData.discount[0]}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3">
                            <div class="form-group">
                                <label>Minimum Order Amount
                                    <sup>*</sup>
                                </label>
                                <input value={this.state.minimumOrderAmount} onChange={(e) => this.handleChange(e, "minimumOrderAmount")} type="text" class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData ?.minimumOrderAmount &&
                                        this.state.errorData.minimumOrderAmount[0]}
                                </span>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-3">
                            <div class="form-group">
                                <label>Valid Upto
                                    <sup>*</sup>
                                </label>
                                <input value={this.state.couponValidity} onChange={(e) => this.handleChange(e, "couponValidity")} type="date" min={this.state.minimumDate} class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData ?.couponValidity &&
                                        this.state.errorData.couponValidity[0]}
                                </span>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-3">
                            <div class="form-group">
                                <label>Level
                                    <sup>*</sup>
                                </label>
                                <select className="form-control"
                                        onChange={(e) => this.handleChange(e, "level")}
                                        value={this.state.level}>
                                    <option value="" disabled selected>--Select--</option>
                                    <option value="Global">Global</option>
                                    <option value="Product">Product</option>
                                </select>
                                <span className="form-field-error">
                                    {this.state.errorData?.level &&
                                        this.state.errorData.level[0]}
                                </span>
                            </div>
                        </div>

                        {
                            this.state.level === 'Product' &&
                            <div className="col-lg-3 col-md-3">
                                <div className="form-group">
                                    <label>Product
                                        <sup>*</sup>
                                    </label>
                                    <select className="form-control"
                                            onChange={(e) => this.handleChange(e, "productId")}
                                            value={this.state.productId}>
                                        <option value="" disabled selected>--Select--</option>
                                        {
                                            this.state.allProducts?.map((product) => <option key={product.id}
                                                                                             value={product.id}> {product.name} </option>)
                                        }
                                    </select>
                                    <span className="form-field-error">
                                    {this.state.errorData?.productId &&
                                    this.state.errorData.productId[0]}
                                </span>
                                </div>
                            </div>
                        }


                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Description
                                    <sup>*</sup>
                                </label>
                                <textarea value={this.state.couponDescription}
                                       onChange={(e) => this.handleChange(e, "couponDescription")}
                                       type="text"
                                       class="form-control"
                                ></textarea>
                                <span className="form-field-error">
                                    {this.state.errorData ?.couponDescription &&
                                        this.state.errorData.couponDescription[0]}
                                </span>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-btn text-center">
                                <button type="button" onClick={this.handleSubmit} class="orange-outline-btn">submit
                                {(() => {
                                        if (this.state ?.showLoader) {
                                            return <span class="spinner-border spinner-border-sm ml-1"></span>
                                        }
                                    })()}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        createCoupon,
        getCouponDetail,
        updateCouponDetail,
        getAllProducts
    }, dispatch)
}

export default connect(null, mapDispatchToProps)(Coupon)
