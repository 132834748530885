import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce } from 'lodash';

import { getEmailTemplateList } from '../../Redux/Actions/emailTemplateAction';

class EmailTemplates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            maxLimit: 20,
            offset: 0,
            prevOffset: 0,
            items: [],
            searchKeyword: "",
            hasMore: true,
            showLoader: false
        };
    }

    componentDidMount() {
        this.fetchMoreData(true);
    }

    fetchMoreData = async (firstLoad) => {
        this.setState({
            showLoader: true
        })
        if (this.state.offset !== this.state.prevOffset || firstLoad) {
            if (this.state.hasMore && this.state.maxLimit && (this.state.maxLimit === "all" || this.state.maxLimit > this.state.offset)) {
                let result = await this.props.getEmailTemplateList(this.state.offset);

                // let result = await this.props.getUsersList(this.state.offset);
                if (result ?.data ?.data ?.length > 0) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            prevOffset: prevState.offset,
                            offset: prevState.offset + 20,
                            items: prevState.items.concat(result.data.data),
                            showLoader: false
                        }
                    })
                }
                else {
                    this.setState({ hasMore: false, showLoader: false });
                }
            } else {
                this.setState({
                    showLoader: false
                })
            }
        } else {
            this.setState({
                showLoader: false
            })
        }
    };

    handleChange = async (event, field) => {
        event.preventDefault();
        if (field === "maxLimit") {
            await this.setState({
                [field]: event.target.value === "all" ? event.target.value : +event.target.value
            })
            this.fetchMoreData(false);
        }
    };

    render() {

        const emailTemplateList = this.state.items.map((i, index) => {
            return (
                <tr key={`${index}-${i.id}`}>
                    <td>{i.type}</td>
                    <td>{i.subject}</td>
                    <td align="center">
                        <div class="vendor-table-btn">
                            <Link to={`edit-email-template/${i.id}`}>
                                <button type="button" class="orange-outline-btn">Edit
                                </button>
                            </Link>
                        </div>
                    </td>
                </tr>
            )
        })

        return (
            <div class="admin-card">
                <div class="admin-card-head">
                    <h2>Email Templates</h2>
                </div>

                <div class="admin-card-box">
                    <div class="vendor-table">

                        <div class="vendor-table-head">
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="entries-drop">
                                        <label>show</label>
                                        <select class="entries-controls" onChange={(e) => this.handleChange(e, "maxLimit")} value={this.state.maxLimit}>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="all">All</option>
                                        </select>
                                        <p>Entries</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="vendor-table-body">
                            <div class="table-responsive">
                                <InfiniteScroll
                                    dataLength={this.state.items.length}
                                    next={() => this.fetchMoreData(false)}
                                    hasMore={this.state.hasMore}
                                >
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <td><b>Type</b></td>
                                            <td><b>Subject</b></td>
                                            <td align="center"><b>Action</b></td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {emailTemplateList}
                                        </tbody>

                                    </table>
                                </InfiniteScroll>
                                {this.state.showLoader ? (
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (null)}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getEmailTemplateList }, dispatch);
}

export default connect(null, mapDispatchToProps)(EmailTemplates)
