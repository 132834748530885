import React, { Component } from 'react'
import logo from '../../Assets/Images/logo.png';
import '../../Styles/sidebar.scss'
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleSidebar } from '../../Redux/Actions/commonAction';
import { logoutUser } from '../../Redux/Actions/authAction';
// import { getProductListCountForApproval } from '../../Redux/Actions/productAction';

class Sidebar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isShowSidebar: false
        }
        console.log("Inside sidebar constructor");
    }

    componentDidMount() {
        // this.props.getProductListCountForApproval();
    }

    handleLogout = async (event) => {
        event.preventDefault();
        this.props.logoutUser();
    };


    componentWillReceiveProps(nextProps) {
        if (nextProps.isShowSidebar !== this.props.isShowSidebar) {
            this.setState({
                isShowSidebar: nextProps.isShowSidebar
            })
        }
    }


    render() {
        const {permission } = this.props;
        return (
            <div class={this.props.isShowSidebar ? "sidebar sidebar-show" : "sidebar"}>
                <div class="sidebar-head">
                    <img src={logo} />
                    <div class="toggle-close-btn">
                        <button onClick={() => this.props.toggleSidebar()} type="button"><i class="fas fa-times"></i></button>
                    </div>
                </div>
                {Object.keys(permission).length === 0? <div className="sidebar-menu"></div>: (
                    <div className="sidebar-menu">
                        <ul>
                            {(permission?.dashboard?.R === 1) &&
                            <li><Link to={'/dashboard'}
                                      className={this.props.location.pathname === '/dashboard' ? 'active' : 'null'}><i
                                className="fas fa-home"></i> dashboard</Link></li>
                            }
                            {
                                (permission?.notifications?.R === 1) &&
                                <li><Link to={'/notifications'}
                                          className={this.props.match.path.includes('/notifications') ? 'active' : 'null'}><i
                                    className="fas fa-bell"></i> Notifications </Link></li>
                            }
                            {
                                (permission?.orders_list?.R === 1) &&
                                <li><Link to={'/orders'}
                                          className={this.props.location.pathname === '/orders' || this.props.match.path == '/orders/:orderId' || this.props.match.path == '/orders/track/:trackId' ? 'active' : 'null'}><i
                                    className="fas fa-history"></i> orders list</Link></li>
                            }
                            {
                                (permission?.users_list?.R === 1) &&
                                <li><Link to={'/users'}
                                          className={this.props.location.pathname === '/users' || this.props.match.path == '/users/:userId' ? 'active' : 'null'}><i
                                    className="fas fa-users"></i> users list</Link></li>
                            }
                            {
                                (permission?.payments_list?.R === 1) &&
                                <li><Link to={'/payments'}
                                          className={this.props.location.pathname === '/payments' ? 'active' : 'null'}><i
                                    className="fas fa-money-check"></i> Payments List</Link></li>
                            }
                            {
                                (permission?.coupons_list?.R === 1) &&
                                <li><Link to={'/coupon'}
                                          className={this.props.location.pathname === '/coupon' || this.props.match.path == '/create-coupon' || this.props.match.path == '/edit-coupon/:couponId' ? 'active' : 'null'}><i
                                    className="fas fa-gift"></i> coupons list</Link></li>
                            }
                            {
                                (permission?.category_list?.R === 1) &&
                                <li><Link to={'/product-category'}
                                          className={this.props.location.pathname === '/product-category' || this.props.match.path == '/create-product-category' || this.props.match.path == '/edit-product-category/:categoryId' ? 'active' : 'null'}><i
                                    className="fas fa-tags"></i> category list</Link></li>
                            }
                            {
                                (permission?.product_list?.R === 1) &&
                                <li><Link to={'/product'}
                                          className={this.props.location.pathname === '/product' || this.props.match.path == '/create-product-category' || this.props.match.path == '/product' ? 'active' : 'null'}><i
                                    className="fas fa-book-medical"></i> product list</Link></li>
                            }
                            {
                                (permission?.report_management?.R === 1) &&
                                <li><Link to={'/report-management'}
                                          className={this.props.match.path == '/report-management' ? 'active' : 'null'}><i
                                    className="fas fa-file"></i> Report Management</Link></li>
                            }
                            {
                                (permission?.subscription_management?.R === 1) &&
                                <li><Link to={'/subscriptions'}
                                          className={this.props.match.path.includes('/subscription') ? 'active' : 'null'}><i
                                    className="fas fa-arrow-up"></i> Subscription Management</Link></li>
                            }
                            {
                                (permission?.email_templates?.R === 1) &&
                                <li><Link to={'/email-templates'}
                                          className={this.props.match.path.includes('email-template') ? 'active' : 'null'}><i
                                    className="fas fa-envelope"></i> Email Templates</Link></li>
                            }
                            {
                                (permission?.pages?.R === 1) &&
                                <li><Link to={'/pages'}
                                          className={this.props.match.path.includes('/page') ? 'active' : 'null'}
                                ><i
                                    className="fas fa-scroll"></i> Pages</Link></li>
                            }
                            {
                                (permission?.settings?.R === 1) &&
                                <li><Link to={'/settings'}
                                          className={this.props.match.path.includes('/settings') ? 'active' : 'null'}><i
                                    className="fas fa-cog"></i> Settings</Link></li>
                            }
                            {
                                (permission?.activities?.R === 1) &&
                                <li><Link to={'/activities'}
                                          className={this.props.match.path.includes('/activities') ? 'active' : 'null'}><i
                                    className="fa fa-briefcase"></i> Activities </Link></li>
                            }
                            {
                                (permission?.covid_19_test?.R === 1) &&
                                <li><Link to={'/covid-19-testing'}
                                          className={this.props.match.path.includes('/covid-19-testing') ? 'active' : 'null'}><i
                                    className="fas fa-syringe"></i> COVID-19 Test </Link></li>
                            }

                            {/* <li><Link to={'/pages'} className={this.props.location.pathname === '/pages' || this.props.match.path == '/pages/:pageId' ? 'active' : 'null'}><i class="fas fa-list"></i> Pages List</Link></li> */}
                            <li><a onClick={(event) => this.handleLogout(event)}><i
                                className="fas fa-sign-out-alt"></i> Logout</a></li>
                            {/* <li><Link to={'/vendors'} className={this.props.location.pathname === '/vendors' || this.props.match.path == '/vendors/:vendorId' || this.props.match.path == '/vendors/product/:vendorId' || this.props.match.path == '/vendors/product/:vendorId/edit/:slug' ? 'active' : 'null'}><i class="fas fa-list"></i> vendors list</Link></li> */}

                            {/* <li><Link to={'/change-password'} className={this.props.location.pathname === '/change-password' ? 'active' : 'null'}><i class="fas fa-user"></i> change password</Link></li> */}


                            {/* <li><Link to={'/product-approval'} className={this.props.location.pathname === '/product-approval' ? 'active' : 'null'}><i class="fas fa-list"></i> product approval {this.props.productApprovalCount && this.props.productApprovalCount > 0 ? <span class="badge badge-dark">{this.props.productApprovalCount}</span> : null}</Link></li> */}


                            {/* <li><a href="javascript:;"><i class="fas fa-pen"></i> add product</a></li>
                        <li><a href="javascript:;"><i class="fas fa-list"></i> product list</a></li>
                        <li><a href="javascript:;"><i class="fas fa-list"></i> order history</a></li>
                        // <li><a href="javascript:;"><i class="fas fa-dollar-sign"></i> subscription packages</a></li> */}
                        </ul>
                    </div>

                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isShowSidebar: state.commonReducer.showSidebar,
        permission: state.settingReducer.permission,
        role: state.settingReducer.role,
        // productApprovalCount: state.productReducer.approvalProductListCount
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ toggleSidebar,logoutUser }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar))
