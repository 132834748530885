import React  from "react";
import logo from '../../Assets/Images/retrogen-logo.png';
import moment from 'moment';

class AccessionLabel extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {item} = this.props;
        return (
            <div className={'accession-label'}>
                <div className={'mb-0 pb-0'}><img className={'logo'} src={logo}/> </div>
                <label className={'content d-block p-0 m-0'}><i>{moment(item.dateCollected).format('MM/DD/YYYY')}</i></label>
                <label className={'content d-block pb-2 m-0'}><i>{moment(item.dateReceived).format('MM/DD/YYYY')}</i></label>
                <label className={'content d-block p-0 m-0'}><b>{item.accessionNumber}</b></label>
            </div>
        );
    }
}

export default AccessionLabel;
