import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import {bindActionCreators} from "redux";
import {getUserPermission} from "../Redux/Actions/settingAction";
import {logoutUser} from "../Redux/Actions/authAction";
import {checkPermission, getPathName} from "../Containers/helper/helper";

class PrivateGuard extends React.Component {
  dontShowAfterLogin=[
    '/',
    '/signup',
    '/signUp',
    '/login',
    '/reset-password',
    '/forgot-password'

  ];

  async componentDidMount() {
    const userId = localStorage.getItem("adminUserId");
    if (userId) {
      await this.props.getUserPermission(userId);
    }
  };

  render() {
    const route = this.props.location?.pathname;
    let returnData = "";

    if (localStorage.getItem("adminUserId")) {
      if(!this.dontShowAfterLogin.includes(route)){
        if(Object.keys(this.props.settingReducer.permission).length !== 0) {
          const readAllowed = this.props.settingReducer.role === 'admin' ||
              checkPermission(route, this.props.settingReducer.permission, 'R');
          if(!readAllowed) {
            this.props.logoutUser();
            return '';
          }
        }

        returnData = <Route {...this.props} />;
      }else{
        if(Object.keys(this.props.settingReducer.permission).length !== 0) {
          const pathName = getPathName(this.props.settingReducer.permission);
          returnData = (
              <Redirect
                  to={{
                    pathname: pathName,
                    state: { from: this.props.location },
                  }}
              />
          );
        } else {
          returnData = (
              <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: this.props.location },
                  }}
              />
          );
        }
      }

    } else {
      // returnData = <Route {...this.props} />;
      if(this.dontShowAfterLogin.includes(route)){
        returnData = <Route {...this.props} />;
      }else {
      returnData = (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: this.props.location },
          }}
        />
      );
    }
  }

    return <div>{returnData}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    authData: state.authReducer.authData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getUserPermission, logoutUser }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateGuard);
