import React, { useEffect, useState } from 'react';
import {
    updateCovidLocation,
    createCovidLocation
} from "../../Redux/Actions/covidAction";
import {useDispatch} from "react-redux";
import Select from 'react-select';
const testOptions = [
    { value: 'Antigen', label: 'Antigen' },
    { value: 'RT-PCR', label: 'RT-PCR' }
];

const AddCovidLocation = (props) => {
    const {covidLocationData, locationEdit, handleCloseAddLocation} = props;
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const [locationData, setLocationData] = useState({
        id: '',
        name: '',
        description: '',
        test_type: '[]',
        street: '',
        state: '',
        city: '',
        zipCode: '',
        location_admin: '',
        timezone: ''
    });
    const [errorData, setErrorData] = useState({
        name: '',
        description: '',
        test_type: '',
        street: '',
        state: '',
        city: '',
        zipCode: '',
        location_admin: '',
        timezone: ''
    });
    const [saveLocationLoading, setSaveLocationLoading] = useState(false);

    useEffect(() => {
        if(locationEdit && covidLocationData) {
            setLocationData({
                id: covidLocationData.id,
                description: covidLocationData.description,
                test_type: covidLocationData.test_type,
                timezone: covidLocationData.timezone,
                location_admin: covidLocationData.location_admin,
                name: covidLocationData.name,
                street: covidLocationData.street,
                state: covidLocationData.state,
                city: covidLocationData.city,
                zipCode: covidLocationData.zipCode,
            });
        }
    }, []);

    const validateLocationInfo = () => {
        let isFormValid = true;
        const error = {
            name: '',
            street: '',
            state: '',
            city: '',
            zipCode: ''
        };
        if (locationData.name.trim().length === 0) {
            isFormValid = false;
            error.name = "This field is required";
        }
        if (locationData.street.trim().length === 0) {
            isFormValid = false;
            error.street = "This field is required";
        }
        if (locationData.test_type.length === 0) {
            isFormValid = false;
            error.test_type = "This field is required";
        }
        if (!locationData.timezone) {
            isFormValid = false;
            error.timezone = "This field is required";
        }
        if (locationData.location_admin.trim().length === 0) {
            isFormValid = false;
            error.location_admin = "This field is required";
        }
        if (
            locationData.location_admin.trim().length > 0 &&
            /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
                locationData.location_admin
            ) === false
        ) {
            isFormValid = false;
            error.location_admin = "Invalid email address";
        }
        if (locationData.state.trim().length === 0) {
            isFormValid = false;
            error.state = "This field is required";
        }
        if (locationData.city.trim().length === 0) {
            isFormValid = false;
            error.city = "This field is required";
        }
        if (locationData.zipCode.trim().length === 0) {
            isFormValid = false;
            error.zipCode = 'This field is required';
        }

        setErrorData(error);
        return isFormValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formValid = validateLocationInfo();
        if(!formValid) {
            return;
        }
        setSaveLocationLoading(true);
        const body = {
            name: locationData.name,
            description: locationData.description,
            test_type: locationData.test_type,
            timezone: locationData.timezone,
            location_admin: locationData.location_admin,
            street: locationData.street,
            state: locationData.state,
            city: locationData.city,
            zipCode: locationData.zipCode
        };
        let response;
        if(locationEdit) {
            body.test_type = typeof body.test_type === 'string' && body.test_type? JSON.parse(body.test_type): body.test_type;
            response = await dispatch(updateCovidLocation(locationData.id, body));
        } else {
            response = await dispatch(createCovidLocation(body));
        }
        if (response && response.data) {
            if (response.data.isSuccess) {
                setSaveLocationLoading(false);
                handleCloseAddLocation(response.data.data);
            }
            else {
                // response with failed case
                setErrorMessage(response.data.errorMessage);
                setSaveLocationLoading(false);
            }
        }
        else {
            setErrorMessage('Something went wrong. Please try again later.');
            setSaveLocationLoading(false);
        }
    };

    const handleChange = (e, key) => {
        let { value } = e.target;
        if(key === 'zipCode') {
            const regEx = /^\b\d{0,5}\b$/;
            if (value === '' || regEx.test(value)) {
                setLocationData(prev => ({...prev, [key]: value}))
            }
            return;
        }
        setLocationData(prev => ({...prev, [key]: value}))
    };

    const handleTestTypeChange = (event) => {
        const selectedTest = [];
        if (event && event.length > 0) {
            event.forEach(test => {
                selectedTest.push(test.value)
            });
        }
        setLocationData(prev => ({...prev, test_type: selectedTest}));
    };

    const handleTimezoneChange = (value) => {
        setLocationData(prev => ({...prev, timezone: value}));
    };

    return (
        <div className="admin-card">
            <div className="admin-card-box">
                {
                    errorMessage &&
                    <div className="alert alert-danger">{errorMessage}</div>
                }
                <div className="row mt-3">
                    <div className="col-lg-12 col-md-12 d-flex">
                        <div className="form-group w-100">
                            <label>Name
                                <sup>*</sup>
                            </label>
                            <input type="text" onChange={(e) => handleChange(e, "name")}
                                   value={locationData.name} className="form-control"/>
                            <span className="form-field-error">
                                {errorData.name}
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 d-flex">
                        <div className="form-group  w-100">
                            <label>Description</label>
                            <textarea onChange={(e) => handleChange(e, "description")} style={{'height': '100px'}}
                                   value={locationData.description} className="form-control"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex pr-0">
                        <div className="form-group w-100">
                            <label>Test Type
                                <sup>*</sup>
                            </label>
                            <Select options={testOptions}
                                    isMulti
                                    onChange={(e) => handleTestTypeChange(e)}
                                    value={testOptions.filter(option => ((typeof locationData.test_type === 'string' && JSON.parse(locationData.test_type || '[]')) || locationData.test_type)?.includes(option.value)) || []}

                            />
                            <span className="form-field-error">
                                {errorData.test_type}
                            </span>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 d-flex">
                        <div className="form-group w-100">
                            <label>Timezone
                                <sup>*</sup>
                            </label>
                            <select className="form-control"
                                    onChange={(e) => handleTimezoneChange(e.target.value)}
                                    value={locationData.timezone}>
                                <option value="" disabled selected>--Select--</option>
                                <option value="EST">EST</option>
                                <option value="MT">MT</option>
                                <option value="PST">PST</option>
                            </select>
                            <span className="form-field-error">
                                {errorData.timezone}
                            </span>
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12 d-flex">
                        <div className="form-group w-100">
                            <label>Location Admin Email
                                <sup>*</sup>
                            </label>
                            <input type="email" onChange={(e) => handleChange(e, "location_admin")}
                                   value={locationData.location_admin} className="form-control"/>
                            <span className="form-field-error">
                                {errorData.location_admin}
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 d-flex">
                        <div className="form-group  w-100">
                            <label>Street
                                <sup>*</sup>
                            </label>
                            <input type="text" onChange={(e) => handleChange(e, "street")}
                                   value={locationData.street} className="form-control"/>
                            <span className="form-field-error">
                                {errorData.street}
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 d-flex">
                        <div className="col-lg-4 col-md-4 pr-0 pl-0">
                            <div className="form-group">
                                <label>State
                                    <sup>*</sup>
                                </label>
                                <input type="text" onChange={(e) => handleChange(e, "state")}
                                       value={locationData.state} className="form-control"/>
                                        <span className="form-field-error">
                                    {errorData.state}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 pr-0">
                            <div className="form-group">
                                <label>City
                                    <sup>*</sup>
                                </label>
                                <input type="text" onChange={(e) => handleChange(e, "city")}
                                       value={locationData.city} className="form-control"/>
                                        <span className="form-field-error">
                                    {errorData.city}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 pr-0">
                            <div className="form-group">
                                <label>Zip Code
                                    <sup>*</sup>
                                </label>
                                <input type="text" onChange={(e) => handleChange(e, "zipCode")}
                                       value={locationData.zipCode} className="form-control"/>
                                        <span className="form-field-error">
                                    {errorData.zipCode}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-md-12">
                    <div className="form-btn text-center">
                        <button type="button" onClick={(e) => handleSubmit(e)}
                                className="orange-outline-btn">submit
                            {
                                saveLocationLoading && <span className="spinner-border spinner-border-sm ml-1"></span>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AddCovidLocation;
