import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getPermissionSettings = () => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/permissions/list`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const savePermission = (id, permissionObj) => async (dispatch) => {
    try {
        const res = await axios({
            method: "put",
            url: `${URL}/api/v1/admin/permissions/${id}`,
            data: permissionObj,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const searchSystemUser = (offset, searchKeyword) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/company-users/search?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const getSystemUserList = (offset) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/company-users/list?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const createCompanyUser = (body) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/company-users/add`,
            data: body,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const updateCompanyUser = (userId, body) => async (dispatch) => {
    try {
        const res = await axios({
            method: "put",
            url: `${URL}/api/v1/admin/company-users/update/${userId}`,
            data: body,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const getUserPermission = (userId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/permissions/user/${userId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        dispatch({
            type: "PERMISSION_FETCHED",
            payload: { data: res.data },
        });
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const searchPanel = (offset, searchKeyword) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/panels/search?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const getPanelList = (offset) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/panels/list?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const searchKit = (offset, searchKeyword) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/available-kit/search?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const getKitList = (offset) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/available-kit/list?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const getAllPathogens = () => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/pathogens`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res.data || null;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
};

export const updatePanel = (panelId, body) => async (dispatch) => {
    try {
        const res = await axios({
            method: "put",
            url: `${URL}/api/v1/admin/panels/update/${panelId}`,
            data: body,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const generateKit = (body) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/available-kit/create`,
            data: body,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const getSingleKit = (productId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/available-kit/single/${productId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const updateUsedKitId = (productId, kitId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "put",
            url: `${URL}/api/v1/admin/available-kit/single/used/${productId}`,
            data: {kitId},
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const searchShippingSettings = (offset, searchKeyword) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/shipping-setting/search?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const getShippingSettingsList = (offset) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/shipping-setting/list?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const updateShippingSettings = (productId, body) => async (dispatch) => {
    try {
        const res = await axios({
            method: "put",
            url: `${URL}/api/v1/admin/shipping-setting/update/${productId}`,
            data: body,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const updateShippingAddress = (userId, body) => async (dispatch) => {
    try {
        userId = userId? userId: localStorage.getItem("adminUserId");
        const res = await axios({
            method: "put",
            url: `${URL}/api/v1/admin/admin-setting/shipping-update/${userId}`,
            data: body,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const getShippingAddress = () => async (dispatch) => {
    try {
        const userId = localStorage.getItem("adminUserId");
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/admin-setting/shipping-address/${userId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res.data;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};
