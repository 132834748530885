export const initialState = {
    permission: {},
    role: ''
};

export const settingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "PERMISSION_FETCHED": {
            return {
                ...action.payload.data.data
            }
        }

        default:
            return state
    }
};
