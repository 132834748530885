import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;


export const getProductList = (offset) => async (dispatch) => {
    console.log("getProductList parameters");
    try {
        // let categoryID = getCategoryID(categoryName).
        let categoryID = 1
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/product-category/product?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        console.log("data from backend", res);
        dispatch({ type: "LOADING_COMPLETED" });
        dispatch({
            type: "PRODUCT_LIST",
            payload: { data: res.data },
        });
        return res;
    } catch (error) {
        console.log("catch");
        dispatch(handleError(error));
    }
}

export const getAllProducts = () => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/product-category/product/all`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        return res;
    } catch (error) {
        console.log("catch");
        dispatch(handleError(error));
    }
}

export const getProductCategoryList = (offset) => async (dispatch) => {
    // console.log("getProductCategoryList offset is", offset);
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/product-category/list?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("getProductCategoryList response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

export const getProductName = (offset) => async (dispatch) => {
    // console.log("getProductCategoryList offset is", offset);
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/product-category/productNames`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("getProductCategoryList response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

export const getProductCategoryDetail = (categoryId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/product-category/detail/${categoryId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("getProductCategoryDetail response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

export const getProductDetail = (productId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/product-category/product/detail/${productId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("getProductCategoryDetail response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

export const createProduct = (productInfo,userId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/product-category/product/add`,
            data: {productInfo,userId},
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        // console.log("createProductCategory res from backend", res);

        return res;
    }
    catch (error) {
        console.log('error at createProductCategory action', error);
        dispatch(handleError(error));
    }
}

export const updateProduct = (productCategoryInfo, productId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/product-category/product/update-product/${productId}`,
            data: productCategoryInfo,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("updateProductCategoryDetail response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}



export const createProductCategory = (productCategoryInfo) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/product-category/add`,
            data: productCategoryInfo,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        // console.log("createProductCategory res from backend", res);

        return res;
    }
    catch (error) {
        console.log('error at createProductCategory action', error);
        dispatch(handleError(error));
    }
}



export const updateProductCategoryDetail = (productCategoryInfo, categoryId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/product-category/update-product-category/${categoryId}`,
            data: productCategoryInfo,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("updateProductCategoryDetail response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}



export const searchProductCategory = (offset, searchKeyword) => async (dispatch) => {
    // console.log("getOrdersList offset is", offset);
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/product-category/by/search?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("orders list response from backend", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}
export const searchProduct = (offset, searchKeyword) => async (dispatch) => {
    // console.log("getOrdersList offset is", offset);
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/product-category/by/search-product?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("orders list response from backend", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

