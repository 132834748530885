import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getCovidAppointments = (fromDate, toDate, searchText, locationId) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        let searchQuery = 'q=1';
        if(fromDate) {
            searchQuery += `&fromDate=${fromDate}`;
        }
        if(fromDate) {
            searchQuery += `&toDate=${toDate}`;
        }
        if(searchText) {
            searchQuery += `&search=${searchText}`;
        }
        if(locationId) {
            searchQuery += `&locationId=${locationId}`;
        }
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/appointments/appointment-list?${searchQuery}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;

    } catch (error) {
        dispatch(handleError(error));
    }
};

export const getAppointmentDetail = (appointmentId) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/appointments/detail/${appointmentId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;

    } catch (error) {
        dispatch(handleError(error));
    }
};

export const confirmCheckIn = (appointmentId) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/appointments/confirm-check-in/${appointmentId}`,
            data: {},
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;

    } catch (error) {
        dispatch(handleError(error));
    }
};

export const generateTestReport = (appointmentId, testResultObj) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/appointments/generate-report/${appointmentId}`,
            data: {testResultObj},
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;

    } catch (error) {
        dispatch(handleError(error));
    }
};

export const getCovidLocations = (searchText) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        let searchQuery = 'q=1';
        if(searchText) {
            searchQuery += `&search=${searchText}`;
        }
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/test-location/all?${searchQuery}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;

    } catch (error) {
        dispatch(handleError(error));
    }
};

export const createCovidLocation = (body) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/test-location/add`,
            data: body,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const updateCovidLocation = (locationId, body) => async (dispatch) => {
    try {
        const res = await axios({
            method: "put",
            url: `${URL}/api/v1/admin/test-location/update/${locationId}`,
            data: body,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const removeCovidLocations = (id)=> async(dispatch)=>{
    try {
        const res = await axios({
            method: "delete",
            url: `${URL}/api/v1/admin/test-location/delete/${id}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        return res.data;
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const createCovidLocationAppointments = (locationId, body) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/appointments/create/${locationId}`,
            data: body,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const getAllAppointments = (locationId, filterDate, searchText) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        let searchQuery = 'q=1';
        if(filterDate) {
            searchQuery += `&filterDate=${filterDate}`;
        }
        if(searchText) {
            searchQuery += `&search=${searchText}`;
        }
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/appointments/appointment-list/location/${locationId}?${searchQuery}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res.data;

    } catch (error) {
        dispatch(handleError(error));
    }
};

export const changeAppointmentStatus = (appointmentId, body) => async (dispatch) => {
    try {
        const res = await axios({
            method: "put",
            url: `${URL}/api/v1/admin/appointments/${appointmentId}/status`,
            data: body,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res.data;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const changeAllAppointmentStatus = (body) => async (dispatch) => {
    try {
        const res = await axios({
            method: "put",
            url: `${URL}/api/v1/admin/appointments/status`,
            data: body,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res.data;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};
