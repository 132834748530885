export const initialState = {
    categoryId: "",
    categoryName: "",
    productList: [],
    productDetail: {},
    approvalProductListCount: 0
};

export const productReducer = (state = initialState, action) => {
    console.log("product reducer", action);
    switch (action.type) {
        case "CATEGORY_CHANGE": {
            return {
                categoryId: action.payload.categoryId,
                categoryName: action.payload.categoryName,
                productList: []

            }
        }
        case "PRODUCT_LIST":
        return {
            ...state,
            productList: [...action.payload.data.data]
        }
        case "PRODUCT_DETAIL":
        return {
            ...state,
            productDetail: action.payload.data.data
        }
        case "APPROVAL_PRODUCT_COUNT":
        return {
            ...state,
            approvalProductListCount: action.payload.data.data.count
        }
        default:
            return state
    }
}