import React, { Component } from 'react';
import '../../Styles/user-detail.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {createFAQ, updateFAQ, getFAQCategories} from "../../Redux/Actions/helpAction";
import ReactQuill from "react-quill";
import CreatableSelect from 'react-select/creatable';

class AddFAQ extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            saveFAQLoading: false,
            category: [],
            heading: '',
            content: '',
            faqCategories: [],
            errorMessage: '',
            errorData: {
                category: [],
                heading: [],
                content: [],
            }
        };

        this.modules = {
            toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ size: [] }],
                [{ 'color': [] }, { 'background': [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                    { 'indent': '-1' }, { 'indent': '+1' }],
                ['clean']
            ],
            clipboard: {
                matchVisual: false
            },
        };

        this.formats = [
            'header', 'font', 'size', 'color',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            // 'link', 'image', 'video'
        ];

        this.rteChange = this.rteChange.bind(this);
    }

    async componentDidMount() {
        const { faqData, faqEdit } = this.props;
        if(faqData && faqEdit) {
            this.setState({
                category: !faqData.category? []: JSON.parse(faqData.category).map((cat) => {
                    return {
                        label: cat,
                        value: cat
                    }
                }),
                heading: faqData.heading,
                content: faqData.content,
            })
        }
        const categories = await this.props.getFAQCategories();
        const finalCategories = (categories? categories.data: []).map((category) => {
            return {
                label: category.name,
                value: category.name
            }
        });
        this.setState({
            faqCategories: finalCategories
        })
    }

    handleChange = (e, key) => {
        if (this.state.errorData && this.state.errorData[key] && this.state.errorData[key][0]) {
            this.state.errorData[key][0] = null;
        }

        let { value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            [key]: value
        }))
    };

    validateFAQ = (formData) => {
        let isFormValid = true;
        const error = {
            category: [],
            heading: [],
            content: [],
        };

        if (formData.category.length === 0) {
            isFormValid = false;
            error.category = ["Category cannot be empty!"];
        }

        if (formData.heading.trim().length === 0) {
            isFormValid = false;
            error.heading = ["Heading cannot be empty!"];
        }

        if (
            !this.state.content ||
            this.state.content.trim().length === 0 ||
            !this.state.content.trim().replace(/<[^>]*>?/gm, '')
        ) {
            isFormValid = false;
            error.content = ["Content cannot be empty!"];
        }
        this.setState({
            ...this.state,
            errorData: error
        });
        return isFormValid;
    };

    onSaveFAQ = async (e) => {
        const {faqData, faqEdit} = this.props;
        e.preventDefault();

        if (!this.validateFAQ(this.state)) {
            return ;
        }

        this.setState({
            saveFAQLoading: true
        });

        const body = {
            category: this.state.category,
            heading: this.state.heading,
            content: this.state.content,
        };
        let response;
        if(faqEdit) {
            response = await this.props.updateFAQ(faqData.id, body);
        } else {
            response = await this.props.createFAQ(body);
        }

        if (response && response.data) {
            if (response.data.isSuccess) {
                this.setState({
                    category: [],
                    heading: '',
                    content: '',
                    saveFAQLoading: false
                });
                this.props.reloadFAQs();
            }
            else {
                // response with failed case
                this.setState({
                    errorMessage: response.data.errorMessage,
                    saveFAQLoading: false
                })
            }
        }
        else {
            this.setState({
                errorMessage: "Something went wrong. Please try again later.",
                saveFAQLoading: false
            })
        }
    };

    rteChange = (content, delta, source, editor) => {
        this.setState(prevState => {
            return (
                prevState.content = editor.getHTML()
            )
        });
    };

    handleCategoryChange = (newValue) => {
        if(newValue && newValue.length !== 0) {
            if (this.state.errorData && this.state.errorData['category'] && this.state.errorData['category'][0]) {
                this.state.errorData['category'][0] = null;
            }
        }
        this.setState(prevState => ({
            ...prevState,
            category: newValue || []
        }));

    };

    render() {
        const {
            handleCloseAddFAQ,
        } = this.props;

        return (
            <div className={'faq'}>
                <div className="admin-card-head d-flex justify-content-end mb-2">
                    <button type="text" className="danger-outline-btn mr-2" onClick={handleCloseAddFAQ}>
                        Cancel
                    </button>
                    <button type="text" className="orange-outline-btn" onClick={this.onSaveFAQ}>
                        Save
                        {(() => {
                            if (this.state ?.saveFAQLoading) {
                                return <span class="spinner-border spinner-border-sm ml-1"></span>
                            }
                        })()}
                    </button>
                </div>

                <div className="admin-card-box col-md-12">
                    <section className="track-section">
                        <div className="container-full">
                            {this.state.showLoader ? (
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (null)}
                            {(() => {
                                if (this.state.errorMessage) {
                                    return <div className="alert alert-danger">{this.state.errorMessage}</div>
                                }
                            })()}
                            <form>
                                <div className="form-group col-md-12">
                                    <label>Category*</label>
                                    <CreatableSelect
                                        isMulti
                                        onChange={this.handleCategoryChange}
                                        options={this.state.faqCategories}
                                        value={this.state.category}
                                    />
                                    <span style={{color: "red", fontSize: "12px"}}
                                          className="form-field-error">
                                                    {this.state.errorData?.category &&
                                                    this.state.errorData.category[0]}
                                                </span>
                                    {/*{(() => {*/}
                                    {/*    return (*/}
                                    {/*        <>*/}
                                    {/*            <input type="text"*/}
                                    {/*                   onChange={(event) => this.handleChange(event, "category")}*/}
                                    {/*                   value={this.state.category}*/}
                                    {/*                   className="form-control"*/}
                                    {/*                   id="pageTitleInput"*/}
                                    {/*            />*/}
                                    {/*            <span style={{color: "red", fontSize: "12px"}}*/}
                                    {/*                  className="form-field-error">*/}
                                    {/*                {this.state.errorData?.category &&*/}
                                    {/*                this.state.errorData.category[0]}*/}
                                    {/*            </span>*/}
                                    {/*        </>*/}
                                    {/*    )*/}
                                    {/*})()}*/}

                                </div>
                                <div className="form-group col-md-12">
                                    <label>Heading*</label>
                                    {(() => {
                                        return (
                                            <>
                                                <input type="text"
                                                       onChange={(event) => this.handleChange(event, "heading")}
                                                       value={this.state.heading}
                                                       className="form-control"
                                                />
                                                <span style={{color: "red", fontSize: "12px"}}
                                                      className="form-field-error">
                                                    {this.state.errorData?.heading &&
                                                    this.state.errorData.heading[0]}
                                                </span>
                                            </>
                                        )
                                    })()}

                                </div>
                                <div className="form-group col-md-12">
                                    <label htmlFor="pageContentTextarea">Content*</label>
                                    {(() => {
                                        return (
                                            <>
                                                <ReactQuill theme="snow" modules={this.modules}
                                                            formats={this.formats} onChange={this.rteChange}
                                                            value={this.state.content || ''}
                                                            placeholder="Write something..."/>
                                                <span style={{color: "red", fontSize: "12px"}}
                                                      className="form-field-error">
                                                    {this.state.errorData?.content &&
                                                    this.state.errorData.content[0]}
                                                </span>
                                            </>
                                        )
                                    })()}

                                </div>
                            </form>

                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        createFAQ,
        updateFAQ,
        getFAQCategories,
    }, dispatch);
};

export default connect(null, mapDispatchToProps)(AddFAQ)
