import React from "react";
import PrivateGuard from "./privateGuard";
import { Switch, Redirect, Route, Router } from "react-router-dom";
import Sidebar from '../Containers/Sidebar/Sidebar';
import Header from '../Components/Header/header';
import Dashboard from "../Containers/Dashboard/dashboard";
import Order_List from '../Containers/Order-List/Order_List';
import Order_Detail from '../Containers/Order-Detail/Order_Detail';
import User_List from '../Containers/User-List/User_List';
import User_Detail from '../Containers/User-Detail/User_Detail';
import Order_Track from '../Containers/Order-Track/Order_Track';
import OrderShip from '../Containers/Shipping/OrderShip';
import Product from '../Containers/Product/product';
import AddProduct from '../Containers/Add Product/addProduct';
import Subscription from "../Containers/Subscription/subscription";
import Settings from "../Containers/Settings/settings";
import Covid19Testing from "../Containers/Covid19Testing/covid19Testing";
import Notifications from "../Containers/Notifications/notifications";
import Activities from "../Containers/Activities/activities";
import Product_Detail from "../Containers/Product-Details/productDetails";
import Payment_List from '../Containers/Payment-List/Payment_List';
import MainPageList from '../Containers/Page-List/Main-page-list';
import Page_Detail from '../Containers/Page-Detail/Page_Detail';
import Coupon_List from '../Containers/Coupon-List/Coupon_List';
import EmailTemplates from '../Containers/EmailTemplates/emailTemplates';
import Coupon from "../Containers/Edit-Coupon/Coupon";
import EmailTemplate from "../Containers/EmailTemplates/editEmailTemplates";
import ReportManagement from "../Containers/Reports/reports";
import UploadReport from "../Containers/UploadReports/upload-report-details";
import Vendor_List from '../Containers/Vendor-List/Vendor_List';
import Vendor_Edit from '../Containers/Vendor-Edit/Vendor-Edit';
import Vendor_Product_List from '../Containers/Vendor-Product-List/Vendor_Product_List';
import Vendor_Product_Edit from '../Containers/Vendor-Product-Edit/Vendor_Product_Edit';

import Product_Category_List from '../Containers/Product-Category-List/Product_Category_List';
import Edit_Product_Category from "../Containers/Edit-Product-Category/Edit_Product_Category";
import Login from '../Containers/login/Login'
import SignUp from '../Containers/SignUp/signup'
import ForgotPassword from "../Containers/ForgotPassword/ForgotPassword";
import ResetPassword from '../Containers/ResetPassword/ResetPassword';
import Change_Password from '../Containers/Change-Password/Change_Password';

import Approve_Products from '../Containers/Approve-Products/Approve_Products';

class PrivateRoutes extends React.Component {
  render() {
    return (
      <div>
        <div className="PrivateArea__content">
          <div className="main_sec">
            {/* admin routes */}
            <Switch>

              <Route
                path="/"
                component={() => <Redirect to="/login" />}
                exact
              />

              <PrivateGuard
                path='/login'
                component={Login}
                exact
              />

              <PrivateGuard
                path='/dashboard'
                component={Container}
                exact
              />
              <PrivateGuard
                path='/report-management'
                component={Container}
                exact
              />
              <PrivateGuard
                path='/email-templates'
                component={Container}
                exact
              />
                <PrivateGuard
                path='/report-details/:userId'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/orders'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/users'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/users/:userId'
                component={Container}
                exact
              />
               <PrivateGuard
                path='/subscription/:id'
                component={Container}
                exact
              />
               <PrivateGuard
                path='/subscriptions'
                component={Container}
                exact
              />
               <PrivateGuard
                path='/settings'
                component={Container}
                exact
              />
               <PrivateGuard
                path='/activities'
                component={Container}
                exact
              />
               <PrivateGuard
                path='/covid-19-testing'
                component={Container}
                exact
              />
               <PrivateGuard
                path='/notifications'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/orders/:orderId'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/orders/:orderId/process-shipping'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/orders/track/:trackId'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/payments'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/pages'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/pages/:pageId'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/coupon'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/create-coupon'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/edit-coupon/:couponId'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/edit-email-template/:templateId'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/product-category'
                component={Container}
                exact
              />
               <PrivateGuard
                path='/product'
                component={Container}
                exact
              />
                 <PrivateGuard
                path='/add-product'
                component={Container}
                exact
              />
               <PrivateGuard
                path='/edit-product/:id'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/create-product-category'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/edit-product-category/:categoryId'
                component={Container}
                exact
              />
              <PrivateGuard
                path='/product-detail/:productId'
                component={Container}
                exact
              />
               <PrivateGuard
                path='/create-product'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/vendors'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/vendors/:vendorId'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/vendors/product/:vendorId'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/vendors/product/:vendorId/edit/:slug'
                component={Container}
                exact
              />

              {/* <PrivateGuard
                path='/signup'
                component={SignUp}
                exact
              /> */}
              <PrivateGuard
                path='/forgot-password'
                component={ForgotPassword}
                exact
              />
              <PrivateGuard
                path='/reset-password'
                component={ResetPassword}
                exact
              />


              <PrivateGuard
                path='/change-password'
                component={Container}
                exact
              />

              <PrivateGuard
                path='/product-approval'
                component={Container}
                exact
              />





              {/* wildcard route */}
              <Route
                path="*"
                component={() => <Redirect to="/login" />}
                exact
              />



            </Switch>
          </div>
        </div>
      </div>
    );
  }
}



const Container = ({ match }) => {
  // console.log("match is", { match })
  return (
    <div class="main-body">
      <Sidebar />
      <div class="main-content">
        <div class="container-fluid">
          <Header />
          <Switch>
            <Route
              path={'/dashboard'}
              component={Dashboard}
              exact
            />

            <Route
              path={'/orders'}
              component={Order_List}
              exact
            />

            <Route
              path='/orders/:orderId'
              component={Order_Detail}
              exact
            />
            <Route
              path={'/report-management'}
              component={ReportManagement}
              exact
            />
            <Route
              path={'/email-templates'}
              component={EmailTemplates}
              exact
            />
             <Route
              path={'/report-details/:userId'}
              component={UploadReport}
              exact
            />
            <Route
              path='/orders/track/:trackId'
              component={Order_Track}
              exact
            />

            <Route
              path={'/users'}
              component={User_List}
              exact
            />

            <Route
              path={'/users/:userId'}
              component={User_Detail}
              exact
            />

            <Route
              path={'/payments'}
              component={Payment_List}
              exact
            />

            <Route
              path={'/pages'}
              component={MainPageList}
              exact
            />

            <Route
              path={'/pages/:pageId'}
              component={Page_Detail}
              exact
            />

            <Route
              path={'/coupon'}
              component={Coupon_List}
              exact
            />

            <Route
              path={'/create-coupon'}
              component={Coupon}
              exact
            />

            <Route
              path='/edit-coupon/:couponId'
              component={Coupon}
              exact
            />

            <Route
              path='/edit-email-template/:templateId'
              component={EmailTemplate}
              exact
            />


            <Route
              path='/product-category'
              component={Product_Category_List}
              exact
            />
            <Route
              path='/product'
              component={Product}
              exact
            />
             <Route
              path='/add-product'
              component={AddProduct}
              exact
            />
               <Route
              path='/edit-product/:id'
              component={AddProduct}
              exact
            />

            <Route
              path='/create-product-category'
              component={Edit_Product_Category}
              exact
            />
            <Route
              path='/subscription/:id'
              component={Subscription}
              exact
            />
            <Route
              path='/subscriptions'
              component={Subscription}
              exact
            />
            <Route
              path='/settings'
              component={Settings}
              exact
            />
            <Route
              path='/activities'
              component={Activities}
              exact
            />
            <Route
              path='/covid-19-testing'
              component={Covid19Testing}
              exact
            />
            <Route
              path='/notifications'
              component={Notifications}
              exact
            />
            <Route
              path='/orders/:orderId/process-shipping'
              component={OrderShip}
              exact
            />

            <Route
              path='/edit-product-category/:categoryId'
              component={Edit_Product_Category}
              exact
            />
             <Route
              path='/product-detail/:productId'
              component={Product_Detail}
              exact
            />
            <Route
              path='/create-product'
              component={Product_Detail}
              exact
            />

            <Route
              path='/vendors'
              component={Vendor_List}
              exact
            />

            <Route
              path='/vendors/:vendorId'
              component={Vendor_Edit}
              exact
            />

            <Route
              path='/vendors/product/:vendorId'
              component={Vendor_Product_List}
              exact
            />

            <Route
              path='/vendors/product/:vendorId/edit/:slug'
              component={Vendor_Product_Edit}
              exact
            />

            <Route
              path={'/change-password'}
              component={Change_Password}
              exact
            />

            <Route
              path={'/product-approval'}
              component={Approve_Products}
              exact
            />

          </Switch>
          <div class="main-footer">
            <p>© {new Date().getFullYear()} Accugenedx. All rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  )
};



// const Container = ({ match }) => (
//   // console.log("match is", {match})
//   <div>
//     <Sidebar />
//     <div className="main">
//       <Route
//         path={'/dashboard'}
//         component={Dashboard}
//       />

//       <Route
//         path={'/orders'}
//         component={Order_List}
//       />

//       <Route
//         path={'/users'}
//         component={User_List}
//       />

//       <Route
//         path={'/profile'}
//         component={Profile}
//       />
//     </div>
//   </div>
// );

export default PrivateRoutes;
