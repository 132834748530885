import React from 'react'
import { Link } from 'react-router-dom'
import "./signUp.scss";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { signup, vemail, login, logout } from "../../Redux/Actions/authAction";
class SignUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      formValues: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirmPassword: "",
        isacceptedtnc: false,
        newsletter: false

      },
      errorData: {},
      vcodeFlag: false,
      isLogedIn: false,
      successMessage: '',
      errorMessage: '',
      showLoader: false,
      tncVisible: false

    };

  }

  validateUserInfo = () => {
    let isFormValid = true;
    let formValues = this.state.formValues;
    this.state.errorData = {
      "firstname": [],
      "lastname": [],
      "email": [],
      "isacceptedtnc": [],
      "password": [],
      "confirmPassword": []
    }

    if (formValues.firstname.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.firstname = ["First Name field cannot be empty."]
    }

    if (formValues.lastname.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.lastname = ["Last Name field cannot be empty."]
    }

    if (formValues.email.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.email = ["Email field cannot be empty."]
    }

    if (!formValues.isacceptedtnc) {
      isFormValid = false;
      this.state.errorData.isacceptedtnc = ["Terms & Conditions must be accepted."]
    }

    if (formValues.password.trim().length < 6) {
      isFormValid = false;
      this.state.errorData.password = ["Password should be at least 6 characters."]
    }

    if (formValues.password.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.password = ["Password field cannot be empty."]
    }

    if (formValues.password.trim() !== formValues.confirmPassword.trim()) {
      isFormValid = false;
      this.state.errorData.confirmPassword = ["Password confirmation does not match password."]
    }

    if (formValues.confirmPassword.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.confirmPassword = ["Confirm Password field cannot be empty."]
    }

    let tempState = this.state;

    this.setState({
      ...tempState
    })

    return isFormValid;
  }

  handleSignUp = async (event) => {

    event.preventDefault();

    await this.setState({
      successMessage: '',
      errorMessage: '',
      errorData: {},
      showLoader: true
    });


    // event.persist()

    // validate input fields here
    if (this.validateUserInfo()) {
      await this.props.signup({ ...this.state.formValues });
      console.log(this.props.isRegistered);
      if (this.props.isRegistered) {
        await this.setState({
          successMessage: "Your account has been registered successfully!",
          showLoader: false,
          formValues: {
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            confirmPassword: "",
            isacceptedtnc: false,
            newsletter: false
          }
        });

      }
      else {
        let message = '';

        if (this.props.code === 'ER_DUP_ENTRY') {
          message = 'Email is already registered with different account!'
        }
        await this.setState({
          errorData: this.props.errorData,
          errorMessage: message,
          showLoader: false
        });
      }
    }
    else {
      // input validations failed
      await this.setState({
        showLoader: false
      });
    }
  };

  handleChange = (event, field) => {
    console.log(event.target.checked, 'event', field, 'field');
    if (
      this.state.errorData &&
      this.state.errorData[field] &&
      this.state.errorData[field][0]
    ) {
      this.state.errorData[field][0] = null;
    }
    const test = this.state;
    if (field == 'isacceptedtnc' || field == 'newsletter') {
      test.formValues[field] = event.target.checked;
      if (field == 'isacceptedtnc') {
        test.tncVisible = event.target.checked
      }
    } else {
      test.formValues[field] = event.target.value;

    }
    this.setState({ ...test });
  };

  onCancelTnC = () => {
    this.state.formValues.isacceptedtnc = false;

    this.setState({ ...this.state })
  }

  onAcceptTnC = () => {
    this.state.formValues.isacceptedtnc = true;

    this.setState({ ...this.state })
  }

  render() {
    return (
      <div>
        <main className="mainContainer">
          <div className="container">
            <div className="signup">
              <form>
                <div className="signup-head">
                  <h2>CREATE NEW ACCOUNT</h2>
                  <p>Creating an account is easy. Just fill in the form below.</p>
                </div>
                {(() => {
                  if (this.state.successMessage !== '' && this.state.successMessage !== undefined) {
                    return <div className="alert alert-success">{this.state.successMessage}</div>
                  } else if (this.state.errorMessage !== '' && this.state.errorMessage !== undefined) {
                    return <div className="alert alert-danger">{this.state.errorMessage}</div>
                  }
                })()}


                <div className="signup-mid">
                  <h3>PERSONAL INFORMARTION</h3>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="First Name"
                          value={this.state.formValues.firstname}
                          onChange={(event) =>
                            this.handleChange(event, "firstname")
                          }
                        />
                        <span className="form-field-error">
                          {this.state.errorData ?.firstname &&
                            this.state.errorData.firstname[0]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Last Name"
                          value={this.state.formValues.lastname}
                          onChange={(event) =>
                            this.handleChange(event, "lastname")
                          }
                        />
                        <span className="form-field-error">
                          {this.state.errorData ?.lastname &&
                            this.state.errorData.lastname[0]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input type="email" className="form-control" placeholder="Email Address"
                          value={this.state.formValues.email}
                          onChange={(event) =>
                            this.handleChange(event, "email")
                          }
                        />
                        <span className="form-field-error">
                          {this.state.errorData ?.email &&
                            this.state.errorData.email[0]}
                        </span>
                      </div>

                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input type="password" className="form-control" placeholder="Password"
                          value={this.state.formValues.password}
                          onChange={(event) =>
                            this.handleChange(event, "password")
                          }
                        />
                        <span className="form-field-error">
                          {this.state.errorData ?.password &&
                            this.state.errorData.password[0]}
                        </span>
                      </div>

                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input type="password" className="form-control" placeholder="Confirm Password"
                          value={this.state.formValues.confirmPassword}
                          onChange={(event) =>
                            this.handleChange(event, "confirmPassword")
                          }
                        />
                        <span className="form-field-error">
                          {this.state.errorData ?.confirmPassword &&
                            this.state.errorData.confirmPassword[0]}
                        </span>
                      </div>

                    </div>
                  </div>

                  <div className="signup-trums-check">
                    <div className="sign-check">
                      <label className="custom-check">Terms of Use
                    <input type="checkbox"
                          data-toggle="modal" data-target="#exampleModal"
                          checked={this.state.formValues.isacceptedtnc}
                          onChange={(event) =>
                            this.handleChange(event, "isacceptedtnc")
                          } />
                        <span className="checkmark"></span>
                      </label>
                      {/* {(() => {
                        if (this.state.tncVisible) {
                          return <Link className="tnc-condition-link" to={"#"}>Go to terms & conditions</Link>
                        }
                      })()} */}

                      <span className="form-field-error">
                        {this.state.errorData ?.isacceptedtnc &&
                          this.state.errorData.isacceptedtnc[0]}
                      </span>
                    </div>
                    <div className="sign-check">
                      <label className="custom-check">SIGN UP FOR NEWSLETTER
                    <input type="checkbox"
                          checked={this.state.formValues.newsletter}
                          onChange={(event) =>
                            this.handleChange(event, "newsletter")
                          } />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>



                  <div class="modal fade modal-blog" id="exampleModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog  modal-lg">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">Terms & Conditions</h5>
                          <button type="button" onClick={this.onCancelTnC} class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">

                          <div class="modal-blog-detail">
                            <p><i><b>To agree to these terms, click the “I agree” button. If you do not agree to these terms, do not click “I agree,” and do not use the services.</b></i></p>
                            <p>By clicking the “I agree” button:</p>
                            <p>•	You acknowledge that you have read and understand these terms of use and agree to abide by them as a binding agreement.</p>
                            <p>•	You affirm that you are at least 18 years of age, or at least 13 years of age and have permission from your parent or guardian to accept this agreement and use the services. The services are not intended for use by children under the age of 13.</p>
                            <p>•	You affirm that you understand that if you provide your phone number to the company, you are giving the company express written consent to contact you about your account or purchases.</p>
                            <p>•	You specifically acknowledge that you have read and understand the disclaimers and limitations on your rights contained in Sections 8, 9, and 10 of the terms of use.</p>
                            <p>•	If you are a Vendor you understand that the Business Terms in section 18 apply to your use of the Services.</p>
                            <h2>TERMS OF USE</h2>
                            <p><b>Please read these terms carefully.</b> This is a binding agreement between Subsciety LLC (referred to as “Company,” “we,” “us” or “our”) and any person who accesses or establishes a connection to the Services (“you” or “User”).  By using or otherwise accessing the Services, or indicating your assent hereto by clicking “I agree” or similarly expressing acceptance where other options exist, you will be deemed to have accepted the terms of this agreement. The Company retains any rights not specifically granted to you in this agreement.</p>
                            <h3>1.	ACCESS TO THIS SITE</h3>
                            <p>To access any part of this website, Subsciety.com (the “Website”), mobile application called Subsciety (the “App”),  or other software, resources or services available through the Website or App (all of the foregoing, the “Services”), you agree to comply with all of the terms of this agreement. In order to use some or all of the Services on the Website, you may be asked to provide registration information. It is a condition of your use of the Services that all the information you provide is correct, current, and complete. If the Company believes the information you provide is inaccurate, the Company may terminate or suspend your access to the Services.</p>
                            <p>You agree that the Company may, without prior notice for any reason or no reason, immediately terminate your account and access to the Services. You agree that all terminations for cause shall be made in the Company’s discretion and that the Company shall not be liable to you or any third-party for any termination of your account. In the event of termination, the Company will provide you with reasonable means to export your data from the Services.</p>
                            <h3>2.	HOW THE SERVICES WORK</h3>
                            <p>The Company requires all Users to sign up as either a Consumer or a Vendor. “Consumer” means a User that is using the Company’s services to search, sign up, and manage their subscription plans with Vendors. “Vendor” means a User that is offering subscription plans to Consumers through the Services.</p>
                            <p>The Company provides Consumers with a one-stop shop for searching or comparing, signing up, and managing their subscription accounts with Vendors including management of existing subscriptions, enrolling in new subscriptions, and discovery of new subscription services.</p>
                            <p>The Company provides Vendors with a forum to offer services to the Consumers. The Company may provide additional services to Vendors including trend analysis, marketing opportunities, and the creation of customized virtual storefronts as further described  in section 18(a).</p>
                            <h3>3.	PRIVACY</h3>
                            <p>The Company’s Privacy Policy, located at [privacy policy URL], describes the Company’s collection and use of your personal and other information.</p>
                            <h3>4.	RESTRICTIONS ON USE</h3>
                            <p>Consumers may access the Services only for non-commercial use by you or your organization for personal or internal business purposes. Consumers may not use the Services for any other purpose. You may not, for example, (a) modify, publish, distribute, transmit, systematically download, use automated means to index or extract data from, participate in the transfer or sale or rental of, translate, create derivative works from, frame, co-brand, or in any way exploit any part of the Services other than for personal or internal business use or as specifically permitted in this agreement, without the Company’s written consent, or (b) use the Services in any harmful or illegal manner or interfere with any party’s use or enjoyment of the Services. You agree to cooperate with the Company in causing any of your unauthorized use of the Services to immediately cease.</p>
                            <h3>5.	OWNERSHIP</h3>
                            <p>The Company owns all right, title and interest in and to the Services, including all intellectual property rights therein.</p>
                            <p>The material accessible from the App and Website, including text, data, images, interfaces, the “look and feel” of the App and Website, and other materials or works of authorship (the “Content”) is owned or licensed by the Company. You may not copy, distribute, republish, upload, post, or transmit Content in any way without the prior written consent of the Company, except that you may print out limited portions of the Content solely for your personal use. You may not remove or alter, or cause to be removed or altered, any copyright, trademark, trade name, service mark, or any other proprietary notice or legend appearing on any of the Content. The Company has the right to modify, manage or eliminate any Content at any time.</p>
                            <p>The Company’s name, logos, and other product and service identifiers are the Company’s trademarks.  All other trademarks appearing on the App and Website are the property of their respective owners.  No rights are granted to you in these trademarks.</p>
                            <h3>6.	USER SUBMISSIONS </h3>
                            <p>A “Submission” means any information, ideas or materials that Users provide to us via any post, upload, input or other submission to the Services. You retain ownership of your Submissions. If you provide a Submission that is posted on the Website, you hereby grant the Company a perpetual, royalty-free, exclusive, non-transferable, non-sublicensable, worldwide license to publicly display and use the Submission. </p>
                            <p>You are solely responsible for any violation under any theory of law that may be alleged relating to your Submissions, and any damages resulting therefrom. You may not post, send, submit, publish, or transmit in connection with the Services any material that (a) you do not have the right to post, including proprietary material of any third party, (b) contains information obtained illegally or advocates illegal activity or discusses an intent to commit an illegal act, (c) is vulgar, obscene, abusive or threatening, (d) libels, defames, or invades the privacy of other Users, (e) does not pertain directly to the subject matter of the Services or advertises another product or service, (f) includes programs that contain viruses, worms, or any other malicious computer code, or (g) contains hyperlinks to other sites that contain content that falls within the descriptions set forth above. </p>
                            <p>Without limiting the foregoing responsibilities of the Users, the Company may monitor use of the App and Website to determine compliance with this agreement. The Company may remove or refuse Submissions for any reason. </p>
                            <h3>7.	HYPERLINKS</h3>
                            <p>The Website may include hyperlinks to other websites that are not owned or operated by the Company. These links are provided for your convenience, and the Company may receive commissions or other financial compensation from the owners of these websites. Hyperlinks are to be accessed at your own risk.  The Company may not have reviewed, and does not necessarily endorse, the content of other websites. The Company has no control over other websites and is not liable for any content, advertising, products, services or other materials on or available from those websites. Nonetheless, we wish to protect the Users of the Website, and we therefore invite feedback about websites that are linked from this Website.</p>
                            <h3>8.	DIGITAL MILLENNIUM COPYRIGHT ACT (“DMCA”) NOTICE TO COPYRIGHT OWNERS</h3>
                            <p>The Company owns, protects and enforces copyrights in its own creative material and respects the copyrights of others. Materials may be made available on the Services, or via the Services, by third parties not within the control of the Company. It is our policy not to permit materials known by us to be infringing to remain on this Website. </p>
                            <p>In accordance with the Digital Millennium Copyright Act, or “DMCA” (summary <a href="http://www.copyright.gov/reports/studies/dmca/dmca_executive.html" target="_blank">here</a>), you should notify us promptly if you believe any materials displayed within the Services infringe your copyright; please send your notice by e-mail for prompt attention. Regardless of whether we are liable for such infringement, our response may include removing or disabling access to material claimed to be infringing activity and/or terminating the alleged infringer’s access to the Website. If we remove or disable access in response to your notice, we will make a good-faith attempt to contact the person who submitted the material so that they may have the opportunity to submit a counter notification.</p>
                            <p>Please send all notices to the Company at DMCA@mygoodcounsel.com or 1 S. Dearborn, 20th Floor, Chicago, IL 60603.</p>
                            <p>Your notice of alleged copyright infringement should include:</p>
                            <ul>
                              <li>A description of how your copyrighted work or other intellectual property has been infringed;</li>
                              <li>A description of where the infringing material is located on the Website;</li>
                              <li>Where we can contact you and, if different, where the allegedly infringing party can contact you;</li>
                              <li>A statement that you believe that the use of the material is not authorized by the copyright or other intellectual property rights owner, by its agent, or by law;</li>
                              <li>A statement, under penalty of perjury, that the information in the notification is correct and that you are authorized to act on behalf of the owner of the exclusive right that is alleged to be infringed; and</li>
                              <li>Your electronic or physical signature.</li>
                            </ul>
                            <p>Please note that under the DMCA, misrepresentations made in your notices or counter notices can expose you to liability for substantial damages. If you are not sure whether material available on the Website infringes your copyright, or whether material posted by you is infringing, you should seek legal advice.</p>
                            <h3>9.	SECURITY AND SECURITY VIOLATIONS</h3>
                            <p>If you are a User who has registered an account for the Services, passwords used to access the Services are for individual use only. You are responsible for the security of your own password and for all activities that occur through the use of your account if accessed with your password, including liability for damages resulting from misuse. If you use a password that the Company considers insecure, the Company may require you to change the password or terminate your account.</p>
                            <p>You may not attempt to violate the security of the Services, or use the Services to violate the security of other persons or websites or to violate the law, including by: (a) accessing data not owned by or intended for you or logging into an account that you are not authorized to access; (b) attempting to probe, scan or test the vulnerability of the Services or to breach security or authentication measures; (c) attempting to interfere with service to any User, host or network, including without limitation, by submitting a virus to the Services; (d) sending unsolicited e-mail; (e) forging any TCP/IP packet header or any part of the header information in any e-mail; or (f) attempting to alter, make derivative works of, copy, disassemble or reverse engineer any of the software making up any part of the Services.</p>
                            <p>The Company will take all reasonably necessary steps to investigate suspected violations of this agreement. The Company reserves the right to involve and fully cooperate with any law enforcement authorities and comply with court orders requesting or directing the Company to disclose the identity of anyone engaging in conduct that is believed to violate the law. The Company further reserves the right, in its discretion, to release your details to system administrators at other sites in order to assist them in resolving security incidents. </p>
                            <p><b>You release the Company from and against any action taken by the Company during or as a result of its investigations and from any actions taken as a consequence of investigations by either the Company or law enforcement authorities.</b></p>
                            <h3>10.	IMPORTANT DISCLAIMERS</h3>
                            <p><b>Your use of the Services is at your own risk. The Services are provided on an “as is” and “as available” basis. The Company disclaims any warranties, express or implied, including any implied warranties of merchantability, fitness for a particular purpose, title, or non-infringement, and any warranties arising out of course of dealing or usage of trade.</b></p>
                            <p><b>The Company does not complete background checks on Users or make and representations about the location, safety, or quality of the Users or Services. The Company has no responsibility for your interactions with other Users of the Services. Your interactions with such persons are at your own risk.</b></p>
                            <p>By way of illustration, and without limiting the generality of the disclaimer in the paragraph above, the Company disclaims any warranty that:</p>
                            <ul>
                              <li>The Services will be uninterrupted or error-free, or that defects will be corrected;</li>
                              <li>The App and Website and the servers that make the Services available are free of viruses or other harmful components; or</li>
                              <li>The Content is accurate, complete, or free of typographical errors.</li>
                            </ul>
                            <p>The inclusion or offering for sale of any product or service as part of the Services does not constitute an endorsement or recommendation by the Company, and you agree not to make any claim against the Company relating to the purchase of these products or services.</p>
                            <p>Updates to the App may not be consistent across all platforms and devices. If you do not refresh the App after an update, the App may not reflect the most recent features, functionality, or Content.  </p>
                            <h3>11.	LIMITATION ON LIABILITY</h3>
                            <p><b>The Company, its subsidiaries, affiliates, licensors, service providers, content providers, employees, agents, officers, and directors shall not be liable for any incidental, direct, indirect, punitive, actual, consequential, special, exemplary, or other damages, including loss of revenue or income, pain and suffering, emotional distress, or similar damages, even if the Company has been advised of the possibility of such damages. If you are a California resident, you waive California Civil Code Section 1542, which states, in part: “A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.”</b></p>
                            <p><b>In no event will the collective liability of the Company and its subsidiaries, affiliates, licensors, service providers, content providers, employees, agents, officers, and directors to any party (regardless of the form of action, whether in contract, tort, or otherwise) exceed $500.</b></p>
                            <h3>12.	INDEMNIFICATION</h3>
                            <p>To the maximum extent permitted by law, you shall indemnify the Company, its subsidiaries, affiliates, licensors, service providers, content providers, employees, agents, officers, and directors from and against all third-party claims, liabilities and expenses, including legal fees and costs, relating to your use of the Services or your breach of any representation or obligation contained in this agreement. The Company reserves the right, in its sole discretion and at its own expense, to assume the exclusive defense and control of any claim for which you are obligated to provide indemnification under this section. You shall fully cooperate as reasonably required in the defense of any claim.</p>
                            <h3>13.	AMENDMENT</h3>
                            <p>This agreement constitutes the entire agreement between the parties relating to the subject matter contained herein. The Company may modify this agreement at any time by posting the revised terms on the App or Website and providing you with the opportunity to accept or reject the modifications during your next sign-on to the Services. If you do not agree to the modifications, you may be required to cease your access to the Services.  Continued use or access of the Service after modification will constitute your acceptance of this agreement as modified.</p>
                            <h3>14.	DISPUTE RESOLUTION; JURY WAIVER</h3>
                            <p>All disputes relating to the interpretation of this agreement or the rights of the parties hereunder will be exclusively settled by arbitration administered by the American Arbitration Association (“AAA”) under its Commercial Arbitration Rules. Disputes involving $75,000 or less shall use the AAA’s Expedited Rules. The parties shall mutually agree upon a single commercial arbitrator, and in the absence of agreement, the AAA shall select the arbitrator. The place of arbitration shall be Chicago, Illinois. The parties will share equally in the costs of arbitration payable to the AAA, including the arbitrator. The award of the arbitrator will be accompanied by a reasoned opinion. Judgment on an arbitration award may be entered in accordance with the Federal Arbitration Act in any federal court having jurisdiction.</p>
                            <p><b>You acknowledge and agree that you and the Company are each waiving the right to a trial by jury or to participate as a plaintiff or class member in any purported class action or representative proceeding. Further, unless both you and the Company otherwise agree in writing, the arbitrator may not consolidate more than one person's claims and may not otherwise preside over any form of any class or representative proceeding. </b></p>
                            <p>The parties to this agreement may, notwithstanding the above, seek equitable relief in any proper court to enjoin a breach or threatened breach of any obligations under this agreement that might cause irreparable harm (without any requirement to post bond).</p>
                            <h3>15.	MISCELLANEOUS</h3>
                            <p>This agreement shall be governed and interpreted pursuant to the laws of the State of Illinois, United States of America, without regard to its choice of law rules. If any part of this agreement is unlawful, void, or unenforceable, that part will be deemed severable and will not affect the validity and enforceability of any remaining provisions. Any notices or other communications permitted or required hereunder will be in writing and given by the Company via e-mail, to the address that you provided when registering for the Services, and will be effective upon transmission.</p>
                            <h3>16.	ADA</h3>
                            <p>We are committed to ensuring that individuals with disabilities can access the Services.
Please provide us with Feedback if you have trouble accessing the Website or App.
</p>
                            <h3>17.	CONTACT AND FEEDBACK</h3>
                            <p>We welcome and encourage feedback, comments and suggestions for improvements to the Services (“Feedback”). You may submit Feedback by emailing us at Info@subsciety.com or through the “Help” section of the Website. You agree that all Feedback will become the sole and exclusive property of the Company and you hereby irrevocably assign to the Company all of your right in and to all Feedback.</p>
                            <p>If you are a resident of California, you may request additional information or submit claims or complaints regarding the Services by calling the Complaint Assistance Unit of the Division of Consumer Services of California Department of Consumer Affairs at (916) 445-1254, or in writing at: </p>
                            <p>Complaint Assistance Unit<br />
                              Division of Consumer Services<br />
                              California Department of Consumer Affairs<br />
                              1625 N. Market Blvd., Suite N 112<br />
                              Sacramento, California 95834.
</p>
                            <br />
                            <p>Last updated: July 12, 2020</p>









                          </div>


                          <label className="custom-check" style={{ marginTop: "20px" }}>accept terms of use
                              <input type="checkbox"
                              checked={true}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <div className="button-center">
                            <button type="button" class="btn btn-secondary" onClick={this.onCancelTnC} data-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-primary" onClick={this.onAcceptTnC} data-dismiss="modal">Accept</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>
                <div className="signup-bottom">
                  <div className="signup-submit">
                    <button type="button"
                      onClick={(event) => this.handleSignUp(event)}
                    >continue
                       {(() => {
                        if (this.state.showLoader) {
                          return <span class="spinner-border spinner-border-sm ml-1"></span>
                        }
                      })()}
                    </button>
                  </div>
                  <div className="already-account-link">
                    <p>already have an account? <Link to={'/login'}> login now</Link></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    isRegistered: state.authReducer.isRegistered,
    code: state.authReducer.code,
    isLoggedIn: state.authReducer.isLoggedIn,
    authData: state.authReducer.authData,
    errorData: state.commonReducer.errorData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ signup, vemail, login, logout }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));
