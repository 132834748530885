import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getOrdersList = (offset) => async (dispatch) => {
    // console.log("getOrdersList userId is", userId, " offset is", offset);
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/orders?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("orders list response from backend", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

export const getOrdersListByUser = (userId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/orders/${userId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}


export const searchOrder = (offset, searchKeyword) => async (dispatch) => {
    // console.log("getOrdersList offset is", offset);
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/orders/by/search?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("orders list response from backend", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}


export const getOrderDetail = (orderId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/orders/${orderId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("orders details response", res);
        return res;
    }
    catch (error) {
        console.log("error while getting order details", error)
        dispatch(handleError(error));
    }
}

export const updateOrderDetail = (orderDetail) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/orders/update-order`,
            data: orderDetail,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        dispatch({ type: "LOADING_COMPLETED" });

        console.log("update data response is", res);

        return res;
    }
    catch (error) {
        console.log("error while updating order details", error)
        dispatch(handleError(error));
    }
}

export const getOrderTracking = (trackingId) => async (dispatch) => {
    try{
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/usps/${trackingId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("orders tracking response", res);
        await dispatch({
            type: "GET_ORDER_TRACKING_DETAILS",
            payload: { data: res?.data?.trackData?.elements }
          });

        return res;
    }
    catch(error){
        console.log("error while getting order details", error)
        dispatch(handleError(error));
    }
}
