import React, { Component } from 'react'
import '../../Styles/dashboard.scss'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logoutUser } from '../../Redux/Actions/authAction';
import { toggleSidebar } from '../../Redux/Actions/commonAction';


class Header extends Component {

    constructor(props) {
        super(props);
    }

    handleLogout = async (event) => {
        event.preventDefault();
        this.props.logoutUser();
    };

    render() {
        return (
            <div style={{marginTop:20}}>
                {/* <div class="row">
                    <div class="col-lg-10 col-md-10">
                        <div class="header-top-left">
                            <div class="toggle-btn">
                                <button onClick={() => this.props.toggleSidebar()} type="button"><i class="fas fa-bars"></i></button>
                            </div>
                            <div class="header-top-search">
                                <button type="button"><i class="fas fa-search"></i></button>
                                <input class="form-control" type="text" placeholder="SEARCH" />
                            </div>
                        </div>


                    </div>
                    <div class="col-lg-2 col-md-2">
                        <div class="header-top-menu">
                            <ul>
                                <li><a title="notifications" href="javascript:;" class="header-addtocart-item"><i class="far fa-bell"></i><span>30</span></a></li>
                                <li><a title="sign out" onClick={(event) => this.handleLogout(event)} href="#"><i class="fas fa-power-off"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div> */}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ logoutUser, toggleSidebar }, dispatch)
}

export default connect(null, mapDispatchToProps)(Header)
