import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";

import './notifications.scss';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import {getNotifications} from "../../Redux/Actions/notificationAction";

const Notifications = (props) => {
    const dispatch = useDispatch();

    const [notificationLoading, setNotificationLoading] = useState(false);
    const [notificationsList, setNotificationsList] = useState([]);
    const [totalNotifications, setTotalNotifications] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        (async () => {
            await fetchNotificationList();
        })();
    }, []);

    const fetchNotificationList = async (page) => {
        (!page || page === 1) && setNotificationLoading(true);
        const notificationResponse = await dispatch(getNotifications(page || 1));
        setNotificationLoading(false);
        if(!notificationResponse.isSuccess) {
            alert('Error fetching notifications. Please try again.');
            return;
        }
        setNotificationsList(prev => ([...prev, ...notificationResponse.data]));
        setTotalNotifications(notificationResponse.total);
    };

    const getIcon = (notification) => {
        switch (notification.type) {
            case 'USER_SIGN_UP': {
                return 'fa fa-user';
            }
            case 'ORG_USER_SIGN_UP': {
                return 'fa fa-users';
            }
            case 'ORDER_PLACED_SUCCESSFULLY': {
                return 'fa fa-shopping-cart';
            }
            case 'COVID-19-APPOINTMENT': {
                return 'fa fa-address-card';
            }
            case 'COVID-19-APPOINTMENT-CANCEL': {
                return 'fa fa-user-times'
            }
            case 'ORDER_FEEDBACK_RECEIVED': {
                return 'fa fa-comments'
            }
            default:
                return 'fa fa-circle';
        }
    };

    const renderNotifications = () => {
        if(notificationsList.length === 0) {
            return (
                <span><b>There are no notifications found</b></span>
            )
        }

        return notificationsList?.map((notification) => {
            return (
                <div className={'notification'}>
                    <div className={'d-flex flex-row'}>
                        <div className={'icon'}>
                            <span><i className={getIcon(notification)}></i></span>
                        </div>
                        <div className={'message'} dangerouslySetInnerHTML={{ __html: notification.message }}></div>
                    </div>
                    <div className={'date'}>{moment(new Date(notification.createTS)).startOf('seconds').fromNow()}</div>
                </div>
            )
        });
    };

    const fetchMoreNotifications = async () => {
        const newPage = page + 1;
        setPage(newPage);
        await fetchNotificationList(newPage);
    };

    return (
        <div className="admin-card">
            <div className="admin-card-head">
                <h2>Notifications</h2>
            </div>
            <div className="admin-card-box">
                <div className="vendor-table">
                    <div className="vendor-table-head">
                        {/*<div className="d-flex justify-content-end">*/}
                        {/*    <button*/}
                        {/*        type="button"*/}
                        {/*        className="ml-2 secondary-outline-btn"*/}
                        {/*    ><i className="fa fa-eye" aria-hidden="true"></i> Mark all as read*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </div>

                    <div className="vendor-table-body">
                        <div className="notifications-container">
                            {
                                notificationLoading? (
                                    <div className={'spinner-div'}>
                                        <span className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </span>
                                    </div>
                                ) :
                                    <InfiniteScroll
                                        dataLength={notificationsList.length}
                                        next={() => fetchMoreNotifications()}
                                        hasMore={totalNotifications > notificationsList.length}
                                    >
                                        {renderNotifications()}
                                    </InfiniteScroll>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Notifications;
