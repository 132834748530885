import React, { Component } from 'react';
import '../../Styles/user-detail.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    updateShippingAddress,
    getShippingAddress
} from "../../Redux/Actions/settingAction";
import {
    getCountries,
    getStates,
    getCities
} from "../../Redux/Actions/userAction";
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import 'react-confirm-alert/src/react-confirm-alert.css';

class ShippingAddress extends Component {

    constructor(props) {
        super(props);
        this.state = {
            saveSettingsLoading: false,
            loadSettings: false,
            name: '',
            company: '',
            street1: '',
            state: '',
            city: '',
            zip: '',
            email: '',
            phoneInput: '',
            phone: '',
            getStateListByCountries: [],
            getCitiesByState: [],
            errorMessage: '',
            errorData: {
                name: [],
                company: [],
                street1: [],
                state: [],
                city: [],
                zip: [],
                email: [],
                phone: [],
            }
        };

    }

    async componentDidMount() {
        this.setState({
            loadSettings: true
        });
        const countries = await this.props.getCountries();
        let states;
        if (countries.data) {
            const country = countries.data[0];
            states = (await this.props.getStates(country.id)).data;

            this.setState({
                getStateListByCountries: states,
            });
        }

        const existingAddress = await this.props.getShippingAddress();
        if(existingAddress.data) {
            const address = existingAddress.data;

            const selectedState = (states.find((state) => state.state_code === address.state) || {});
            const city = (await this.props.getCities(selectedState.id || states[0].id)).data;
            this.setState({
                getCitiesByState: city,
            });
            this.setState({
                name: address.name || '',
                company: address.company || '',
                street1: address.street1 || '',
                state: selectedState.state_name || '',
                city: address.city || '',
                zip: address.zip || '',
                email: address.email || '',
                phone: address.phone || '',
                getCitiesByState: city || '',
                loadSettings: false
            });
            this.renderPhoneInput(address.phone || '');
        }
    }

    handleChange = (e, key) => {
        if (this.state.errorData && this.state.errorData[key] && this.state.errorData[key][0]) {
            this.state.errorData[key][0] = null;
        }

        let { value } = e.target;

        if(key === 'zip') {
            const regEx = /^\b\d{0,5}\b$/;
            if (value === '' || regEx.test(value)) {
                this.setState({
                    zip: value
                });
            }
            return;
        }
        this.setState(prevState => ({
            ...prevState,
            [key]: value
        }))
    };

    validateAddressInfo = (formData) => {
        let isFormValid = true;
        const error = {
            name: [],
            company: [],
            street1: [],
            state: [],
            city: [],
            zip: [],
            email: [],
            phone: [],
        };

        if (!formData.name || !formData.name.trim()) {
            isFormValid = false;
            error.name = ["Required field"];
        }
        if (!formData.company || !formData.company.trim()) {
            isFormValid = false;
            error.company = ["Required field"];
        }
        if (!formData.street1 || !formData.street1.trim()) {
            isFormValid = false;
            error.street1 = ["Required field"];
        }
        if (!formData.state || !formData.state.trim()) {
            isFormValid = false;
            error.state = ["Required field"];
        }
        if (!formData.city || !formData.city.trim()) {
            isFormValid = false;
            error.city = ["Required field"];
        }
        if (!formData.zip || !formData.zip.trim()) {
            isFormValid = false;
            error.zip = ["Required field"];
        }

        if (!formData.email || !formData.email.trim()) {
            isFormValid = false;
            error.email = ["Required field"];
        }
        if (!formData.phone || !formData.phone.trim()) {
            isFormValid = false;
            error.phone = ["Required field"];
        }

        this.setState({
            ...this.state,
            errorData: error
        });
        return isFormValid;
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const formValid = this.validateAddressInfo(this.state);
        if(!formValid) {
            return;
        }
        this.setState({
            saveSettingsLoading: true
        });

        let body = {
            name: this.state.name,
            company: this.state.company,
            street1: this.state.street1,
            state: this.state.getStateListByCountries.find((state) => state.state_name === this.state.state).state_code,
            city: this.state.city,
            zip: this.state.zip,
            email: this.state.email,
            phone: this.state.phone,
        };

        const response = await this.props.updateShippingAddress(null, body);

        if (response && response.data) {
            if (!response.data.isSuccess) {
                this.setState({
                    errorMessage: response.data.errorMessage,
                })
            }
            this.setState({
                saveSettingsLoading: false
            });
        }
        else {
            this.setState({
                errorMessage: "Something went wrong. Please try again later.",
                saveSettingsLoading: false
            })
        }
    };

    handlePhoneNumberChange(value) {
        this.setState({
            phone: formatPhoneNumberIntl(value)
        });
    }

    renderPhoneInput = (phone) => {
        const phnInput = <PhoneInput
            defaultCountry="US"
            countries={["US"]}
            addInternationalOption={false}
            class="form-control"
            placeholder="+x xxx xxx xxxx"
            value={phone? formatPhoneNumberIntl(phone): this.state.phone}
            limitMaxLength={true}
            onChange={(value) => this.handlePhoneNumberChange(value)}/>;

        this.setState({
            phoneInput: phnInput
        });
    };

    stateChange = async (e) => {
        e.preventDefault()
        let selectedState = this.state.getStateListByCountries.find((state) => state.state_name === e.target.value);
        const city = (await this.props.getCities(parseInt(selectedState.id))).data;
        if (this.state.errorData && this.state.errorData.state && this.state.errorData.state[0]) {
            this.state.errorData.state[0] = null;
        }
        this.setState({
            state: selectedState.state_name,
            getCitiesByState: city
        });
    };

    cityChange = (e) => {
        let selectedCity = this.state.getCitiesByState.find((city) => city.city === e.target.value);
        if (this.state.errorData && this.state.errorData.city && this.state.errorData.city[0]) {
            this.state.errorData.city[0] = null;
        }
        this.setState({
            city: selectedCity.city,
        });
    };

    render() {
        const {loadSettings} = this.state;
        return (
            <div class="admin-card">
                <div class="admin-card-box">
                    {loadSettings ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (null)}

                    {
                        this.state.errorMessage &&
                        <div className="alert alert-danger">{this.state.errorMessage}</div>
                    }
                    <div className="row mt-3">
                        <div className="col-lg-12 col-md-12 d-flex">
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>Name<sup>*</sup>
                                    </label>
                                    <input type="text" onChange={(e) => this.handleChange(e, "name")}
                                           value={this.state.name} className="form-control"/>
                                    <span className="form-field-error">
                                        {this.state.errorData?.name &&
                                        this.state.errorData.name[0]}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>Company<sup>*</sup>
                                    </label>
                                    <input type="text" onChange={(e) => this.handleChange(e, "company")}
                                           value={this.state.company} className="form-control"/>
                                    <span className="form-field-error">
                                        {this.state.errorData?.company &&
                                        this.state.errorData.company[0]}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 d-flex">
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>Address Line 1<sup>*</sup>
                                    </label>
                                    <input type="text" onChange={(e) => this.handleChange(e, "street1")}
                                           value={this.state.street1} className="form-control"/>
                                    <span className="form-field-error">
                                        {this.state.errorData?.street1 &&
                                        this.state.errorData.street1[0]}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 d-flex">
                            <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>State <sup>*</sup></label>
                                    <select
                                        className="form-control"
                                        value={this.state.state}
                                        onChange={(e) => this.stateChange(e)}>
                                        <option value="" disabled>
                                            Select State
                                        </option>
                                        {
                                            this.state.getStateListByCountries?.map((state, index) =>
                                                <option key={index}
                                                        value={state.state_name}>
                                                    {state.state_name}
                                                </option>
                                            )}
                                    </select>
                                    <span className="form-field-error">
                                        {this.state.errorData?.state &&
                                        this.state.errorData.state[0]}
                                    </span>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>City <sup>*</sup></label>
                                    <select
                                        className="form-control"
                                        value={this.state.city}
                                        onChange={(e) => this.cityChange(e)}>
                                        <option value="" disabled>
                                            Select City
                                        </option>
                                        {
                                            this.state.getCitiesByState?.map((city, index) =>
                                                <option key={index}
                                                        value={city.city}>
                                                    {city.city}
                                                </option>
                                            )}
                                    </select>
                                    <span className="form-field-error">
                                        {this.state.errorData?.city &&
                                        this.state.errorData.city[0]}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>Zip Code <sup>*</sup></label>
                                    <input
                                        type="text"
                                        value={this.state.zip}
                                        class="form-control"
                                        onChange={(e) => this.handleChange(e, "zip")}
                                    />
                                    <span className="form-field-error">
                                        {this.state.errorData?.zip &&
                                        this.state.errorData.zip[0]}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 d-flex">
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>Email<sup>*</sup>
                                    </label>
                                    <input type="email" onChange={(e) => this.handleChange(e, "email")}
                                           value={this.state.email} className="form-control"/>
                                    <span className="form-field-error">
                                        {this.state.errorData?.email &&
                                        this.state.errorData.email[0]}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>Phone Number<sup>*</sup>
                                    </label>
                                    {this.state.phoneInput}
                                    <span className="form-field-error">
                                        {this.state.errorData?.phone &&
                                        this.state.errorData.phone[0]}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-btn text-center">
                            <button type="button" onClick={(e)=> this.handleSubmit(e)} class="orange-outline-btn">submit
                                {(() => {
                                    if (this.state ?.saveSettingsLoading) {
                                        return <span class="spinner-border spinner-border-sm ml-1"></span>
                                    }
                                })()}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateShippingAddress,
        getShippingAddress,
        getCountries,
        getStates,
        getCities,
    }, dispatch);
};

export default connect(null, mapDispatchToProps)(ShippingAddress)
