import React from "react";
import { Link } from "react-router-dom";
// import "./permissionError.scss";

class PermissionError extends React.Component {
  render() {
    return (
      <div className="lock-auth">
        <div className="lock-admin">
          <span>
            <i className="fa fa-lock" aria-hidden="true"></i>
          </span>
        </div>
        <div className="error-auth-msg">
          <h1>Oops! You don't have access to this page.</h1>
          <Link to="/signup">Login here again</Link>
        </div>
      </div>
    );
  }
}

export default PermissionError;
