// check users and replace with items
// confirm status column value in the list to display

import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce } from 'lodash';

import { getProductCategoryList, searchProduct ,getProductList} from '../../Redux/Actions/productCategoryAction';
// import { getProductList } from '../../Redux/Actions/productAction';

class Product_Category_List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            maxLimit: 10,
            offset: 0,
            prevOffset: 0,
            items: [],
            searchKeyword: "",
            hasMore: true,
            showLoader: false
        };
    }

    componentDidMount() {
        this.fetchMoreData(true);
    }

    fetchMoreData = async (firstLoad) => {
        this.setState({
            showLoader: true
        })
        if (this.state.offset !== this.state.prevOffset || firstLoad) {
            if (this.state.hasMore && this.state.maxLimit && (this.state.maxLimit === "all" || this.state.maxLimit > this.state.offset)) {
                let result;
                if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
                    result = await this.props.searchProduct(this.state.offset, this.state.searchKeyword.trim());
                }
                else {
                    // result = await this.props.getProductCategoryList(this.state.offset);
                    result = await this.props.getProductList(this.state.offset);
                    
                }

                // let result = await this.props.getUsersList(this.state.offset);
                if (result ?.data ?.data ?.length > 0) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            prevOffset: prevState.offset,
                            offset: prevState.offset + 10,
                            items: prevState.items.concat(result.data.data),
                            showLoader: false
                        }
                    })
                }
                else {
                    this.setState({ hasMore: false, showLoader: false });
                }
            } else {
                this.setState({
                    showLoader: false
                })
            }
        } else {
            this.setState({
                showLoader: false
            })
        }
    };

    handleChange = async (event, field) => {
        event.preventDefault();
        // console.log("event value is", +event.target.value);

        if (field === "maxLimit") {
            await this.setState({
                [field]: event.target.value === "all" ? event.target.value : +event.target.value
            })
            this.fetchMoreData(false);
        }
        else {
            await this.setState({
                [field]: event.target.value,
                offset: 0,
                prevOffset: 0,
                hasMore: true
            })

            this.debounceSearchCall();
        }
    };

    debounceSearchCall = debounce(() => {
        this.setState({
            items: []
        })
        this.fetchMoreData(true);
    }, 1000);

    render() {

        const itemsList = this.state.items.map(i => {
            console.log("IEEEEEE",i)
            return (
                <tr key={i.id}>
                    <td align="center">{i.id ? i.id : "-"}</td>
                    <td>{i.name ? i.name : "-"}</td>
                    <td>{i.isActive === 1 ? <p style={{color: "green"}}>Active</p> :<p style={{color: "red"}}>Deactivated</p> }</td>
                    {/* <td>{i.image_name ? i.image_name : "-"}</td> */}
                    {/* <td>{i.parentId ? i.parentId : "-"}</td> */}

                    <td align="center">
                        <div class="vendor-table-btn">
                            <Link to={`edit-product/${i.id}`}>
                                <button type="button" class="orange-outline-btn" >Edit
                                </button>
                            </Link>
                        </div>
                    </td>
                </tr>
            )
        })

        return (
            <div class="admin-card">
                <div class="admin-card-head">
                    <h2>Product list</h2>
                    <Link to={'/add-product'}>
                        <button type="text" class="orange-outline-btn">
                            <i class="fas fa-plus"></i> Add
                        </button>
                    </Link>
                </div>

                <div class="admin-card-box">
                    <div class="vendor-table">

                        <div class="vendor-table-head">
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="entries-drop">
                                        <label>show</label>
                                        <select class="entries-controls" onChange={(e) => this.handleChange(e, "maxLimit")} value={this.state.maxLimit}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="all">All</option>
                                        </select>
                                        <p>Entries</p>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8">
                                    <div class="vendor-table-head-right">
                                        <div class="vendor-table-search">
                                            <input class="form-control" onChange={(e) => this.handleChange(e, "searchKeyword")} value={this.state.searchKeyword} type="text" placeholder="search" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="vendor-table-body">
                            <div class="table-responsive">
                                <InfiniteScroll
                                    dataLength={this.state.items.length}
                                    next={() => this.fetchMoreData(false)}
                                    hasMore={this.state.hasMore}
                                >
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td align="center">#</td>
                                                <td>name</td>
                                                {/* <td>image</td> */}
                                                {/* <td>Parent ID</td> */}
                                                <td >Status</td>
                                                <td align="center">action</td>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {itemsList}

                                        </tbody>

                                    </table>
                                </InfiniteScroll>
                                {this.state.showLoader ? (
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (null)}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getProductCategoryList, searchProduct,getProductList }, dispatch);
}

export default connect(null, mapDispatchToProps)(Product_Category_List)
