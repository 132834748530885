import React  from "react";
import logo from '../../Assets/Images/retrogen-logo.png';

class KitIdLabel extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {item} = this.props;
        return (
            <div className={'kit-id-label'}>
                <div className={'mb-0 pb-0'}><img className={'logo'} src={logo}/> </div>
                <label className={'content d-block pt-3 m-0'}><b>{item.registrationKitId}</b></label>
            </div>
        );
    }
}

export default KitIdLabel;
