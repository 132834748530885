import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getStatisticsData = (timeframe, startDate, endDate) => async (dispatch) => {
  console.log("inside getStatisticsData timeframe is", timeframe);
  
  try {

    let url = "";

    if (timeframe === "date") {
      if (startDate && startDate != "" && endDate && endDate != "") {
        url = `${URL}/api/v1/admin/stats/${timeframe}?startDate=${startDate}&endDate=${endDate}`
      }
      else if (startDate && startDate != "") {
        url = `${URL}/api/v1/admin/stats/${timeframe}?startDate=${startDate}`
      }
      else if (endDate && endDate != "") {
        url = `${URL}/api/v1/admin/stats/${timeframe}?endDate=${endDate}`
      }
    }
    else {
      url = `${URL}/api/v1/admin/stats/${timeframe}`
    }


    const res = await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });

    return res;
  }
  catch (error) {
    console.log('error at getStatisticsData', error);
    dispatch(handleError(error));
  }
}
