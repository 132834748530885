import React  from "react";
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import './settings.scss';

import {debounce} from "lodash";
import {
    searchKit,
    getKitList,
    generateKit,
    getSingleKit,
    updateUsedKitId,
} from "../../Redux/Actions/settingAction";
import InfiniteScroll from "react-infinite-scroll-component";
import {Button, Modal} from "react-bootstrap";
import ReactToPrint from "react-to-print";
import KitIdLabel from "../Order-Detail/kit-id-label";
import {labelPrint} from "../helper/helper";
const URL = process.env.REACT_APP_API_URL;

class PanelSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allKits:[],
            loadKits: false,
            offset: 0,
            prevOffset: 0,
            maxLimit: 20,
            hasMore: true,
            searchKeyword: "",
            showGenerateModal: false,
            showExportModal: false,
            errorMessage: '',
            saveKitLoading: false,
            exportKitLoading: false,
            showAfterPrintModal: false,
            totalKit: '',
            exportKits: '',
            selectedKit: {},
            singleKit: {},
            componentRef: {},
            errorData: {
                totalKit: [],
            }
        };
    }
    async componentDidMount() {
        this.fetchMoreData(true);
    }

    fetchMoreData = async (firstLoad) => {
        this.setState({
            loadKits: true
        });

        if (this.state.offset !== this.state.prevOffset || firstLoad) {
            if (this.state.hasMore && this.state.maxLimit && (this.state.maxLimit === "all" || this.state.maxLimit > this.state.offset)) {
                let result;
                if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
                    result = await this.props.searchKit(this.state.offset, this.state.searchKeyword.trim());
                }
                else {
                    result = await this.props.getKitList(this.state.offset);
                }

                if (result ?.data ?.data ?.length > 0) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            prevOffset: prevState.offset,
                            offset: prevState.offset + 20,
                            allKits: prevState.allKits.concat(result.data.data),
                            loadKits: false
                        }
                    })
                }
                else {
                    this.setState({ hasMore: false, loadKits: false });
                }
            } else {
                this.setState({
                    loadKits: false
                })
            }
        } else {
            this.setState({
                loadKits: false
            })
        }

    };

    handleChange = async (event, field) => {
        event.preventDefault();
        if (field === "maxLimit") {
            await this.setState({
                [field]: event.target.value === "all" ? event.target.value : +event.target.value
            });
            this.fetchMoreData(false);
        }
        else if (field === 'searchKeyword') {
            await this.setState({
                [field]: event.target.value,
                offset: 0,
                prevOffset: 0,
                hasMore: true
            });
            this.debounceSearchCall();
        } else {
            await this.setState({
                [field]: event.target.value,
            });
        }
    };

    debounceSearchCall = debounce(() => {
        this.setState({
            allKits: [],
        });
        this.fetchMoreData(true);
    }, 1000);

    onShowGenerateModal = (selectedKit) => {
        this.setState({
            showGenerateModal: true,
            selectedKit
        });
    };

    onShowExportModal = (selectedKit) => {
        this.setState({
            showExportModal: true,
            errorMessage: '',
            exportKits: '',
            selectedKit
        });
    };

    handleCloseGenerateModal = () => {
        this.setState({
            showGenerateModal: false,
            selectedKit: {},
        });
    };

    handleCloseExportModal = () => {
        this.setState({
            showExportModal: false,
            selectedKit: {},
        });
    };

    validateKitGenerateForm = (formData) => {
        let isFormValid = true;
        const error = {
            totalKit: [],
        };

        if (!formData.totalKit || formData.totalKit === 0) {
            isFormValid = false;
            error.totalKit = ["Total kits cannot be 0"];
        }

        this.setState({
            ...this.state,
            errorData: error
        });
        return isFormValid;
    };

    validateKitExportForm = (formData) => {
        let isFormValid = true;
        const error = {
            exportKits: [],
        };

        if (!formData.exportKits || formData.exportKits === 0) {
            isFormValid = false;
            error.exportKits = ["Total kits cannot be 0"];
        }

        if (formData.exportKits > this.state.selectedKit.newKits) {
            isFormValid = false;
            error.exportKits = [`Cannot export ${formData.exportKits} kit ids as only ${this.state.selectedKit.newKits} kit ids are available`];
        }

        this.setState({
            ...this.state,
            errorData: error
        });
        return isFormValid;
    };

    handleSaveGenerate = async () => {
        const formValid = this.validateKitGenerateForm(this.state);
        if(!formValid) {
            return;
        }

        this.setState({
            saveKitLoading: true
        });

        const body = {
            productId: this.state.selectedKit.id,
            totalKits: this.state.totalKit
        };
        const response = await this.props.generateKit(body);
        if (response && response.data) {
            if (response.data.isSuccess) {
                this.setState({
                    showGenerateModal: false,
                    saveKitLoading: false,
                    offset: 0,
                    prevOffset: 0,
                    hasMore: true,
                    errorMessage: '',
                });
                this.setState({
                    allKits: this.state.allKits.map((kit) => {
                      if(kit.id === this.state.selectedKit.id) {
                          kit.totalKits += response.data.data;
                          kit.newKits += response.data.data;
                      }
                      return kit;
                    })
                });
            }
            else {
                // response with failed case
                this.setState({
                    errorMessage: response.data.errorMessage,
                    saveKitLoading: false
                })
            }
        }
        else {
            this.setState({
                errorMessage: "Something went wrong. Please try again later.",
                saveKitLoading: false
            })
        }
    };

    handleSaveExport = async () => {
        const formValid = this.validateKitExportForm(this.state);
        if(!formValid) {
            return;
        }

        this.setState({
            exportKitLoading: true
        });


        window.open(`${URL}/api/v1/admin/available-kit/export?authToken=Bearer ${localStorage.getItem("token")}
            &productId=${this.state.selectedKit.id}&exportKits=${this.state.exportKits}&productName=${this.state.selectedKit.name}`, '_blank');

        this.setState({
            showExportModal: false,
            exportKitLoading: false,
            allKits: this.state.allKits.map((kit) => {
                if(kit.id === this.state.selectedKit.id) {
                    kit.newKits -= parseInt(this.state.exportKits);
                    kit.usedKits += parseInt(this.state.exportKits);
                }
                return kit;
            })
        });
    }

    printKitId = (kitObj) => {
        labelPrint.init();
        return (<i className="fa fa-print" aria-hidden="true" title={'Print kit number'}></i>);
    };

    onExportClick = (kitObj) => {
        this.onShowExportModal(kitObj)
    };

    onBeforeGetContent = () => {
        return new Promise((resolve) => {
           setTimeout(async () => {
               const response = await this.props.getSingleKit(this.state.selectedKit.id);
               if(response && response.data && response.data.isSuccess) {
                   this.setState({
                       singleKit: {
                           registrationKitId: response.data.data
                       }
                   });
               }
               setTimeout(() => {
                   resolve()
               }, 1000);
           }, 500);
        });
    };

    onAfterPrint = async() => {
        this.setState({
            loadKits: true
        });
        await this.updateUsedKitId();
        this.setState({
            loadKits: false
        });
    };

    updateUsedKitId = async () => {
        const response = await this.props.updateUsedKitId(
            this.state.selectedKit.id,
            this.state.singleKit.registrationKitId
        );
        if(response && response.data) {
            if (response.data.isSuccess) {
                this.setState({
                    allKits: this.state.allKits.map((kit) => {
                        if(kit.id === this.state.selectedKit.id) {
                            kit.usedKits += 1;
                            kit.newKits -= 1;
                        }
                        return kit;
                    })
                });
            }
        }
    };

    render() {
        const {
            allKits,
            loadKits,
            showGenerateModal,
            showExportModal,
            singleKit
        } = this.state;

        const panelsList = allKits?.map((kitObj, index) => {
            return (
                <tr key={`${kitObj.id}-${index}`}>
                    <td>{kitObj.name}</td>
                    <td>{kitObj.newKits}</td>
                    <td>{kitObj.usedKits}</td>
                    <td>{kitObj.totalKits}</td>
                    <td>
                        <div className="vendor-table-btn">
                            <button
                                type="button"
                                className="orange-outline-btn"
                                onClick={() => this.onShowGenerateModal(kitObj)}
                            >
                                Generate
                            </button>
                            {
                                kitObj.newKits ?
                                    (
                                        <>
                                            <span className="ml-2"
                                                  onClick={() => {
                                                      this.setState({
                                                          selectedKit: kitObj,
                                                      })
                                                  }
                                                  }
                                            >

                                            <ReactToPrint
                                                key={kitObj.id}
                                                trigger={() => this.printKitId()}
                                                content={() => this.state.componentRef[kitObj.id]}
                                                onBeforeGetContent={() => this.onBeforeGetContent()}
                                                onAfterPrint={() => this.onAfterPrint()}
                                            />
                                             <span className={'d-none'}>
                                                <KitIdLabel ref={(el) => (this.state.componentRef[kitObj.id] = el)} item={singleKit}/>
                                             </span>
                                            </span>
                                            <span className="ml-2"
                                                  onClick={() => this.onExportClick(kitObj)}
                                            >
                                                <i className="fa fa-file-export" aria-hidden="true" title={'Export Kit Ids'}></i>
                                            </span>

                                        </>
                                    ): null
                            }
                        </div>
                    </td>
                </tr>
            );
        });
        return (
            <div className="admin-card">
                <div className="admin-card-box">
                    <div className="vendor-table">
                        <div className="vendor-table-head">
                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <div className="entries-drop">
                                        <label>show</label>
                                        <select className="entries-controls"
                                                onChange={(e) => this.handleChange(e, "maxLimit")}
                                                value={this.state.maxLimit}>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="all">All</option>
                                        </select>
                                        <p>Entries</p>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8">
                                    <div className="vendor-table-head-right">
                                        <div className="vendor-table-search">
                                            <input className="form-control"
                                                   onChange={(e) => this.handleChange(e, "searchKeyword")}
                                                   value={this.state.searchKeyword} type="text" placeholder="search"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="vendor-table-body">
                            <div className="table-responsive">
                                {loadKits ? (
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (null)}

                                <InfiniteScroll
                                    dataLength={this.state.allKits.length}
                                    next={() => this.fetchMoreData(false)}
                                    hasMore={this.state.hasMore}
                                >
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>New</th>
                                            <th>Used</th>
                                            <th>Total</th>
                                            <th style={{'width': '15%'}}>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody> {panelsList} </tbody>
                                    </table>
                                </InfiniteScroll>

                                <Modal show={showGenerateModal} onHide={this.handleCloseGenerateModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Generate Kit Ids</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {(() => {
                                            if (this.state.errorMessage) {
                                                return <div className="alert alert-danger">{this.state.errorMessage}</div>
                                            }
                                        })()}
                                        <form>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Total Kits
                                                        <sup>*</sup>
                                                    </label>
                                                    <input type="number"
                                                           onChange={(e) => this.handleChange(e, "totalKit")}
                                                           value={this.state.totalKit} className="form-control"/>
                                                    <span className="form-field-error">
                                                        {this.state.errorData?.totalKit &&
                                                        this.state.errorData.totalKit[0]}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="form-btn text-center">
                                                <button type="button" onClick={(e) => this.handleSaveGenerate(e)}
                                                        className="orange-outline-btn">submit
                                                    {(() => {
                                                        if (this.state?.saveKitLoading) {
                                                            return <span
                                                                className="spinner-border spinner-border-sm ml-1"></span>
                                                        }
                                                    })()}
                                                </button>
                                            </div>
                                        </form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={this.handleCloseGenerateModal}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={showExportModal} onHide={this.handleCloseExportModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Export Kit Ids</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {(() => {
                                            if (this.state.errorMessage) {
                                                return <div className="alert alert-danger">{this.state.errorMessage}</div>
                                            }
                                        })()}
                                        <form>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Total Kit IDs to Export:
                                                        <sup>*</sup>
                                                    </label>
                                                    <input type="number"
                                                           onChange={(e) => this.handleChange(e, "exportKits")}
                                                           value={this.state.exportKits} className="form-control"/>
                                                    <span className="form-field-error">
                                                        {this.state.errorData?.exportKits &&
                                                        this.state.errorData.exportKits[0]}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="form-btn text-center">
                                                <button type="button" onClick={(e) => this.handleSaveExport(e)}
                                                        className="orange-outline-btn">submit
                                                    {(() => {
                                                        if (this.state?.exportKitLoading) {
                                                            return <span
                                                                className="spinner-border spinner-border-sm ml-1"></span>
                                                        }
                                                    })()}
                                                </button>
                                            </div>
                                        </form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={this.handleCloseExportModal}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        ...state
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        searchKit,
        getKitList,
        generateKit,
        getSingleKit,
        updateUsedKitId,
    }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PanelSettings));
