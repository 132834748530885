import React, { Component } from 'react';
import '../../Styles/user-detail.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import Dropzone from '../../Components/dropzone/Dropzone';
import { uploadImage } from '../../Utils/fileUpload';
import { createProduct, getProductDetail, updateProduct } from '../../Redux/Actions/productCategoryAction';
import { getProductCategoryList } from '../../Redux/Actions/productCategoryAction';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'

import ImageUploading from "react-images-uploading";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


class Add_Product extends Component {

    id = "";
    userId = localStorage.getItem("adminUserId");

    productFields = {
        productId: null,
        productModelNo: "",
        productTitle: "",
        productPrice: "",
        productImage: "",
        galleryImages: [],
        base64GalleryImages: [],
        removedGalleryImages: [],
        productTimeFrame: "",
        isActive: 1,
        productDescription: "",
        previewProductImage: null,
        productCategoryList: [],
        // selectedProductCategoryId: '[{"catId":1},{"catId":2}]',
        selectedProductCategoryId: [],
        defaultSelectedOptions: [],
        isEdit: false,
        successMessage: '',
        errorMessage: '',
        errorData: {},
        quantity: 0,
        showLoader: false,
        isUploadImage: false,
        measures: "",
        symptoms: [],
        collection_details: '',
        kit_works: '',
        get_tested_details: '',
        collection_method: '',
        videoURL: ''
    };

    constructor(props) {
        super(props);
        this.state = { ...this.productFields };

        this.fileSelectHandler = this.fileSelectHandler.bind(this);
        this.fileUpload = React.createRef()


        console.log("this.props==============>>", this.props);
        if (this.props.match.path === "/edit-product/:id") {

            this.id = this.props.match.params.id;
            this.fetchProductDetail();
        }
        else {
            this.fetchProductCategoryList()
        }
    }

    fetchProductDetail = async () => {
        // this.fetchProductCategoryList()
        let productDetail = await this.props.getProductDetail(this.id);
        console.log("SS", productDetail)
        if (productDetail?.data?.data?.length > 0) {
            // product found
            productDetail = productDetail.data.data[0];

            let selectedCategories = [];

            if (productDetail.selectedCategories && productDetail.selectedCategories.length > 0) {
                productDetail.selectedCategories.forEach(category => {
                    selectedCategories.push(category.productCategoryId);
                })
            }

            const res = this.createSelectOptions(productDetail.categoriesList, selectedCategories)
            // const defaultGalleryImages = this.createDefaultGalleryImagePattern(productDetail.galleryImages ? JSON.parse(productDetail.galleryImages) : [])
            // console.log(productDetail.selectedCategories, 'selectedCategories');
            console.log("res is**", productDetail);
            if(productDetail.symptoms && productDetail.symptoms.charAt(0) === "\"") {
                productDetail.symptoms = productDetail.symptoms.substring(1, productDetail.symptoms.length-1);
            }
            this.setState({
                productId: productDetail.id,
                // productModelNo: "",
                productTitle: productDetail.name,
                productPrice: productDetail.price.toString(),
                productImage: productDetail.image_name,
                measures: productDetail.measures,
                // productTimeFrame: productDetail.timeFrame ? productDetail.timeFrame.split(",")[1] : null,
                isActive: productDetail.isActive,
                productDescription: productDetail.description,
                previewProductImage: productDetail.image_name,
                quantity: productDetail.quantity,
                // galleryImages: defaultGalleryImages,
                selectedProductCategoryId: selectedCategories,
                productCategoryList: res.selectOptions ? res.selectOptions : [],
                defaultSelectedOptions: res.defaultSelectedOptions ? res.defaultSelectedOptions : [],
                isEdit: true,
                symptoms:productDetail.symptoms ? JSON.parse(productDetail.symptoms) : [],
                collection_details: productDetail.collection_details ? productDetail.collection_details : '',
                kit_works: productDetail.kit_works ? productDetail.kit_works : '',
                get_tested_details: productDetail.get_tested_details ? productDetail.get_tested_details : '',
                collection_method: productDetail.collection_method ? productDetail.collection_method : '',
                videoURL: productDetail.videoURL ? productDetail.videoURL : ''
            })
        }
        else {
            // product not found
            // redirect user to product-list page
            this.props.history.push("/product-list");
        }
    }

    fetchProductCategoryList = async () => {
        const res = await this.props.getProductCategoryList();
        if (res?.data?.data?.length > 0) {

            const selectOptionRes = this.createSelectOptions(res.data.data, [])

            this.setState({
                productCategoryList: selectOptionRes.selectOptions ? selectOptionRes.selectOptions : []
            });
        }
    }

    createDefaultGalleryImagePattern = (images) => {
        let defaultImages = images.map(imgPath => {
            return {
                data_url: imgPath
            }
        })

        return defaultImages;
    }

    createSelectOptions = (categoryList, selectedProductCategory) => {
        let defaultSelectedOptions = [];

        let selectOptions = categoryList.map(item => {
            if (selectedProductCategory.includes(item.id)) {
                let id = item.id
                defaultSelectedOptions.push({ value: id, label: `${item.name}` })
            }
            return (
                { value: item.id, label: `${item.name}` }
            )
        })

        return { defaultSelectedOptions, selectOptions }
    }

    handleChange = (e, key) => {

        if (this.state.errorData && this.state.errorData[key] && this.state.errorData[key][0]) {
            this.state.errorData[key][0] = null;
        }

        let { value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    confirmProductInactiveAlert(event, key) {
        event.persist()
        confirmAlert({
            title: 'Confirm change?',
            message: "Inactive this product will cancel all the customer's subscription associated with this product.",
            buttons: [
                {
                    label: 'Continue',
                    onClick: () => { this.handleChange(event, key) }
                },
                {
                    label: 'Cancel',
                    onClick: () => { }
                }
            ]
        });
    }


    handleGalleryImages = async (images) => {
        // console.log("selected images are", images);

        if (images.length > 0) {
            images = images.map(img => {
                if (img.file) {
                    img["fileName"] = img.file.name;
                    return img;
                }
                return img;
            })
        }

        console.log("selected images are", images);

        this.setState({
            galleryImages: images
        })
    }



    handleProductCategoryChange = (event) => {
        console.log("event is", event);

        let selectedCategories = [];

        if (event && event.length > 0) {
            event.forEach(category => {
                selectedCategories.push(category.value)
            })
        }

        let defaultSelectedOptions = event;
        this.setState({
            selectedProductCategoryId: selectedCategories,
            defaultSelectedOptions
        }, () => { console.log("this.state.is", this.state.selectedProductCategoryId) })
    }

    convertImageToBase64(file) {

        //Create an instance of FileReader
        const fileReader = new FileReader();
        //Implement onLoad function
        console.log("================", this.state.base64GalleryImages);
        fileReader.onloadend = (event) =>
            this.setState(prevState => {
                return {
                    base64GalleryImages: [...prevState.base64GalleryImages, fileReader.result]
                }
            }, () => { console.log("this.state is", this.state.base64GalleryImages); })

        //Start reading the file
        fileReader.readAsDataURL(file);
    }

    async fileSelectHandler(e) {
        let imgStatus = false;
        if (e.target.files[0] !== undefined)
            if (e.target.files[0].type !== undefined) {
                let imgType = e.target.files[0].type.split('/');
                let MimeTypeArray = ['jpg', 'jpeg', 'png', 'gif'];
                // console.log(imgType, 'imgType');
                if (imgType[1] !== undefined) {
                    if (MimeTypeArray.includes(imgType[1])) {
                        if (e.target && e.target.files[0]) {
                            let img = e.target.files[0];
                            if (this.state.errorData && this.state.errorData["productImage"] && this.state.errorData["productImage"][0]) {
                                this.state.errorData["productImage"][0] = null;
                            }
                            this.setState(prevState => ({
                                ...prevState,
                                productImage: img
                            }))
                            this.readFile(img);
                        }
                    }
                    else {
                        imgStatus = true;
                    }
                }
            }
        this.setState({ invalidImage: imgStatus });
    }

    readFile(file) {
        console.log("file", file)
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
            console.log("file", reader)
            if (reader?.result) {
                // showloader(true);
                this.setState({ isUploadImage: true })
                const imagePath = await uploadImage(
                    {
                        dataUrl: reader.result,
                        format:
                            file.type == "image/png"
                                ? "png"
                                : file.type === "image/jpeg"
                                    ? "jpeg"
                                    : file.type === "image/jpg"
                                        ? "jpg"
                                        : null,
                    },
                    'accugenedx-files',
                    `img`
                );
                console.log("imagePath", imagePath)
                this.setState({ productImage: imagePath, previewProductImage: imagePath, isUploadImage: false })
                // let test = formData;
                // test["teamLogo"] = imagePath;
                // setFormValue(test);
                // setLogo(imagePath);
                // showloader(false);
            }
        };






    }

    triggerClick = () => {

        this.fileUpload.current.click();
    }

    validateProductInfo = () => {
        let isFormValid = true;
        this.state.errorData = {
            // productModelNo: [],
            productTitle: [],
            productPrice: [],
            productImage: [],
            // productTimeFrame: [],
            productDescription: [],
            measures: []
        }

        // if (this.state.productModelNo.trim().length === 0) {
        //     isFormValid = false;
        //     this.state.errorData.productModelNo = ["Model Number field cannot be empty."]
        // }

        if (this.state.productTitle.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.productTitle = ["Product Title field cannot be empty."]
        }

        if (this.state.productPrice.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.productPrice = ["Price field cannot be empty."]
        }
        else if (this.state.productPrice.trim().length > 0 && isNaN(+this.state.productPrice.trim())) {
            isFormValid = false;
            this.state.errorData.productPrice = ["Enter a valid price."]
        }

        if (!this.state.productImage || this.state.productImage == '') {
            isFormValid = false;
            this.state.errorData.productImage = ["Product Image field cannot be empty."]
        }

        // if (this.state.productTimeFrame.trim().length === 0) {
        //     isFormValid = false;
        //     this.state.errorData.productTimeFrame = ["Product time-frame field cannot be empty."]
        // }

        if (this.state.productDescription.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.productDescription = ["Product Description field cannot be empty."]
        }

        if (this.state.selectedProductCategoryId.length === 0) {
            isFormValid = false;
            this.state.errorData.selectedProductCategoryId = ["Select alteast one category."]
        }
        if (this.state.measures.length === 0) {
            isFormValid = false;
            this.state.errorData.measures = ["This field is required."]
        }

        let tempState = this.state;
        this.setState({
            ...tempState
        })

        return isFormValid;
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        if (this.userId) {
            await this.setState({
                successMessage: '',
                errorMessage: '',
                showLoader: true
            });
            console.log("ADDPRODUCT", this.state)
            if (this.validateProductInfo()) {
                let res;
                const { measures, productModelNo, productTitle, productPrice, productImage, galleryImages,
                    productTimeFrame, isActive, productDescription, selectedProductCategoryId,
                    quantity, symptoms, collection_details, kit_works,
                    get_tested_details, videoURL,
                    collection_method } = this.state;

                if (this.state.isEdit) {
                    // call edit product api
                    res = await this.props.updateProduct(
                        {
                            measures, productModelNo, productTitle,
                            productPrice, productImage, productTimeFrame,
                            isActive, productDescription,
                            selectedProductCategoryId: JSON.stringify(selectedProductCategoryId),
                            quantity, symptoms: JSON.stringify(symptoms),
                            collection_details, kit_works,
                            get_tested_details, videoURL,
                            collection_method
                        },
                        this.state.productId
                    )
                }
                else {
                    // call add product api
                    res = await this.props.createProduct(
                        {
                            measures, productModelNo,
                            productTitle, productPrice, productImage, productTimeFrame,
                            isActive, productDescription,
                            selectedProductCategoryId: JSON.stringify(selectedProductCategoryId),
                            quantity, symptoms: JSON.stringify(symptoms),
                            collection_details, kit_works,
                            get_tested_details, videoURL,
                            collection_method
                        },
                        this.userId
                    )
                }
                if (res && res.data) {
                    if (res.data.isSuccess) {
                        // success case
                        this.setState({
                            successMessage: res.data.message,
                            showLoader: false
                        });
                        window.scrollTo(0, 0);

                        if (this.state.isEdit) {
                            setTimeout(() => {
                                this.setState({
                                    successMessage: ''
                                })
                            }, 4000);
                        }
                        else {

                            this.setState({
                                productId: null,
                                productModelNo: "",
                                productTitle: "",
                                productPrice: "",
                                productImage: "",
                                galleryImages: [],
                                base64GalleryImages: [],
                                removedGalleryImages: [],
                                productTimeFrame: "",
                                productDescription: "",
                                previewProductImage: null,
                                // productCategoryList: [],
                                // selectedProductCategoryId: '[{"catId":1},{"catId":2}]',
                                selectedProductCategoryId: [],
                                defaultSelectedOptions: [],
                            })
                            // this.fetchProductDetail();

                            setTimeout(() => {
                                this.setState({
                                    successMessage: ''
                                })
                            }, 4000);
                        }
                    }
                    else {
                        // response with failed case
                        this.setState({
                            errorMessage: res.data.message,
                            showLoader: false
                        })
                    }
                }
                else {
                    // error to get respone
                    this.setState({
                        errorMessage: "Something went wrong. Please try again later.",
                        showLoader: false
                    })
                }

            }
            else {
                console.log("product invalid");
                this.setState({
                    showLoader: false
                });
            }
        }
        else {
            // user is not logged in
            this.props.history.push('/login');
        }
    }


    handleChangeSymptoms = (newValue, actionMeta) => {
        console.log(`new value`, newValue)
        this.setState({
            symptoms: newValue
        })
    }


    render() {

        // console.log("this.state.previewProductImage is", this.state.previewProductImage);

        const galleryImages = this.state.galleryImages.length > 0 ? this.state.galleryImages.map(imgPath => {
            return (
                {
                    source: imgPath
                }
            )
        }) : []
        return (
            <div class="admin-card">
                <div class="admin-card-head">
                    <h2>{this.props.match.params.id ? "edit" : "add"} product</h2>
                </div>
                <div class="admin-card-box">
                    {(() => {
                        if (this.state.successMessage !== '' && this.state.successMessage !== undefined) {
                            return <div className="alert alert-success">{this.state.successMessage}</div>
                        } else if (this.state.errorMessage !== '' && this.state.errorMessage !== undefined) {
                            return <div className="alert alert-danger">{this.state.errorMessage}</div>
                        }
                    })()}
                    <div class="row">
                        {/* <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Model Number
                                    <sup>*</sup>
                                </label>
                                <input type="text" onChange={(e) => this.handleChange(e, "productModelNo")} value={this.state.productModelNo} class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData ?.productModelNo &&
                                        this.state.errorData.productModelNo[0]}
                                </span>
                            </div>
                        </div> */}
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>product title
                                    <sup>*</sup>
                                </label>
                                <input type="text" onChange={(e) => this.handleChange(e, "productTitle")} value={this.state.productTitle} class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData?.productTitle &&
                                        this.state.errorData.productTitle[0]}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>price
                                    <sup>*</sup>
                                </label>
                                <input type="number" onChange={(e) => this.handleChange(e, "productPrice")} value={this.state.productPrice} class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData?.productPrice &&
                                        this.state.errorData.productPrice[0]}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-9">
                            <div class="form-group">
                                <div className="user-profile-img">
                                    <img style={{ width: "200px", height: "200px" }} src={this.state.previewProductImage && this.state.previewProductImage !== "" ? this.state.previewProductImage : null} />
                                </div>
                                {(() => {
                                    if (this.state?.invalidImage &&
                                        this.state.invalidImage) {
                                        return <span className="form-field-error">Invalid image format</span>
                                    }
                                })()}
                                <div className="upload-btn">
                                    <button onClick={this.triggerClick} type="button">upload image    {(() => {
                                        if (this.state?.isUploadImage) {
                                            return <span class="spinner-border spinner-border-sm ml-1"></span>
                                        }
                                    })()}</button>
                                </div>
                                <input
                                    ref={this.fileUpload}
                                    className="droup-file-input"
                                    type="file"
                                    onChange={this.fileSelectHandler}
                                    style={{ display: "none" }}
                                >
                                </input>
                                <span className="form-field-error">
                                    {this.state.errorData?.productImage &&
                                        this.state.errorData.productImage[0]}
                                </span>
                            </div>
                        </div>

                        {/* <div class="col-lg-12 col-md-12">
                            <div class="form-group label-radio-inline">
                                <label class="mr-4">Select product time-frame</label>
                                <div class="custom-radio-inline">
                                    <label class="custon-radio">Weekly
                                        <input type="radio" onChange={(e) => this.handleChange(e, "productTimeFrame")} value="weekly" checked={this.state.productTimeFrame === "weekly"} name="radio" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="custon-radio">Monthly
                                        <input type="radio" onChange={(e) => this.handleChange(e, "productTimeFrame")} value="monthly" checked={this.state.productTimeFrame === "monthly"} name="radio" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="custon-radio">Annually
                                        <input type="radio" onChange={(e) => this.handleChange(e, "productTimeFrame")} value="annually" checked={this.state.productTimeFrame === "annually"} name="radio" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <span className="form-field-error">
                                        {this.state.errorData ?.productTimeFrame &&
                                            this.state.errorData.productTimeFrame[0]}
                                    </span>
                                </div>
                            </div>
                        </div> */}


                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Category
                                    <sup>*</sup>
                                </label>
                                <Select
                                    value={this.state.defaultSelectedOptions}
                                    isMulti
                                    name="value"
                                    options={this.state.productCategoryList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(e) => { this.handleProductCategoryChange(e) }}
                                />
                                <span className="form-field-error">
                                    {this.state.errorData?.selectedProductCategoryId &&
                                        this.state.errorData.selectedProductCategoryId[0]}
                                </span>
                                {/* <div class="plant-type-option">
                                    <div class="dropdown">
                                        <button class="btn dropdown-toggle" type="button" data-toggle="dropdown">{this.state.option2 ? this.state.option2 : "Option2"}
                                            <span className="add-icon"><i class="fas fa-plus"></i></span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            {categoryList}
                                        </ul>
                                        <span className="form-field-error">
                                            {this.state.errorData ?.option2 &&
                                                this.state.errorData.option2[0]}
                                        </span>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Quantity
                                    <sup>*</sup>
                                </label>
                                <input type="number" onChange={(e) => this.handleChange(e, "quantity")} value={this.state.quantity} class="form-control" />
                                <span className="form-field-error">
                                    {/* {this.state.errorData ?.productPrice &&
                                        this.state.errorData.productPrice[0]} */}
                                </span>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div class="form-group">
                                <label>Measures
                            <sup>*</sup>
                                </label>
                                <input type="text" onChange={(e) => this.handleChange(e, "measures")} value={this.state.measures} class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData?.measures &&
                                        this.state.errorData.measures[0]}
                                </span>
                            </div>
                        </div>



                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>product Description</label>
                                <textarea onChange={(e) => this.handleChange(e, "productDescription")} value={this.state.productDescription} class="form-control form-textarea" placeholder="enter your text here..."></textarea>
                                <span className="form-field-error">
                                    {this.state.errorData?.productDescription &&
                                        this.state.errorData.productDescription[0]}
                                </span>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>symptoms</label>
                                {console.log(`@@@@@@@@@@@@@@@@@@@`,this.state.symptoms)}
                                <CreatableSelect
                                    isMulti
                                    value={this.state.symptoms}
                                    options={this.state.symptoms}
                                    onChange={this.handleChangeSymptoms}
                                // options={colourOptions}
                                />
                                {/* <textarea onChange={(e) => this.handleChange(e, "productDescription")} value={this.state.productDescription} class="form-control form-textarea" placeholder="enter your text here..."></textarea>
                                <span className="form-field-error">
                                    {this.state.errorData ?.productDescription &&
                                        this.state.errorData.productDescription[0]}
                                </span> */}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="form-group">
                                <label>How to collect the sample?
                                </label>
                                <input type="text" onChange={(e) => this.handleChange(e, "collection_method")} value={this.state.collection_method} class="form-control" />

                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Why Get Tested?</label>
                                <textarea onChange={(e) => this.handleChange(e, "get_tested_details")} value={this.state.get_tested_details} class="form-control form-textarea" placeholder="enter your text here..."></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Video URL</label>
                                <input type="url" onChange={(e) => this.handleChange(e, "videoURL")}
                                       value={this.state.videoURL} className="form-control"/>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Collection Details?</label>
                                <textarea onChange={(e) => this.handleChange(e, "collection_details")} value={this.state.collection_details} class="form-control form-textarea" placeholder="enter your text here..."></textarea>
                                <span className="form-field-error">
                                    {this.state.errorData?.productDescription &&
                                        this.state.errorData.productDescription[0]}
                                </span>
                            </div>
                        </div>
{/*
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>How it works?</label>
                                <textarea onChange={(e) => this.handleChange(e, "kit_works")} value={this.state.kit_works} class="form-control form-textarea" placeholder="enter your text here..."></textarea>
                            </div>
                        </div> */}

                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <label>Is Active?</label>
                                <div className="custom-radio-input">
                                    <p className="container-sub mr-2">
                                        Inactive
                                        <span>
                                            <input
                                                type="radio"
                                                value="0"
                                                onChange={(event) =>
                                                    this.confirmProductInactiveAlert(event, "isActive")
                                                }
                                                checked={this.state.isActive == 0}
                                                name="isActiveRadio"
                                            />
                                        </span>
                                        <span class="checkmark"></span>
                                    </p>

                                    <p className="container-sub mr-2">
                                        Active
                                        <span>
                                            <input
                                                type="radio"
                                                value="1"
                                                onChange={(event) =>
                                                    this.handleChange(event, "isActive")
                                                }
                                                checked={this.state.isActive == 1}
                                                name="isActiveRadio"
                                            />
                                        </span>
                                        <span class="checkmark"></span>
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-12 col-md-12">
                            <div class="form-btn text-center">
                                <button
                                    disabled={this.state.isUploadImage}
                                    type="button" onClick={(e) => this.handleSubmit(e)} class="orange-outline-btn">submit
                                {(() => {
                                        if (this.state?.showLoader) {
                                            return <span class="spinner-border spinner-border-sm ml-1"></span>
                                        }
                                    })()}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ createProduct, getProductDetail, updateProduct, getProductCategoryList }, dispatch);
}

export default connect(null, mapDispatchToProps)(Add_Product)
