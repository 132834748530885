import React  from "react";
import { connect } from "react-redux";
import {Link, withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import './settings.scss';

import {debounce} from "lodash";
import {searchSystemUser, getSystemUserList} from "../../Redux/Actions/settingAction";
import InfiniteScroll from "react-infinite-scroll-component";
import {Button, Modal} from "react-bootstrap";
import AddSystemUser from "./add-system-user";

class SystemUsers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allUsers:[],
            loadSystemUsers: false,
            offset: 0,
            prevOffset: 0,
            maxLimit: 10,
            hasMore: true,
            searchKeyword: "",
            showAddNewModal: "",
            userData: null,
            userEdit: false,
        };
    }
    async componentDidMount() {
        this.fetchMoreData(true);
    }

    fetchMoreData = async (firstLoad) => {
        this.setState({
            loadSystemUsers: true
        });

        if (this.state.offset !== this.state.prevOffset || firstLoad) {
            if (this.state.hasMore && this.state.maxLimit && (this.state.maxLimit === "all" || this.state.maxLimit > this.state.offset)) {
                let result;
                if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
                    result = await this.props.searchSystemUser(this.state.offset, this.state.searchKeyword.trim());
                }
                else {
                    result = await this.props.getSystemUserList(this.state.offset);
                }

                if (result ?.data ?.data ?.length > 0) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            prevOffset: prevState.offset,
                            offset: prevState.offset + 10,
                            allUsers: prevState.allUsers.concat(result.data.data),
                            loadSystemUsers: false
                        }
                    })
                }
                else {
                    this.setState({ hasMore: false, loadSystemUsers: false });
                }
            } else {
                this.setState({
                    loadSystemUsers: false
                })
            }
        } else {
            this.setState({
                loadSystemUsers: false
            })
        }

    };

    handleChange = async (event, field) => {
        event.preventDefault();
        if (field === "maxLimit") {
            await this.setState({
                [field]: event.target.value === "all" ? event.target.value : +event.target.value
            })
            this.fetchMoreData(false);
        }
        else {
            await this.setState({
                [field]: event.target.value,
                offset: 0,
                prevOffset: 0,
                hasMore: true
            });

            this.debounceSearchCall();
        }
    };

    debounceSearchCall = debounce(() => {
        this.setState({
            allUsers: [],
        });
        this.fetchMoreData(true);
    }, 1000);

    prepareUserEdit = (userId) => {
        this.setState({
            showAddNewModal: true,
            userEdit: true,
            userData: this.state.allUsers.find(user => user.id === userId)
        });
    };

    showAddNewCompanyUser = () => {
        this.setState({
            showAddNewModal: true,
        });
    };

    handleCloseAddUser = () => {
        this.setState({
            showAddNewModal: false,
            userData: null,
            userEdit: false
        });
    };

    completeUserAdd = () => {
        this.setState({
            offset: 0,
            prevOffset: 0,
            hasMore: true,
            showAddNewModal: false,
            userData: null,
            userEdit: false
        });

        this.debounceSearchCall();
    };

    render() {
        const {
            allUsers,
            loadSystemUsers,
            showAddNewModal,
            userEdit,
        } = this.state;

        const usersList = allUsers?.map((usersObj, index) => {
            return (
                <tr key={`${usersObj.id}-${index}`}>
                    <td>{usersObj.firstname}</td>
                    <td>{usersObj.lastname}</td>
                    <td>{usersObj.email}</td>
                    <td>{usersObj.role === 'techSupport'? 'Tech Support': usersObj.role}</td>
                    <td>{usersObj.isactive?
                        <span className={'active'}>Active</span>:
                        <span className={'inactive'}>Inactive</span>
                    }</td>
                    <td>
                        <div className="vendor-table-btn">
                            <button
                                type="button"
                                className="orange-outline-btn"
                                onClick={() => this.prepareUserEdit(usersObj.id)}
                            >
                                Edit
                            </button>
                        </div>
                    </td>
                </tr>
            );
        });
        return (
            <div className="admin-card">
                <div className="admin-card-head d-flex justify-content-end mb-2">
                    <button type="text" class="orange-outline-btn" onClick={this.showAddNewCompanyUser}>
                        <i class="fas fa-plus"></i> Add User
                    </button>
                </div>
                <div className="admin-card-box">
                    <div className="vendor-table">
                        <div className="vendor-table-head">
                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <div className="entries-drop">
                                        <label>show</label>
                                        <select className="entries-controls"
                                            onChange={(e) => this.handleChange(e, "maxLimit")}
                                            value={this.state.maxLimit}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="all">All</option>
                                        </select>
                                        <p>Entries</p>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8">
                                    <div className="vendor-table-head-right">
                                        <div className="vendor-table-search">
                                            <input className="form-control"
                                                   onChange={(e) => this.handleChange(e, "searchKeyword")}
                                                   value={this.state.searchKeyword} type="text" placeholder="search"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="vendor-table-body">
                            <div className="table-responsive">
                                <InfiniteScroll
                                    dataLength={this.state.allUsers.length}
                                    next={() => this.fetchMoreData(false)}
                                    hasMore={this.state.hasMore}
                                >
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th>Role</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody> {usersList} </tbody>
                                    </table>
                                </InfiniteScroll>
                                {loadSystemUsers ? (
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (null)}

                                <Modal show={showAddNewModal} onHide={this.handleCloseAddUser}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{userEdit? 'Edit User': 'Add User'}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <AddSystemUser
                                            handleCloseAddReport={this.completeUserAdd}
                                            userData={this.state.userData}
                                            userEdit={this.state.userEdit}
                                        />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={this.handleCloseAddUser}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        ...state
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        searchSystemUser,
        getSystemUserList,
    }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SystemUsers));
