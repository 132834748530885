import React, { useEffect, useState } from 'react';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import moment from 'moment';

const CovidVisitInfo = (props) => {
    const [visitInfo, setVisitInfo] = useState({
        date_of_birth: '',
        patient_relationship: '',
        patient_first_name: '',
        patient_last_name: '',
        address_line_1: '',
        address_line_2: '',
        country: '',
        state: '',
        city: '',
        zipCode: '',
        gender: '',
        race: '',
        ethnicity: '',
        patient_email: '',
        patient_mobile: '',
        is_primary_phone: '',
        primary_phoneNumber: '',
        received_vaccine: '',
        first_time_vaccine: '',
        previous_test_positive: '',
        vaccine_dose: '',
        vaccine_manufacturer_1: '',
        vaccine_manufacturer_2: '',
        taken_2_dose: '',
        first_dose_date: '',
        second_dose_date: '',
        medical_condition: '',
        medical_issues: {
            is_asthma: false,
            is_cirrhosis: false,
            is_cancer: false,
            is_diabetes: false,
            is_hard_cough: false,
            is_obesity: false,
            is_hypertension: false,
            is_kidney_failure: false,
            is_heart_problem: false,
        },
        is_pregnant: '',
        payment_select: '',
        heath_insurance: '',
        insurance_company: '',
        insurance_id: '',
        front_insurance_card_img: '',
        back_insurance_card_img: '',
        group_number: '',
        policy_holder: 'self',
        confirm_insurance_id: '',
        health_info_available: '',
        ssn: '',
        driver_license: '',
        hhs_state: '',
        is_acknowledge: '',
        confirm_no_health_insurance: '',
        payment_charge_id: '',
        test_type: '',
    });
    const [phoneInput, setPhoneInput] = useState('');
    const [primartPhoneInput, setPrimartPhoneInput] = useState('');
    const [testType, setTestType] = useState('RT-PCR');

    useEffect(() => {
        const visitInfoObj = props.visitInfo;
        if(visitInfoObj.test_type === 'Antigen') {
            visitInfoObj.payment_select = 1;
        }
        setVisitInfo(props.visitInfo);
        setTestType(visitInfoObj.test_type);
        renderPhoneInput(props.visitInfo.patient_mobile || null, 'phoneInput');
        renderPhoneInput(props.visitInfo.primary_phoneNumber || null, 'primaryPhoneInput');
    }, []);


    const renderPhoneInput = (userPhone, type) => {
        const phnInput = <PhoneInput
            defaultCountry="US"
            countries={["US"]}
            addInternationalOption={false}
            class="form-control"
            placeholder="(xxx) xxx-xxxx"
            value={userPhone? formatPhoneNumber(userPhone): ''}
            limitMaxLength={true}
        />;

        if(type === 'phoneInput') {
            setPhoneInput(phnInput);
        }
        if(type === 'primaryPhoneInput') {
            setPrimartPhoneInput(phnInput);
        }
    };

    const openInsuranceImage = (imageURL) => {
        if(imageURL) {
            window.open(imageURL, "_blank");
        }
    };

    return (
        <div id="visit-container">
            <div className="container">
            <div id="visit-row" className="row justify-content-center">
                <div className={"visit-form col-md-12"}>
                    <div className={"section"}>
                        <h5>Patient Information</h5>
                        <div className={"section-content col-md-12"}>
                            <div>
                                <div className="form-group">
                                    <label>
                                        <b>Patient date of birth</b>
                                    </label>
                                    <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                        <input type="date" name="date_of_birth"
                                               value={moment(new Date(visitInfo.date_of_birth)).format('YYYY-MM-DD')}
                                               disabled={true}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>
                                        <b>Relationship to patient</b>
                                    </label>
                                    <div className="col-md-12 p-0 pt-2 d-flex flex-column">
                                        <label className="radio-inline mb-2">
                                            <input
                                                type="radio"
                                                name="patient_relationship" value="patient" className={'mr-2'}
                                                checked={visitInfo.patient_relationship === 'patient'}
                                            />
                                            I am the patient
                                        </label>
                                        <label className="radio-inline mb-2">
                                            <input
                                                type="radio"
                                                name="patient_relationship" value="parent" className={'mr-2'}
                                                checked={visitInfo.patient_relationship === 'parent'}
                                            />
                                            Parent of the patient
                                        </label>
                                        <label className="radio-inline mb-2">
                                            <input
                                                type="radio"
                                                name="patient_relationship" value="legal_guardian" className={'mr-2'}
                                                checked={visitInfo.patient_relationship === 'legal_guardian'}
                                            />
                                            Legal guardian of the patient
                                        </label>
                                        <label className="radio-inline mb-2">
                                            <input
                                                type="radio"
                                                name="patient_relationship" value="auth_representative" className={'mr-2'}
                                                checked={visitInfo.patient_relationship === 'auth_representative'}
                                            />
                                            Auth. representative of the patient
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group mt-4">
                                    <label>
                                        <b>Patient First Name</b>
                                    </label>
                                    <input type="text" name="patient_first_name"
                                           className="form-control"
                                           value={visitInfo.patient_first_name}
                                    />
                                </div>
                                <div className="form-group mt-4">
                                    <label>
                                        <b>Patient Last name</b>
                                    </label>
                                    <input type="text" name="patient_last_name"
                                           className="form-control"
                                           value={visitInfo.patient_last_name}
                                    />
                                </div>
                                <div className="form-group mt-4">
                                    <label>
                                        <b>Address line 1</b>
                                    </label>
                                    <input type="text" name="address_line_1"
                                           className="form-control"
                                           value={visitInfo.address_line_1}
                                    />
                                </div>
                                <div className="form-group mt-4">
                                    <label>
                                        <b>Address line 2</b>
                                    </label>
                                    <input type="text" name="address_line_2"
                                           className="form-control"
                                           value={visitInfo.address_line_2}
                                    />
                                </div>
                                <div className="form-group mt-4 d-flex flex-row justify-content-between address">
                                   <div>
                                       <label>
                                           <b>Country</b>
                                       </label>
                                       <input type="text" name="country"
                                              className="form-control"
                                              value={visitInfo.country}
                                       />
                                   </div>
                                   <div>
                                       <label>
                                           <b>State</b>
                                       </label>
                                       <input type="text" name="state"
                                              className="form-control"
                                              value={visitInfo.state}
                                       />
                                   </div>
                                   <div>
                                       <label>
                                           <b>City</b>
                                       </label>
                                       <input type="text" name="city"
                                              className="form-control"
                                              value={visitInfo.city}
                                       />
                                   </div>
                                    <div>
                                        <label>
                                            <b>Zip Code</b>
                                        </label>
                                        <input type="text" name="zipCode"
                                               className="form-control"
                                               value={visitInfo.zipCode}
                                        />
                                    </div>
                                </div>
                                <div className="form-group mt-4">
                                    <label>
                                        <b>Demographic information</b>
                                    </label>
                                    <p className={'info-text'}>
                                        The Centers for Disease Control and Prevention (CDC) requires we collect this demographic information.
                                        We’re committed to providing equal access to COVID-19 testing.
                                        Your gender identity, race, or ethnicity are in no way limiting factors in scheduling your COVID-19 test.
                                    </p>
                                    <div className="col-md-12 p-0 pt-2 d-flex flex-column">
                                        <label>
                                            <b>Gender</b>
                                        </label>
                                        <label className="radio-inline mb-2">
                                            <input
                                                type="radio"
                                                name="gender" value="female"
                                                checked={visitInfo.gender === 'female'} className={'mr-2'}
                                            />
                                            Female
                                        </label>
                                        <label className="radio-inline mb-2">
                                            <input
                                                type="radio"
                                                name="gender" value="male"
                                                checked={visitInfo.gender === 'male'} className={'mr-2'}
                                            />
                                            Male
                                        </label>
                                        <label className="radio-inline mb-2">
                                            <input
                                                type="radio"
                                                name="gender" value="no_specify"
                                                checked={visitInfo.gender === 'no_specify'} className={'mr-2'}
                                            />
                                            Prefer not to answer
                                        </label>
                                    </div>
                                    <div className="form-group mt-4 d-flex flex-row justify-content-start flex-basis">
                                        <div className={'mr-3'}>
                                            <label>
                                                <b>Race</b>
                                            </label>
                                            <select className="form-control"
                                                    value={visitInfo.race}
                                                    name="race">
                                                <option value="" disabled>--Select--</option>
                                                <option value="American Indian or Alaska Native"> American Indian or Alaska Native </option>
                                                <option value="Asian"> Asian </option>
                                                <option value="Black or African American"> Black or African American </option>
                                                <option value="Hispanic"> Hispanic </option>
                                                <option value="Native Hawaiian or Other Pacific Islander"> Native Hawaiian or Other Pacific Islander </option>
                                                <option value="Other"> Other </option>
                                                <option value="Patient Refused"> Patient Refused </option>
                                                <option value="Unknown"> Unknown </option>
                                                <option value="White or Caucasian"> White or Caucasian </option>
                                            </select>
                                        </div>
                                        <div>
                                            <label>
                                                <b>Ethnicity</b>
                                            </label>
                                            <select className="form-control"
                                                    value={visitInfo.ethnicity}
                                                    name="ethnicity">
                                                <option value="" disabled>--Select--</option>
                                                <option value="Hispanic or Latino"> Hispanic or Latino </option>
                                                <option value="Not Hispanic or Latino"> Not Hispanic or Latino </option>
                                                <option value="Patient Refused"> Patient Refused </option>
                                                <option value="Unknown"> Unknown </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"section"}>
                        <h5>Contact Information</h5>
                        <div className={"section-content col-md-12"}>
                            <p className={'info-text'}>
                                We will send your test results by email. Your contact information is only used for messages about your COVID-19 test.
                            </p>
                            <div>
                                <div className="form-group mt-4">
                                    <label>
                                        <b>Email Address</b>
                                    </label>
                                    <input type="text" name="patient_email"
                                           className="form-control"
                                           value={visitInfo.patient_email}
                                    />
                                </div>
                                <div className="form-group mt-4">
                                    <label>
                                        <b>Mobile Number</b>
                                    </label>
                                        {phoneInput}
                                </div>
                                <div className={"mt-4"}>
                                    <div className="form-group">
                                        <label>
                                            <b>Is your mobile number your primary number?</b>
                                        </label>
                                        <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                            <label className="radio-inline mr-3">
                                                <input
                                                    type="radio"
                                                    name="is_primary_phone" value="1" className={'mr-2'}
                                                    checked={visitInfo.is_primary_phone === 1}
                                                />
                                                Yes
                                            </label>
                                            <label className="radio-inline">
                                                <input
                                                    type="radio"
                                                    name="is_primary_phone" value="0" className={'mr-2'}
                                                    checked={visitInfo.is_primary_phone === 0}
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {
                                    visitInfo.is_primary_phone === 0 &&
                                    <div className={"mt-4"}>
                                        <div className="form-group">
                                            <label>
                                                <b>Primary Phone Number</b>
                                            </label>
                                            {primartPhoneInput}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={"section"}>
                        <h5>COVID-19 Government Reporting</h5>
                        <div className={"section-content col-md-12"}>
                            <h5>Federal government mandated questions</h5>
                            <div>
                                <div className={"mt-4"}>
                                    <div className="form-group">
                                        <label>
                                            <b>Have you received a COVID-19 vaccine?</b>
                                        </label>
                                        <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                            <label className="radio-inline mr-3">
                                                <input
                                                    type="radio"
                                                    name="received_vaccine" value="1" className={'mr-2'}
                                                    checked={visitInfo.received_vaccine === 1}
                                                />
                                                Yes
                                            </label>
                                            <label className="radio-inline">
                                                <input
                                                    type="radio"
                                                    name="received_vaccine" value="0" className={'mr-2'}
                                                    checked={visitInfo.received_vaccine === 0}
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {
                                    visitInfo.received_vaccine === 1 &&
                                    <div className={"mt-4"}>
                                        <div className="form-group">
                                            <label>
                                                <b>How many dose did your vaccine require?</b>
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="vaccine_dose" value="1" className={'mr-2'}
                                                        checked={visitInfo.vaccine_dose === 1}
                                                    />
                                                    1 dose
                                                </label>
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="vaccine_dose" value="2" className={'mr-2'}
                                                        checked={visitInfo.vaccine_dose === '2'}
                                                    />
                                                    2 dose
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="vaccine_dose" value="no_specify" className={'mr-2'}
                                                        checked={visitInfo.vaccine_dose === 'no_specify'}
                                                    />
                                                    Prefer not to specify
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    visitInfo.received_vaccine === 1 && visitInfo.vaccine_dose === 1 &&
                                    <div className={"mt-4"}>
                                        <div className="form-group">
                                            <label>
                                                <b>Who was the manufacturer of your vaccine?</b>
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="vaccine_manufacturer_1" value="Johnson & Johnson" className={'mr-2'}
                                                        checked={visitInfo.vaccine_manufacturer_1 === 'Johnson & Johnson'}
                                                    />
                                                    Johnson & Johnson
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="vaccine_manufacturer_1" value="other" className={'mr-2'}
                                                        checked={visitInfo.vaccine_manufacturer_1 === 'other'}
                                                    />
                                                    Other / I don't know
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    visitInfo.received_vaccine === 1 && visitInfo.vaccine_dose === '2' &&
                                    <>
                                        <div className={"mt-4"}>
                                            <div className="form-group">
                                                <label>
                                                    <b>Who was the manufacturer of your vaccine?</b>
                                                </label>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="radio"
                                                            name="vaccine_manufacturer_2" value="Moderna" className={'mr-2'}
                                                            checked={visitInfo.vaccine_manufacturer_2 === 'Moderna'}
                                                        />
                                                        Moderna
                                                    </label>
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="radio"
                                                            name="vaccine_manufacturer_2" value="Pfizer" className={'mr-2'}
                                                            checked={visitInfo.vaccine_manufacturer_2 === 'Pfizer'}
                                                        />
                                                        Pfizer
                                                    </label>
                                                    <label className="radio-inline">
                                                        <input
                                                            type="radio"
                                                            name="vaccine_manufacturer_2" value="other" className={'mr-2'}
                                                            checked={visitInfo.vaccine_manufacturer_2 === 'other'}
                                                        />
                                                        Other / I don't know
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"mt-4"}>
                                            <div className="form-group">
                                                <label>
                                                    <b>Have you taken both doses?</b>
                                                </label>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <label className="radio-inline mr-3">
                                                        <input
                                                            type="radio"
                                                            name="taken_2_dose" value="1" className={'mr-2'}
                                                            checked={visitInfo.taken_2_dose === 1}
                                                        />
                                                        Yes
                                                    </label>
                                                    <label className="radio-inline">
                                                        <input
                                                            type="radio"
                                                            name="taken_2_dose" value="0" className={'mr-2'}
                                                            checked={visitInfo.taken_2_dose === 0}
                                                        />
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    visitInfo.received_vaccine === 1 && (visitInfo.vaccine_dose === 1 || visitInfo.vaccine_dose === '2') &&
                                    <>
                                        <div className={"mt-4"}>
                                            <div className="form-group">
                                                <label>
                                                    <b>Date you received first dose</b>
                                                </label>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <input type="date" name="first_dose_date"
                                                           value={moment(new Date(visitInfo.first_dose_date)).format('YYYY-MM-DD')} />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    visitInfo.received_vaccine === 1 && visitInfo.vaccine_dose === '2' && visitInfo.taken_2_dose === 1 &&
                                    <>
                                        <div className={"mt-4"}>
                                            <div className="form-group">
                                                <label>
                                                    <b>Date you received second dose</b>
                                                </label>
                                                <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                    <input type="date" name="second_dose_date"
                                                           value={moment(new Date(visitInfo.second_dose_date)).format('YYYY-MM-DD')}
                                                       />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className={"mt-4"}>
                                    <div className="form-group">
                                        <label>
                                            <b>Is this your first time taking the COVID-19 test?</b>
                                        </label>
                                        <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                            <label className="radio-inline mr-3">
                                                <input
                                                    type="radio"
                                                    name="first_time_vaccine" value="1" className={'mr-2'}
                                                    checked={visitInfo.first_time_vaccine === 1}
                                                />
                                                Yes
                                            </label>
                                            <label className="radio-inline">
                                                <input
                                                    type="radio"
                                                    name="first_time_vaccine" value="0" className={'mr-2'}
                                                    checked={visitInfo.first_time_vaccine === 0}
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {
                                    visitInfo.first_time_vaccine === 0 &&
                                    <div className={"mt-4"}>
                                        <div className="form-group">
                                            <label>
                                                <b>Have you previously tested positive for COVID-19?</b>
                                            </label>
                                            <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                <label className="radio-inline mr-3">
                                                    <input
                                                        type="radio"
                                                        name="previous_test_positive" value="1" className={'mr-2'}
                                                        checked={visitInfo.previous_test_positive === 1}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="previous_test_positive" value="0" className={'mr-2'}
                                                        checked={visitInfo.previous_test_positive === 0}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className={"mt-4"}>
                                    <div className="form-group">
                                        <label>
                                            <b>Do you have any medical conditions?</b>
                                        </label>
                                        <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                            <label className="radio-inline mr-3">
                                                <input
                                                    type="radio"
                                                    name="medical_condition" value="1" className={'mr-2'}
                                                    checked={visitInfo.medical_condition === 1}
                                                />
                                                Yes
                                            </label>
                                            <label className="radio-inline">
                                                <input
                                                    type="radio"
                                                    name="medical_condition" value="0" className={'mr-2'}
                                                    checked={visitInfo.medical_condition === 0}
                                                />
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {
                                    visitInfo.medical_condition === 1 &&
                                    <div className={"mt-4"}>
                                        <label>
                                            <b>Which medical conditions do you have?</b> (Select any that apply)
                                        </label>
                                        <div className="form-group checklist">
                                            <div className="col-md-12 p-0 d-flex flex-column">
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_asthma"
                                                        checked={visitInfo.medical_issues.is_asthma}
                                                    />
                                                    <span className={'d-block pt-2'}>Asthma or chronic lung disease</span>
                                                </label>
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_cirrhosis"
                                                        checked={visitInfo.medical_issues.is_cirrhosis}
                                                    />
                                                    <span className={'d-block pt-2'}>Cirrhosis of the liver</span>
                                                </label>
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_cancer"
                                                        checked={visitInfo.medical_issues.is_cancer}
                                                    />
                                                    <span className={'d-block pt-2'}>Conditions that result in a weakened immune system, including cancer treatment</span>
                                                </label>
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_diabetes"
                                                        checked={visitInfo.medical_issues.is_diabetes}
                                                    />
                                                    <span className={'d-block pt-2'}>Diabetes</span>
                                                </label>
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_hard_cough"
                                                        checked={visitInfo.medical_issues.is_hard_cough}
                                                    />
                                                    <span className={'d-block pt-2'}>Disease or condition that make it harder to cough</span>
                                                </label>
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_obesity"
                                                        checked={visitInfo.medical_issues.is_obesity}
                                                    />
                                                    <span className={'d-block pt-2'}>Extreme obesity</span>
                                                </label>
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_hypertension"
                                                        checked={visitInfo.medical_issues.is_hypertension}
                                                    />
                                                    <span className={'d-block pt-2'}>
                                                        Hypertension or high blood pressure
                                                    </span>
                                                </label>
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_kidney_failure"
                                                        checked={visitInfo.medical_issues.is_kidney_failure}
                                                    />
                                                    <span className={'d-block pt-2'}>
                                                        Kidney failure or end stage renal disease
                                                    </span>
                                                </label>
                                                <label className="form-group mb-2 align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_heart_problem"
                                                        checked={visitInfo.medical_issues.is_heart_problem}
                                                    />
                                                    <span className={'d-block pt-2'}>
                                                        Serious heart condition, such as congestive heart failure
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className={"mt-4"}>
                                    <div className="form-group">
                                        <label>
                                            <b>If applicable, are you currently pregnant?</b>
                                        </label>
                                        <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                            <label className="radio-inline mr-3">
                                                <input
                                                    type="radio"
                                                    name="is_pregnant" value="1" className={'mr-2'}
                                                    checked={visitInfo.is_pregnant === 1}
                                                />
                                                Yes
                                            </label>
                                            <label className="radio-inline mr-3">
                                                <input
                                                    type="radio"
                                                    name="is_pregnant" value="0" className={'mr-2'}
                                                    checked={visitInfo.is_pregnant === 0}
                                                />
                                                No
                                            </label>
                                            <label className="radio-inline">
                                                <input
                                                    type="radio"
                                                    name="is_pregnant" value="2" className={'mr-2'}
                                                    checked={visitInfo.is_pregnant === '2'}
                                                />
                                                I don't know
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        testType === 'RT-PCR' &&
                        <div className={"section"}>
                            <h5>Payment</h5>
                            <div className={"section-content col-md-12"}>

                                <div className="form-group">
                                    <label>
                                        <b>Do you want to pay the test with credit card?</b>
                                    </label>
                                    <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                        <label className="radio-inline mr-3">
                                            <input
                                                type="radio"
                                                name="payment_select" value="1" className={'mr-2'}
                                                checked={visitInfo.payment_select === 1}
                                            />
                                            Yes
                                        </label>
                                        <label className="radio-inline">
                                            <input
                                                type="radio"
                                                name="payment_select" value="0" className={'mr-2'}
                                                checked={visitInfo.payment_select === 0}
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                                {
                                    visitInfo.payment_select === 0 &&
                                    <>
                                        <div>
                                            <div className={"mt-4"}>
                                                <div className="form-group">
                                                    <label>
                                                        <b>Do you have health insurance, Medicare, Medicaid or any commercial or government-funded health benefit plan?</b>
                                                    </label>
                                                    <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                                        <label className="radio-inline mr-3">
                                                            <input
                                                                type="radio"
                                                                name="heath_insurance" value="1" className={'mr-2'}
                                                                checked={visitInfo.heath_insurance === 1}
                                                            />
                                                            Yes
                                                        </label>
                                                        <label className="radio-inline">
                                                            <input
                                                                type="radio"
                                                                name="heath_insurance" value="0" className={'mr-2'}
                                                                checked={visitInfo.heath_insurance === 0}
                                                            />
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                visitInfo.heath_insurance === 1 &&
                                                <div className={"mt-4"}>
                                                    <div className="form-group">
                                                        <label>
                                                            <b>Insurance Company/or Medicare /or Medical Plan</b>
                                                        </label>
                                                        <input type="text" name="insurance_company"
                                                               className="form-control"
                                                               value={visitInfo.insurance_company}
                                                        />
                                                    </div>
                                                    <div className="form-group mt-4">
                                                        <label>
                                                            <b>Member Number/ or ID Number</b> (usually found on your insurance card. Eg: ID W2222 66666)
                                                        </label>
                                                        <input type="text" name="insurance_id"
                                                               className="form-control"
                                                               value={visitInfo.insurance_id}
                                                        />
                                                    </div>
                                                    <div className="form-group mt-4">
                                                        <label>
                                                            <b>Group number (optional)</b> (usually found on your insurance card. Eg: 111100-010-00001)
                                                        </label>
                                                        <input type="text" name="group_number"
                                                               className="form-control"
                                                               value={visitInfo.group_number}
                                                        />
                                                    </div>
                                                    <div className="form-group mt-4">
                                                        <label><b>Who is the policyholder?</b></label>
                                                        <p className={'info-text'}>
                                                            This is the person in whose name the policy is registered, and typically pays the premium.
                                                        </p>
                                                        <select className="form-control"
                                                                value={visitInfo.policy_holder}
                                                                name="policy_holder">
                                                            <option value="Self"> Self</option>
                                                            <option value="Spouse"> Spouse</option>
                                                            <option value="Child"> Child</option>
                                                            <option value="Mother"> Mother</option>
                                                            <option value="Father"> Father</option>
                                                            <option value="Stepson or Stepdaughter"> Stepson or Stepdaughter</option>
                                                            <option value="Grandfather or Grandmother"> Grandfather or Grandmother</option>
                                                            <option value="Grandson or Granddaughter"> Grandson or Granddaughter</option>
                                                            <option value="Niece or Nephew"> Niece or Nephew</option>
                                                            <option value="Significant Other"> Significant Other</option>
                                                            <option value="Foster Child"> Foster Child</option>
                                                            <option value="Dependent of a Minor Dependent"> Dependent of a Minor Dependent</option>
                                                            <option value="Ward"> Ward</option>
                                                            <option value="Emancipated Minor"> Emancipated Minor</option>
                                                            <option value="Other Relationship"> Other Relationship</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group mt-4">
                                                        <label><b>Share an image of your insurance card</b></label>
                                                        <p className={'info-text'}>
                                                            Image of Insurance Card
                                                        </p>
                                                        <div className={"insurance-upload-container"}>
                                                            <div className={'w-50'}>
                                                                <div>
                                                                    <span>Front</span>
                                                                </div>
                                                                <div className={'insurance-front'}>
                                                                    <img src={visitInfo.front_insurance_card_img} alt={"front_img"}
                                                                         title={'Open image'}
                                                                         onClick={() => openInsuranceImage(visitInfo.front_insurance_card_img)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={'w-50'}>
                                                                <div>
                                                                    <span>Back</span>
                                                                </div>
                                                                <div className={'insurance-back'}>
                                                                    <img src={visitInfo.back_insurance_card_img} alt={"back_img"}
                                                                         title={'Open image'}
                                                                         onClick={() => openInsuranceImage(visitInfo.back_insurance_card_img)}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                            {
                                                visitInfo.heath_insurance === 0 &&
                                                <>
                                                    <div className={"mt-4"}>
                                                        <div className="form-group col-md-12 p-0 pl-4 d-flex flex-row align-items-center">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    name="confirm_no_health_insurance"
                                                                    checked={visitInfo.confirm_no_health_insurance === 1}
                                                                />
                                                                <p className={'info-text pt-1 ml-3 d-block'}>
                                                                    I confirm and attest that I have no active health insurance, Medicare, Medicaid, or any
                                                                    commercial or government-funded health benefit plan.
                                                                    <b>
                                                                        I understand If I fail to report active health insurance I may be charged $99.
                                                                    </b>
                                                                </p>
                                                            </label>
                                                        </div>
                                                        <p className={'info-text'}>
                                                            Please provide a valid Social Security Number OR a driver's license/state ID number, which is used
                                                            for the HHS Uninsured program administration. You may continue to book an appointment if you do not have or prefer
                                                            not to provide this information.
                                                        </p>
                                                    </div>
                                                    <div className={"mt-4"}>
                                                        <div className="form-group">
                                                            <label>
                                                                <b>Social Security Number</b>(preferred)
                                                            </label>
                                                            <input type="text" name="ssn"
                                                                   className="form-control"
                                                                   value={visitInfo.ssn}
                                                            />
                                                        </div>
                                                        <div className={'or-block mt-4 mb-4'}>
                                                            <span>OR</span>
                                                            <hr/>
                                                        </div>
                                                        <div className="form-group mt-3 d-flex flex-row justify-content-start flex-basis">
                                                            <div className="form-group mr-5">
                                                                <label>
                                                                    <b>Driver's license/state ID number</b>
                                                                </label>
                                                                <input type="text" name="driver_license"
                                                                       className="form-control"
                                                                       value={visitInfo.driver_license}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>
                                                                    <b>State</b>
                                                                </label>
                                                                <input type="text" name="hhs_state"
                                                                       className="form-control"
                                                                       value={visitInfo.hhs_state}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    }
                    <div className={"section"}>
                        <h5>Consent & Acknowledgments</h5>
                        <div className={"section-content col-md-12"}>
                            <div>
                                <div className="form-group">
                                    <label>
                                        <b>My personal health information may be made available electronically to other healthcare providers
                                            who have a treatment relationship with me or to my health plan for operations and care coordination as permitted by Federal and state laws.</b>
                                    </label>
                                    <div className="col-md-12 p-0 pt-2 d-flex flex-row">
                                        <label className="radio-inline mr-3">
                                            <input
                                                type="radio"
                                                name="health_info_available" value="1" className={'mr-2'}
                                                checked={visitInfo.health_info_available === 1}
                                            />
                                            Yes
                                        </label>
                                        <label className="radio-inline">
                                            <input
                                                type="radio"
                                                name="health_info_available" value="0" className={'mr-2'}
                                                checked={visitInfo.health_info_available === 0}
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div className={"mt-5"}>
                                    <div className="form-group col-md-12 p-0 pl-4 d-flex flex-row align-items-center">
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="is_acknowledge"
                                                checked={visitInfo.is_acknowledge === 1}
                                            />
                                            <b className={'ml-3 pt-1 d-block'}>
                                              I understand that I am scheduling to receive testing for COVID-19 for myself or a minor on whose behalf I am authorized to provide consent.
                                                I have been informed that testing is voluntary, that I have the right to decline any tests and that test results will be reported to the state health department where required by law.
                                                I consent to testing for COVID-19 and agree to these terms.
                                            </b>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
};

export default CovidVisitInfo;
