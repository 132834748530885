import React, {useCallback, useEffect, useState} from 'react';
import {
    getCovidLocations,
    removeCovidLocations
} from "../../Redux/Actions/covidAction";
import {useDispatch} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import AddCovidLocation from "./addCovidLocation";
import {debounce} from "lodash";
import CovidLocationAppointments from "./covidLocationAppointments";
import CovidLocationAppointmentList from "./covidLocationAppointmentList";

const CovidLocations = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            await fetchCovidLocationList();
        })();
    }, []);

    const [covidLocations, setCovidLocations] = useState([]);
    const [selectedCovidLocation, setSelectedCovidLocation] = useState(0);
    const [editCovidLocationObj, setEditCovidLocationObj] = useState({});
    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [editLocation, setEditLocation] = useState(false);
    const [deleteLocationId, setDeleteLocationId] = useState(null);
    const [generateLocationId, setGenerateLocationId] = useState(null);
    const [showGenerateModal, setShowGenerateModal] = useState(false);
    const [viewAppointmentLocationId, setViewAppointmentLocationId] = useState(null);
    const [showListAppointmentModal, setShowListAppointmentModal] = useState(false);
    const [processDelete, setProcessDelete] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState({
        loadCovidLocations: false,
    });

    const fetchCovidLocationList = async (searchText) => {
        setLoading((prev) => ({...prev, loadCovidLocations: true}));
        const locationResponse = await dispatch(getCovidLocations(searchText));
        setLoading((prev) => ({...prev, loadCovidLocations: false}));
        if(!locationResponse.isSuccess) {
            alert('Error fetching covid locations. Please try again.');
            return;
        }
        setCovidLocations(locationResponse.data);
    };

    const prepareLocationEdit = (covidLocation) => {
        setEditCovidLocationObj(covidLocation);
        setShowAddNewModal(true);
        setEditLocation(true);
    };

    const deleteLocation = (locationId) => {
        setDeleteLocationId(locationId);
    };

    const viewAppointmentList = (locationId) => {
        setViewAppointmentLocationId(locationId);
        setShowListAppointmentModal(true);
    };

    const closeModalCancelLocation = () => {
        setDeleteLocationId(null);
    };

    const processDeleteLocation = async () => {
        const id = deleteLocationId;
        setProcessDelete(true);
        const deletedResponse = await dispatch(removeCovidLocations(id));
        setProcessDelete(false);
        closeModalCancelLocation();
        if(!deletedResponse.isSuccess) {
            alert('Error deleting covid locations. Please try again.');
            return;
        }
        setCovidLocations(prev => {
            return prev.filter((item) => {
                if(item.id !== id) {
                    return item;
                }
            });
        })
    };

    const handleCloseAddLocation = () => {
        setEditCovidLocationObj({});
        setShowAddNewModal(false);
        setEditLocation(false);
    };

    const handleCloseGenerateAppointment = () => {
        setShowGenerateModal(false);
        setGenerateLocationId(null);
    };

    const handleCloseListAppointment = () => {
        setShowListAppointmentModal(false);
        setViewAppointmentLocationId(null);
    };

    const completeLocationAdd = (locationData) => {
        if(editLocation) {
            setCovidLocations(prev => {
                return prev.map((item) => {
                    if(item.id === locationData.id) {
                        return locationData;
                    }
                    return item;
                });
            });
        } else {
            setCovidLocations(prev => [locationData, ...prev]);
        }
        handleCloseAddLocation();
    };

    const renderCovidSettings = () => {
        if(covidLocations.length === 0) {
            return (
                <tr>
                    <td colspan={8} className={'text-center'}>
                        <span>There are no locations found</span>
                    </td>
                </tr>
            )
        }

        return covidLocations?.map((covidLocation) => {
            return (
                <tr key={`${covidLocation.id}`} className={selectedCovidLocation == covidLocation.id ? 'selected-row': ''}
                    onClick={() => setSelectedCovidLocation(covidLocation.id)}>
                    <td>{covidLocation.name}</td>
                    <td>{covidLocation.description}</td>
                    <td>{covidLocation.test_type? JSON.parse(covidLocation.test_type).join(', '): ''}</td>
                    <td>{covidLocation.street}</td>
                    <td>{covidLocation.city}</td>
                    <td>{covidLocation.state}</td>
                    <td>{covidLocation.zipCode}</td>
                    <td>
                        <div className="vendor-table-btn">
                            <button
                                className="orange-outline-btn"
                                onClick={() => prepareLocationEdit(covidLocation)}
                            >
                                Edit
                            </button>
                            <button
                                className="ml-2 secondary-outline-btn"
                                onClick={() => generateAppointment(covidLocation.id)}
                            >
                                Create
                            </button>
                            <button
                                className="orange ml-2"
                                onClick={() => deleteLocation(covidLocation.id)}
                            >
                                Delete
                            </button>
                            <button
                                className="primary ml-2"
                                title={"View appointment list"}
                                onClick={() => viewAppointmentList(covidLocation.id)}
                            >
                                <span><i class="fa fa-eye"></i></span>
                            </button>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const showAddNewLocation = () => {
        setEditCovidLocationObj({});
        setShowAddNewModal(true);
        setEditLocation(false);
    };

    const debounceSearchCall = useCallback(debounce(fetchCovidLocationList, 1000), []);

    const handleLocationSearch = (event) => {
        const searchValue = event.target.value;
        setSearchText(searchValue);
        debounceSearchCall(searchValue);
    };

    const generateAppointment = (locationId) => {
        setGenerateLocationId(locationId);
        setShowGenerateModal(true);
    };

    return (
        <div className="admin-card">
            <div className="row mt-2">
                <div className="col-md-12">
                    <div className="admin-card-head d-flex justify-content-end mb-2">
                        <button type="text" className="orange-outline-btn mr-2" onClick={showAddNewLocation}>
                            <i className="fas fa-plus"></i> Add Location
                        </button>
                        <div className="search w-25">
                            <input className="form-control"
                                   type="text"
                                   value={searchText}
                                   onChange={(e) => handleLocationSearch(e)}
                                   placeholder="search"
                            />
                        </div>
                    </div>
                    <div className="vendor-table">
                        <div className="vendor-table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th style={{'width': '10%'}}>Name</th>
                                        <th style={{'width': '18%'}}>Description</th>
                                        <th style={{'width': '10%'}}>Tests</th>
                                        <th style={{'width': '15%'}}>Street</th>
                                        <th style={{'width': '9%'}}>City</th>
                                        <th style={{'width': '8%'}}>State</th>
                                        <th style={{'width': '7%'}}>Zip Code</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        loading.loadCovidLocations? (
                                            <tr>
                                                <td colSpan={8} className={'text-center'}>
                                                    <span className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </span>
                                                </td>
                                            </tr>
                                        ) : renderCovidSettings()
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {
                        showAddNewModal &&
                        <Modal show={showAddNewModal} onHide={handleCloseAddLocation}>
                            <Modal.Header closeButton>
                                <Modal.Title>{editLocation? 'Edit Location': 'Add Location'}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className={'add-location-body'}>
                                    <AddCovidLocation
                                        handleCloseAddLocation={completeLocationAdd}
                                        covidLocationData={editCovidLocationObj}
                                        locationEdit={editLocation}
                                    />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseAddLocation}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    }
                    {deleteLocationId ? (
                        <div
                            className="modal"
                            id="removeItem"
                            style={{ display: "block" }}
                        >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">
                                            {" "}
                                            Are you sure you want to remove this location?
                                        </h4>
                                        <button
                                            type="button"
                                            class="close"
                                            data-dismiss="modal"
                                            onClick={closeModalCancelLocation}
                                        >
                                                <span>
                                                  <i class="far fa-times-circle"></i>
                                                </span>
                                        </button>
                                    </div>

                                    <div className="modal-body" style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}>
                                        <button
                                            className="orange-outline-btn"
                                            onClick={processDeleteLocation}
                                            disabled={processDelete}
                                        >
                                            Yes
                                            {
                                                processDelete &&
                                                <span className="spinner-border ml-2" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </span>
                                            }
                                        </button>
                                        <button
                                            className="danger-outline-btn"
                                            onClick={closeModalCancelLocation}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {
                        showGenerateModal &&
                        <Modal show={showGenerateModal} onHide={handleCloseGenerateAppointment}>
                            <Modal.Header closeButton>
                                <Modal.Title>Generate Appointments</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <CovidLocationAppointments
                                    handleCloseGenerateAppointment={handleCloseGenerateAppointment}
                                    generateLocationId={generateLocationId}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseGenerateAppointment}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    }
                    {
                        showListAppointmentModal &&
                        <Modal show={showListAppointmentModal} onHide={handleCloseListAppointment} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Appointment List</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <CovidLocationAppointmentList
                                    selectedLocationId={viewAppointmentLocationId}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseListAppointment}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    }
                </div>
            </div>
        </div>
    )
};

export default CovidLocations;
