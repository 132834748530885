import React, { Component } from 'react';
import '../../Styles/user-detail.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {updateShippingSettings} from "../../Redux/Actions/settingAction";

import 'react-confirm-alert/src/react-confirm-alert.css';

class EditShippingSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            saveSettingsLoading: false,
            errorMessage: '',
            length: 0,
            width: 0,
            height: 0,
            weight: 0,
            distance_unit: 'in',
            mass_unit: 'lb',
            errorData: {
                length: [],
                width: [],
                height: [],
                weight: [],
            }
        };

    }

    componentDidMount() {
        const { settingsEditData } = this.props;
        if(settingsEditData) {
            this.setState({
                length: settingsEditData.length,
                width: settingsEditData.width,
                height: settingsEditData.height,
                weight: settingsEditData.weight,
            })
        }
    }

    handleChange = (e, key) => {
        if (this.state.errorData && this.state.errorData[key] && this.state.errorData[key][0]) {
            this.state.errorData[key][0] = null;
        }

        let { value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            [key]: value
        }))
    };

    validateSettingsInfo = (formData) => {
        let isFormValid = true;
        const error = {
            length: [],
            width: [],
            height: [],
            weight: [],
        };

        const floatRegEx = /^[+-]?[0-9]{1,9}(?:\.[0-9]{1,6})?$/;
        if (!formData.length) {
            isFormValid = false;
            error.length = ["Required field"];
        }
        if (!floatRegEx.test(formData.length)) {
            isFormValid = false;
            error.length = ["Invalid length"];
        }
        if (!formData.width) {
            isFormValid = false;
            error.width = ["Required field"];
        }
        if (!floatRegEx.test(formData.width)) {
            isFormValid = false;
            error.width = ["Invalid width"];
        }
        if (!formData.height) {
            isFormValid = false;
            error.height = ["Required field"];
        }
        if (!floatRegEx.test(formData.height)) {
            isFormValid = false;
            error.height = ["Invalid height"];
        }
        if (!formData.weight) {
            isFormValid = false;
            error.weight = ["Required field"];
        }
        if (!floatRegEx.test(formData.weight)) {
            isFormValid = false;
            error.weight = ["Invalid weight"];
        }

        this.setState({
            ...this.state,
            errorData: error
        });
        return isFormValid;
    };

    handleSubmit = async (e) => {
        const {settingsEditData} = this.props;
        e.preventDefault();
        const formValid = this.validateSettingsInfo(this.state);
        if(!formValid) {
            return;
        }

        this.setState({
            saveSettingsLoading: true
        });

        let body = {
            length: this.state.length,
            width: this.state.width,
            height: this.state.height,
            weight: this.state.weight,
            distance_unit: this.state.distance_unit,
            mass_unit: this.state.mass_unit,
        };
       const response = await this.props.updateShippingSettings(settingsEditData.mainProductId, body);

        if (response && response.data) {
            if (response.data.isSuccess) {
                this.setState({
                    length: 0,
                    width: 0,
                    height: 0,
                    weight: 0,
                    saveSettingsLoading: false
                });
                this.props.handleCloseEditSettings(response.data.data);
            }
            else {
                // response with failed case
                this.setState({
                    errorMessage: response.data.errorMessage,
                    saveSettingsLoading: false
                })
            }
        }
        else {
            this.setState({
                errorMessage: "Something went wrong. Please try again later.",
                saveSettingsLoading: false
            })
        }
    };

    render() {
        const {settingsEditData} = this.props;

        return (
            <div class="admin-card">
                <div class="admin-card-box">
                    {
                        this.state.errorMessage &&
                        <div className="alert alert-danger">{this.state.errorMessage}</div>
                    }
                    <div className="row mt-3">
                        <div className="col-lg-12 col-md-12 d-flex">
                            <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <label>Product Name</label>
                                    <input type="text" disabled readOnly className="form-control" value={settingsEditData.productName}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-12 col-md-12 d-flex">
                            <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>Length(in)<sup>*</sup>
                                    </label>
                                    <input type="number" onChange={(e) => this.handleChange(e, "length")}
                                           value={this.state.length} className="form-control"/>
                                    <span className="form-field-error">
                                        {this.state.errorData?.length &&
                                        this.state.errorData.length[0]}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>Width(in)<sup>*</sup>
                                    </label>
                                    <input type="number" onChange={(e) => this.handleChange(e, "width")}
                                           value={this.state.width} className="form-control"/>
                                    <span className="form-field-error">
                                        {this.state.errorData?.width &&
                                        this.state.errorData.width[0]}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>Height(in)<sup>*</sup>
                                    </label>
                                    <input type="number" onChange={(e) => this.handleChange(e, "height")}
                                           value={this.state.height} className="form-control"/>
                                    <span className="form-field-error">
                                        {this.state.errorData?.height &&
                                        this.state.errorData.height[0]}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 d-flex">
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>Weight(lb)<sup>*</sup>
                                    </label>
                                    <input type="number" onChange={(e) => this.handleChange(e, "weight")}
                                           value={this.state.weight} className="form-control"/>
                                    <span className="form-field-error">
                                        {this.state.errorData?.weight &&
                                        this.state.errorData.weight[0]}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-btn text-center">
                            <button type="button" onClick={(e)=> this.handleSubmit(e)} class="orange-outline-btn">submit
                                {(() => {
                                    if (this.state ?.saveSettingsLoading) {
                                        return <span class="spinner-border spinner-border-sm ml-1"></span>
                                    }
                                })()}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateShippingSettings,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(EditShippingSettings)
