import React  from "react";
import moment from "moment";

class ConfirmationCodeLabel extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {item} = this.props;
        return (
            <div className={'confirmation-code-label'}>
                <label className={'content d-block m-0'}>
                    <b>{item.patient_first_name.charAt(0)} {item.patient_last_name}</b>
                </label>
                <label className={'content d-block m-0'}><b>DOB:</b></label>
                <label className={'content d-block m-0'}><b>{item.date_of_birth && moment(new Date(item.date_of_birth)).format('YYYY-DD-MM')}</b></label>
                <label className={'content code d-block m-0'}><b>{item.confirmation_code}</b></label>
            </div>
        );
    }
}

export default ConfirmationCodeLabel;
