import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import '../../Styles/track-orders.scss';
import {
    getShipment,
    purchaseLabel,
} from '../../Redux/Actions/shippingAction';
import "./OrderShip.scss";
import {formatPhoneNumber} from "react-phone-number-input";
import {Button, Modal} from "react-bootstrap";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import {Link} from "react-router-dom";

const OrderShip = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [purchaseLoading, setPurchaseLoading] = useState(false);
    const [apiError, setAPIError] = useState(null);
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    const [rateObjectId, setRateObjectId] = useState(false);
    const [purchaseError, setPurchaseError] = useState(null);
    const [shipmentType, setShipmentType] = useState('');
    const [shipmentTrackingId, setShipmentTrackingId] = useState({});
    const [purchaseFor, setPurchaseFor] = useState({});
    const [shipment, setShipment] = useState({
        address_from: {},
        address_to: {},
        parcel: {},
        rates: [],
    });
    const [returnShipment, setReturnShipment] = useState({
        address_from: {},
        address_to: {},
        parcel: {},
        rates: [],
    });
    const orderId = props.match.params.orderId;

    const init = async () => {
        const shipmentResponse = await dispatch(getShipment(orderId));
        setLoading(false);

        if(!shipmentResponse.isSuccess) {
            setAPIError(shipmentResponse.errorMessage);
            return;
        }
        setShipmentTrackingId({
            trackingId: shipmentResponse.trackingId,
            returnTrackingId: shipmentResponse.returnTrackingId,
        });
        setShipment(shipmentResponse.shipment);
        setReturnShipment(shipmentResponse.returnShipment);
    };

    useEffect(() => {
        init();
    }, []);

    const getFormattedPhone = (phone) => {
        if(!phone) {
            return '';
        }

        return formatPhoneNumber(phone.replace('001', '+1'));
    };

    const onPurchaseClick = (rateId, type) => {
       setRateObjectId(rateId);
       setPurchaseError(null);
       setShipmentType(type);
       togglePurchaseModal();
    };

    const getRates = (type) => {
      const shipmentObj = type === 'send'? shipment: returnShipment;
      if(shipmentObj.rates.length === 0) {
          return;
      }
      return shipmentObj.rates.map((rate, index) => {
         let ribbonClass, tag = '';
         if(rate.attributes.length) {
             if(rate.attributes[0] === 'BESTVALUE') {
                 ribbonClass = 'green';
                 tag = 'BESTVALUE';
             }
             if(rate.attributes[0] === 'CHEAPEST') {
                 ribbonClass = 'blue';
                 tag = 'CHEAPEST';
             }
             if(rate.attributes[0] === 'FASTEST') {
                 ribbonClass = 'orange';
                 tag = 'FASTEST';
             }
         }
         return (
             <div className="card" key={index}>
                 {
                     ribbonClass &&
                     <div className={`ribbon ${ribbonClass}`}><span>{tag}</span></div>
                 }
                 <div className="card-body">
                     <div className="mx-auto d-block">
                         <img className="carrier-logo mx-auto d-block"
                              src={rate.provider_image_200}
                              alt="carrier_logo"/>
                     </div>
                     <hr/>
                     <div className="details">
                         <div className={"d-flex flex-row justify-content-between"}>
                             <span className={"label"}> <b>Price: </b></span>
                             <span className={"value"}>{rate.currency_local} {rate.amount_local}</span>
                         </div>
                         <div className={"d-flex flex-row justify-content-between"}>
                             <span className={"label"}> <b>Est Days: </b></span>
                             <span className={"value"}>{rate.estimated_days}</span>
                         </div>
                         <div className={"d-flex flex-row justify-content-between"}>
                             <span className={"label"}> <b>Type: </b></span>
                             <span className={"value"}>{rate.servicelevel.name}</span>
                         </div>
                         {
                             rate.attributes.length?
                             <div className={"d-flex flex-row justify-content-between"}>
                                 <span className={"label"}> <b>Attributes: </b></span>
                                 <span className={"value"}> &nbsp; {rate.attributes.map(x=> x.toLowerCase()).join(', ')}</span>
                             </div>: ''
                         }

                     </div>
                     <hr/>
                     <div className="additional-details">
                        <span className="fa fa-exclamation-triangle"> {rate.duration_terms}</span>
                     </div>
                 </div>
                 <div className="card-footer text-center">
                     <button
                         type="button"
                         className="orange-outline-btn ml-2"
                         onClick={() => onPurchaseClick(rate.object_id, type)}
                     >Purchase
                     </button>
                 </div>
             </div>
         )
      });
    };

    const togglePurchaseModal = () => {
        setShowPurchaseModal(prev => !prev);
    };

    const onPurchaseShipment = async () => {
        setPurchaseLoading(true);
        const payload = { rateId: rateObjectId, orderId, trackCompany: 'USPS', shipmentType };
        const purchaseResponse = await dispatch(purchaseLabel(payload));
        setPurchaseLoading(false);
        if(!purchaseResponse.isSuccess) {
            setPurchaseError(purchaseResponse.errorMessage);
            return;
        }
        setPurchaseFor(purchaseResponse.shipmentType);
        togglePurchaseModal();
    };

    return (
        <div class="admin-card">
            <div class="admin-card-head">
                <h2>Shipment</h2>
                <Link to={`/orders/${orderId}`}>
                    <button type="text" className="orange-outline-btn">
                        <i className="fas fa-arrow-left"></i> Back to Order
                    </button>
                </Link>
            </div>
            <div className="admin-card-box">

                {
                    apiError &&
                    <div className="alert alert-danger">{apiError}</div>
                }

                {loading && (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}

                {!loading && !apiError &&
                    <div className="vendor-table">
                        <div className={"row address"}>
                            <div className={"col-md-12"}>
                                <div className="card">
                                    <div className="card-header">
                                        <h4>Shipping From</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className={"d-flex flex-column"}>
                                            <div className={"item"}>
                                                <span className={"label"}>Name</span>
                                                <span className={"value"}>{shipment.address_from.name}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>Company</span>
                                                <span className={"value"}>{shipment.address_from.company}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>Address Line 1</span>
                                                <span className={"value"}>{shipment.address_from.street1}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>State</span>
                                                <span className={"value"}>{shipment.address_from.state}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>City</span>
                                                <span className={"value"}>{shipment.address_from.city}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>Country</span>
                                                <span className={"value"}>{shipment.address_from.country}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>Zip Code</span>
                                                <span className={"value"}>{shipment.address_from.zip}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>Email</span>
                                                <span className={"value"}>{shipment.address_from.email}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>Phone</span>
                                                <span className={"value"}>{getFormattedPhone(shipment.address_from.phone)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h4>Shipping To</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className={"d-flex flex-column"}>
                                            <div className={"item"}>
                                                <span className={"label"}>Name</span>
                                                 <span className={"value"}>{shipment.address_to.name}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>Address Line 1</span>
                                                 <span className={"value"}>{shipment.address_to.street1}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>State</span>
                                                 <span className={"value"}>{shipment.address_to.state}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>City</span>
                                                 <span className={"value"}>{shipment.address_to.city}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>Country</span>
                                                 <span className={"value"}>{shipment.address_to.country}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>Zip Code</span>
                                                 <span className={"value"}>{shipment.address_to.zip}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>Email</span>
                                                 <span className={"value"}>{shipment.address_to.email}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>Phone</span>
                                                <span className={"value"}>{getFormattedPhone(shipment.address_to.phone)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h4>Parcel Detail</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className={"d-flex flex-column"}>
                                            <div className={"item"}>
                                                <span className={"label"}>Length</span>
                                                 <span className={"value"}>{shipment.parcel.length} {shipment.parcel.distance_unit}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>Width</span>
                                                 <span className={"value"}>{shipment.parcel.width} {shipment.parcel.distance_unit}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>Height</span>
                                                 <span className={"value"}>{shipment.parcel.height} {shipment.parcel.distance_unit}</span>
                                            </div>
                                            <div className={"item"}>
                                                <span className={"label"}>Weight</span>
                                                 <span className={"value"}>{shipment.parcel.weight} {shipment.parcel.mass_unit}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr/>
                        <h4>Send Label</h4>
                        {
                            shipmentTrackingId.trackingId &&
                            <div className="alert alert-success">
                                <span className="fa fa-exclamation-triangle">
                                    The send label is already generated for this order. If you want to create another label, it will overwrite the existing label.
                                </span>
                            </div>
                        }
                        {
                            purchaseFor.sendLabel ?
                                (
                                <div className="alert alert-success">
                                    <span className="fa fa-exclamation-triangle">
                                        The send label is generated for this order. You can download the send label from the order detail page.
                                    </span>
                                </div>
                                ): (
                                    <div className={"row shipment"}>
                                        {getRates('send')}
                                    </div>
                                )
                        }
                        <hr/>
                        <h4>Return Label</h4>
                        {
                            shipmentTrackingId.returnTrackingId &&
                            <div className="alert alert-success">
                                <span className="fa fa-exclamation-triangle">
                                    The return label is already generated for this order. If you want to create another return label, it will overwrite the existing return label.
                                </span>
                            </div>
                        }
                        {
                            purchaseFor.returnLabel ?
                                (
                                    <div className="alert alert-success">
                                    <span className="fa fa-exclamation-triangle">
                                        The return label is generated for this order. You can download the return label from the order detail page.
                                    </span>
                                    </div>
                                ): (
                                    <div className={"row shipment"}>
                                        {getRates('return')}
                                    </div>
                                )
                        }
                    </div>
                }
            </div>

            {showPurchaseModal ? (
                <div
                    className="modal"
                    id="purchaseShipment"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">
                                    {" "}
                                    Are you sure you want to purchase this shipment? A label will be generated and
                                    you will be charged from the card which you have added in the shipping portal.
                                </h4>
                                {purchaseLoading && (
                                    <div className="purchase-loader">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                )}
                                <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    onClick={togglePurchaseModal}
                                    disabled={purchaseLoading}
                                >
                                    <span>
                                      <i class="far fa-times-circle"></i>
                                    </span>
                                </button>
                            </div>

                            <div className="modal-body" style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <div className={"d-flex flex-column w-100"}>
                                    <div className={"d-flex text-center"}>
                                        {
                                            purchaseError &&
                                            <div className="alert alert-danger w-100">{purchaseError}</div>
                                        }
                                    </div>

                                    <div className={"d-flex flex-row justify-content-between"}>
                                        <div>
                                            <button
                                                className="orange-outline-btn"
                                                onClick={onPurchaseShipment}
                                                disabled={purchaseLoading}
                                            >
                                                Yes
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="danger-outline-btn"
                                                onClick={togglePurchaseModal}
                                                disabled={purchaseLoading}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default OrderShip;
