import React  from "react";
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import './settings.scss';

import SystemUsers from "./system-users";
import PanelSettings from "./panel-settings";
import KitSettings from "./kitId-settings";
import ShippingSettings from "./shipping-settings";
import {getPermissionSettings, savePermission} from "../../Redux/Actions/settingAction";
import ShippingAddress from "./shipping-address";

class Settings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allPermissions:[],
            showPermissionLoader: false,
            showSaveLoader: false,
            permissionState: {},
            clonePermissionObj: {},
            loadSystemUsers: false,
            loadPanelSettings: false,
            loadKitIdSettings: false,
            loadShippingSettings: false,
            loadParcelSettings: false,
            loadShippingAddressSettings: false,
        };
    }
    async componentDidMount() {
        this.setState({
            showPermissionLoader: true
        });
        let allPermissions = await this.props.getPermissionSettings();
        allPermissions = allPermissions?.data?.data.map((obj) => {
            return {
                ...obj,
                admin: JSON.parse(obj.admin),
                management: JSON.parse(obj.management),
                techSupport: JSON.parse(obj.techSupport),
                doctor: JSON.parse(obj.doctor)
            };
        }) || [];

        const permissionStateObj = {};
        allPermissions.map((permissionObj) => {
            permissionStateObj[permissionObj.id] = true;
        });
        this.setState({
            showPermissionLoader: false,
            allPermissions: allPermissions,
            permissionState: permissionStateObj
        });
    }

    prepareEdit = (permissionId) => {
        const clonePermissionObj = JSON.parse(JSON.stringify(this.state.allPermissions.find((obj) => obj.id === permissionId)));

        this.setState({
            permissionState: {
                ...this.state.permissionState,
                ...{[permissionId]: false}
            },
            clonePermissionObj: {
                ...this.state.clonePermissionObj,
                [permissionId]: clonePermissionObj
            }
        })
    };

    savePermission = async (permissionId) => {
        this.setState({
            showSaveLoader: true
        });
        const permissionObj = this.state.allPermissions.find((obj) => obj.id === permissionId);
        await this.props.savePermission(permissionId, permissionObj);
        delete this.state.clonePermissionObj[permissionId];

        this.setState({
            showSaveLoader: false,
            clonePermissionObj: this.state.clonePermissionObj,
            permissionState: {
                ...this.state.permissionState,
                ...{[permissionId]: true}
            }
        });
    };

    cancelEdit = (permissionId) => {
        const allPermissions = this.state.allPermissions.map((obj) => {
            if(obj.id === permissionId) {
                return this.state.clonePermissionObj[permissionId];
            }
            return obj;
        });

        this.setState({
            allPermissions,
            permissionState: {
                ...this.state.permissionState,
                ...{[permissionId]: true}
            }
        });

    };

    changeCheckbox = (event, permissionId, type, permission) => {
        const allPermissions = this.state.allPermissions.map((permissionObj) => {
            if(permissionObj.id === permissionId) {
                permissionObj[type][permission] = event.target.checked ? 1: 0
            }
            return permissionObj;
        });

        this.setState({
            allPermissions
        })
    };

    getCbOptions = (permissionId, permissionObj, type) => {
        if(!permissionObj) {
            return;
        }

        const {permissionState} = this.state;
        return Object.keys(permissionObj).map((key) => {
            return (
                <div key={`${key}-${permissionId}-${type}`}>
                    <div className={'d-flex'}>
                        <label htmlFor={`${key}-${permissionId}-${type}`} class="mr-1">{
                            key === 'R'? 'Read':
                                key === 'U'? 'Update':
                                key === 'D'? 'Delete': ''
                        }</label>
                        <input id={`${key}-${permissionId}-${type}`} type="checkbox"
                               checked={permissionObj[key] === 1}
                               style={{'margin-top': '2px'}}
                               disabled={type === 'admin' || permissionState[permissionId]}
                               onChange={(e) => this.changeCheckbox(e, permissionId, type, key)}/> <br/>
                    </div>
                </div>
            );
        });
    };

    prepareTab = (tabName) => {
        const obj = {
            loadSystemUsers: tabName === 'systemUser',
            loadPanelSettings: tabName === 'panelSettings',
            loadKitIdSettings: tabName === 'kitIdSettings',
            loadShippingSettings: tabName === 'shippingSettings',
        };
        this.setState({
            ...this.state,
            ...obj
        });

    };

    prepareSubTab = (tabName) => {
        const obj = {
            loadParcelSettings: tabName === 'parcel-settings',
            loadShippingAddressSettings: tabName === 'shipping-address',
        };
        this.setState({
            ...this.state,
            ...obj
        });

    };

    render() {
        const {
            allPermissions,
            permissionState,
            loadSystemUsers,
            loadPanelSettings,
            loadKitIdSettings,
            loadShippingSettings,
            showSaveLoader,
            showPermissionLoader,
            loadShippingAddressSettings,
        } = this.state;

        const permissionList = allPermissions?.map((permissionObj, index) => {
            return (
                <tr key={`${permissionObj.id}-${index}`}>
                    <td>{permissionObj.type}</td>
                    <td> {this.getCbOptions(permissionObj.id, permissionObj.admin, 'admin')} </td>
                    <td> {this.getCbOptions(permissionObj.id, permissionObj.management, 'management')} </td>
                    <td> {this.getCbOptions(permissionObj.id, permissionObj.techSupport, 'techSupport')} </td>
                    <td> {this.getCbOptions(permissionObj.id, permissionObj.doctor, 'doctor')} </td>
                    <td>
                        <div className="vendor-table-btn">
                        {
                            permissionState[permissionObj.id] ?
                                (<button
                                    type="button"
                                    className="orange-outline-btn mr-2"
                                    onClick={() => this.prepareEdit(permissionObj.id)}
                                >
                                    Edit
                                </button>): (
                                    <>
                                        <button
                                            type="button"
                                            className="orange-outline-btn mr-2"
                                            onClick={() => this.savePermission(permissionObj.id)}
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="danger-outline-btn"
                                            onClick={() => this.cancelEdit(permissionObj.id)}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                )
                        }
                        </div>
                    </td>
                </tr>
            );
        });
         return (
            <div className="admin-card">
                <div className="admin-card-box">
                    <div className="custom-tab">
                        <nav>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <a className="nav-item nav-link active" id="permission-settings-tab" data-toggle="tab"
                                   href="#permission-settings" role="tab" aria-controls="custom-nav-permission-settings"
                                   aria-selected="true" onClick={() => this.prepareTab('permission')}>Permission Settings</a>
                                <a className="nav-item nav-link" id="system-users-tab" data-toggle="tab"
                                   href="#system-users" role="tab" aria-controls="custom-nav-system-users"
                                   aria-selected="true" onClick={() => this.prepareTab('systemUser')}>Company Users</a>
                                <a className="nav-item nav-link" id="panel-settings-tab" data-toggle="tab"
                                   href="#panel-settings" role="tab" aria-controls="custom-nav-panel-settings"
                                   aria-selected="true" onClick={() => this.prepareTab('panelSettings')}>Panel Settings</a>
                                <a className="nav-item nav-link" id="kit-id-tab" data-toggle="tab"
                                   href="#kit-id-settings" role="tab" aria-controls="custom-nav-kit-id"
                                   aria-selected="true" onClick={() => this.prepareTab('kitIdSettings')}>Kit Id Settings</a>
                                <a className="nav-item nav-link" id="shipping-settings-tab" data-toggle="tab"
                                   href="#shipping-settings" role="tab" aria-controls="custom-nav-shipping-settings"
                                   aria-selected="true" onClick={() => this.prepareTab('shippingSettings')}>Shipping Settings</a>
                            </div>
                        </nav>

                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="permission-settings" role="tabpanel"
                                 aria-labelledby="permission-settings-tab">
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <div className="vendor-table">
                                            <div className="vendor-table-body">
                                                <div className="table-responsive">
                                                    {showSaveLoader || showPermissionLoader? (
                                                        <div class="d-flex justify-content-center">
                                                            <div class="spinner-border" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                    <table className="table">
                                                        <thead>
                                                        <tr>
                                                            <th>Type</th>
                                                            <th>Admin</th>
                                                            <th>Management</th>
                                                            <th>Tech Support</th>
                                                            <th>Doctor</th>
                                                            <th class="w-25">Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>{permissionList}</tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="system-users" role="tabpanel"
                                 aria-labelledby="system-users-tab">
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <div className="vendor-table">
                                            <div className="vendor-table-body">
                                                {loadSystemUsers && <SystemUsers />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="panel-settings" role="tabpanel"
                                 aria-labelledby="panel-settings-tab">
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <div className="vendor-table">
                                            <div className="vendor-table-body">
                                                {loadPanelSettings && <PanelSettings />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="kit-id-settings" role="tabpanel"
                                 aria-labelledby="kit-id-tab">
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <div className="vendor-table">
                                            <div className="vendor-table-body">
                                                {loadKitIdSettings && <KitSettings />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="shipping-settings" role="tabpanel"
                                 aria-labelledby="shipping-settings-tab">
                                <div className="admin-card">
                                    <div className="admin-card-box">
                                    <div className="custom-tab">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <a className="nav-item nav-link active" id="parcel-settings-tab" data-toggle="tab"
                                                   href="#parcel-settings" role="tab" aria-controls="custom-nav-parcel-settings"
                                                   aria-selected="true" onClick={() => this.prepareSubTab('parcel-settings')}>Parcel Settings</a>
                                                <a className="nav-item nav-link" id="shipping-address-tab" data-toggle="tab"
                                                   href="#shipping-address" role="tab" aria-controls="custom-nav-shipping-address"
                                                   aria-selected="true" onClick={() => this.prepareSubTab('shipping-address')}>Shipping Address</a>
                                            </div>
                                        </nav>

                                        <div className="tab-content" id="nav-sub-tabContent">
                                            <div className="tab-pane fade show active" id="parcel-settings" role="tabpanel"
                                                 aria-labelledby="parcel-settings-tab">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        {loadShippingSettings && <ShippingSettings />}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="shipping-address" role="tabpanel"
                                                 aria-labelledby="shipping-address-tab">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        {loadShippingAddressSettings && <ShippingAddress />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                     </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        ...state
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getPermissionSettings,
        savePermission,
    }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
