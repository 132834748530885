import React, { Component } from 'react';
import '../../Styles/user-detail.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {createCompanyUser, updateCompanyUser} from "../../Redux/Actions/settingAction";

import 'react-confirm-alert/src/react-confirm-alert.css';

class AddSystemUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            saveUserLoading: false,
            invalidUser: false,
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            cnfPassword: '',
            role: '',
            status: '',
            errorMessage: '',
            errorData: {
                firstName: [],
                lastName: [],
                email: [],
                password: [],
                cnfPassword: [],
                role: [],
            }
        };

    }

    componentDidMount() {
        const { userData, userEdit } = this.props;
        if(userData && userEdit) {
            this.setState({
                firstName: userData.firstname,
                lastName: userData.lastname,
                email: userData.email,
                role: userData.role,
                status: userData.isactive.toString(),
            })
        }
    }

    handleChange = (e, key) => {
        if (this.state.errorData && this.state.errorData[key] && this.state.errorData[key][0]) {
            this.state.errorData[key][0] = null;
        }

        let { value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            [key]: value
        }))
    };

    validateReportUploadInfo = (formData) => {
        let isFormValid = true;
        const {userEdit} = this.props;
        const error = {
            firstName: [],
            lastName: [],
            email: [],
            password: [],
            cnfPassword: [],
            role: [],
        };

        if (formData.email.trim().length === 0) {
            isFormValid = false;
            error.email = ["Email cannot be empty!"];
        }
        if (
            formData.email.trim().length > 0 &&
            /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
                formData.email
            ) === false
        ) {
            isFormValid = false;
            error.email = ["Email must be a valid email address"];
        }

        if(!userEdit) {
            if (formData.password.trim().length === 0) {
                isFormValid = false;
                error.password = ["Password cannot be empty!"];
            }
            const passwordErr = this.validatePasswordStrength(formData.password?.trim());
            if (passwordErr) {
                isFormValid = false;
                error.password = [passwordErr];
            }
            if (formData.cnfPassword.trim().length === 0) {
                isFormValid = false;
                error.cnfPassword = ["Confirm Password cannot be empty!"];
            }

            const confPasswordErr = this.validatePasswordStrength(formData.cnfPassword?.trim());
            if (confPasswordErr) {
                isFormValid = false;
                error.cnfPassword = [confPasswordErr];
            }

            if (
                formData.cnfPassword.trim().length > 0 &&
                formData.password.trim() !== formData.cnfPassword.trim()
            ) {
                isFormValid = false;
                error.cnfPassword = ["Passwords Don’t Match!"];
            }
        }

        if (formData.firstName.trim().length === 0) {
            isFormValid = false;
            error.firstName = ["First name is required"];
        }
        if (formData.lastName.trim().length === 0) {
            isFormValid = false;
            error.lastName = ["Last name is required"];
        }
        if (formData.role.trim().length === 0) {
            isFormValid = false;
            error.role = ["Role is required"];
        }
        this.setState({
            ...this.state,
            errorData: error
        });
        return isFormValid;
    };

    handleSubmit = async (e) => {
        const {userEdit, userData} = this.props;
        e.preventDefault();
        const formValid = this.validateReportUploadInfo(this.state);
        if(!formValid) {
            return;
        }

        this.setState({
            saveUserLoading: true
        });

        let body, response;
        if(userEdit) {
            body = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                role: this.state.role,
                status: this.state.status
            };
            response = await this.props.updateCompanyUser(userData.id, body);
        } else {
            body = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                password: this.state.password,
                cnfPassword: this.state.cnfPassword,
                role: this.state.role,
            };
            response = await this.props.createCompanyUser(body);
        }

        if (response && response.data) {
            if (response.data.isSuccess) {
                this.setState({
                    firstName: [],
                    lastName: [],
                    email: [],
                    password: [],
                    cnfPassword: [],
                    role: [],
                    saveUserLoading: false
                });
                this.props.handleCloseAddReport();
            }
            else {
                // response with failed case
                this.setState({
                    errorMessage: response.data.errorMessage,
                    saveUserLoading: false
                })
            }
        }
        else {
            this.setState({
                errorMessage: "Something went wrong. Please try again later.",
                saveUserLoading: false
            })
        }
    };

    validatePasswordStrength(password) {
        if(
            !password ||
            password.length > 0 &&
            (password.length < 8 || password.length > 15)
        ) {
            return 'Length must be between 8 to 15 characters'
        }
        if(!password.match(/[A-Z]/)) {
            return 'At least one uppercase character required';
        }
        if(!password.match(/[a-z]/)) {
            return 'At least one lowercase character required';
        }
        if(!password.match(/[0-9]/)) {
            return 'At least one digit required';
        }
        if(!password.match(/[#?!@$%^&*-]/)) {
            return 'At least one special character required';
        }

        return null;
    };

    render() {
        const {userEdit} = this.props;

        return (
            <div class="admin-card">
                {this.state.showLoader ? (
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (null)}
                <div class="admin-card-box">
                    {
                        this.state.errorMessage &&
                        <div className="alert alert-danger">{this.state.errorMessage}</div>
                    }
                    <div className="row mt-3">
                        <div className="col-lg-12 col-md-12 d-flex">
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>First Name
                                        <sup>*</sup>
                                    </label>
                                    <input type="text" onChange={(e) => this.handleChange(e, "firstName")}
                                           value={this.state.firstName} className="form-control"/>
                                    <span className="form-field-error">
                                        {this.state.errorData?.firstName &&
                                        this.state.errorData.firstName[0]}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>Last Name
                                        <sup>*</sup>
                                    </label>
                                    <input type="text" onChange={(e) => this.handleChange(e, "lastName")}
                                           value={this.state.lastName} className="form-control"/>
                                    <span className="form-field-error">
                                        {this.state.errorData?.lastName &&
                                        this.state.errorData.lastName[0]}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 d-flex">
                            <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <label>Email
                                        <sup>*</sup>
                                    </label>
                                    <input type="email" onChange={(e) => this.handleChange(e, "email")}
                                           value={this.state.email} className="form-control" disabled={userEdit} readOnly={userEdit}/>
                                    <span className="form-field-error">
                                        {this.state.errorData?.email &&
                                        this.state.errorData.email[0]}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        !userEdit &&
                        <div className="row">
                            <div className="col-lg-12 col-md-12 d-flex">
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label>Password
                                            <sup>*</sup>
                                        </label>
                                        <input type="password" onChange={(e) => this.handleChange(e, "password")}
                                               value={this.state.password} className="form-control"/>
                                        <span className="form-field-error">
                                        {this.state.errorData?.password &&
                                        this.state.errorData.password[0]}
                                    </span>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label>Confirm Password
                                            <sup>*</sup>
                                        </label>
                                        <input type="password" onChange={(e) => this.handleChange(e, "cnfPassword")}
                                               value={this.state.cnfPassword} className="form-control"/>
                                        <span className="form-field-error">
                                        {this.state.errorData?.cnfPassword &&
                                        this.state.errorData.cnfPassword[0]}
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-lg-12 col-md-12 d-flex">
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>Role
                                        <sup>*</sup>
                                    </label>
                                    <select className="form-control"
                                            onChange={(e) => this.handleChange(e, "role")}
                                            value={this.state.role}>
                                        <option value="" disabled selected>--Select--</option>
                                        <option value="admin">Admin</option>
                                        <option value="management">Management</option>
                                        <option value="techSupport">Tech Support</option>
                                        <option value="doctor">Doctor</option>
                                    </select>
                                    <span className="form-field-error">
                                        {this.state.errorData?.role &&
                                        this.state.errorData.role[0]}
                                    </span>
                                </div>
                            </div>
                            {
                                userEdit &&
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Status</label>
                                            <select className="form-control"
                                                    onChange={(e) => this.handleChange(e, "status")}
                                                    value={this.state.status}>
                                                <option value="" disabled selected>--Select--</option>
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </select>
                                            <span className="form-field-error">
                                        {this.state.errorData?.status &&
                                        this.state.errorData.status[0]}
                                    </span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-btn text-center">
                            <button type="button" onClick={(e)=> this.handleSubmit(e)} class="orange-outline-btn">submit
                                {(() => {
                                    if (this.state ?.saveUserLoading) {
                                        return <span class="spinner-border spinner-border-sm ml-1"></span>
                                    }
                                })()}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        createCompanyUser,
        updateCompanyUser,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(AddSystemUser)
