import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import { bindActionCreators } from 'redux';
import {
    updateItemAccession,
    createItemAccession
} from "../../Redux/Actions/activityAction";

import 'react-confirm-alert/src/react-confirm-alert.css';

class AddAccession extends Component {

    constructor(props) {
        super(props);
        const currentDate = moment(new Date()).format('YYYY-MM-DD');
        this.state = {
            saveAccessionLoading: false,
            sampleType: '',
            dateCollected: currentDate,
            dateReceived: currentDate,
            errorMessage: '',
            errorData: {
                sampleType: [],
                dateCollected: [],
                dateReceived: [],
            }
        };

    }

    componentDidMount() {
        const { accessionEdit, selectedItem } = this.props;
        if(accessionEdit) {
            this.setState({
                sampleType: selectedItem.sampleType,
                dateCollected: moment(selectedItem.dateCollected).format('YYYY-MM-DD'),
                dateReceived: moment(selectedItem.dateReceived).format('YYYY-MM-DD'),
            })
        }
    }

    handleChange = (e, key) => {
        if (this.state.errorData && this.state.errorData[key] && this.state.errorData[key][0]) {
            this.state.errorData[key][0] = null;
        }

        let { value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            [key]: value
        }))
    };

    validateAccessionInfo = (formData) => {
        let isFormValid = true;
        const error = {
            sampleType: [],
            dateCollected: [],
            dateReceived: [],
        };

        if (formData.sampleType.trim().length === 0) {
            isFormValid = false;
            error.sampleType = ["Sample Type is required!"];
        }

        if (formData.dateCollected.trim().length === 0) {
            isFormValid = false;
            error.dateCollected = ["Date Collected is required!"];
        }

        if (formData.dateReceived.trim().length === 0) {
            isFormValid = false;
            error.dateReceived = ["Date Received is required!"];
        }

        this.setState({
            ...this.state,
            errorData: error
        });
        return isFormValid;
    };

    handleSubmit = async (e) => {
        const {accessionEdit, selectedItem} = this.props;
        e.preventDefault();
        const formValid = this.validateAccessionInfo(this.state);
        if(!formValid) {
            return;
        }

        this.setState({
            saveAccessionLoading: true
        });

        let body = {
            sampleType: this.state.sampleType,
            dateCollected: this.state.dateCollected,
            dateReceived: this.state.dateReceived,
            orderId: selectedItem.orderId,
            productId: selectedItem.productId,
            kitRegistrationId: selectedItem.id,
        };
        let response;
        if(accessionEdit) {
            response = await this.props.updateItemAccession(selectedItem.accessionId, body);
        } else {
            response = await this.props.createItemAccession(body);
        }

        if (response && response.data) {
            if (response.data.isSuccess) {
                this.setState({
                    saveAccessionLoading: false
                });
                this.props.handleCloseAccessionModal();
            }
            else {
                // response with failed case
                this.setState({
                    errorMessage: response.data.errorMessage,
                    saveAccessionLoading: false
                })
            }
        }
        else {
            this.setState({
                errorMessage: "Something went wrong. Please try again later.",
                saveAccessionLoading: false
            })
        }
    };

    render() {
        return (
            <div class="admin-card">
                <div class="admin-card-box">
                    {
                        this.state.errorMessage &&
                        <div className="alert alert-danger">{this.state.errorMessage}</div>
                    }


                    <div className="row mt-3">
                        <div className="col-lg-12 col-md-12 d-flex">

                            <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>Sample Type
                                        <sup>*</sup>
                                    </label>
                                    <input value={this.state.sampleType}
                                           onChange={(e) => this.handleChange(e, "sampleType")} type="text"
                                           className="form-control"/>
                                    <span className="form-field-error">
                                        {this.state.errorData?.sampleType &&
                                        this.state.errorData.sampleType[0]}
                                    </span>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>Date Received
                                        <sup>*</sup>
                                    </label>
                                    <input value={this.state.dateReceived}
                                           onChange={(e) => this.handleChange(e, "dateReceived")} type="date"
                                           className="form-control"/>
                                    <span className="form-field-error">
                                        {this.state.errorData?.dateReceived &&
                                        this.state.errorData.dateReceived[0]}
                                    </span>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label>Date Collected
                                        <sup>*</sup>
                                    </label>
                                    <input value={this.state.dateCollected}
                                           onChange={(e) => this.handleChange(e, "dateCollected")} type="date"
                                           className="form-control"/>
                                    <span className="form-field-error">
                                        {this.state.errorData?.dateCollected &&
                                        this.state.errorData.dateCollected[0]}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-btn text-center">
                            <button type="button" onClick={(e)=> this.handleSubmit(e)} class="orange-outline-btn">submit
                                {(() => {
                                    if (this.state ?.saveAccessionLoading) {
                                        return <span class="spinner-border spinner-border-sm ml-1"></span>
                                    }
                                })()}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateItemAccession,
        createItemAccession,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(AddAccession)
