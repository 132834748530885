import React, { Component } from 'react';
import logo from '../../Assets/Images/logo.png';
import '../../Styles/login.scss';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { signup, vemail, login, logout } from "../../Redux/Actions/authAction";
import CryptoJS from "react-native-crypto-js";
import {getUserPermission} from "../../Redux/Actions/settingAction";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formValues: {
                email: "",
                password: "",
                rememberme: false
            },
            errorData: {},
            errorMessage: '',
            vcodeFlag: false,
            isLogedIn: false,
            showLoader: false
        };
        let rememberme = localStorage.getItem("rememberme");

        if (rememberme) {
            rememberme = CryptoJS.AES.decrypt(rememberme, 'remembermesecret$#');
            rememberme = rememberme.toString(CryptoJS.enc.Utf8);
            rememberme = JSON.parse(rememberme);
            this.state.formValues = rememberme;
        }
        //   console.log(rememberme,'rememberme');
    }


    validateUserInfo = () => {
        let isFormValid = true;
        let formValues = this.state.formValues;
        this.state.errorData = {
            "email": [],
            "password": []
        }

        if (formValues.email.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.email = ["Email field cannot be empty."]
        }

        if (formValues.password.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.password = ["Password field cannot be empty."]
        }

        let tempState = this.state;

        this.setState({
            ...tempState
        })

        return isFormValid;
    }


    handleLogin = async (event) => {
        event.preventDefault();

        await this.setState({ showLoader: true });

        if (this.validateUserInfo()) {
            if (this.state.formValues.rememberme) {
                const rememberme = CryptoJS.AES.encrypt(JSON.stringify(this.state.formValues), 'remembermesecret$#').toString();
                localStorage.setItem("rememberme", rememberme);
            } else {
                localStorage.removeItem("rememberme");
            }

            await this.props.login({ ...this.state.formValues });
            if (this.props.isLoggedIn) {
                console.log("AUTHDATA",this.props.authData )
                localStorage.setItem("adminUserId", this.props.authData ?.id);
                localStorage.setItem("adminUserData", JSON.stringify(this.props.authData));
                localStorage.setItem("token", this.props.authData ?.token);
                localStorage.removeItem("companyId");
                this.setState({ isLogedIn: true, showLoader: false });
                await this.props.getUserPermission(this.props.authData?.id);
            } else {
                this.setState({
                    errorData: this.props.errorData,
                    errorMessage: this.props.errorMessage,
                    showLoader: false
                });

            }
        }
        else {
            await this.setState({ showLoader: false });
        }
    };

    handleChange = (event, field) => {
        if (
            this.state.errorData &&
            this.state.errorData[field] &&
            this.state.errorData[field][0]
        ) {
            this.state.errorData[field][0] = null;
        }
        const test = this.state;
        if (field === "rememberme") {
            test.formValues[field] = event.target.checked
        } else {
            test.formValues[field] = event.target.value;
        }
        this.setState({ ...test });
    };

    render() {
        return (
            <div class="main-body">
                <div class="container">

                    <div class="signup">
                        <form>
                            <div class="signup-head">
                                <div class="subcciety-logo">
                                    <img src={logo} />
                                </div>
                                <h2>LOGIN TO YOUR ACCOUNT</h2>
                                <p>WELCOME BACK! LOGIN TO ACCESS ADMIN ACCOUNT.</p>
                            </div>
                            {(() => {
                                if (this.state.errorMessage !== '') {
                                    return <div className="alert alert-danger">{this.state.errorMessage}</div>
                                }
                            })()}
                            <div class="signup-mid">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="email" class="form-control" value={this.state.formValues.email} onChange={(event) => this.handleChange(event, "email")} placeholder="Email Address" />
                                            <span className="form-field-error">
                                                {this.state.errorData ?.email &&
                                                    this.state.errorData.email[0]}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="password" class="form-control" value={this.state.formValues.password} onChange={(event) => this.handleChange(event, "password")} placeholder="Password" />
                                            <span className="form-field-error">
                                                {this.state.errorData ?.password &&
                                                    this.state.errorData.password[0]}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="signup-trums-check">
                                    <div class="sign-check">
                                        <label class="custom-check">REMEMBER ME
                                            <input type="checkbox" checked={this.state.formValues.rememberme} onChange={(event) => this.handleChange(event, "rememberme")} />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="signup-bottom">
                                <div class="signup-submit">
                                    <button
                                        onClick={(event) => this.handleLogin(event)}
                                        type="button">continue
                                            {(() => {
                                            if (this.state.showLoader) {
                                                return <span class="spinner-border spinner-border-sm ml-1"></span>
                                            }
                                        })()}

                                    </button>
                                </div>
                                <div className="forgot-password">
                                    <Link to={"/forgot-password"}>FORGOT YOUR PASSWORD?</Link>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="signup-footer">
                        <p>© {new Date().getFullYear()} Accugenedx. All rights reserved</p>
                    </div>

                </div>
            </div>
        )
    }

    // render() {
    //     return (
    //         <div>
    //             <main className="mainContainer">
    //                 <div className="container">
    //                     <div className="signup">
    //                         <form>
    //                             <div className="signup-head">
    //                                 <h2>LOGIN TO YOUR ACCOUNT</h2>
    //                                 <p>WELCOME BACK! LOGIN TO ACCESS HOME GROWN.</p>
    //                             </div>
    //                             {(() => {
    //                                 if (this.state.errorMessage !== '') {
    //                                     return <div className="alert alert-danger">{this.state.errorMessage}</div>
    //                                 }
    //                             })()}
    //                             <div className="signup-mid">
    //                                 <h3>PERSONAL INFORMARTION</h3>
    //                                 <div className="row">
    //                                     <div className="col-lg-12">
    //                                         <div className="form-group">
    //                                             <input type="email" className="form-control" placeholder="Email Address"
    //                                                 value={this.state.formValues.email}
    //                                                 onChange={(event) =>
    //                                                     this.handleChange(event, "email")
    //                                                 }
    //                                             />
    //                                             <span className="form-field-error">
    //                                                 {this.state.errorData ?.email &&
    //                                                     this.state.errorData.email[0]}
    //                                             </span>
    //                                         </div>
    //                                     </div>
    //                                     <div className="col-lg-12">
    //                                         <div className="form-group">
    //                                             <input type="password" className="form-control" placeholder="Password"
    //                                                 value={this.state.formValues.password}
    //                                                 onChange={(event) =>
    //                                                     this.handleChange(event, "password")
    //                                                 }
    //                                             />
    //                                             <span className="form-field-error">
    //                                                 {this.state.errorData ?.password &&
    //                                                     this.state.errorData.password[0]}
    //                                             </span>
    //                                         </div>
    //                                     </div>
    //                                 </div>

    //                                 <div className="signup-trums-check">
    //                                     <div className="sign-check">
    //                                         <label className="custom-check">REMEMBER ME
    //                                           <input type="checkbox"

    //                                                 checked={this.state.formValues.rememberme}
    //                                                 onChange={(event) =>
    //                                                     this.handleChange(event, "rememberme")
    //                                                 } />
    //                                             <span className="checkmark"></span>
    //                                         </label>
    //                                     </div>
    //                                 </div>

    //                             </div>



    //                             <div className="signup-bottom">
    //                                 <div className="signup-submit">
    //                                     <button
    //                                         onClick={(event) => this.handleLogin(event)}
    //                                         type="button">continue
    //                                         {(() => {
    //                                             if (this.state.showLoader) {
    //                                                 return <span class="spinner-border spinner-border-sm ml-1"></span>
    //                                             }
    //                                         })()}

    //                                     </button>
    //                                 </div>
    //                                 <div className="forgot-password">
    //                                     <Link to={"/forgot-password"}>FORGOT YOUR PASSWORD?</Link>
    //                                 </div>
    //                                 <div className="already-account-link">
    //                                     <p>don't have an account? <Link to={'/signup'}>register now</Link></p>
    //                                 </div>
    //                             </div>
    //                         </form>

    //                     </div>
    //                 </div>

    //             </main>
    //         </div>
    //     )
    // }
}


const mapStateToProps = (state) => {
    return {
        ...state,
        isLoggedIn: state.authReducer.isLoggedIn,
        errorMessage: state.commonReducer.errorMessage,
        authData: state.authReducer.authData,
        errorData: state.commonReducer.errorData,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ signup, vemail, login, logout, getUserPermission }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
