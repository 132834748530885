import React, {useState} from 'react';
import {createCovidLocationAppointments} from "../../Redux/Actions/covidAction";
import {useDispatch} from "react-redux";
import moment from "moment";

const CovidLocationAppointments = (props) => {
    const { handleCloseGenerateAppointment, generateLocationId } = props;
    const dispatch = useDispatch();
    const [appointment, setAppointment] = useState({
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
    });

    const [errorData, setErrorData] = useState({
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [generateAppointmentLoading, setGenerateAppointmentLoading] = useState(false);

    const handleChange = (e) => {
        let { value, name } = e.target;
        setAppointment(prev => ({...prev, [name]: value}));
        setErrorData(prev => ({...prev, [name]: ''}));
    };

    const validateAppointmentInfo = () => {
        let isFormValid = true;
        const error = {
            startDate: '',
            endDate: '',
            startTime: '',
            endTime: '',
        };
        if (appointment.startDate.trim().length === 0) {
            isFormValid = false;
            error.startDate = "This field is required";
        }
        if (appointment.endDate.trim().length === 0) {
            isFormValid = false;
            error.endDate = "This field is required";
        }
        if (appointment.startTime.trim().length === 0) {
            isFormValid = false;
            error.startTime = "This field is required";
        }
        if (appointment.endTime.trim().length === 0) {
            isFormValid = false;
            error.endTime = "This field is required";
        }
        if (new Date(appointment.startDate) > new Date(appointment.endDate)) {
            isFormValid = false;
            error.endDate = "To date cannot be less than from date";
        }
        if (moment(appointment.startTime, "HH:mm a").isAfter(moment(appointment.endTime, "HH:mm a"))) {
            isFormValid = false;
            error.endTime = "End time cannot be less than start time";
        }
        if (appointment.startTime === appointment.endTime) {
            isFormValid = false;
            error.endTime = "End time cannot be same as start time";
        }
        setErrorData(error);
        return isFormValid;
    };

    const  handleSubmit = async (e) => {
        e.preventDefault();
        const formValid = validateAppointmentInfo();
        if(!formValid) {
            return;
        }
        setGenerateAppointmentLoading(true);
        const body = {
            startDate: appointment.startDate,
            endDate: appointment.endDate,
            startTime: appointment.startTime,
            endTime: appointment.endTime,
        };
        let response = await dispatch(createCovidLocationAppointments(generateLocationId, body));

        if (response.data) {
            if (response.data.isSuccess) {
                setGenerateAppointmentLoading(false);
                handleCloseGenerateAppointment();
            }
            else {
                // response with failed case
                setErrorMessage(response.data.errorMessage);
                setGenerateAppointmentLoading(false);
            }
        }
        else {
            setErrorMessage('Something went wrong. Please try again later.');
            setGenerateAppointmentLoading(false);
        }
    };

    return (
        <div className="admin-card">
            <div className="admin-card-box location-appointments">
                {
                    errorMessage &&
                    <div className="alert alert-danger">{errorMessage}</div>
                }
                <div className={"date-selection"}>
                    <div className={"date-div"}>
                        <label>From:</label>
                        <input type="date"
                            name="startDate"
                            onChange={(e) => handleChange(e)}
                        />
                         <span className="form-field-error">
                            {errorData.startDate}
                        </span>
                    </div>
                    <div className={"date-div"}>
                        <label>To:</label>
                        <input type="date"
                            name="endDate"
                            onChange={(e) => handleChange(e)}
                        />
                         <span className="form-field-error">
                            {errorData.endDate}
                        </span>
                    </div>
                </div>
                <div className={"time-selection"}>
                    <div className={"time-div"}>
                        <label>Start Time:</label>
                        <select className={"time-select"}
                            name="startTime"
                            onChange={(e) => handleChange(e)}
                        >
                            <option defaultValue="" disabled selected>--Select--</option>
                            <option value="08:00:00">8:00 AM</option>
                            <option value="09:00:00">9:00 AM</option>
                            <option value="10:00:00">10:00 AM</option>
                            <option value="11:00:00">11:00 AM</option>
                            <option value="13:00:00">1:00 PM</option>
                            <option value="14:00:00">2:00 PM</option>
                            <option value="15:00:00">3:00 PM</option>
                            <option value="16:00:00">4:00 PM</option>
                            <option value="17:00:00">5:00 PM</option>
                            <option value="18:00:00">6:00 PM</option>
                            <option value="19:00:00">7:00 PM</option>
                        </select>
                         <span className="form-field-error">
                            {errorData.startTime}
                        </span>
                    </div>
                    <div className={"time-div"}>
                        <label>End Time:</label>
                        <select className={"time-select"}
                            name="endTime"
                            onChange={(e) => handleChange(e)}
                        >
                            <option defaultValue="" disabled selected>--Select--</option>
                            <option value="08:00:00">8:00 AM</option>
                            <option value="09:00:00">9:00 AM</option>
                            <option value="10:00:00">10:00 AM</option>
                            <option value="11:00:00">11:00 AM</option>
                            <option value="13:00:00">1:00 PM</option>
                            <option value="14:00:00">2:00 PM</option>
                            <option value="15:00:00">3:00 PM</option>
                            <option value="16:00:00">4:00 PM</option>
                            <option value="17:00:00">5:00 PM</option>
                            <option value="18:00:00">6:00 PM</option>
                            <option value="19:00:00">7:00 PM</option>
                        </select>
                         <span className="form-field-error">
                            {errorData.endTime}
                        </span>
                    </div>
                </div>

                <div className="col-lg-12 col-md-12 mt-4">
                    <div className="form-btn text-center">
                        <button type="button" onClick={(e) => handleSubmit(e)}
                                className="orange-outline-btn" disabled={generateAppointmentLoading}>Generate Appointment
                            {
                                generateAppointmentLoading && <span className="spinner-border spinner-border-sm ml-1"></span>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CovidLocationAppointments;
