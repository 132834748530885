import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import Dropzone from '../../Components/dropzone/Dropzone';

import { getVendorProductDetail, updateVendorProductDetail } from '../../Redux/Actions/vendorAction';
import { getProductListCountForApproval } from '../../Redux/Actions/productAction';
import { createProduct, getProductDetail, updateProduct } from '../../Redux/Actions/productCategoryAction';

import Select from 'react-select';
import ImageUploading from "react-images-uploading";


class Edit_Product_Category extends Component {

    slug = "";
    vendorId = "";

    productFields = {
        productId: null,
        productModelNo: "",
        productTitle: "",
        productPrice: "",
        productImage: "",
        galleryImages: [],
        base64GalleryImages: [],
        removedGalleryImages: [],
        productTimeFrame: "",
        isApprove: '',
        updateForApprove: false,
        productDescription: "",
        previewProductImage: "",
        productCategoryList: [],
        // selectedProductCategoryId: '[{"catId":1},{"catId":2}]',
        selectedProductCategoryId: [],
        defaultSelectedOptions: [],
        isEdit: false,
        successMessage: '',
        errorMessage: '',
        errorData: {},
        showLoader: false
    };

    constructor(props) {
        super(props);
        this.state = { ...this.productFields };

        this.fileSelectHandler = this.fileSelectHandler.bind(this);
        this.fileUpload = React.createRef()

        console.log("this.props==============>>", this.props);

        this.slug = this.props.match.params.slug;
        this.vendorId = this.props.match.params.vendorId;

    }

    componentDidMount() {
        // this.checkForProductApprove();
        // this.fetchProductDetail();
    }


    checkForProductApprove = () => {

        this.setState({
            updateForApprove: false
        })
        let queryParams = this.props.location.search;
        if (queryParams.length > 0) {
            queryParams = queryParams.slice(1);
            if (queryParams === "approve") {
                this.setState({
                    updateForApprove: true
                })
            }
        }
    }

    fetchProductDetail = async () => {
        let productDetail = await this.props.getVendorProductDetail(this.vendorId, this.slug);

        if (productDetail?.data?.data?.length > 0) {
            // product found
            productDetail = productDetail.data.data[0];

            let selectedCategories = [];

            if (productDetail.selectedCategories && productDetail.selectedCategories.length > 0) {
                productDetail.selectedCategories.forEach(category => {
                    selectedCategories.push(category.productCategoryId);
                })
            }

            const res = this.createSelectOptions(productDetail.categoriesList, selectedCategories);
            const defaultGalleryImages = this.createDefaultGalleryImagePattern(productDetail.galleryImages ? JSON.parse(productDetail.galleryImages) : [])

            console.log("res is", res);

            this.setState({
                productId: productDetail.id,
                // productModelNo: "",
                productTitle: productDetail.name,
                productPrice: productDetail.price.toString(),
                productImage: productDetail.imageName,
                productTimeFrame: productDetail.timeFrame ? productDetail.timeFrame.split(",")[1] : null,
                isApprove: productDetail.status,
                productDescription: productDetail.description,
                previewProductImage: productDetail.imageName,
                galleryImages: defaultGalleryImages,
                selectedProductCategoryId: selectedCategories,
                productCategoryList: res.selectOptions ? res.selectOptions : [],
                defaultSelectedOptions: res.defaultSelectedOptions ? res.defaultSelectedOptions : [],
                isEdit: true
            })
        }
        else {
            // product not found
            // redirect user to product-list page
            this.props.history.push(`/vendors/product/${this.vendorId}`);
        }
    }



    createSelectOptions = (categoryList, selectedProductCategory = []) => {
        let defaultSelectedOptions = [];

        let selectOptions = categoryList.map(item => {
            if (selectedProductCategory.includes(item.id)) {
                defaultSelectedOptions.push({ value: `${item.id}`, label: `${item.name}` })
            }
            return (
                { value: `${item.id}`, label: `${item.name}` }
            )
        })

        return { defaultSelectedOptions, selectOptions }
    }


    createDefaultGalleryImagePattern = (images) => {
        let defaultImages = images.map(imgPath => {
            return {
                data_url: imgPath
            }
        })

        return defaultImages;
    }



    handleChange = (e, key) => {
        if (this.state.errorData && this.state.errorData[key] && this.state.errorData[key][0]) {
            this.state.errorData[key][0] = null;
        }

        let { value } = e.target

        this.setState(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    handleGalleryImages = async (images) => {
        // console.log("selected images are", images);

        if (images.length > 0) {
            images = images.map(img => {
                if (img.file) {
                    img["fileName"] = img.file.name;
                    return img;
                }
                return img;
            })
        }

        console.log("selected images are", images);

        this.setState({
            galleryImages: images
        })
    }

    handleProductCategoryChange = (event) => {
        console.log("event is", event);

        let selectedCategories = [];

        if (event && event.length > 0) {
            event.forEach(category => {
                selectedCategories.push(category.value)
            })
        }
        let defaultSelectedOptions = event;
        this.setState({
            selectedProductCategoryId: selectedCategories,
            defaultSelectedOptions
        }, () => { console.log("this.state.is", this.state.selectedProductCategoryId) })
    }

    convertImageToBase64(file) {

        //Create an instance of FileReader
        const fileReader = new FileReader();
        //Implement onLoad function
        console.log("================", this.state.base64GalleryImages);
        fileReader.onloadend = (event) =>
            this.setState(prevState => {
                return {
                    base64GalleryImages: [...prevState.base64GalleryImages, fileReader.result]
                }
            }, () => { console.log("this.state is", this.state.base64GalleryImages); })

        //Start reading the file
        fileReader.readAsDataURL(file);
    }

    async fileSelectHandler(e) {
        let imgStatus = false;
        if (e.target.files[0] !== undefined)
            if (e.target.files[0].type !== undefined) {
                let imgType = e.target.files[0].type.split('/');
                let MimeTypeArray = ['jpg', 'jpeg', 'png', 'gif'];
                // console.log(imgType, 'imgType');
                if (imgType[1] !== undefined) {
                    if (MimeTypeArray.includes(imgType[1])) {
                        if (e.target && e.target.files[0]) {
                            let img = e.target.files[0];
                            if (this.state.errorData && this.state.errorData["productImage"] && this.state.errorData["productImage"][0]) {
                                this.state.errorData["productImage"][0] = null;
                            }
                            this.setState(prevState => ({
                                ...prevState,
                                productImage: img
                            }))
                            this.readFile(img);
                        }
                    }
                    else {
                        imgStatus = true;
                    }
                }
            }
        this.setState({ invalidImage: imgStatus });
    }

    readFile(file) {

        //Create an instance of FileReader
        const fileReader = new FileReader();
        //Implement onLoad function
        fileReader.onloadend = (event) =>
            this.setState({
                previewProductImage: [fileReader.result]
            });
        //Start reading the file
        fileReader.readAsDataURL(file);
    }

    triggerClick = () => {
        this.fileUpload.current.click();
    }

    validateProductInfo = () => {
        let isFormValid = true;
        this.state.errorData = {
            // productModelNo: [],
            productTitle: [],
            productPrice: [],
            productImage: [],
            productTimeFrame: [],
            productDescription: []
        }

        // if (this.state.productModelNo.trim().length === 0) {
        //     isFormValid = false;
        //     this.state.errorData.productModelNo = ["Model Number field cannot be empty."]
        // }

        if (this.state.productTitle.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.productTitle = ["Product Title field cannot be empty."]
        }

        if (this.state.productPrice.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.productPrice = ["Price field cannot be empty."]
        }
        else if (this.state.productPrice.trim().length > 0 && isNaN(+this.state.productPrice.trim())) {
            isFormValid = false;
            this.state.errorData.productPrice = ["Enter a valid price."]
        }

        if (!this.state.productImage || this.state.productImage == '') {
            isFormValid = false;
            this.state.errorData.productImage = ["Product Image field cannot be empty."]
        }

        if (this.state.productTimeFrame.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.productTimeFrame = ["Product time-frame field cannot be empty."]
        }

        if (this.state.productDescription.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.productDescription = ["Product Description field cannot be empty."]
        }

        if (this.state.selectedProductCategoryId.length === 0) {
            isFormValid = false;
            this.state.errorData.selectedProductCategoryId = ["Select alteast one category."]
        }


        let tempState = this.state;
        this.setState({
            ...tempState
        })

        return isFormValid;
    }

    handleSubmit = async () => {
        if (this.vendorId) {
            await this.setState({
                successMessage: '',
                errorMessage: '',
                showLoader: true
            });

            if (this.validateProductInfo()) {
                let res;
                const { productModelNo, productTitle, productPrice, productImage, galleryImages, productTimeFrame, isApprove, productDescription, selectedProductCategoryId } = this.state;

                res = await this.props.updateVendorProductDetail(
                    { productModelNo, productTitle, productPrice, productImage, galleryImages: JSON.stringify(galleryImages), productTimeFrame, isApprove, productDescription, selectedProductCategoryId: JSON.stringify(selectedProductCategoryId) },
                    this.vendorId,
                    this.state.productId
                )

                if (res && res.data) {
                    if (res.data.isSuccess) {
                        // success case                        
                        this.setState({
                            successMessage: res.data.message,
                            showLoader: false
                        });
                        window.scrollTo(0, 0);

                        setTimeout(() => {
                            this.setState({
                                successMessage: ""
                            });
                        }, 4000);

                        await this.props.getProductListCountForApproval();

                    }
                    else {
                        // response with failed case
                        this.setState({
                            errorMessage: res.data.message,
                            showLoader: false
                        })
                    }
                }
                else {
                    // error to get respone
                    this.setState({
                        errorMessage: "Something went wrong. Please try again later.",
                        showLoader: false
                    })
                }

            }
            else {
                console.log("product invalid");
                this.setState({
                    showLoader: false
                });
            }
        }
        else {
            // user is not logged in
            this.props.history.push('/login');
        }
    }



    render() {

        const galleryImages = this.state.galleryImages.length > 0 ? this.state.galleryImages.map(imgPath => {
            return (
                {
                    source: imgPath
                }
            )
        }) : []

        return (
            <div class="admin-card">
                <div class="admin-card-head">
                    <h2>edit product</h2>
                </div>
                <div class="admin-card-box">
                    {(() => {
                        if (this.state.successMessage !== '' && this.state.successMessage !== undefined) {
                            return <div className="alert alert-success">{this.state.successMessage}</div>
                        } else if (this.state.errorMessage !== '' && this.state.errorMessage !== undefined) {
                            return <div className="alert alert-danger">{this.state.errorMessage}</div>
                        }
                    })()}
                    <div class="row">
                        {/* <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Model Number
                                    <sup>*</sup>
                                </label>
                                <input type="text" onChange={(e) => this.handleChange(e, "productModelNo")} value={this.state.productModelNo} class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData ?.productModelNo &&
                                        this.state.errorData.productModelNo[0]}
                                </span>
                            </div>
                        </div> */}
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>product title
                                    <sup>*</sup>
                                </label>
                                <input type="text" onChange={(e) => this.handleChange(e, "productTitle")} value={this.state.productTitle} class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData?.productTitle &&
                                        this.state.errorData.productTitle[0]}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3">
                            <div class="form-group">
                                <label>price
                                    <sup>*</sup>
                                </label>
                                <input type="text" onChange={(e) => this.handleChange(e, "productPrice")} value={this.state.productPrice} class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData?.productPrice &&
                                        this.state.errorData.productPrice[0]}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-9">
                            <div class="form-group">
                                <div className="user-profile-img">
                                    <img style={{ width: "200px", height: "200px" }} src={this.state?.previewProductImage || this.state.previewProductImage !== "" ? this.state.previewProductImage : null} />
                                </div>
                                {(() => {
                                    if (this.state?.invalidImage &&
                                        this.state.invalidImage) {
                                        return <span className="form-field-error">Invalid image format</span>
                                    }
                                })()}
                                <div className="upload-btn">
                                    <button onClick={this.triggerClick} type="button">upload image</button>
                                </div>
                                <input
                                    ref={this.fileUpload}
                                    className="droup-file-input"
                                    type="file"
                                    onChange={this.fileSelectHandler}
                                    style={{ display: "none" }}
                                >
                                </input>
                                <span className="form-field-error">
                                    {this.state.errorData?.productImage &&
                                        this.state.errorData.productImage[0]}
                                </span>
                            </div>
                        </div>

                        {/* <div class="col-lg-12 col-md-12">
                            <div class="form-group label-radio-inline">
                                <label class="mr-4">Select product time-frame</label>
                                <div class="custom-radio-inline">
                                    <label class="custon-radio">Weekly
                                        <input type="radio" onChange={(e) => this.handleChange(e, "productTimeFrame")} value="weekly" checked={this.state.productTimeFrame === "weekly"} name="radio" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="custon-radio">Monthly
                                        <input type="radio" onChange={(e) => this.handleChange(e, "productTimeFrame")} value="monthly" checked={this.state.productTimeFrame === "monthly"} name="radio" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="custon-radio">Annually
                                        <input type="radio" onChange={(e) => this.handleChange(e, "productTimeFrame")} value="annually" checked={this.state.productTimeFrame === "annually"} name="radio" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <span className="form-field-error">
                                        {this.state.errorData ?.productTimeFrame &&
                                            this.state.errorData.productTimeFrame[0]}
                                    </span>
                                </div>
                            </div>
                        </div> */}


                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Category
                                    <sup>*</sup>
                                </label>
                                <Select
                                    value={this.state.defaultSelectedOptions}
                                    isMulti
                                    name="value"
                                    options={this.state.productCategoryList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(e) => { this.handleProductCategoryChange(e) }}
                                />
                                <span className="form-field-error">
                                    {this.state.errorData?.selectedProductCategoryId &&
                                        this.state.errorData.selectedProductCategoryId[0]}
                                </span>
                                {/* <div class="plant-type-option">
                                    <div class="dropdown">
                                        <button class="btn dropdown-toggle" type="button" data-toggle="dropdown">{this.state.option2 ? this.state.option2 : "Option2"}
                                            <span className="add-icon"><i class="fas fa-plus"></i></span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            {categoryList}
                                        </ul>
                                        <span className="form-field-error">
                                            {this.state.errorData ?.option2 &&
                                                this.state.errorData.option2[0]}
                                        </span>
                                    </div>
                                </div> */}
                            </div>
                        </div>


                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>product Description</label>
                                <textarea onChange={(e) => this.handleChange(e, "productDescription")} value={this.state.productDescription} class="form-control form-textarea" placeholder="enter your text here..."></textarea>
                                <span className="form-field-error">
                                    {this.state.errorData?.productDescription &&
                                        this.state.errorData.productDescription[0]}
                                </span>
                            </div>
                        </div>
                        


                        {this.state.updateForApprove === true ? (
                            <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <label>Approve Product ?</label>
                                    <div className="custom-radio-input">
                                        <p className="container-sub mr-2">
                                            No
                                        <span>
                                                <input
                                                    type="radio"
                                                    value="pending"
                                                    onChange={(event) =>
                                                        this.handleChange(event, "isApprove")
                                                    }
                                                    checked={this.state.isApprove === 'pending'}
                                                    name="isApproveRadio"
                                                />
                                            </span>
                                            <span class="checkmark"></span>
                                        </p>

                                        <p className="container-sub mr-2">
                                            Yes
                                        <span>
                                                <input
                                                    type="radio"
                                                    value="approved"
                                                    onChange={(event) =>
                                                        this.handleChange(event, "isApprove")
                                                    }
                                                    checked={this.state.isApprove === 'approved'}
                                                    name="isApproveRadio"
                                                />
                                            </span>
                                            <span class="checkmark"></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null}


                        {/* multiple image upload */}

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Gallery Images</label>
                                <div className="App">
                                    <ImageUploading
                                        multiple
                                        value={this.state.galleryImages}
                                        onChange={this.handleGalleryImages}
                                        maxNumber={10}
                                        acceptType={['jpg', 'jpeg']}
                                        dataURLKey="data_url"
                                    >
                                        {({
                                            galleryImages,
                                            onImageUpload,
                                            onImageRemoveAll,
                                            onImageUpdate,
                                            onImageRemove,
                                            isDragging,
                                            dragProps
                                        }) => (
                                                // write your building UI
                                                <div className="upload__image-wrapper">
                                                    <div className="text-left mb-4">
                                                        <button
                                                            style={isDragging ? { color: "red" } : null}
                                                            className="orange-outline-btn"
                                                            onClick={onImageUpload}
                                                            {...dragProps}
                                                        >
                                                            Click or Drop here
                                                    </button>
                                                        &nbsp;
                                                    <button onClick={onImageRemoveAll} className="orange-outline-btn">Remove all images</button>
                                                    </div>
                                                    {this.state.galleryImages.map((image, index) => (
                                                        <div key={index} style={{ width: "25%", display: "flex" }} className="image-item">
                                                            <img src={image.data_url} alt="" width="100" />
                                                            <div className="image-item__btn-wrapper">
                                                                <button onClick={() => onImageUpdate(index)} className="orange-outline-btn mb-2">Update</button>
                                                                <button onClick={() => onImageRemove(index)} className="orange-outline-btn">Remove</button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                    </ImageUploading>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-btn text-center">
                                <button type="button" onClick={this.handleSubmit} class="orange-outline-btn">submit
                                {(() => {
                                        if (this.state?.showLoader) {
                                            return <span class="spinner-border spinner-border-sm ml-1"></span>
                                        }
                                    })()}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ createProduct, getProductDetail, updateProduct, getVendorProductDetail, updateVendorProductDetail, getProductListCountForApproval }, dispatch);
}

export default connect(null, mapDispatchToProps)(Edit_Product_Category)

