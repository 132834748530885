import React, { Component } from 'react'
import '../../Styles/order-list.scss'
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrdersList, searchOrder } from '../../Redux/Actions/orderAction';
import { debounce } from 'lodash';

const style = {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8
};

class Order_List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maxLimit: 10,
            offset: 0,
            prevOffset: 0,
            items: [],
            searchKeyword: "",
            hasMore: true,
            showLoader: false
        };
    }

    componentDidMount() {
        this.fetchMoreData(true);
    }


    fetchMoreData = async (firstLoad) => {
        this.setState({
            showLoader: true
        })
        if (this.state.offset !== this.state.prevOffset || firstLoad) {
            if (this.state.hasMore && this.state.maxLimit && (this.state.maxLimit === "all" || this.state.maxLimit > this.state.offset)) {
                let result;

                if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
                    result = await this.props.searchOrder(this.state.offset, this.state.searchKeyword.trim().replace(/[^a-zA-Z 0-9.]+/g,''));
                }
                else {
                    result = await this.props.getOrdersList(this.state.offset);
                }
                if (result ?.data ?.data ?.length > 0) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            prevOffset: prevState.offset,
                            offset: prevState.offset + 10,
                            items: prevState.items.concat(result.data.data),
                            showLoader: false
                        }
                    })
                }
                else {
                    this.setState({ hasMore: false, showLoader: false });
                }
            } else {
                this.setState({
                    showLoader: false
                })
            }
        } else {
            this.setState({
                showLoader: false
            })
        }
    };


    handleChange = async (event, field) => {
        event.preventDefault();
        // console.log("event value is", +event.target.value);

        if (field === "maxLimit") {
            await this.setState({
                [field]: event.target.value === "all" ? event.target.value : +event.target.value
            })
            this.fetchMoreData(false);
        }
        else {
            await this.setState({
                [field]: event.target.value,
                offset: 0,
                prevOffset: 0,
                hasMore: true
            })

            this.debounceSearchCall();
        }
    };

    debounceSearchCall = debounce(() => {
        this.setState({
            items: []
        })
        this.fetchMoreData(true);
    }, 1000);

    // async getOrderList() {
    //     let result = await this.props.getOrdersList(localStorage.getItem("adminUserId"), this.state.offset);
    //     if (result ?.data ?.data ?.length > 0) {
    //         this.setState(prevState => {
    //             return {
    //                 ...prevState,
    //                 offset: prevState.offset + 10,
    //                 items: prevState.items.concat(result.data.data)
    //             }
    //         })
    //     }
    //     else {
    //         this.setState({ hasMore: false });
    //     }
    // }


    render() {
        const orderItems = this.state.items.map(i => {
            const fullAddress = i.address ? JSON.parse(i.address) : null;
            const date = i.createTS ? new Date(i.createTS).toISOString().substring(0, 10) : null;
            return (
                <tr key={i.id}>
                    <td>
                        <div class="vendor-table-check">
                            <label class="custon-radio">
                                <input type="checkbox" name="starter" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </td>
                    <td key={i.id} align="center">#{i.orderNumber ? i.orderNumber : i.id}</td>
                    <td>{i.fullname ? i.fullname : "-"}</td>
                    <td>{date}</td>
                    <td>{fullAddress ? fullAddress.city : null}</td>
                    <td>${(i.total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td><p class="orange">{i.status}</p></td>
                    <td>
                        {i.trackingId ? <span class="orange">
                            <a href={i.trackingURLProvider} target="_blank">{i.trackingId}</a>
                        </span> : null}
                    </td>
                    <td>
                        <Link class="orange" to={`/orders/${i.id}`}>order detail</Link>
                    </td>
                </tr>
            )
        })
        return (
            <div class="admin-card">
                <div class="admin-card-head">
                    <h2>orders list</h2>
                </div>
                <div class="admin-card-box">
                    <div class="vendor-table">

                        <div class="vendor-table-head">
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="entries-drop">
                                        <label>show</label>
                                        <select class="entries-controls" onChange={(e) => this.handleChange(e, "maxLimit")} value={this.state.maxLimit}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="all">All</option>
                                        </select>
                                        <p>Entries</p>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8">
                                    <div class="vendor-table-head-right">
                                        <div class="vendor-table-search mr-2">
                                            <input class="form-control" onChange={(e) => this.handleChange(e, "searchKeyword")} value={this.state.searchKeyword} type="text" placeholder="search" />
                                        </div>
                                        {/* <div class="download-statement-btn">
                                            <button type="button" class="hyper-link-btn">download statement</button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="vendor-table-body">
                            <div class="table-responsive">
                                <InfiniteScroll
                                    dataLength={this.state.items.length}
                                    next={() => this.fetchMoreData(false)}
                                    hasMore={this.state.hasMore}
                                >
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td>
                                                    <div class="vendor-table-check">
                                                        <label class="custon-radio">
                                                            <input type="checkbox" name="starter" />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td align="center">Order No.</td>
                                                <td>customer</td>
                                                <td>sold on</td>
                                                <td>ship to</td>
                                                <td>price</td>
                                                <td>status</td>
                                                <td>Tracking Number</td>
                                                <td>Action</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderItems}

                                            {/* {true ? (
                                                <tr>
                                                    <td>
                                                        <div class="d-flex justify-content-center">
                                                            <div class="spinner-border" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : (null)} */}

                                            {/* <tr>
                                            <td>
                                                <div class="vendor-table-check">
                                                    <label class="custon-radio">
                                                        <input type="checkbox" name="starter" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td align="center">5420</td>
                                            <td>Loren ipsum</td>
                                            <td>Nov 04, 2019</td>
                                            <td>new york</td>
                                            <td>$499.00</td>
                                            <td>
                                                <p class="orange">Due</p>
                                            </td>
                                            <td align="center">
                                                <a class="orange" href="javascript:;">View Details</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="vendor-table-check">
                                                    <label class="custon-radio">
                                                        <input type="checkbox" name="starter" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td align="center">5420</td>
                                            <td>Loren ipsum</td>
                                            <td>Nov 04, 2019</td>
                                            <td>new york</td>
                                            <td>$499.00</td>
                                            <td>
                                                <p class="black">paid</p>
                                            </td>
                                            <td align="center">
                                                <a class="orange" href="javascript:;">View Details</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="vendor-table-check">
                                                    <label class="custon-radio">
                                                        <input type="checkbox" name="starter" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td align="center">5420</td>
                                            <td>Loren ipsum</td>
                                            <td>Nov 04, 2019</td>
                                            <td>new york</td>
                                            <td>$499.00</td>
                                            <td>
                                                <p class="black">paid</p>
                                            </td>
                                            <td align="center">
                                                <a class="orange" href="javascript:;">View Details</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="vendor-table-check">
                                                    <label class="custon-radio">
                                                        <input type="checkbox" name="starter" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td align="center">5420</td>
                                            <td>Loren ipsum</td>
                                            <td>Nov 04, 2019</td>
                                            <td>new york</td>
                                            <td>$499.00</td>
                                            <td>
                                                <p class="black">paid</p>
                                            </td>
                                            <td align="center">
                                                <a class="orange" href="javascript:;">View Details</a>
                                            </td>
                                        </tr> */}
                                        </tbody>

                                    </table>
                                </InfiniteScroll>
                                {this.state.showLoader ? (
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (null)}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            // <main class="mainContainer">

            //     <section class="home-product-section mt-4">
            //         <div class="container">
            //             <div class="product-list-head product-list-border-bottom">
            //                 <ul>
            //                     <li><a href="javascript:;">Order List</a></li>
            //                 </ul>
            //             </div>
            //         </div>
            //     </section>


            //     <section class="profile">
            //         <div class="container">
            //             <div class="row">

            //                 <div class="col-lg-9">
            //                 <Header />
            //                     <section class="track-section">
            //                         <div class="container">
            //                             <div class="track-section-head">
            //                                 <h2>Order List</h2>
            //                                 <p>Check your order detail.</p>
            //                             </div>

            //                             {this.state.items.length > 0 ? (
            //                                 <div class="track-box">

            //                                     <div class="vendor-table">

            //                                         {/* <div class="vendor-table-head">
            //                                         <div class="row">
            //                                             <div class="col-lg-4 col-md-4">
            //                                                 <div class="entries-drop">
            //                                                     <label>show</label>
            //                                                     <select class="entries-controls">
            //                                                         <option value="10">10</option>
            //                                                         <option value="20">20</option>
            //                                                         <option value="50">50</option>
            //                                                         <option value="100">100</option>
            //                                                     </select>
            //                                                     <p>Entries</p>
            //                                                 </div>
            //                                             </div>
            //                                             <div class="col-lg-8 col-md-8">
            //                                                 <div class="vendor-table-head-right">
            //                                                     <div class="vendor-table-search mr-2">
            //                                                         <input class="form-control" type="text" placeholder="search" />
            //                                                     </div>
            //                                                 </div>
            //                                             </div>
            //                                         </div>
            //                                     </div> */}

            //                                         <div class="vendor-table-body">
            //                                             <div class="table-responsive">
            //                                                 <InfiniteScroll
            //                                                     dataLength={this.state.items.length}
            //                                                     next={() => this.fetchMoreData(false)}
            //                                                     hasMore={this.state.hasMore}
            //                                                 >
            //                                                     <table class="table">
            //                                                         <thead>
            //                                                             <tr>
            //                                                                 <td align="center">Order No.</td>
            //                                                                 <td>customer</td>
            //                                                                 <td>sold on</td>
            //                                                                 <td>ship to</td>
            //                                                                 <td>price</td>
            //                                                                 <td>status</td>
            //                                                                 <td align="center">Tracking Number</td>
            //                                                             </tr>
            //                                                         </thead>
            //                                                         <tbody>
            //                                                             {orderItems}
            //                                                             {/* {this.state.items.map((i) => (
            //                                                             <tr key={i.id}>
            //                                                                 <td key={i.id} align="center">{i.id}</td>
            //                                                                 <td>Loren ipsum</td>
            //                                                                 <td>{i.createTS}</td>
            //                                                                 <td>{i.address}</td>
            //                                                                 <td>${i.total}</td>
            //                                                                 <td><p class="orange">{i.status}</p></td>
            //                                                                 <td align="center">
            //                                                                     <a class="orange" href="javascript:;">order detail</a>
            //                                                                 </td>
            //                                                             </tr>
            //                                                         ))} */}

            //                                                             {/* <tr>
            //                                                         <td align="center">5420</td>
            //                                                         <td>Loren ipsum</td>
            //                                                         <td>Nov 04, 2019</td>
            //                                                         <td>new york</td>
            //                                                         <td>$499.00</td>
            //                                                         <td><p class="orange">Due</p></td>
            //                                                         <td align="center">
            //                                                             <a class="orange" href="javascript:;">order detail</a>
            //                                                         </td>
            //                                                     </tr>
            //                                                     <tr>
            //                                                         <td align="center">5420</td>
            //                                                         <td>Loren ipsum</td>
            //                                                         <td>Nov 04, 2019</td>
            //                                                         <td>new york</td>
            //                                                         <td>$499.00</td>
            //                                                         <td><p class="black">paid</p></td>
            //                                                         <td align="center">
            //                                                             <a class="orange" href="javascript:;">order detail</a>
            //                                                         </td>
            //                                                     </tr>
            //                                                     <tr>
            //                                                         <td align="center">5420</td>
            //                                                         <td>Loren ipsum</td>
            //                                                         <td>Nov 04, 2019</td>
            //                                                         <td>new york</td>
            //                                                         <td>$499.00</td>
            //                                                         <td><p class="black">paid</p></td>
            //                                                         <td align="center">
            //                                                             <a class="orange" href="javascript:;">order detail</a>
            //                                                         </td>
            //                                                     </tr>
            //                                                     <tr>
            //                                                         <td align="center">5420</td>
            //                                                         <td>Loren ipsum</td>
            //                                                         <td>Nov 04, 2019</td>
            //                                                         <td>new york</td>
            //                                                         <td>$499.00</td>
            //                                                         <td><p class="black">paid</p></td>
            //                                                         <td align="center">
            //                                                             <a class="orange" href="javascript:;">order detail</a>
            //                                                         </td>
            //                                                     </tr> */}
            //                                                         </tbody>

            //                                                     </table>
            //                                                 </InfiniteScroll>
            //                                             </div>
            //                                         </div>

            //                                     </div>



            //                                 </div>
            //                             ) : (<p className="no-order">You have no order.</p>)}

            //                         </div>
            //                     </section>
            //                 </div>

            //             </div>
            //         </div>
            //     </section>




            // </main>
        )
    }
}

// const mapStateToProps = (state) => {
//     console.log("inside order_list state is", state);
//     return {
//         ...state
//     };
// };

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getOrdersList, searchOrder }, dispatch);
};

export default connect(null, mapDispatchToProps)(Order_List)
