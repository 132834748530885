import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getSubscriptionList = (userId, offset, limit = 10) => async (dispatch) => {
    // console.log("getOrdersList offset is", offset);
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/subscriptions/admin/${userId}?offset=${offset}&limit=${limit}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("orders list response from backend", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
};

export const getAllSubscriptionList = (offset, limit = 10) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/subscriptions/admin/list?offset=${offset}&limit=${limit}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
};

export const searchSubscriptionList = (offset, searchKeyword) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/subscriptions/admin/search?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("orders list response from backend", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
};

export const searchSubscriptionListByUser = (user, offset, searchKeyword) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/subscriptions/admin/search?userId=${user}&offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("orders list response from backend", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
};

export const getSingleSubscription = (subscriptionId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/subscriptions/admin/subscription-detail/${subscriptionId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
};

export const deleteSubscription = (subscriptionId)=>async(dispatch)=>{
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/stripe/cancelSubscription`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: {subscriptionId}
        });

        return res;

    } catch (error) {
        console.log("catch");
        dispatch(handleError(error));
    }
}

