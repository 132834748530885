import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;


export const getVendorsList = (offset) => async (dispatch) => {
  // console.log("getOrdersList offset is", offset);
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/vendors/list?offset=${offset}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });
    // console.log("orders list response from backend", res);
    return res;
  }
  catch (error) {
    console.log('wrong', error);
    dispatch(handleError(error));
  }
}

export const getVendorById = (vendorId) => async (dispatch) => {
  // console.log("getVendorById id is", vendorId);
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/vendors/by/id/${vendorId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });
    console.log("vendor detail response from backend", res);
    return res;
  }
  catch (error) {
    console.log('wrong', error);
    dispatch(handleError(error));
  }
}


export const updateVendorProfile = (data) => async (dispatch) => {
  // console.log(`update vendor profile data in action is`, data)
  try {
    dispatch({ type: "LOADING_STARTED" });
    let formData = new FormData()
    for (var key in data) {
      formData.append(key, data[key]);
    }
    // formData.append("selectedImg", data.selectedImg);
    // formData.append("lastname", data.lastname);

    const res = await axios({
      method: 'post',
      url: `${URL}/api/v1/admin/vendors/update/by/id/${data.id}`,
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });
    // dispatch({
    //   type: "SETUSERINFO",
    //   payload: res.data.data,
    // });

    console.log("vendor data after update is", res)

    return res;
  } catch (error) {
    return error
  }
}


export const searchVendor = (offset, searchKeyword) => async (dispatch) => {
  // console.log("getOrdersList offset is", offset);
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/vendors/list/by/search?offset=${offset}&search=${searchKeyword}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });
    // console.log("orders list response from backend", res);
    return res;
  }
  catch (error) {
    console.log('wrong', error);
    dispatch(handleError(error));
  }
}

export const getVendorProductList = (vendorId, start) => async (dispatch) => {
  // console.log("getVendorProductList parameters", vendorId, " start at ", start);
  try {

    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/vendors/product/list/${vendorId}?offset=${start}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });

    console.log("getVendorProductList data from backend", res);

    return res;

  } catch (error) {
    console.log("catch");
    dispatch(handleError(error));
  }
}

export const getVendorProductDetail = (vendorId, slug) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/vendors/product/detail/${vendorId}/${slug}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });

    console.log("data from backend", res);

    return res;

  } catch (error) {
    console.log("catch");
    dispatch(handleError(error));
  }
}

export const updateVendorProductDetail = (productInfo, vendorId, productId) => async (dispatch) => {
  try {
    let formData = new FormData();
    for (var key in productInfo) {
      formData.append(key, productInfo[key]);
    }

    const res = await axios({
      method: 'post',
      url: `${URL}/api/v1/admin/vendors/product/update/${vendorId}?id=${productId}`,
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });
    console.log("updateVendorProductDetail response is", res);
    return res;
  }
  catch (error) {
    dispatch(handleError(error));
  }
}


export const searchVendorProduct = (offset, vendorId, searchKeyword) => async (dispatch) => {
  // console.log("searchVendorProduct offset is", offset);
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/vendors/product/list/${vendorId}/by/search?offset=${offset}&search=${searchKeyword}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });
    // console.log("orders list response from backend", res);
    return res;
  }
  catch (error) {
    console.log('wrong', error);
    dispatch(handleError(error));
  }
}