import axios from 'axios';
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getProductListForApproval = (offset) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/products/list?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("getProductListForApproval response from backend", res);
        return res;
    }
    catch (error) {
        console.log('error to getProductListForApproval', error);
        dispatch(handleError(error));
    }
}


export const getProductListCountForApproval = () => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/products/count`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("getProductListCountForApproval response from backend", res);

        dispatch({
            type: "APPROVAL_PRODUCT_COUNT",
            payload: { data: res.data },
        })


        // return res;
    }
    catch (error) {
        console.log('error to getProductListCountForApproval', error);
        dispatch(handleError(error));
    }
}


export const searchProductListForApproval = (offset, searchKeyword) => async (dispatch) => {
    // console.log("searchProductListForApproval offset is", offset);
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/products/list/by/search?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("searchProductListForApproval response from backend", res);
        return res;
    }
    catch (error) {
        console.log('error to searchProductListForApproval', error);
        dispatch(handleError(error));
    }
}

// function getCategoryID(categoryName) {
//     let categoryId;
//     switch (categoryName) {
//         case "new-arrival":
//             categoryId = 1;
//             break;
//         case "best-sellers":
//             categoryId = 2;
//             break;
//         case "featured":
//             categoryId = 3;
//             break;
//         case "weekly":
//             categoryId = 4;
//             break;
//         case "monthly":
//             categoryId = 5;
//             break;
//         case "annually":
//             categoryId = 6;
//             break;
//         default:
//             categoryId = 1;
//     }
//     return categoryId;
// }

// export const changeCategory = (categoryName) => async (dispatch) => {
//     let categoryId = getCategoryID(categoryName);

//     dispatch({
//         type: "CATEGORY_CHANGE",
//         payload: { categoryId, categoryName }
//     })
// }

// export const getProductList = (categoryName,start,limit) => async (dispatch) => {
//     // console.log("getProductList parameters", categoryName);
//     try {
//         let categoryID = getCategoryID(categoryName)
//         dispatch({ type: "LOADING_STARTED" });
//         const res = await axios({
//             method: "get",
//             url: `${URL}/api/v1/product/category/${categoryID}?start=${start}&limit=${limit}`
//         });
//         // console.log("data from backend", res);
//         dispatch({ type: "LOADING_COMPLETED" });
//         dispatch({
//             type: "PRODUCT_LIST",
//             payload: { data: res.data },
//         });
//     } catch (error) {
//         console.log("catch");
//         dispatch(handleError(error));
//     }
// }

// export const getProductDetail = (itemId) => async (dispatch) => {
//     // console.log("getProductDetail parameters", itemId);
//     try {
//         dispatch({ type: "LOADING_STARTED" });
//         const res = await axios({
//             method: "get",
//             url: `${URL}/api/v1/product/${itemId}`
//         });
//         console.log("data from backend", res);
//         dispatch({ type: "LOADING_COMPLETED" });
//         dispatch({
//             type: "PRODUCT_DETAIL",
//             payload: { data: res.data },
//         });
//     } catch (error) {
//         console.log("catch");
//         dispatch(handleError(error));
//     }
// }