import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getAllFAQs = (offset) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/faq/list?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
};

export const searchFAQs = (offset, searchKeyword) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/faq/search?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const createFAQ = (body) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/faq/add`,
            data: body,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const updateFAQ = (userId, body) => async (dispatch) => {
    try {
        const res = await axios({
            method: "put",
            url: `${URL}/api/v1/admin/faq/update/${userId}`,
            data: body,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
};

export const deleteFAQ = (faqId)=>async(dispatch)=>{
    try {
        const res = await axios({
            method: "delete",
            url: `${URL}/api/v1/admin/faq/${faqId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res;

    } catch (error) {
        console.log("catch");
        dispatch(handleError(error));
    }
};

export const getFAQCategories = () => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/faq-category`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res.data || null;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
};
