import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { forgotPassword } from '../../Redux/Actions/authAction';

class ForgotPassword extends Component {

    constructor(props) {
        super(props)

        this.state = {
            email: '',
            successMessage: "",
            errorMessage: "",
            errorData: {},
            success: null,
            showLoader: false,
        };

    }

    handleEmailChange = (event) => {
        this.setState({
            email: event.target.value,
            errorData: {}
        })
    }

    validateUserInfo = () => {
        let isFormValid = true;
        this.state.errorData = {
            "email": []
        }

        if (this.state.email.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.email = ["Email is required."]
        }

        let tempState = this.state;

        this.setState({
            ...tempState
        })

        return isFormValid;
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        await this.setState({
            successMessage: '',
            errorMessage: '',
            errorData: {},
            showLoader: true
        });
        

        if (this.validateUserInfo()) {
            const res = await this.props.forgotPassword(this.state.email);
            if (res.data.success) {
                this.setState({
                    errorMessage: "",
                    successMessage: res.data.message,
                    success: res.data.success,
                    showLoader: false
                });
            }
            else {
                this.setState({
                    errorMessage: res.data.message,
                    successMessage: "",
                    success: res.data.success,
                    showLoader: false
                })
            }
        }
        else {
            this.setState({
                showLoader: false
            });
        }
    }

    render() {
        return (
            <div>
                <main className="mainContainer">
                    <div className="container">
                        <div className="signup">
                            <form>
                                <div className="signup-head">
                                    <h2>FORGOT YOUR PASSWORD</h2>
                                    <p>ENTER YOUR EMAIL ADDRESS AND WE WILL SEND YOU INSTRUCTIONS TO RESET YOUR PASSWORD.</p>
                                </div>
                                {(() => {
                                    if (this.state.successMessage !== '' && this.state.success) {
                                        return <div className="alert alert-success">{this.state.successMessage}</div>
                                    } else if (this.state.errorMessage !== '') {
                                        return <div className="alert alert-danger">{this.state.errorMessage}</div>
                                    }
                                })()}
                                <div className="signup-mid">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input type="email" className="form-control"
                                                    placeholder='EMAIL'
                                                    value={this.state.email}
                                                    onChange={this.handleEmailChange}
                                                />
                                                <span className="form-field-error">
                                                    {this.state.errorData ?.email &&
                                                        this.state.errorData.email[0]}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="signup-bottom">
                                    <div className="signup-submit">
                                        <button onClick={(event) => this.handleSubmit(event)} type="submit">continue
                                        {(() => {
                                                if (this.state.showLoader) {
                                                    return <span class="spinner-border spinner-border-sm ml-1"></span>
                                                }
                                            })()}
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ forgotPassword }, dispatch);
}

export default connect(null, mapDispatchToProps)(ForgotPassword)