export const initialState = {
  teamData: {},
  teamDetails: [],
  isTeamAdded: "",
  isDeleted: "",
};

export const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GETTEAMBYCOMPANY":
      return {
        ...state,
        teamData: action.payload.data.data,
      };
    case "GETTEAMDETAILS":
      return {
        ...state,
        teamDetails: action.payload.data.data,
      };
    case "ADDTEAMS":
      return {
        ...state,
        isTeamAdded: action.payload.data.message,
      };
    case "DELETETEAMS":
      return {
        ...state,
        isTeamDeleted: action.payload.data.message,
      };
    case "UPDATETEAM":
      return {
        ...state,
        isTeamUpdated: action.payload.data.message,
      };
    default:
      return state;
  }
};
