import React, { Component } from 'react';
import '../../Styles/user-detail.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {uploadPdf} from '../../Utils/fileUpload';
// import Dropzone from '../../Components/dropzone/Dropzone';

import { createProduct } from '../../Redux/Actions/productCategoryAction';
import {  getReportDetails } from '../../Redux/Actions/reportAction';
import { uploadReport } from '../../Redux/Actions/reportAction';

import { getProductName } from '../../Redux/Actions/productCategoryAction';

import Select from 'react-select';

import ImageUploading from "react-images-uploading";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment'
import {formatPhoneNumber} from "react-phone-number-input";

class Upload_Report extends Component {

    id = "";
    userId = localStorage.getItem("adminUserId");
    productFields = {
        email:"",
        productId: null,
        productModelNo: "",
        patientName: "",
        testDate: "",
        productImage: "",
        galleryImages: [],
        base64GalleryImages: [],
        removedGalleryImages: [],
        productTimeFrame: "",
        isActive: 1,
        productDescription: "",
        previewProductImage: null,
        productCategoryList: [],
        // selectedProductCategoryId: '[{"catId":1},{"catId":2}]',
        selectedProductCategoryId: [],
        defaultSelectedOptions: [],
        isEdit: false,
        successMessage: '',
        errorMessage: '',
        errorData: {},
        mobileNumber:0,
        showLoader: false,
        isUploadImage:false,
        reportData:[]
    };

    constructor(props) {
        super(props);
        this.state = { ...this.productFields };

        this.fileSelectHandler = this.fileSelectHandler.bind(this);
        this.fileUpload = React.createRef()


        console.log("this.props==============>>", this.props);
        this.id = this.props.userId;
        this.fetchProductDetail();

        // if (this.props.match.path === "/edit-product/:id") {
        //     this.id = this.props.match.params.id;
        //     this.fetchProductDetail();
        // }
        // else {
        //     this.fetchProductCategoryList()
        // }
    }

    fetchProductDetail = async () => {
        // this.fetchProductCategoryList()
        let reportData = await this.props.getReportDetails( this.id );
console.log("SS",reportData)
        if (reportData ?.data ?.data ?.length > 0) {
            reportData = reportData.data.data;
            this.setState({
                reportData: reportData
            })
            console.log("res is", this.state);
        }
        else {
            // product not found
            // redirect user to product-list page
            // this.props.history.push("/product-list");
        }
    }

    fetchProductCategoryList = async () => {
        const res = await this.props.getProductName();
        if (res ?.data ?.data ?.length > 0) {

            const selectOptionRes = this.createSelectOptions(res.data.data, [])

            this.setState({
                productCategoryList: selectOptionRes.selectOptions ? selectOptionRes.selectOptions : []
            });
        }
    }

    createDefaultGalleryImagePattern = (images) => {
        let defaultImages = images.map(imgPath => {
            return {
                data_url: imgPath
            }
        })

        return defaultImages;
    }

    createSelectOptions = (categoryList, selectedProductCategory ) => {
        let defaultSelectedOptions = [];

        let selectOptions = categoryList && categoryList.map(item => {
            if (selectedProductCategory.includes(item.id)) {
                let id = item.id
                defaultSelectedOptions.push({ value: id, label: `${item.name}` })
            }
            return (
                { value: item.id, label: `${item.name}` }
            )
        })

        return { defaultSelectedOptions, selectOptions }
    }

    handleChange = (e, key) => {
        if (this.state.errorData && this.state.errorData[key] && this.state.errorData[key][0]) {
            this.state.errorData[key][0] = null;
        }

        let { value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    confirmProductInactiveAlert(event, key) {
        event.persist()
        confirmAlert({
            title: 'Confirm change?',
            message: "Inactive this product will cancel all the customer's subscription associated with this product.",
            buttons: [
                {
                    label: 'Continue',
                    onClick: () => { this.handleChange(event, key) }
                },
                {
                    label: 'Cancel',
                    onClick: () => { }
                }
            ]
        });
    }


    handleGalleryImages = async (images) => {
        // console.log("selected images are", images);

        if (images.length > 0) {
            images = images.map(img => {
                if (img.file) {
                    img["fileName"] = img.file.name;
                    return img;
                }
                return img;
            })
        }

        console.log("selected images are", images);

        this.setState({
            galleryImages: images
        })
    }



    handleProductCategoryChange = (event) => {
        console.log("event is", event);

        let selectedCategories = [];

        if (event && event.length > 0) {
            event.forEach(category => {
                selectedCategories.push(category.value)
            })
        }

        let defaultSelectedOptions = event;
        this.setState({
            selectedProductCategoryId: selectedCategories,
            defaultSelectedOptions
        }, () => { console.log("this.state.is", this.state.selectedProductCategoryId) })
    }

    convertImageToBase64(file) {

        //Create an instance of FileReader
        const fileReader = new FileReader();
        //Implement onLoad function
        console.log("================", this.state.base64GalleryImages);
        fileReader.onloadend = (event) =>
            this.setState(prevState => {
                return {
                    base64GalleryImages: [...prevState.base64GalleryImages, fileReader.result]
                }
            }, () => { console.log("this.state is", this.state.base64GalleryImages); })

        //Start reading the file
        fileReader.readAsDataURL(file);
    }

    async fileSelectHandler(e) {
        let imgStatus = false;
        if (e.target.files[0] !== undefined)
            if (e.target.files[0].type !== undefined) {
                let imgType = e.target.files[0].type.split('/');
                let MimeTypeArray = ['jpg', 'jpeg', 'png', 'pdf'];
                // console.log(imgType, 'imgType');
                if (imgType[1] !== undefined) {
                    if (MimeTypeArray.includes(imgType[1])) {
                        if (e.target && e.target.files[0]) {
                            let img = e.target.files[0];
                            if (this.state.errorData && this.state.errorData["productImage"] && this.state.errorData["productImage"][0]) {
                                this.state.errorData["productImage"][0] = null;
                            }
                            this.setState(prevState => ({
                                ...prevState,
                                productImage: img
                            }))
                            this.readFile(img);
                        }
                    }
                    else {
                        imgStatus = true;
                    }
                }
            }
        this.setState({ invalidImage: imgStatus });
    }

    readFile(file) {
        console.log("file",file)
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
            console.log("file",reader)
          if (reader?.result) {
            // showloader(true);
            this.setState({isUploadImage: true})
            const imagePath = await uploadPdf(
              {
                dataUrl: reader.result,
                format:
                  file.type == "application/pdf"
                    ? "pdf"
                    :  null,
              },
              'accugenedx-files',
              `report`
            );
            console.log("imagePath",imagePath)
            this.setState({productImage: imagePath, previewProductImage: imagePath,isUploadImage: false, fileName:file.name})
            // let test = formData;
            // test["teamLogo"] = imagePath;
            // setFormValue(test);
            // setLogo(imagePath);
            // showloader(false);
          }
        };
    }

    triggerClick = () => {
        this.fileUpload.current.click();
    }

    validateProductInfo = () => {
        let isFormValid = true;
        this.state.errorData = {
            // productModelNo: [],
            patientName: [],
            testDate: [],
            productImage: [],
            email:[],
        }

        // if (this.state.productModelNo.trim().length === 0) {
        //     isFormValid = false;
        //     this.state.errorData.productModelNo = ["Model Number field cannot be empty."]
        // }

        if (this.state.patientName.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.patientName = ["Product Title field cannot be empty."]
        }
        if (this.state.email.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.patientName = ["Email Title field cannot be empty."]
        }

        if (this.state.testDate.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.testDate = ["Price field cannot be empty."]
        }
        // else if (this.state.testDate.trim().length > 0 && isNaN(+this.state.testDate.trim())) {
        //     isFormValid = false;
        //     this.state.errorData.testDate = ["Enter a valid price."]
        // }

        if (!this.state.productImage || this.state.productImage == '') {
            isFormValid = false;
            this.state.errorData.productImage = ["Report File field cannot be empty."]
        }

        // if (this.state.productTimeFrame.trim().length === 0) {
        //     isFormValid = false;
        //     this.state.errorData.productTimeFrame = ["Product time-frame field cannot be empty."]
        // }

        // if (this.state.productDescription.trim().length === 0) {
        //     isFormValid = false;
        //     this.state.errorData.productDescription = ["Product Description field cannot be empty."]
        // }

        if (this.state.selectedProductCategoryId.length === 0) {
            isFormValid = false;
            this.state.errorData.selectedProductCategoryId = ["Select alteast one category."]
        }


        let tempState = this.state;
        this.setState({
            ...tempState
        })
        console.log("*******************", this.state)
        return isFormValid;
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        console.log("*******************", this.validateProductInfo())
        if (this.userId) {

            await this.setState({
                successMessage: '',
                errorMessage: '',
                showLoader: true
            });

            if (this.validateProductInfo()) {
                console.log("VALIDATEEEEEEEEEEEEEEEEEEEE", this.state)
                let res;
                const { email,productModelNo, patientName, testDate, productImage, galleryImages, productTimeFrame, isActive, productDescription, selectedProductCategoryId,mobileNumber } = this.state;

                res = await this.props.uploadReport(
                    {email,  productModelNo, patientName, testDate, productImage, productTimeFrame, isActive, productDescription, selectedProductCategoryId: JSON.stringify(selectedProductCategoryId) ,mobileNumber }
                )
                if (res && res.data) {
                    if (res.data.isSuccess) {
                        // success case
                        this.setState({
                            successMessage: res.data.message,
                            showLoader: false
                        });
                        window.scrollTo(0, 0);

                        if (this.state.isEdit) {
                            setTimeout(() => {
                                this.setState({
                                    successMessage: ''
                                })
                            }, 4000);
                        }
                        else {

                            this.setState({
                                productId: null,
                                productModelNo: "",
                                patientName: "",
                                testDate: "",
                                productImage: "",
                                galleryImages: [],
                                base64GalleryImages: [],
                                removedGalleryImages: [],
                                productTimeFrame: "",
                                productDescription: "",
                                previewProductImage: null,
                                // productCategoryList: [],
                                // selectedProductCategoryId: '[{"catId":1},{"catId":2}]',
                                selectedProductCategoryId: [],
                                defaultSelectedOptions: [],
                            })

                            setTimeout(() => {
                                this.setState({
                                    successMessage: ''
                                })
                            }, 4000);
                        }
                    }
                    else {
                        // response with failed case
                        this.setState({
                            errorMessage: res.data.message,
                            showLoader: false
                        })
                    }
                }
                else {
                    // error to get respone
                    this.setState({
                        errorMessage: "Something went wrong. Please try again later.",
                        showLoader: false
                    })
                }
            }


        }
        else {
            // user is not logged in
            this.props.history.push('/login');
        }
    }



    render() {

        // console.log("this.state.previewProductImage is", this.state.previewProductImage);

        const galleryImages = this.state.galleryImages.length > 0 ? this.state.galleryImages.map(imgPath => {
            return (
                {
                    source: imgPath
                }
            )
        }) : []


        const fullName = this.state?.reportData.length > 0 ? this.state.reportData[0].patient_name : '-';
        const email = this.state?.reportData.length > 0 ? this.state.reportData[0].email : '-';
        const mobilePhone = this.state?.reportData.length > 0 ? this.state.reportData[0].mobile_number : '-';
        const address = this.state?.reportData.length > 0 ? this.state.reportData[0].address : '-';
        const city = this.state?.reportData.length > 0 ? this.state.reportData[0].city : '-';
        const state = this.state?.reportData.length > 0 ? this.state.reportData[0].state : '-';
        const zipCode = this.state?.reportData.length > 0 ? this.state.reportData[0].zipCode : '-';
        const country = this.state?.reportData.length > 0 ? this.state.reportData[0].country : '-';

        return (
            <div class="admin-card">
                <div class="admin-card-box">
                    {(() => {
                        if (this.state.successMessage !== '' && this.state.successMessage !== undefined) {
                            return <div className="alert alert-success">{this.state.successMessage}</div>
                        } else if (this.state.errorMessage !== '' && this.state.errorMessage !== undefined) {
                            return <div className="alert alert-danger">{this.state.errorMessage}</div>
                        }
                    })()}
                    <div className="row report-header">
                        <div className="col-lg-12 col-md-12 d-flex">
                            <div className="col-lg-6 col-md-6">
                                <div className="patient-heading">
                                    <h5>Patient Information</h5>
                                </div>
                                <div className="patient-info">
                                    <div className={"value"}>
                                        <div>{fullName}</div>
                                    </div>

                                    <div className={"value"}>
                                        <div>{email}</div>
                                        {mobilePhone && formatPhoneNumber(mobilePhone)}
                                    </div>

                                    {
                                        address &&
                                        <div className={"value"}>
                                            <div>{address}</div>
                                            <div>{`${city}, ${state}, ${zipCode}`}</div>
                                            <div>{country}</div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row report-container mt-3">
                        <div className="col-lg-12 col-md-12">
                            <div className="col-lg-12 col-md-12">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th>Order No.</th>
                                            <th>Test Name</th>
                                            <th>Date</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.reportData.length > 0 ? this.state.reportData.map((report) =>
                                                <tr key={report.id}>
                                                    <td>{report.orderId}</td>
                                                    <td>{report.test_name}</td>
                                                    <td>{moment(report.created_ts).format('l')}</td>
                                                    <td>
                                                        <div className={'d-flex'} style={{width: '100%'}}>
                                                            <button
                                                                style={{
                                                                    'min-width': '100%'
                                                                }}
                                                                type="button"
                                                                className="orange-outline-btn"
                                                                onClick={() => window.open(report.report_doc, "_blank")}>View
                                                            </button>
                                                            {/*<button*/}
                                                            {/*    style={{*/}
                                                            {/*        'min-width': '49%'*/}
                                                            {/*    }}*/}
                                                            {/*    type="button"*/}
                                                            {/*    className="danger-outline-btn ml-2"*/}
                                                            {/*    onClick={() => window.open(report.report_doc, "_blank")}>Delete*/}
                                                            {/*</button>*/}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : 'No reports available'
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ createProduct, getReportDetails, uploadReport, getProductName }, dispatch);
}

export default connect(null, mapDispatchToProps)(Upload_Report)
