import React, { Component } from "react";
import "../../Styles/order-list.scss";
import Header from "../../Components/Header/header";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getReportList,
  searchUserReport,
} from "../../Redux/Actions/reportAction";
import { debounce } from "lodash";
import { uploadPdf } from "../../Utils/fileUpload";
import { uploadReport } from "../../Redux/Actions/reportAction";
import { Modal, Button } from "react-bootstrap";
import UserReportDeatils from "../UploadReports/upload-report-details";
import UploadPatientReport from "../UploadReports/uploadPatientReport";
import moment from "moment";

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};

class User_List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 10,
      offset: 0,
      prevOffset: 0,
      users: [],
      searchKeyword: "",
      hasMore: true,
      showLoader: false,
      productImage: "",
      show: false,
      showAddReport: false,
      fileLoader: false,
      // errorData: {},
    };
    this.id = null;
    this.fileUpload = React.createRef();
  }

  componentDidMount() {
    this.fetchMoreData(true);
  }

  // const [show, setShow] = useState(false);

  handleClose = () => this.setState({ show: false });
  handleCloseAddReport = () => this.setState({ showAddReport: false });
  handleShow = () => this.setState({ show: true });

  fetchMoreData = async (firstLoad, force) => {

    this.setState({
      showLoader: true,
    });
    if (this.state.offset !== this.state.prevOffset || firstLoad) {

      if (
        this.state.hasMore &&
        this.state.maxLimit &&
        (this.state.maxLimit === "all" ||
          this.state.maxLimit > this.state.offset)
          || force
      ) {
        let result;
        if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
            console.log("+++++++++")
          result = await this.props.searchUserReport(
            this.state.offset,
            this.state.searchKeyword.trim()
          );
        } else {
            console.log("/////////")
          result = await this.props.getReportList(this.state.offset);
          console.log("RESULT", result);
        }

        // let result = await this.props.getReportList(this.state.offset);
        if (result?.data?.data?.length > 0) {
          if(force) {
            this.setState((prevState) => {
              return {
                ...prevState,
                users: result.data.data,
                showLoader: false,
              };
            });
          } else {
            this.setState((prevState) => {
              return {
                ...prevState,
                prevOffset: prevState.offset,
                offset: prevState.offset + 10,
                users: prevState.users.concat(result.data.data),
                showLoader: false,
              };
            });
          }
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }
      } else {
        console.log("------")
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  handleChange = async (event, field) => {
    event.preventDefault();
    // console.log("event value is", +event.target.value);
    // if (this.state.errorData && this.state.errorData[key] && this.state.errorData[key][0]) {
    //     this.state.errorData[key][0] = null;
    // }
    if (field === "maxLimit") {
      await this.setState({
        [field]:
          event.target.value === "all"
            ? event.target.value
            : +event.target.value,
      });
      this.fetchMoreData(false);
    } else {
      await this.setState({
        [field]: event.target.value,
        offset: 0,
        prevOffset: 0,
        hasMore: true,
      });

      this.debounceSearchCall();
    }
  };

  debounceSearchCall = debounce(() => {
    this.setState({
      users: [],
    });
    this.fetchMoreData(true);
  }, 1000);

  fileSelectHandler = async (e, i) => {
    console.log("INSIDE ONCHANE", i);
    let imgStatus = false;
    if (e.target.files[0] !== undefined)
      if (e.target.files[0].type !== undefined) {
        let imgType = e.target.files[0].type.split("/");
        let MimeTypeArray = ["jpg", "jpeg", "png", "pdf"];
        // console.log(imgType, 'imgType');
        if (imgType[1] !== undefined) {
          if (MimeTypeArray.includes(imgType[1])) {
            if (e.target && e.target.files[0]) {
              let img = e.target.files[0];
              console.log("IMG", img);
              // if (this.state.errorData && this.state.errorData["productImage"] && this.state.errorData["productImage"][0]) {
              //     this.state.errorData["productImage"][0] = null;
              // }
              // this.setState(prevState => ({
              //     ...prevState,
              //     productImage: img
              // }))
              this.readFile(img, i);
              this.setState({ fileLoader: true ,selectedId: i.id});
            }
          } else {
            imgStatus = true;
          }
        }
      }
    // this.setState({ invalidImage: imgStatus });
  };

  readFile = async (file, i) => {
    console.log("file", i);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      console.log("file", reader);
      if (reader?.result) {
        // showloader(true);
        this.setState({ isUploadImage: true });
        const imagePath = await uploadPdf(
          {
            dataUrl: reader.result,
            format: file.type == "application/pdf" ? "pdf" : null,
          },
          "accugenedx-files",
          `report`
        );
       const res = await this.props.uploadReport({
          userId: i.id,
          email: i.email,
          patientName: i.full_name,
          testDate: moment().format(),
          mobileNumber: i.phonenumber,
          productImage: imagePath,
        });
        // this.setState({hasMore: true, offset:0 })
        if(res && res.data && res.data.isSuccess){

            let result = await this.props.getReportList(0);
            console.log("RESULTS//",result)
            if (result?.data?.data?.length > 0) {
                this.setState({
                    users: result.data.data,
                    showLoader: false,
                });
              }
        }
        this.setState({ fileLoader: false ,selectedId: 'null'});

      }
    };
  };

  triggerClick = () => {
    this.fileUpload.current.click();
  };

  render() {
    const usersList = this.state.users.map((i, j) => {
      // const fullAddress = i.address ? JSON.parse(i.address) : null;
      const date = i.createts
        ? new Date(i.createts).toISOString().substring(0, 10)
        : null;
      return (
        <tr key={`${i.id}-${j}`}>
          <td>{j + 1}</td>
          <td>{i.full_name ? i.full_name : "-"}</td>
          <td>{i.email ? i.email : "-"}</td>
          <td>{i.phonenumber ? i.phonenumber : "-"}</td>
          <td>{i.totalReports ? i.totalReports : "-"}</td>

          {/* <td>{i.test_date}</td> */}
          {/*{this.state.fileLoader === true && i.id === this.state.selectedId ? (*/}
          {/* <td>Uploading...</td>*/}
          {/*) : (*/}
          {/*  <td key={j}>*/}
          {/*  <div className="upload-btn"></div>*/}
          {/*  <input*/}
          {/*    key={j}*/}
          {/*    ref={this.fileUpload}*/}
          {/*    className="droup-file-input"*/}
          {/*    type="file"*/}
          {/*    onChange={(e) => this.fileSelectHandler(e, i)}*/}
          {/*    // style={{ display: "none" }}*/}
          {/*  ></input>*/}
          {/*</td> )}*/}

          {/* {i?.totalReports ?  <td onClick={()=>{this.props.history.push(`/report-details/${i.id}`)}}>View</td> : <td>No Report</td>} */}
          {i?.totalReports ? (
            <td>
                <button
                    type="button"
                    className="orange-outline-btn"
                    onClick={() => {
                      this.setState({ show: true });
                      this.id = i.id;
                    }}>View Report
                </button>
                <button
                    type="button"
                    className="secondary-outline-btn ml-2"
                    onClick={() => {
                      this.setState({ showAddReport: true });
                      this.id = i.id;
                    }}>Upload Report
                </button>
            </td>
          ) : (
            <td>
              <button
                  type="button"
                  className="secondary-outline-btn"
                  onClick={() => {
                    this.setState({ showAddReport: true });
                    this.id = i.id;
                  }}>Upload Report
              </button>
            </td>
          )}

          {/* <td><a style={{ textDecoration: "none" }} className="table-btn" href={i.report_doc} target='_blank'>View</a></td> */}
        </tr>
      );
    });
    return (
      <div class="admin-card">
        <div class="admin-card-head">
          <h2>Product reports</h2>

        </div>
        <div class="admin-card-box">
          <div class="vendor-table">
            <div class="vendor-table-head">
              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <div class="entries-drop">
                    <label>show</label>
                    <select
                      class="entries-controls"
                      onChange={(e) => this.handleChange(e, "maxLimit")}
                      value={this.state.maxLimit}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="all">All</option>
                    </select>
                    <p>Entries</p>
                  </div>
                </div>
                <div class="col-lg-8 col-md-8">
                  <div class="vendor-table-head-right">
                    <div class="vendor-table-search mr-2">
                      <input
                        class="form-control"
                        onChange={(e) => this.handleChange(e, "searchKeyword")}
                        value={this.state.searchKeyword}
                        type="text"
                        placeholder="search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="vendor-table-body">
              <div class="table-responsive">
                <InfiniteScroll
                  dataLength={this.state.users.length}
                  next={() => this.fetchMoreData(false)}
                  hasMore={this.state.hasMore}
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <td>S.no</td>
                        <td>Patient name</td>
                        <td>Email</td>
                        <td>Mobile Number</td>
                        <td>Total reports</td>
                        <td className={'w-25'}>Action</td>
                      </tr>
                    </thead>
                    <tbody>{usersList}</tbody>
                  </table>
                </InfiniteScroll>
                {this.state.showLoader ? (
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <Modal show={this.state.show} onHide={this.handleClose} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>User Reports</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <UserReportDeatils userId={this.id}></UserReportDeatils>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showAddReport} onHide={this.handleCloseAddReport} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Upload Report</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <UploadPatientReport
                    {...this.props}
                    userId = {this.id}
                    handleCloseAddReport={this.handleCloseAddReport}
                    fetchMoreData={this.fetchMoreData}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleCloseAddReport}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getReportList, searchUserReport, uploadReport },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(User_List);
