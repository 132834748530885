import React, { Component } from 'react';
import '../../Styles/user-detail.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {uploadPdf} from '../../Utils/fileUpload';
import { formatPhoneNumber } from 'react-phone-number-input';


import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {getUserDetail} from "../../Redux/Actions/userAction"; // Import css
import {
    getOrderDetail,
    getOrdersListByUser
} from "../../Redux/Actions/orderAction";


class UploadPatientReport extends Component {

    id = "";
    userId = localStorage.getItem("adminUserId");

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            userOrders: [],
            showLoader: false,
            testName: '',
            testDate: '',
            orderId: '',
            productId: '',
            orderDetail: {
                items: []
            },
            reportFile: '',
            invalidReportFile: false,
            isUploadFile: false,
            reportFilePath: '',
            fileName: '',
            errorData: {
                testDate: [],
                testName: [],
                orderId: [],
                productId: [],
                reportFile: [],
            }
        };

        this.fileSelectHandler = this.fileSelectHandler.bind(this);
        this.fileUpload = React.createRef()
    }

    async fetchUserDetail() {
        this.setState({showLoader: true});
        const userDetail = await this.props.getUserDetail(this.props.userId);
        if (userDetail ?.data ?.data) {
            this.setState({user: userDetail.data.data});
        }
    }

    async fetchUserOrderDetail() {
        const userOrderDetail = await this.props.getOrdersListByUser(this.props.userId);
        if (userOrderDetail ?.data ?.data) {
            this.setState({userOrders: userOrderDetail.data.data});
        }
        this.setState({showLoader: false});
    }

    componentDidMount() {
        this.fetchUserDetail();
        this.fetchUserOrderDetail();
    }

    handleChange = (e, key) => {
        if (this.state.errorData && this.state.errorData[key] && this.state.errorData[key][0]) {
            this.state.errorData[key][0] = null;
        }

        let { value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            [key]: value
        }))
    };

    async fileSelectHandler(e) {
        let fileStatus = false;
        if (e.target.files[0] !== undefined)
            if (e.target.files[0].type !== undefined) {
                let imgType = e.target.files[0].type.split('/');
                let MimeTypeArray = ['pdf'];
                // console.log(imgType, 'imgType');
                if (imgType[1] !== undefined) {
                    if (MimeTypeArray.includes(imgType[1])) {
                        if (e.target && e.target.files[0]) {
                            let reportFile = e.target.files[0];
                            if (this.state.errorData["reportFile"][0]) {
                                this.state.errorData["reportFile"][0] = null;
                            }
                            this.setState(prevState => ({
                                ...prevState,
                                reportFile: reportFile
                            }))
                            this.readFile(reportFile);
                        }
                    }
                    else {
                        fileStatus = true;
                    }
                }
            }
        this.setState({ invalidReportFile: fileStatus });
    }

    readFile(file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {

            if (reader?.result) {
                // showloader(true);
                this.setState({isUploadFile: true})
                const filePath = await uploadPdf(
                    {
                        dataUrl: reader.result,
                        format:
                            file.type == "application/pdf"
                                ? "pdf"
                                :  null,
                    },
                    'accugenedx-files',
                    `report`
                );

                this.setState({reportFilePath: filePath ,isUploadFile: false, fileName:file.name})

            }
        };
    }

    triggerClick = () => {
        this.fileUpload.current.click();
    }

    validateReportUploadInfo = () => {
        let isFormValid = true;

        const errorObj = {
            testDate: [],
            testName: [],
            orderId: [],
            productId: [],
            reportFile: [],
        };

        if (this.state.testDate.trim().length === 0) {
            isFormValid = false;
            errorObj.testDate = ["Test date is required"];
        }

        if (this.state.testName.trim().length === 0) {
            isFormValid = false;
            errorObj.testName = ["Test Name is required"];
        }

        if (!this.state.orderId) {
            isFormValid = false;
            errorObj.orderId = ["Please selected Order No"];
        }

        if (!this.state.productId) {
            isFormValid = false;
            errorObj.productId = ["Please selected Product"];
        }

        if (!this.state.reportFilePath || !this.state.fileName) {
            isFormValid = false;
            errorObj.reportFile = ["Test report is required"];
        }

        this.setState({
            ...this.state,
            errorData: errorObj
        });
        return isFormValid;
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const formValid = this.validateReportUploadInfo();
        if(!formValid) {
            return;
        }
        if(!this.userId) {
            // user is not logged in
            this.props.history.push('/login');
            return ;
        }

        this.setState({
            successMessage: '',
            errorMessage: '',
            showLoader: true
        });

        let res;
        const orderId = this.state.userOrders.find(order => order.id === +this.state.orderId)?.orderNumber;
        const {
            user:{
               id: userId,
               email,
               full_name: patientName,
               phonenumber: mobileNumber,
            },
            testDate,
            testName,
            reportFilePath: productImage,
            productId,
        } = this.state;

        res = await this.props.uploadReport(
            {userId, email, testName, patientName, testDate, productImage, orderId, productId ,mobileNumber }
        )
        if (res && res.data) {
            if (res.data.isSuccess) {
                // success case
                this.setState({
                    successMessage: res.data.message,
                    showLoader: false
                });
                window.scrollTo(0, 0);
            }
            else {
                // response with failed case
                this.setState({
                    errorMessage: res.data.message,
                    showLoader: false
                })
            }
        }
        else {
            // error to get respone
            this.setState({
                errorMessage: "Something went wrong. Please try again later.",
                showLoader: false
            })
        }
    }

    fetchOrderDetail = async (orderId) => {
        const result = await this.props.getOrderDetail(orderId);
        if (result ?.data ?.data) {
            await this.setState({
                orderDetail: result.data.data,
                showLoader: false
            });
        }
    };

    handleSelectedOrder = (e) => {
        const {value} = e.target;
        if(value) {
            if (this.state.errorData.orderId[0]) {
                this.state.errorData.orderId[0] = null;
            }
            this.setState({
                orderId: value,
                showLoader: true
            });
            this.fetchOrderDetail(value);
        }
    };

    handleSelectedProduct = (e) => {
        const {value} = e.target;
        if(value) {
            if (this.state.errorData.productId[0]) {
                this.state.errorData.productId[0] = null;
            }
            this.setState({productId: value});
        }
    };

    removeReportFile = () => {
        this.setState({
            reportFile: '',
            invalidReportFile: false,
            isUploadFile: false,
            reportFilePath: '',
            fileName: ''
        });
        document.getElementById("fileUploadInp").value = "";
    };

    render() {

        const {
            user,
            userOrders,
            orderDetail,
            reportFilePath,
            fileName,
        } = this.state;

        return (
            <div class="admin-card">
                {this.state.showLoader ? (
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (null)}
                <div class="admin-card-box">
                    {
                        this.state.invalidReportFile &&
                        <div className="alert alert-danger">The file cannot be uploaded. Please try again.</div>
                    }
                    <div class="row report-header">
                        <div className="col-lg-12 col-md-12 d-flex">
                            <div class="col-lg-6 col-md-6">
                                <div class="patient-heading">
                                    <h5>Patient Information</h5>
                                </div>
                                <div class="patient-info">
                                    <div className={"value"}>
                                        <div>{user.full_name}</div>
                                    </div>

                                    <div className={"value"}>
                                        <div>{user.email}</div>
                                        {user.phonenumber && formatPhoneNumber(user.phonenumber)}
                                    </div>
                                    {
                                        user.address &&
                                        <div className={"value"}>
                                            <div>{user.address}</div>
                                            <div>{`${user.city}, ${user.state}, ${user.zipCode}`}</div>
                                            <div>{user.country}</div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <label>test date
                                        <sup>*</sup>
                                    </label>
                                    <input type="date" onChange={(e) => this.handleChange(e, "testDate")}
                                           value={this.state.testDate} className="form-control"/>
                                    <span className="form-field-error">
                                    {this.state.errorData?.testDate &&
                                    this.state.errorData.testDate[0]}
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row report-container mt-3">
                        <div className="col-lg-12 col-md-12">
                            <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <label>Test Name
                                        <sup>*</sup>
                                    </label>
                                    <input type="text" onChange={(e) => this.handleChange(e, "testName")}
                                           value={this.state.testName} className="form-control"/>
                                    <span className="form-field-error">
                                    {this.state.errorData?.testName &&
                                    this.state.errorData.testName[0]}
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row report-container">
                        <div class="col-lg-12 col-md-12 d-flex">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Order No  <sup>*</sup></label>
                                    <select class="form-control" onChange={this.handleSelectedOrder}>
                                        <option value="" disabled selected>--select--</option>
                                        {
                                            userOrders.map((order) => <option key={order.id} value={order.id} > {order.orderNumber} </option>)
                                        }
                                    </select>
                                    <span className="form-field-error">
                                         {this.state.errorData?.orderId &&
                                         this.state.errorData.orderId[0]}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Product <sup>*</sup></label>
                                    <select class="form-control" onChange={this.handleSelectedProduct}>
                                        <option value="" disabled selected>--select--</option>
                                        {
                                            orderDetail.items.map((product) => <option key={product.id} value={product.productId} > {product.name} </option>)
                                        }
                                    </select>
                                    <span className="form-field-error">
                                         {this.state.errorData?.productId &&
                                         this.state.errorData.productId[0]}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 report-container">
                        <div class="form-group">
                            {
                                reportFilePath && fileName ?
                                    (<div className={"selected-file"}>
                                        <div className={"file-name"}>
                                            <span>{fileName}</span>
                                            <i class="fa fa-trash" onClick={this.removeReportFile}></i>
                                        </div>
                                    </div>): (
                                        <div className="upload-btn">
                                            <button onClick={this.triggerClick} type="button"
                                                    className="secondary-outline-btn">select report
                                                {(() => {
                                                    if (this.state?.isUploadFile) {
                                                        return <span
                                                            className="spinner-border spinner-border-sm ml-1"></span>
                                                    }
                                                })()}</button>
                                            <span className="form-field-error d-block">
                                                 {this.state.errorData?.reportFile &&
                                                 this.state.errorData.reportFile[0]}
                                            </span>
                                        </div>
                                    )
                            }
                            <input
                                ref={this.fileUpload}
                                className="droup-file-input"
                                type="file"
                                id={'fileUploadInp'}
                                onChange={this.fileSelectHandler}
                                style={{ display: "none" }}
                                accept="application/pdf"
                            >
                            </input>
                            <span className="form-field-error">
                                {this.state.errorData ?.productImage &&
                                this.state.errorData.productImage[0]}
                            </span>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        {(() => {
                            const {handleCloseAddReport, fetchMoreData} = this.props;
                            if (this.state.successMessage !== '' && this.state.successMessage !== undefined) {
                                setTimeout(() => {
                                    handleCloseAddReport();
                                    window.location.reload();
                                }, 3000);
                                return <div className="alert alert-success">{this.state.successMessage}</div>
                            } else if (this.state.errorMessage !== '' && this.state.errorMessage !== undefined) {
                                return <div className="alert alert-danger">{this.state.errorMessage}</div>
                            }
                        })()}
                        <div class="form-btn text-center">
                            <button type="button" onClick={(e)=> this.handleSubmit(e)} class="orange-outline-btn">submit
                                {(() => {
                                    if (this.state ?.showLoader) {
                                        return <span class="spinner-border spinner-border-sm ml-1"></span>
                                    }
                                })()}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getUserDetail,
        getOrdersListByUser,
        getOrderDetail
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(UploadPatientReport)
