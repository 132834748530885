import React, { Component } from 'react'
// import '../../Styles/profile.scss';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getVendorById, updateVendorProfile } from '../../Redux/Actions/vendorAction';
import { getProductListCountForApproval } from '../../Redux/Actions/productAction';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class Profile extends Component {

    fieldsList = {
        "id": null,
        "firstname": "",
        "lastname": "",
        "email": "",
        "imagename": "",
        "role": "",
        "isacceptedtnc": null,
        "isactive": null,
        "reset": "",
        "createts": "",
        "updatedts": "",
        "addressId": null,
        "phonenumber": "",
        "address": "",
        "country": "",
        "state": "",
        "city": "",
        "zipCode": "",
        "type": "",
        "sellerStoreId": null,
        "storename": "",
        "storeEmail": "",
        "timestamp": "",
        "successMessage": "",
        "errorMessage": "",
        "errorData": {},
        "showLoader": false
    }

    constructor(props) {
        super(props);
        this.state = { ...this.fieldsList };
    }

    componentDidMount() {
        this.fetchUser(this.props.match.params.vendorId);
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.match.params.vendorId !== this.props.match.params.vendorId) {
            this.fetchUser(this.props.match.params.vendorId);
        }
    }


    fetchUser = async (userId) => {
        let result = await this.props.getVendorById(userId);
        console.log("user detail response from backend", result);
        if (result && result.data && !result.isError) {
            this.setUserState(result.data.data);
        }
        else {
            console.log("something went wrong while getting user details");
        }
    }


    setUserState(userInfo) {
        this.setState({
            ...userInfo
        })
    }

    handleChange = (e, key) => {
        if (this.state.errorData && this.state.errorData[key] && this.state.errorData[key][0]) {
            this.state.errorData[key][0] = null;
        }

        let { value } = e.target

        if (key === "phonenumber" || key === "zipCode") {
            // value = value.replace(/[^a-zA-Z ]/g, "");
            value = value.replace(/\D+/g, '');
        }

        this.setState(prevState => ({
            ...prevState,
            [key]: value
        }))
    }


    confirmVendorInactiveAlert(event, key) {
        event.persist()
        confirmAlert({
            title: 'Confirm change?',
            message: "Inactive vendor will also inactive all this vendor's products which will lead to cancellation of customer's subscription associated with his products.",
            buttons: [
                {
                    label: 'Continue',
                    onClick: () => { this.handleChange(event, key) }
                },
                {
                    label: 'Cancel',
                    onClick: () => { }
                }
            ]
        });
    }


    validateUserInfo = () => {
        let isFormValid = true;
        this.state.errorData = {
            "firstname": [],
            "lastname": [],
            "phonenumber": [],
            "storename": [],
            "storeEmail": []
        }

        if (this.state.firstname.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.firstname = ["Firstname field cannot be empty."]
        }

        if (this.state.lastname.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.lastname = ["Lastname field cannot be empty."]
        }

        if (!this.state.phonenumber || this.state.phonenumber.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.phonenumber = ["Phone number field cannot be empty."]
        }

        if (!this.state.storename || this.state.storename.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.storename = ["Store name field cannot be empty."]
        }

        if (!this.state.storeEmail || this.state.storeEmail.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.storeEmail = ["Email field cannot be empty."]
        }

        let tempState = this.state;
        this.setState({
            ...tempState
        })

        return isFormValid;
    }


    handleSubmit = async () => {
        let st = this.state;
        await this.setState(prevState => ({
            ...st,
            successMessage: '',
            errorMessage: '',
            showLoader: true
        }));

        if (this.validateUserInfo()) {
            const profile = this.state;
            const result = await this.props.updateVendorProfile({ ...profile });
            // console.log(result, 'result');

            if (result.status === 201) {
                // console.log(this.state.authData, 'authdata')
                // if (this.state.authData.image !== undefined) {
                //     this.state.imagename = this.state.authData.image[0];
                // }

                this.props.getProductListCountForApproval();

                this.setState(prevState => ({
                    ...prevState,
                    timestamp: Date.now(),
                    successMessage: 'Profile updated successfully!',
                    showLoader: false
                }));

            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    errorMessage: 'Something went wrong! Please try after some time.',
                    showLoader: false
                }))
            }
        }
        else {
            this.setState({
                showLoader: false
            });
        }
    }


    render() {
        return (
            <div class="admin-card">
                <div class="admin-card-head">
                    <h2>profile</h2>
                </div>
                {(() => {
                    if (this.state.successMessage !== '' && this.state.successMessage !== undefined) {
                        return <div className="alert alert-success">{this.state.successMessage}</div>
                    } else if (this.state.errorMessage !== '' && this.state.errorMessage !== undefined) {
                        return <div className="alert alert-danger">{this.state.errorMessage}</div>
                    }
                })()}
                <div class="admin-card-box">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>first name
                                    <sup>*</sup>
                                </label>
                                <input type="text" maxLength="15" value={this.state.firstname} onChange={(event) => this.handleChange(event, 'firstname')} class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData ?.firstname &&
                                        this.state.errorData.firstname[0]}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>last name
                                    <sup>*</sup>
                                </label>
                                <input type="text" maxLength="15" value={this.state.lastname} onChange={(event) => this.handleChange(event, 'lastname')} class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData ?.lastname &&
                                        this.state.errorData.lastname[0]}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>email address
                                    <sup>*</sup>
                                </label>
                                <input disabled={true} type="text" value={this.state.email} class="form-control" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>phone number
                                    <sup>*</sup>
                                </label>
                                <input type="text" maxLength="15" value={this.state.phonenumber} onChange={(event) => this.handleChange(event, 'phonenumber')} class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData ?.phonenumber &&
                                        this.state.errorData.phonenumber[0]}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>name of store
                                    <sup>*</sup>
                                </label>
                                <input type="text" maxLength="100" value={this.state.storename} onChange={(event) => this.handleChange(event, 'storename')} class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData ?.storename &&
                                        this.state.errorData.storename[0]}
                                </span>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>email to use for contact form
                                    <sup>*</sup>
                                </label>
                                <input type="text" maxLength="50" value={this.state.storeEmail} onChange={(event) => this.handleChange(event, 'storeEmail')} class="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData ?.storeEmail &&
                                        this.state.errorData.storeEmail[0]}
                                </span>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>Is Active?</label>
                                <div className="custom-radio-input">
                                    <p className="container-sub mr-2">Inactive
                                                        <span><input type="radio" value="0" onChange={(event) => this.confirmVendorInactiveAlert(event, 'isactive')} checked={this.state.isactive == 0} name="radio" /></span>
                                        <span class="checkmark"></span>
                                    </p>

                                    <p className="container-sub mr-2">Active
                                                        <span><input type="radio" value="1" onChange={(event) => this.handleChange(event, 'isactive')} checked={this.state.isactive == 1} name="radio" /></span>
                                        <span class="checkmark"></span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>choose your subscription plan</label>
                                <div class="row">

                                    <div class="col-md-4">
                                        <div class="subscription-box">
                                            <div class="subscription-box-top">
                                                <h3>Starter</h3>
                                                <p>FREE</p>
                                                <div class="subscription-box-check">
                                                    <label class="custon-radio">
                                                        <input type="checkbox" name="starter" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="subscription-box-bottom">
                                                <div class="subscription-detail">
                                                    <ul>
                                                        <li>
                                                            <p>12.25% + Transaction Fee</p>
                                                        </li>
                                                        <li>
                                                            <p>$199 Set Up Cost</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="subscription-view">
                                                    <button type="button" class="orange-outline-btn">View</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="subscription-box">
                                            <div class="subscription-box-top">
                                                <h3>Standard</h3>
                                                <p>$49/month</p>
                                                <div class="subscription-box-check">
                                                    <label class="custon-radio">
                                                        <input type="checkbox" name="standard" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="subscription-box-bottom">
                                                <div class="subscription-detail">
                                                    <ul>
                                                        <li>
                                                            <p>10% + Transaction Fee</p>
                                                        </li>
                                                        <li>
                                                            <p>$199 Set Up Cost</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="subscription-view">
                                                    <button type="button" class="orange-outline-btn">View</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="subscription-box">
                                            <div class="subscription-box-top">
                                                <h3>premium</h3>
                                                <p>$149/month</p>
                                                <div class="subscription-box-check">
                                                    <label class="custon-radio">
                                                        <input type="checkbox" name="premium" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="subscription-box-bottom">
                                                <div class="subscription-detail">
                                                    <ul>
                                                        <li>
                                                            <p>8% + Transaction Fee</p>
                                                        </li>
                                                        <li>
                                                            <p>$199 Set Up Cost</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="subscription-view">
                                                    <button type="button" class="orange-outline-btn">View</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group label-radio-inline">
                                <label class="mr-4">Select your subscription time-frame</label>
                                <div class="custom-radio-inline">
                                    <label class="custon-radio">weekly
                            <input type="radio" checked="checked" name="radio" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="custon-radio">Monthly
                            <input type="radio" name="radio" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="custon-radio">annually
                            <input type="radio" name="radio" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div> */}


                        <div class="col-lg-12 col-md-12">
                            <div class="form-btn text-center">
                                <button type="button" onClick={this.handleSubmit} class="orange-outline-btn">save
                                {(() => {
                                        if (this.state ?.showLoader) {
                                            return <span class="spinner-border spinner-border-sm ml-1"></span>
                                        }
                                    })()}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


// const mapStateToProps = (state) => {
//     return {
//         authData: state.userReducer.userInfo
//     }
// }

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getVendorById, updateVendorProfile, getProductListCountForApproval }, dispatch);
}


export default connect(null, mapDispatchToProps)(Profile)