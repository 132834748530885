import React  from "react";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import './activities.scss';

import {
    getAccessionItemList,
    searchAccessionItems
} from "../../Redux/Actions/activityAction";
import {debounce} from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import {Button, Modal} from "react-bootstrap";
import AddAccession from "./add-accession";
import AddTestResult from "./add-test-result";
import AccessionLabel from "./accession-label";
import {labelPrint} from "../../Containers/helper/helper";

class Settings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allAccessioningItems:[],
            loadAccessioningItems: false,
            offset: 0,
            prevOffset: 0,
            maxLimit: 10,
            hasMore: true,
            searchKeyword: "",
            showAddAccessionModal: false,
            showAddGenerateModal: false,
            hideAdd: false,
            hideEdit: false,
            hideGenerate: false,
            hideView: false,
            selectedItem: {},
            componentRef: {},
        };
    }
    async componentDidMount() {
        this.fetchMoreData(true);
        if(['management', 'techSupport'].includes(this.props.settingReducer.role)) {
            this.setState({
                hideAdd: true,
                hideEdit: true,
                hideGenerate: true,
                hideView: true
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.settingReducer.role !== prevProps.settingReducer.role &&
            ['management', 'techSupport'].includes(this.props.settingReducer.role)) {
            this.setState({
                hideAdd: true,
                hideEdit: true,
                hideGenerate: true,
                hideView: true
            })
        }
    }

    fetchMoreData = async (firstLoad) => {
        this.setState({
            loadAccessioningItems: true
        });

        if (this.state.offset !== this.state.prevOffset || firstLoad) {
            if (this.state.hasMore && this.state.maxLimit && (this.state.maxLimit === "all" || this.state.maxLimit > this.state.offset)) {
                let result;
                if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
                    result = await this.props.searchAccessionItems(this.state.offset, this.state.searchKeyword.trim());
                }
                else {
                    result = await this.props.getAccessionItemList(this.state.offset);
                }

                if (result ?.data ?.data ?.length > 0) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            prevOffset: prevState.offset,
                            offset: prevState.offset + 10,
                            allAccessioningItems: prevState.allAccessioningItems.concat(result.data.data),
                            loadAccessioningItems: false
                        }
                    })
                }
                else {
                    this.setState({ hasMore: false, loadAccessioningItems: false });
                }
            } else {
                this.setState({
                    loadAccessioningItems: false
                })
            }
        } else {
            this.setState({
                loadAccessioningItems: false
            })
        }

    };

    handleChange = async (event, field) => {
        event.preventDefault();
        if (field === "maxLimit") {
            await this.setState({
                [field]: event.target.value === "all" ? event.target.value : +event.target.value
            })
            this.fetchMoreData(false);
        }
        else {
            await this.setState({
                [field]: event.target.value,
                offset: 0,
                prevOffset: 0,
                hasMore: true
            });

            this.debounceSearchCall();
        }
    };

    debounceSearchCall = debounce(() => {
        this.setState({
            allAccessioningItems: [],
        });
        this.fetchMoreData(true);
    }, 1000);

    prepareTab = (tabName) => {
        return;
    };

    handleAccessionAdd = (item) => {
        this.setState({
            showAddAccessionModal: true,
            selectedItem: item,
            accessionEdit: false,
        });
    };

    printAccession = () => {
        labelPrint.init();
        return (<i className="fa fa-print" aria-hidden="true" title={'print'}></i>);
    };

    handleAccessionEdit = (item) => {
        this.setState({
            showAddAccessionModal: true,
            selectedItem: item,
            accessionEdit: true,
        });
    };

    handleCloseAccessionModal = () => {
        this.setState({
            showAddAccessionModal: false,
            selectedItem: {},
            accessionEdit: false,
        });
    };

    reloadAccession = () => {
        this.setState({
            showAddAccessionModal: false,
            showAddGenerateModal: false,
            selectedItem: {},
            accessionEdit: false,
            offset: 0,
            prevOffset: 0,
            hasMore: true,
        });
        this.debounceSearchCall();
    };

    handleReportGenerate = (item) => {
        this.setState({
            showAddGenerateModal: true,
            selectedItem: item,
        });
    };

    handleCloseReportGenerate = () => {
        this.setState({
            showAddGenerateModal: false,
            selectedItem: {},
        });
    };

    showReportPdf = (item) => {
        if(item.reportFile) {
            window.open(item.reportFile, '_blank');
        } else {
            alert('Report file do not exist');
        }
    };

    render() {
        const {
            allAccessioningItems,
            loadAccessioningItems,
            hasMore,
            showAddAccessionModal,
            showAddGenerateModal,
            selectedItem,
            accessionEdit,
            hideAdd,
            hideEdit,
            hideGenerate,
            hideView,
        } = this.state;

        const accessioningList = allAccessioningItems?.map((accessioningItem, index) => {
            return (
                <tr key={`${accessioningItem.id}-${index}`}>
                    <td>{accessioningItem.orderNumber || 'N/A'}</td>
                    <td>{accessioningItem.productName}</td>
                    <td>{accessioningItem.registrationKitId}</td>
                    <td>{accessioningItem.fullName}</td>
                    <td>{accessioningItem.accessionNumber? <span>
                        {accessioningItem.accessionNumber} &nbsp;
                        <ReactToPrint trigger={this.printAccession} content={() => this.state.componentRef[accessioningItem.id]} />
                        <span className={'d-none'}>
                            <AccessionLabel ref={(el) => (this.state.componentRef[accessioningItem.id] = el)} item={accessioningItem}/>
                        </span>
                    </span>: 'N/A'}</td>
                    <td>{accessioningItem.reportDate?
                        <span className="active">Generated</span>:
                        <span class="inactive">Not Generated</span>}</td>
                    <td>
                        <div className="vendor-table-btn">
                            {
                                !accessioningItem.accessionNumber?
                                    (
                                            !hideAdd &&
                                            <button
                                                type="button"
                                                className="orange-outline-btn"
                                                onClick={() => this.handleAccessionAdd(accessioningItem)}
                                            >
                                                Add
                                            </button>
                                    ):
                                        accessioningItem.accessionNumber && !accessioningItem.reportDate ?
                                            (
                                                <>
                                                    {
                                                       !hideEdit &&
                                                       <button
                                                           type="button"
                                                           className="orange-outline-btn mr-2"
                                                           onClick={() => this.handleAccessionEdit(accessioningItem)}
                                                       >
                                                           Edit
                                                       </button>
                                                    }
                                                    {
                                                        !hideGenerate &&
                                                        <button
                                                            type="button"
                                                            className="secondary-outline-btn"
                                                            onClick={() => this.handleReportGenerate(accessioningItem)}
                                                        >
                                                            Generate Report
                                                        </button>
                                                    }

                                                </>
                                            ): !hideView && (
                                                <>
                                                    <button
                                                        type="button"
                                                        className="orange-outline-btn mr-2"
                                                        onClick={() => this.showReportPdf(accessioningItem)}
                                                    >
                                                        View
                                                    </button>
                                                </>
                                            )
                            }
                        </div>
                    </td>
                </tr>
            );
        });

        return (
            <div className="admin-card">
                    <div className="admin-card-box">
                        <div className="custom-tab">
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a className="nav-item nav-link active" id="accession-tab" data-toggle="tab"
                                       href="#accession" role="tab" aria-controls="custom-nav-accession"
                                       aria-selected="true" onClick={() => this.prepareTab('accession')}>Accessioning</a>
                                </div>
                            </nav>

                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="accession" role="tabpanel"
                                     aria-labelledby="accession-tab">
                                    <div className="row mt-2">
                                        <div className="col-md-12">
                                            <div className="vendor-table">
                                                <div className="vendor-table-head">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4">
                                                            <div className="entries-drop">
                                                                <label>show</label>
                                                                <select className="entries-controls"
                                                                        onChange={(e) => this.handleChange(e, "maxLimit")}
                                                                        value={this.state.maxLimit}>
                                                                    <option value="10">10</option>
                                                                    <option value="20">20</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                    <option value="all">All</option>
                                                                </select>
                                                                <p>Entries</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8 col-md-8">
                                                            <div className="vendor-table-head-right">
                                                                <div className="vendor-table-search">
                                                                    <input className="form-control"
                                                                           onChange={(e) => this.handleChange(e, "searchKeyword")}
                                                                           value={this.state.searchKeyword} type="text" placeholder="search"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="vendor-table-body">
                                                    <div className="table-responsive">
                                                        <InfiniteScroll
                                                            dataLength={allAccessioningItems.length}
                                                            next={() => this.fetchMoreData(false)}
                                                            hasMore={hasMore}
                                                        >
                                                            <table className="table">
                                                                <thead>
                                                                <tr>
                                                                    <th>Order No</th>
                                                                    <th>Product Name</th>
                                                                    <th>Kit Id</th>
                                                                    <th>Patient Name</th>
                                                                    <th>Accession No</th>
                                                                    <th>Report Status</th>
                                                                    <th style={{'width': '15%'}}>Action</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody> {accessioningList} </tbody>
                                                            </table>
                                                        </InfiniteScroll>
                                                        {loadAccessioningItems ? (
                                                            <div className="d-flex justify-content-center">
                                                                <div className="spinner-border" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                            </div>
                                                        ) : (null)}

                                                        <Modal show={showAddAccessionModal} onHide={this.handleCloseAccessionModal} size="lg">
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Accessioning Item</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <AddAccession
                                                                    selectedItem={selectedItem}
                                                                    accessionEdit={accessionEdit}
                                                                    handleCloseAccessionModal={this.reloadAccession}
                                                                />
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={this.handleCloseAccessionModal}>
                                                                    Close
                                                                </Button>
                                                            </Modal.Footer>
                                                        </Modal>

                                                        <Modal show={showAddGenerateModal} onHide={this.handleCloseReportGenerate} size="md">
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Add Test Results</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <AddTestResult
                                                                    selectedItem={selectedItem}
                                                                    handleCloseReportModal={this.reloadAccession}
                                                                />
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={this.handleCloseReportGenerate}>
                                                                    Close
                                                                </Button>
                                                            </Modal.Footer>
                                                        </Modal>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        ...state
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getAccessionItemList,
        searchAccessionItems,
    }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
