import React  from "react";
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import './pages.scss';
import HelpPage from './help-page';

import Page_List from "./Page_List";

class MainPageList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadHelpPage: false
        };
    }

    componentDidMount() {}

    prepareTab = (tabName) => {
        const obj = {
            loadHelpPage: tabName === 'help',
        };
        this.setState({
            ...this.state,
            ...obj
        });

    };

    render() {
        const {
            loadHelpPage,
        } = this.state;

        return (
            <div className="admin-card">
                <div className="admin-card-box">
                    <div className="custom-tab">
                        <nav>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <a className="nav-item nav-link active" id="page-list-tab" data-toggle="tab"
                                   href="#page-list" role="tab" aria-controls="custom-nav-page-list"
                                   aria-selected="true" onClick={() => this.prepareTab('pageList')}>Page List</a>
                                <a className="nav-item nav-link" id="help-tab" data-toggle="tab"
                                   href="#help" role="tab" aria-controls="custom-nav-help"
                                   aria-selected="true" onClick={() => this.prepareTab('help')}>Help Page</a>
                            </div>
                        </nav>

                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="page-list" role="tabpanel"
                                 aria-labelledby="page-list-tab">
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                       <Page_List />
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="help" role="tabpanel"
                                 aria-labelledby="help-tab">
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <div className="vendor-table-body">
                                            { loadHelpPage && <HelpPage />}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        ...state
    };
};

export default withRouter(connect(mapStateToProps, null)(MainPageList));
