import React, { Component } from 'react'
import "../../Styles/order-detail.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getpageDetail, updatePageDetail } from '../../Redux/Actions/pageAction'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

class Page_Detail extends Component {

    generatedContentTemplate = "";

    constructor(props) {
        super(props)

        this.state = {
            pageDetail: {},
            isEditForm: false,
            errorData: {}
        }

        this.modules = {
            toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
                // ['link', 'image', 'video'],
                ['clean']
            ]
        };

        this.formats = [
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            // 'link', 'image', 'video'
        ];

        this.rteChange = this.rteChange.bind(this);
    }

    componentDidMount() {
        this.fetchPageDetail();
    }

    fetchPageDetail = async () => {
        const result = await this.props.getpageDetail(this.props.match.params.pageId);
        if (result ?.data ?.data) {
            await this.setState({
                pageDetail: result.data.data
            })

            // this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
        }
    }

    onEdit = () => {
        this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
        // this.generatedContentTemplate = this.statePrevCopy.pageDetail.content;
        this.setState({
            isEditForm: true
        })
    }

    rteChange = (content, delta, source, editor) => {
        // this.generatedContentTemplate = ""
        // this.generatedContentTemplate = editor.getHTML();
        // console.log(editor.getHTML()); // rich text

        this.setState(prevState => {
            return (
                prevState.pageDetail["content"] = editor.getHTML()
            )
        })

        // console.log(editor.getText()); // plain text
        console.log(editor.getLength()); // number of characters
    }

    handleChange = (event, field) => {
        if (this.state.errorData && this.state.errorData[field] && this.state.errorData[field][0]) {
            this.state.errorData[field][0] = null;
        }
        console.log("event is", event);
        event.persist();
        this.setState(prevState => {
            return (
                prevState.pageDetail[field] = event.target.value
            )
        })
    }

    validateInfo = () => {
        let isFormValid = true;
        this.state.errorData = {
            "title": [],
            "content": []
        }

        if (this.state.pageDetail.title.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.title = ["Title cannot be empty."]
        }

        if (this.state.pageDetail.content.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.content = ["Content cannot be empty."]
        }

        let tempState = this.state;

        this.setState({
            ...tempState
        })

        return isFormValid;
    }

    onSaveForm = async () => {

        if (this.validateInfo()) {
            this.setState({
                isEditForm: false
            })

            // this.setState(prevState => {
            //     return (
            //         prevState.pageDetail["content"] = this.generatedContentTemplate,
            //         prevState.isEditForm = false
            //     )
            // })

            const res = await this.props.updatePageDetail(this.state.pageDetail);
            if (res && res.data && !res.data.isError) {
                this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
                this.setState({
                    errorData: {}
                })
            }
            else {
                this.setState({
                    ...this.statePrevCopy,
                    errorData: {}
                })
            }
        }

    }

    onCancel = async () => {

        console.log("state copy is", this.statePrevCopy);

        await this.setState({
            ...this.statePrevCopy
        })

        // console.log("state is", this.state);
    }


    render() {
        return (
            <div class="admin-card">
                <div class="order-heading">

                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <h2 class="mb-0">page details</h2>
                            {/* <p>Check your page details.</p> */}
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="order-edit">
                                {(() => {
                                    if (!this.state.isEditForm) {
                                        return (
                                            <button onClick={this.onEdit} type="text" class="orange-outline-btn">
                                                <i class="far fa-edit"></i> Update
                                            </button>
                                        )
                                    }
                                    else {
                                        return (
                                            <>
                                                <button onClick={this.onSaveForm} type="text" class="orange-outline-btn">
                                                    <i class="fa fa-check"></i> Save
                                                </button>
                                                <button onClick={this.onCancel} type="text" class="orange-outline-btn">
                                                    <i class="fa fa-times"></i> Cancel
                                                </button>
                                            </>
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                    </div>


                </div>
                <div class="admin-card-box">
                    <section class="track-section">
                        <div class="container">
                            <form>
                                <div class="form-group">
                                    <label for="pageTitleInput">Page Title</label>
                                    {(() => {
                                        if (!this.state.isEditForm) {
                                            return <p>{this.state.pageDetail.title ? this.state.pageDetail.title : `-`}</p>
                                        }
                                        else {
                                            return (
                                                <>
                                                    <input type="text" onChange={(event) => this.handleChange(event, "title")} value={this.state.pageDetail.title} class="form-control" id="pageTitleInput" placeholder="Add page title" />
                                                    <span style={{ color: "red", fontSize: "12px" }} className="form-field-error">
                                                        {this.state.errorData ?.title &&
                                                            this.state.errorData.title[0]}
                                                    </span>
                                                </>
                                            )
                                        }
                                    })()}

                                </div>
                                <div class="form-group">
                                    <label for="pageContentTextarea">Page Content</label>
                                    {(() => {
                                        if (!this.state.isEditForm) {
                                            return <p dangerouslySetInnerHTML={{ __html: this.state.pageDetail.content ? this.state.pageDetail.content : `-` }}></p>
                                        }
                                        else {
                                            return (
                                                <>
                                                    {/* <textarea class="form-control" onChange={(event) => this.handleChange(event, "content")} value={this.state.pageDetail.content} id="pageContentTextarea" rows="2"></textarea> */}
                                                    <ReactQuill theme="snow" modules={this.modules}
                                                        formats={this.formats} onChange={this.rteChange}
                                                        value={this.state.pageDetail.content || ''}
                                                        placeholder="Write something..." />
                                                    <span style={{ color: "red", fontSize: "12px" }} className="form-field-error">
                                                        {this.state.errorData ?.content &&
                                                            this.state.errorData.content[0]}
                                                    </span>
                                                </>
                                            )
                                        }
                                    })()}

                                </div>
                            </form>

                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getpageDetail, updatePageDetail }, dispatch);
}

export default connect(null, mapDispatchToProps)(Page_Detail)