export const initialState = {
  companyDetails: null,
};

export const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COMPANY_BY_ID":
      console.log("action.payload", action.payload);
      return {
        ...state,
        companyDetails: action.payload.data.data.Item
          ? action.payload.data.data.Item
          : {},
      };

    default:
      return state;
  }
};
