import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const createCoupon = (couponInfo) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/coupons/add`,
            data: couponInfo,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        // console.log("createCoupon res from backend", res);

        return res;
    }
    catch (error) {
        console.log('error at createCoupon action', error);
        dispatch(handleError(error));
    }
}

export const getCouponList = (offset) => async (dispatch) => {
    // console.log("getOrdersList userId is", userId, " offset is", offset);
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/coupons/list?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("getCouponList response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

export const getCouponDetail = (couponId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/coupons/detail/${couponId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("getCouponDetail response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

export const updateCouponDetail = (couponInfo, couponId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/coupons/update-coupon/${couponId}`,
            data: couponInfo,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("updateCouponDetail response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

export const searchCoupon = (offset, searchKeyword) => async (dispatch) => {
    // console.log("getOrdersList offset is", offset);
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/coupons/by/search?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("orders list response from backend", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}