export const initialState = {
    approvalData: {},
    viewIndiviualMessage: {},
    messageHistory: {}

}


export const approvalReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_APPROVAL_MSG":
            return {
                ...state,
                approvalData: action.payload
            };
        case "GET_INDIVIUAL_MESSAGE":
            return {
                ...state,
                viewIndiviualMessage: action.payload
            }
        case 'GET_MESSAGE_HISTORY':
            return {
                ...state,
                messageHistory: action.payload
            }
        default:
            return state;
    }
}