import React  from "react";
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  deleteSubscription,
  getAllSubscriptionList,
  searchSubscriptionList,
  getSingleSubscription,
  getSubscriptionList,
  searchSubscriptionListByUser
} from "../../Redux/Actions/subscription";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import {debounce} from "lodash";
import {Button, Modal} from "react-bootstrap";

class Subscription extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userSubscriptionData:[],
      renderType: 'singleUser',
      offset: 0,
      prevOffset: 0,
      showLoader: false,
      detailsLoader: false,
      maxLimit: 10,
      hasMore: true,
      removeSubscriptionModal: false,
      subscriptionId: null,
      showSubscriptionDetail: false,
      subscriptionDetail: []
    };
  }
  async componentDidMount(){
    this.fetchMoreData(true);
  }

  fetchMoreData = async (firstLoad) => {
    this.setState({
      showLoader: true
    });

    const userId = this.props.match.params.id;
    const path = this.props.match.path;
    this.setState({
      showLoader: true
    });

    if (this.state.offset !== this.state.prevOffset || firstLoad) {

      if (
          this.state.hasMore &&
          this.state.maxLimit &&
          (this.state.maxLimit === "all" ||
              this.state.maxLimit > this.state.offset)
      ) {
        let result;

        if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {

          if(path === '/subscriptions' && !userId) {
            result = await this.props.searchSubscriptionList(this.state.offset, this.state.searchKeyword.trim());
          } else {
            result = await this.props.searchSubscriptionListByUser(userId, this.state.offset, this.state.searchKeyword.trim())
          }

        } else {

          if(path === '/subscriptions' && !userId) {
            result = await this.props.getAllSubscriptionList(this.state.offset);
          } else {
            result = await this.props.getSubscriptionList(userId, this.state.offset)
          }

        }

        // let result = await this.props.getUsersList(this.state.offset);
        if (result ?.data ?.data ?.length > 0) {
          this.setState(prevState => {
            return {
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 10,
              userSubscriptionData: prevState.userSubscriptionData.concat(result.data.data),
              showLoader: false
            }
          })
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }

      } else {
        console.log("------")
        this.setState({
          showLoader: false,
        });
      }
    }else {
      this.setState({
        showLoader: false
      })
    }

  };

  handleChange = async (event, field) => {
    event.preventDefault();
    if (field === "maxLimit") {
      await this.setState({
        [field]: event.target.value === "all" ? event.target.value : +event.target.value
      })
      this.fetchMoreData(false);
    }
    else {
      await this.setState({
        [field]: event.target.value,
        offset: 0,
        prevOffset: 0,
        hasMore: true
      });

      this.debounceSearchCall();
    }
  };

  debounceSearchCall = debounce(() => {
    this.setState({
      userSubscriptionData: [],
    });
    this.fetchMoreData(true);
  }, 1000);

  closeModalCancelSubscription = () => {
    this.setState({
      removeSubscriptionModal: false
    });
  };

  cancelSubscriptionById = async () => {
    this.closeModalCancelSubscription();
    this.setState({
      showLoader: true,
    });
    await this.props.deleteSubscription(this.state.subscriptionId);

    window.location.reload();
  };

  handleSubscriptionCancel = (subscriptionId) => {
    this.setState({
      removeSubscriptionModal: true,
      subscriptionId
    });
  };

  handleSubscriptionSendOut = (orderId) => {
    this.props.history.push(`/orders/${orderId}`);
  };

  showSubscriptionDetail = async (subscriptionId) => {
    this.setState({
      detailsLoader: true,
      showSubscriptionDetail: true,
    });

    const subscriptionDetail = await this.props.getSingleSubscription(subscriptionId);

    this.setState({
      subscriptionDetail: subscriptionDetail.data.isSuccess? subscriptionDetail.data.data: [],
      detailsLoader: false
    });
  };

  handleCloseShowSubscription = () => {
    this.setState({
      showSubscriptionDetail: false,
      subscriptionDetail: [],
    });
  };

  render() {
    const {removeSubscriptionModal} = this.state;

    const subscriptionList = this.state.userSubscriptionData?.length > 0 ? this.state.userSubscriptionData?.map((data,i)=>{
        return (<tr key={`${data.id}-${i}`}>
          <td>{data.subscription_id}</td>
          <td>{data?.productName}</td>
          <td>{data?.selectedQuantity}</td>
          <td>{data?.subscription_start_ts && moment(data?.subscription_start_ts).format("l")}</td>
          <td>{data?.subscription_end_ts && moment(data?.subscription_end_ts).format("l")}</td>
          <td>{data?.subscriptionType === 1 ? '3 Months' : data?.subscriptionType === 2 ? '6 Months' : data?.subscriptionType === 3 ? '12 Months' : 'No Package'}</td>
          <td>
            {
              data.status === 1?
                  <span className="green">Active</span>:
                  <span className="orange">Cancelled</span>
            }
          </td>
          <td>
            <div className="vendor-table-btn">
              <button
                  type="button"
                  className="orange-outline-btn mr-2"
                  onClick={() => this.showSubscriptionDetail(data.subscription_id)}
              >Details
              </button>
              {
                data.status === 1 &&
                <>
                  {
                    !data.trackingCompany && !data.trackingId &&
                    <button
                        type="button"
                        className="secondary-outline-btn"
                        onClick={() => this.handleSubscriptionSendOut(data.orderId)}
                    >Send Out
                    </button>
                  }
                  <button
                      type="button"
                      className="danger-outline-btn"
                      onClick={() => this.handleSubscriptionCancel(data.subscription_id)}
                  >Cancel
                  </button>
                </>
              }
            </div>
          </td>
        </tr>)
      })  : (<p>No Subscription</p>);

    const subscriptionDetailList = this.state.subscriptionDetail?.length > 0 ? this.state.subscriptionDetail?.map((data,i)=>{
        return (<tr key={`${data.id}-${i}`}>
          <td>{data.orderNumber}</td>
          <td>{data?.productName}</td>
          <td>{data?.orderedQuantity}</td>
          <td>{data?.amount}</td>
          <td>{data?.subscription_start_ts && moment(data?.subscription_start_ts).format("l")}</td>
          <td>{data?.subscription_end_ts && moment(data?.subscription_end_ts).format("l")}</td>
          <td>{data?.subscriptionType === 1 ? '3 Months' : data?.subscriptionType === 2 ? '6 Months' : data?.subscriptionType === 3 ? '12 Months' : 'No Package'}</td>
        </tr>)
      })  : (<p>No Subscription Details</p>);

    return (
      <div className="admin-card">
        <div className="admin-card-head">
          <h2>Subscription Management</h2>
        </div>
        <div className="admin-card-box">
          <div className="vendor-table">
            <div className="vendor-table-head">
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="entries-drop">
                    <label>show</label>
                    <select
                        className="entries-controls"
                        onChange={(e) => this.handleChange(e, "maxLimit")}
                        value={this.state.maxLimit}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="all">All</option>
                    </select>
                    <p>Entries</p>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8">
                  <div className="vendor-table-head-right">
                    <div className="vendor-table-search mr-2">
                      <input
                          className="form-control"
                          onChange={(e) => this.handleChange(e, "searchKeyword")}
                          value={this.state.searchKeyword}
                          type="text"
                          placeholder="search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="vendor-table-body">
              <div className="table-responsive">

                <InfiniteScroll
                    dataLength={this.state.userSubscriptionData.length}
                    next={() => this.fetchMoreData(false)}
                    hasMore={this.state.hasMore}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <td>Subscription ID</td>
                        <td>Test</td>
                        <td>Quantity</td>
                        <td>Start Date</td>
                        <td>End Date</td>
                        <td>Subscription Type</td>
                        <td>Status</td>
                        <td className={'w-25'}>Action</td>
                      </tr>
                    </thead>
                    <tbody>{subscriptionList}</tbody>
                  </table>

                </InfiniteScroll>
                {this.state.showLoader ? (
                    <div class="d-flex justify-content-center">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                ) : null}

                {removeSubscriptionModal ? (
                    <div
                        className="modal"
                        id="removeItem"
                        style={{ display: "block" }}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              {" "}
                              Are you sure you want to cancel this subscription?
                            </h4>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                onClick={this.closeModalCancelSubscription}
                            >
                            <span>
                              <i class="far fa-times-circle"></i>
                            </span>
                            </button>
                          </div>

                          <div className="modal-body" style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}>
                            <button
                                className="orange-outline-btn"
                                onClick={(e) => {
                                  this.cancelSubscriptionById();
                                }}
                            >
                              Yes
                            </button>
                            <button
                                className="danger-outline-btn"
                                onClick={this.closeModalCancelSubscription}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                ) : null}

              </div>
            </div>

            <Modal show={this.state.showSubscriptionDetail} onHide={this.handleCloseShowSubscription} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Subscription Detail</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Test</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Subscription Type</th>
                    </tr>
                  </thead>
                  <tbody>{subscriptionDetailList}</tbody>
                </table>
                {this.state.detailsLoader ? (
                    <div class="d-flex justify-content-center">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleCloseShowSubscription}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }

};


const mapStateToProps = (state) => {
  return {
    ...state,
    isRegistered: state.authReducer.isRegistered,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getSubscriptionList,
    getSingleSubscription,
    getAllSubscriptionList,
    searchSubscriptionList,
    deleteSubscription,
    searchSubscriptionListByUser
  }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Subscription));
