const initialState = {
    tempData: {},
    tempHistory: {},
    templateDetails: {}
}

export const templateReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TEMPLATES":
            return {
                ...state,
                tempData: action.payload
            };
        case "GET_TEMPLATE_HISTORY":
            return {
                ...state,
                tempHistory: action.payload
            }
        case 'GET_TEMPLATE_DETAILS':
            return {
                ...state,
                templateDetails: action.payload

            }
        default:
            return state
    }
}